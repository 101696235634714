import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import "antd/dist/antd.css";
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {Link} from "react-router-dom";
import {CityApiService, DistrictApiService, UserApiService, WardApiService} from "../../ultils/EndPoint";
import $ from 'jquery';
import {onNumberOnlyChange, validateEmail, validatePhone} from "../../helpers/function";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";

function UpdateUser() {
    const [citySelected, setCitySelected] = useState(0)
    const [listCities, setListCities] = useState([])
    const [listDistrict, setListDistrict] = useState([])
    const [districtSelected, setDistrictSelected] = useState(0)
    const [listWard, setListWard] = useState([])
    const [wardSelected, setWardSelected] = useState(0)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [isChangeAddress, setIsChangeAddress] = useState(false)

    const history = useHistory();
    let {id} = useParams()

    // show
    useEffect(async () => {
        await CityApiService.getListCity().then(resCity => {
            if (resCity.status) {
                setListCities(resCity.data)
                UserApiService.show(id).then(resUser => {
                    if (resUser.status) {
                        let user = resUser.data
                        setName(user.name)
                        setEmail(user.email)
                        setPhone(user.phone)
                        UserApiService.getListAddress(id).then(resAddress => {
                            if (resAddress.status && resAddress.data.length) {
                                    let address = resAddress.data[0]

                                    setAddress(address.address)
                                    let city = resCity.data.filter(city => {
                                        if (city.id == address.city_id) {
                                            return city
                                        }
                                    })
                                    if (city.length){
                                        setCitySelected(city[0].viet_nam_zone_id)
                                        DistrictApiService.getListDistrictByCityId(city[0].viet_nam_zone_id).then(r => {
                                            if (r.status){
                                                setListDistrict(r.data)
                                                setDistrictSelected(address.district_id)
                                            }
                                        })
                                        WardApiService.getListWardByDistrictId(address.district_id).then(r => {
                                            if (r.status){
                                                setListWard(r.data)
                                                setWardSelected(address.ward_id)
                                            }
                                        })
                                    }
                            }
                        })
                    }
                })
            }
        })
    }, [id]);

    // change option district
    useEffect(() =>{
        if (isChangeAddress){
            WardApiService.getListWardByDistrictId(districtSelected).then(r => {
                if (r.status){
                    setListWard(r.data)
                    if (r.data.length){
                        setWardSelected(r.data[0].id)
                    }
                }
            })
        }
    }, [districtSelected])

    // change option city
    useEffect(() =>{
        if (isChangeAddress && citySelected > 0) {
            DistrictApiService.getListDistrictByCityId(citySelected).then(r => {
                if (r.status){
                    setListDistrict(r.data)
                    setDistrictSelected(r.data[0].id)
                    if (r.data.length){
                        WardApiService.getListWardByDistrictId(r.data[0].id).then(r => {
                            if (r.status){
                                setListWard(r.data)
                                if (r.data.length) {
                                    setWardSelected(r.data[0].id)
                                }
                            }
                        })
                    }
                }
            })
        }else{
            setListDistrict([])
            setDistrictSelected(0)
            setListWard([])
            setWardSelected(0)
        }

    }, [citySelected])

    const handleUpdateUser = () => {
        if (!name){
            $('.error_name_add_address').html("Không được để trống")
        }else{
            $('.error_name_add_address').html("")
        }

        if (!address){
            $('.error_address_add_address').html("Không được để trống")
        }else{
            $('.error_address_add_address').html("")
        }

        if (!email){
            $('.error_email_add_address').html("Không được để trống")
        }
        if (email && !validateEmail(email)){
            $('.error_email_add_address').html("Email không hợp lệ")
        }

        if (email && validateEmail(email)){
            $('.error_email_add_address').html("")
        }

        if (!phone){
            $('.error_phone_add_address').html("Không được để trống")
        }
        if (phone && !validatePhone(phone)){
            $('.error_phone_add_address').html("Số điện thoại không hợp lệ")
        }
        if (phone && validatePhone(phone)){
            $('.error_phone_add_address').html("")
        }

        if (!citySelected || citySelected == "0"){
            $('.error_city_add_address').html("Không được để trống")
        }else{
            $('.error_city_add_address').html("")
        }

        if (!districtSelected){
            $('.error_district_add_address').html("Không được để trống")
        }else{
            $('.error_district_add_address').html("")
        }

        if (!wardSelected){
            $('.error_ward_add_address').html("Không được để trống")
        }else{
            $('.error_ward_add_address').html("")
        }

        if (name && email && validateEmail(email) && phone && validatePhone(phone) && address && citySelected && districtSelected && wardSelected){
            let data = {}

            data.name = name
            data.email = email
            data.phone = phone
            data.address = address

            let city = listCities.filter(city => {
                if (city.viet_nam_zone_id == citySelected) {
                    return city
                }
            })
            if (city.length){
                data.city_id = city[0].id
            }

            data.district_id = districtSelected
            data.ward_id = wardSelected

            UserApiService.update(id, data).then(r => {
                if (r.status) {
                    toast.success('Cập nhật người dùng thành công !')
                    setTimeout(() => {
                        history.push('/main/users')
                    }, 1000);

                } else {
                    for (const [key, value] of Object.entries(r.errors)) {
                        $('.error_' + key + '_add_address').html(value)
                    }
                }
            })
        }
    }

    return (
        <div className="brands pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                   <h1 className="customer-title mb-3">Cập nhật người dùng</h1>
                </div>
            </div>
            <Form.Label className="col-2 pl-0 pr-0">Họ và tên <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control name_partner name_store"
                    value={name}
                    onChange={(e) => {setName(e.target.value)}}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </div>
            <span className="error_name_add_address" style={{color : "red", fontSize: "12px"}}></span>

            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Email <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control name_partner userName"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </div>
            <span className="error_email_add_address" style={{color : "red", fontSize: "12px"}}></span>

            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Phone <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control name_partner userName"
                    value={phone}
                    onChange={(e) => {setPhone(e.target.value)}}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onKeyPress={onNumberOnlyChange}
                />
            </div>
            <span className="error_phone_add_address" style={{color : "red", fontSize: "12px"}}></span>

            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Address <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control name_partner email"
                    value={address}
                    onChange={(e) => {setAddress(e.target.value)}}
                    aria-label="Username"
                    autoComplete="off"
                    aria-describedby="basic-addon1"
                />
            </div>
            <span className="error_address_add_address" style={{color : "red", fontSize: "12px"}}></span>

            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Tỉnh/Thành phố <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <select value={citySelected} onChange={(e) => {
                    setCitySelected(parseInt(e.target.value)); setIsChangeAddress(true)
                }} className="form-control">
                    <option value="0">Lựa chọn tỉnh/thành phố</option>
                    {listCities.length && listCities.map((city, index) =>
                        <option key={index} value={city.viet_nam_zone_id}>{city.name}</option>
                    )}
                </select>
            </div>
            <span className="error_city_add_address" style={{color : "red", fontSize: "12px"}}></span>

            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Quận/huyện <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <select value={districtSelected} name="" id="" onChange={(e) => {
                    setDistrictSelected(parseInt(e.target.value));setIsChangeAddress(true)
                }} className="form-control">
                    {listDistrict.length && listDistrict.map((district, index) =>
                        <option key={index} value={district.id}>{district.name}</option>
                    )}
                </select>
            </div>
            <span className="error_district_add_address" style={{color : "red", fontSize: "12px"}}></span>

            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Phường xã <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <select value={wardSelected} name="" id="" onChange={(e) => {
                    setWardSelected(parseInt(e.target.value));setIsChangeAddress(true)
                }} className="form-control">
                    {listWard.length && listWard.map((ward, index) =>
                        <option key={index} value={ward.id}>{ward.name}</option>
                    )}
                </select>
            </div>
            <span className="error_ward_add_address" style={{color : "red", fontSize: "12px"}}></span>

            <br/>
            <div >
                <Link to="/main/users"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                <button type="button" onClick={handleUpdateUser} className="btn btn-success mt-5">Lưu</button>
            </div>
        </div>
    )
}

export default UpdateUser
