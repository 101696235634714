import React, {useEffect, useState} from 'react'
import ENDPOINT from "../../ultils/EndPoint";
import {formatDateTime, formatCurrency} from "../../helpers/function";
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import {Link} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import $ from 'jquery'
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function Coupon() {
    // set const
    const [listCoupon, setListCoupon] = useState([]);
    const [total, setTotal] = useState(0);
    const [customersPerPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [startDate, setStartDate]= useState('');
    const [endDate, setEndDate]= useState('');
    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');
    const [keyword, setKeyWord] = useState('');
    const [slect, setSelect]= useState('');
    const dispatch = useDispatch();
    const [updateStatusDeal, setUpdateStatusDeal] = useState(false)

    // set modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // set modal update
    const [showUpdate, setShowUpdate] = useState(false);
    const handleCloseUpdate = (id) => {
        setShowUpdate(false)
        $(`#${id}`).prop('checked', false)
    };
    const handleShowUpdate = () => setShowUpdate(true);

    // remove all whitespace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // set token admin
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    const handleSearchSelect = (e) => {
        setSelect(e.target.value)
        setcurrentPage(1)
    }

    // call api set list banner
    useEffect(() => {
        let query = '';
        const data = {
            per_page : customersPerPage,
            page: currentPage,
            code: keyword,
            status: slect,
            start_time: startDate,
            end_time: endDate,
        }
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        let unmounted = false;
        axios.get(`${ENDPOINT.COUPON}` + query, config)
        .then((res) => {
            if (!unmounted) {
                setListCoupon(res.data.data.records);
                setTotal(res.data.data.total_record)
                setUpdateStatusDeal(false)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [
        customersPerPage, 
        currentPage, 
        keyword, 
        startDate, 
        endDate,
        slect,
        dispatch,
        updateStatusDeal
    ]);
    
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.COUPON}/${id}`, config)
            .then(function (response) {
                toast.success('Xóa mã giảm giá thành công !')
                const remainListCoupon = listCoupon.filter((result) => result.id !== id)
                setListCoupon(remainListCoupon)
            })
            .catch(error => {
                alert(error.response.data.error)
                }
            )
    }

    // handle change
    const handleChangeFromDate = (e) => {
        setStartDate(e.target.value)
    }

    const handleChangeToDate = (e) => {
        setEndDate(e.target.value)
    }

    // Handle search debounce
    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    
    const handleInputSearch = (e) => {
        setKeyWord(removeExtraSpace(e.target.value));
    }
    
    // search debounce
    const optimisedChange = debounce(handleInputSearch)

    // prevent enter when search
    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
            e.preventDefault();
            return false;
        }
    });

    // set date curr
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth() < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1))+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes()
    let dateTime = date+' '+time;

    // stop coupon 
    const handleStopCoupon= (id) => {
        axios.put(`${ENDPOINT.COUPON}/update-status/${id}`, config)
            .then(function (response) {
                toast.success('Dừng mã giảm giá thành công !')
                setUpdateStatusDeal(true)
            })
        .catch(error => {
                alert(error.response.data.error)
            }
        )
    }

    const handleChangeUpdateSatatus = (e) => {
        if(e.target.checked){
            handleShowUpdate(true)
        }
    }
            

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Danh sách mã giảm giá</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block w-100">
                    <div className="d-flex justify-content-between">
                        <Form.Group className="col-5 pl-0">
                            <input   id="searchCategory" className="form-control" onChange={optimisedChange} type="search" placeholder="Mã giảm giá" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                            <span className="bx bx-search-alt"></span>
                        </Form.Group>
                        <div className="w-25 col-5 pl-0">
                            <select name="" id="" onChange={handleSearchSelect} className="form-control pl-3">
                                <option value="">Tất cả</option>
                                <option value="0">Sắp diễn ra</option>
                                <option value="1">Đã hết hạn</option>
                                <option value="2">Đang diễn ra</option>

                            </select>
                        </div>
                        <Link to ="/main/coupon/store"><button type="button" className="btn btn-add"> + Thêm mã giảm giá</button></Link>
                    </div>
                    {/* <div className="d-flex mt-2">
                        <Form.Group className="d-flex mr-5">
                            <Form.Label  className="align-items-center mb-0 pl-0 pr-0 col-3 align-self-center">
                                Từ ngày
                            </Form.Label>
                            <Form.Control onChange={handleChangeFromDate} type="date" id="start_date" ></Form.Control>
                        </Form.Group>
                        <div className="grid">
                            <Form.Group className="d-flex">
                                <Form.Label  className=" align-items-center mb-0 pl-0 pr-0  col-3 align-self-center">
                                    Đến ngày
                                </Form.Label>
                                <Form.Control onChange={handleChangeToDate} type="date" id="end_date" ></Form.Control>

                            </Form.Group>
                        </div>
                    </div> */}
                </form>
            </div>
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">CODE</th>
                            <th scope="col">Loại</th>
                            <th scope="col">Số lượt được sử dụng</th>
                            <th scope="col">Số sản phẩm áp dụng</th>
                            <th scope="col">Số người áp dụng</th>
                            <th scope="col">Số lượt đã sử dụng</th>
                            <th scope="col">Giảm giá</th>
                            <th scope="col">Trạng thái</th>
                            <th scope="col">Dừng mã giảm giá</th>
                            <th scope="col">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                    {listCoupon && listCoupon.map((item,index) =>
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>
                                <Link to={`/main/coupon-detail/${item.id}`}>{item.code}</Link>
                            </td>
                            <td style={{minWidth:'100px'}}>
                                {(() => {
                                    if (item.type === 1) {
                                    return (
                                        <span>Đơn hàng</span>
                                    )
                                    } else if (item.type === 2) {
                                    return (
                                        <span>Sản phẩm</span>
                                    )}
                                })()}
                            </td>
                            <td style={{minWidth:'120px'}}>{item.number_of_use} </td>
                            <td style={{minWidth:'120px'}}>{item.number_product}</td>
                            <td style={{minWidth:'120px'}}>{item.number_user}</td>
                            <td style={{minWidth:'120px'}}>{item.used}</td>
                            <td style={{minWidth:'120px'}}>{item.uint === 0 ? formatCurrency(item.value)+ 'đ' : formatCurrency(item.value)+ '%'}</td>
                            <td style={{minWidth:'200px'}}>
                                {(() => {
                                    if (item.status === 0) {
                                    return (
                                        <div>
                                            <span style={{fontWeight:'bold'}}>Sắp diễn ra</span>
                                            <p className="mt-0 mb-0">({formatDateTime(item.start_time)})</p>
                                            <p className="mt-0 mb-0">({formatDateTime(item.end_time)})</p>
                                        </div>
                                    )
                                    }else if (item.status === 1) {
                                    return (
                                        <div>
                                            <span style={{fontWeight:'bold', color:'red'}}>Đã hết hạn</span>
                                            <p className="mt-0 mb-0">({formatDateTime(item.start_time)})</p>
                                            <p className="mt-0 mb-0">({formatDateTime(item.end_time)})</p>
                                        </div>
                                    )}else if(item.status_coupon === false){
                                        return <span style={{color: 'red'}}>Đã dừng</span>
                                    }else if (item.status === 2) {
                                    return (
                                        <div>
                                            <span style={{fontWeight:'bold', color:'darkturquoise'}}>Đang diễn ra</span>
                                            <p className="mt-0 mb-0">({formatDateTime(item.start_time)})</p>
                                            <p className="mt-0 mb-0">({formatDateTime(item.end_time)})</p>
                                        </div>
                                    )}
                                })()}
                            </td>
                            {/* =============================== */}
                            <td style={{minWidth:'200px'}}>
                                {(() => {
                                    if (item.status === 0) {
                                    return (
                                        <div>
                                           
                                        </div>
                                    )
                                    }else if (item.status === 1) {
                                    return (
                                        <div>
                                           
                                        </div>
                                    )}else if (item.status === 2) {
                                    return (
                                        <input onChange={handleChangeUpdateSatatus} id={item.id} onClick={() => setIdUserCurr(item.id)} defaultChecked={item.status_coupon === false ? true : false} disabled={item.status_coupon === false ? true : false} className="ml-4" type="checkbox"/>
                                    )}
                                })()}
                            </td>
                            {/* =============================== */}
                            <td style={{minWidth:'200px'}}> 
                                {(() => {
                                    if (item.type === 1) {
                                    return (
                                        <Link style={{'cursor':'default'}} to="#"><i className="mdi mr-4 font-size-18"></i></Link>
                                    )
                                    } else if (item.type === 2) {
                                    return (
                                        item.status === 1 ? <Link style={{'cursor':'default'}} to="#"><i className="mdi mr-4 font-size-18"></i></Link> : <Link to={`/main/coupon-products/${item.id}`}><i className="mdi mdi-plus-circle font-size-18 mr-2"></i></Link> 
                                    )}
                                })()}
                            {/* =============================== */}
                                {
                                    item.users !== null 
                                    ?  <Link style={{'cursor':'default'}} to="#"><i className="mdi mr-3 font-size-18"></i></Link> 
                                    : 
                                    
                                        item.status === 1
                                        ? 
                                            <Link style={{'cursor':'default'}} to="#"><i className="mdi mr-3 font-size-18"></i></Link>
                                            
                                        :   <Link  onClick={() => {handleShow();  setIdUserCurr(item.id); setNameUserCurr(item.code)}} to="#" className="text-danger">
                                                <i className="mdi mdi-delete font-size-18"></i>
                                            </Link>
                                    
                                }
                                
                            {/* =============================== */}    
                                {item.status === 1 ? <Link style={{'cursor':'default'}} to="#"><i className="mdi mr-3 font-size-18"></i></Link> : <Link to={`/main/coupon-user/${item.id}`}><i className="mdi mdi-account font-size-18 mr-2"></i></Link>  } 
                                {(() => {
                                    if (item.status === 0) {
                                    return (
                                        <Link to={`/main/coupon/edit/${item.id}`}><i className="mdi mdi-pencil font-size-18"></i></Link>
                                    )
                                    }else if (item.status === 1) {
                                    return (
                                        <Link style={{'cursor':'default'}} to="#"><i className="mdi mr-3 font-size-18"></i></Link> 
                                    )}else if (item.status === 2) {
                                    return (
                                        <Link style={{'cursor':'default'}} to="#"><i className="mdi mr-3 font-size-18"></i></Link> 
                                    )}
                                })()}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {total <= 10 ? '' :
                <Pagination
                    onChange={(value) => setcurrentPage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                />
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá mã giảm giá</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá mã giảm giá <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showUpdate} onHide={() => handleCloseUpdate(idUserCurr)}>
                <Modal.Header closeButton>
                    <Modal.Title>Dừng mã giảm giá</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn dừng mã giảm giá <span style={{'color':'blue'}}>{}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCloseUpdate(idUserCurr)}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleStopCoupon(idUserCurr);
                        handleCloseUpdate();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default Coupon
