import React, {useEffect, useState} from 'react'
import ENDPOINT from '../../ultils/EndPoint';
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import $ from "jquery";
import axios from "axios";
import './ListOrders.scss';
import { formatDate, formatCurrency } from '../../helpers/function';
import { ToastContainer, toast } from 'react-toastify';
import {Button, Modal} from "react-bootstrap";
import { useHistory } from 'react-router';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function ListOrderDraft() {
    // set const 
    const [listOrders, setListOrders] = useState([]);
    const [keyword, setFilter] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory()
    const dispatch = useDispatch();

    // set for update
    const [showUpdate, setShowUpdate] = useState(false);
    const handleCloseUpdate = () => setShowUpdate(false);
    const handleShowUpdate = () => setShowUpdate(true);

    const [idStoreCurr, setIdStoreCurr] = useState('');
    const [nameStoreCurr, setNameStoreCurr] = useState('');

    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(10);

    // set Orthers
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // call api set list banner
    useEffect(() => {
        let query = '';
        const data = {
            per_page : customersPerPage,
            page: currentPage,
            keyword: keyword,
            from_date: fromDate,
            to_date: toDate,
            is_draf: 1
        }
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        let unmounted = false;
        axios.get(`${ENDPOINT.LIST_ORDERS}` + query, config)
        .then((res) => {
            if (!unmounted) {
                setListOrders(res.data.data.records);
                setNumberPage(res.data.data.total_record)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [
        customersPerPage, 
        currentPage, 
        keyword, 
        fromDate,  
        toDate, 
        dispatch
    ]);


    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    const handleInputSearch = (e) => {
        setFilter(removeExtraSpace(e.target.value))
        setcurrentPage(1)
    }

    const optimisedChange = debounce(handleInputSearch)

    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    const handleChangeFromDate = (e) => {
        setcurrentPage(1)
        setFromDate(e.target.value)
    }

    const handleChangeToDate = (e) => {
        setcurrentPage(1)
        setToDate(e.target.value)
    }

    // restart all
    const handleRestart = () => {
        setcurrentPage(1)
        setFilter('')
        setFromDate('')
        setToDate('')
        $('#searchCategory').val('')
        $('#start_date').val('')
        $('#end_date').val('')
    }

    // handle delete 
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.LIST_ORDERS}/${id}`, config)
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Xóa đơn hàng nháp thành công !')
                    const remainAll = listOrders.filter((store) => store.id !== id)
                    setListOrders(remainAll)
                }
            })
            .catch(error => {
                toast.error(error.response.data.error)
            }
        )
    }

    // handle update 
    const handleUpdate = (id) => {
        axios.post(`${ENDPOINT.LIST_ORDERS}/${id}/update-draft`, config)
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Chuyển đổi đơn hàng nháp thành công !')
                    setTimeout(() => {
                        history.replace('/main/list-orders')
                    }, 2000);
                }
            })
            .catch(error => {
                toast.error(error.response.data.error)
            }
        )
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="flex-column d-sm-flex justify-content-end align-items-start pb-0">
                    <h1 className="customer-title mb-3">Quản lý đơn hàng nháp</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block">
                    <div className="d-flex ">
                            <Form.Group className="mr-4 w-20">
                                <input 
                                    id="searchCategory" 
                                    className="form-control" 
                                    type="search" 
                                    placeholder="Tìm kiếm" 
                                    aria-label="Search" 
                                    onChange={optimisedChange} 
                                    style={{ background: "#fff", border: '1px solid #ced4da'}}
                                    />
                                <span className="bx bx-search-alt"></span>
                            </Form.Group>
                            <div className="d-flex">
                            <Form.Group className="d-flex mr-5">
                                <Form.Label className="align-items-center mb-0 pl-0 pr-0 col-3 align-self-center">
                                    Từ ngày
                                </Form.Label>
                                <Form.Control onChange={handleChangeFromDate} type="date" id="start_date" ></Form.Control>
                            </Form.Group>
                            <div className="grid">
                                <Form.Group className="d-flex">
                                    <Form.Label className=" align-items-center mb-0 pl-0 pr-0  col-3 align-self-center">
                                        Đến ngày
                                    </Form.Label>
                                    <Form.Control onChange={handleChangeToDate} type="date" id="end_date" ></Form.Control>
                                    {/*<p className="ml-5 pl-4 text-danger" style={{ "position": "absolute"}}>{validationMsg.startDate}</p>*/}
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button type='button'  className="btn btn-info btn-reload" onClick={handleRestart}>
                            <i className="fas fa-sync-alt"></i>
                        </button>
                        <div>
                            <Link style={{'marginLeft':'20px'}} to ="/main/create/order"><button type="button" className="btn btn-add">Tạo đơn hàng nháp</button></Link>
                        </div>
                    </div>
                </form>
            </div>
            <div className="category-manager-wapper">
                <div className="table-responsive group_product">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                        <tr>
                            <th>Mã đơn hàng</th>
                            <th>Nguời mua hàng</th>
                            <th>Ngày mua</th>
                            <th>Giá trị đơn hàng(VNĐ)</th>
                            <th>Phương thức thanh toán</th>
                            <th>Trạng thái giao hàng</th>
                            <th>Trạng thái đơn hàng</th>
                            <th>Hành động</th>
                            <th>Chuyển đổi đơn hàng</th>
                        </tr>
                        </thead>
                        <tbody row="10">
                        {listOrders ? listOrders.map((listOrder, index) => 
                                <tr key={index}>
                                    <th>
                                        <Link to={`/main/list-orders/${listOrder.id}`}> 
                                            {listOrder.code}
                                        </Link>
                                    </th>
                                    <td style={{width:'130px'}}>
                                        {listOrder.name}
                                    </td>
                                    <td style={{width:'120px'}}>
                                        {formatDate(listOrder.created_at)}
                                    </td>
                                    <td style={{width:'120px'}}>
                                        {formatCurrency(listOrder.real_price)}
                                    </td>
                                    <td>
                                        {listOrder.payment_method.name}
                                    </td>
                                    <td>
                                        {
                                           listOrder.payment_status === false
                                           ? 'Chưa thanh toán'
                                           : 'Đã thanh toán'
                                        }
                                    </td>
                                    <td>
                                        {(() => {
                                            if (listOrder.status === 0) {
                                            return (
                                                <td> Chưa xác nhận</td>
                                            )
                                            } else if (listOrder.status === 1) {
                                            return (
                                                <td> Đã xác nhận</td>
                                            )
                                            } else if (listOrder.status === 2){
                                                return (
                                                    <td> Đã hoàn thành</td>
                                                )
                                            } else if (listOrder.status === 3){
                                                return (
                                                    <td> Huỷ bởi khách hàng</td>
                                                )
                                            } else if (listOrder.status === 4){
                                                return (
                                                    <td> Huỷ bởi admin</td>
                                                )
                                            }
                                        })()}
                                    </td>
                                    <td style={{'width': '7%'}}>
                                        <Link to={`/main/list-orders-draft/${listOrder.id}`}><i className="mdi mdi-pencil font-size-18"></i></Link> 
                                        <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                            handleShow();
                                            setIdStoreCurr(listOrder.id)
                                            setNameStoreCurr(listOrder.code)
                                        }} className="mdi mdi-delete font-size-18"></i>
                                    </td>
                                    <td><i className="fas fa-angle-double-up" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                                            handleShowUpdate();
                                            setIdStoreCurr(listOrder.id)
                                            setNameStoreCurr(listOrder.code)
                                        }}></i></td>
                                </tr>
                        ) : ''}
                        </tbody>
                    </table>
                    {numberPage <= 10 ? '' : 
                        <Pagination 
                            onChange={(value) => setcurrentPage(value)}
                            total={numberPage}
                            current={currentPage}
                            showSizeChanger= {false}
                        /> 
                    }
                    </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá đơn hàng nháp</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá đơn hàng nháp <span style={{'color':'blue'}}>{nameStoreCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleClose();
                        handleDelete(idStoreCurr)
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showUpdate} onHide={handleCloseUpdate}>
                <Modal.Header closeButton>
                    <Modal.Title>Chuyển đổi đơn hàng nháp</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn chuyển đổi đơn hàng nháp <span style={{'color':'blue'}}>{nameStoreCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdate}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleCloseUpdate();
                        handleUpdate(idStoreCurr)
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListOrderDraft
