import './Role.scss';
import React, { useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form';
import ENDPOINT from "../../ultils/EndPoint";
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';
import "antd/dist/antd.css";
import $, { trim } from "jquery";
import {Link, useHistory, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function RoleEditAndStore() {
    // set const list permiss
    const [nameRole, setNameRole] = useState('');
    const [permissionList, setPermissionList] = useState([]);
    const [permissCheck, setPermissCheck] = useState([]);
    // set msg
    const [validationMsg, setValidationMsg] = useState({});
    const [msgName, setMsgName] = useState('');
    // set id
    const {id} = useParams();
    // others
    const [disable, setDisable]= useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
 
    // handle change name 
    const handleNameRole = (e) => {
        setNameRole(e.target.value)
    }

    const handleRemoveMess = () => {
        validationMsg.nameRole = ''
        setMsgName('')
        $('.name_store').removeClass('color_danger')
    }

    // handle change permission id
    const handleCheckPermiss = (e) => {
        validationMsg.permissCheck = ''
        if(e.target.checked) {
            setPermissCheck([...permissCheck, parseInt(e.target.value)])
        }else {
            setPermissCheck(permissCheck.filter(item => item !== parseInt(e.target.value)))
        }
    }
    
    // get permission ids
    useEffect(() => {
        axios.get(`${ENDPOINT.PERMISSION}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setPermissionList(response.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
    }, [dispatch])

    // get data role
    useEffect(() => {
        if(id) {
            let unmounted = false;
            axios.get(`${ENDPOINT.ROLE}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setNameRole(response.data.data.name);
                        // setPermissCheck(Array.from(response.data.data.permissions.map(x => x.id).join(``)))
                        setPermissCheck(response.data.data.permissions.map(x => x.id))

                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [id, dispatch])

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(nameRole.length > 255 ){
            msg.nameRole = 'Tên vai trò không dài quá 255 kí tự'
        }else if( nameRole.charAt(0) === '' ){
            msg.nameRole = 'Vui lòng nhập tên vai trò'
            $('.name_store').addClass('color_danger')
        }else if(nameRole.slice(0, 2) === ' ' ){
            msg.nameRole = 'Vui lòng nhập tên vai trò'
            $('.name_store').addClass('color_danger')
        }

        if(permissCheck.length === 0 ){
            msg.permissCheck = 'Vui lòng chọn ít nhất 1 vai trò'
        }
     
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // form data for create 
    const updateNewRole = new FormData();
    updateNewRole.append('name', trim(nameRole));
    updateNewRole.append('permission_ids', permissCheck)
    // update Role
    const updateRole = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.ROLE}/${id}`, updateNewRole,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Cập nhật vai trò thành công !')
                setTimeout(() => {
                    history.push('/main/role')
                }, 2000);
            }
          })
          .catch(error => {
            if(error.request.status === 422) {
                setMsgName('Tên vai trò đã tồn tại')
            }
          }
        )
    }

    // form data for create 
    const createNewRole = new FormData();
    createNewRole.append('name', trim(nameRole));
    createNewRole.append('permission_ids', permissCheck)
    // create role
    const createRole = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.ROLE}`, createNewRole,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Thêm vai trò mới thành công !')
                setTimeout(() => {
                    history.push('/main/role')
                }, 2000);
            }
          })
          .catch(error => {
            if(error.request.status === 422) {
                setMsgName('Tên vai trò đã tồn tại')
            }
          }
        )
    }
    // handle checked
    const res = permissionList.map(x => ({...x,is:permissCheck.includes(x.id)}))

    return (
        <div className="brands pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa role</h1> : <h1 className="customer-title mb-3">Thêm mới role</h1>} 
                </div>
            </div>
            <Form.Label className="col-2 pl-0 pr-0 ">Tên role <span style={{'color':'red'}}>*</span> </Form.Label>
                <div className="input-group">
                <input 
                    type="text" 
                    className="form-control name_partner name_store" 
                    value={nameRole}
                    onChange={handleNameRole} 
                    onKeyDown={handleRemoveMess} 
                    aria-label="Username" 
                    aria-describedby="basic-addon1"
                />
            </div>
            <p className="text-danger mb-0">{validationMsg.nameRole}</p>
            <p className="text-danger mb-0">{msgName}</p>
            <div className="table-responsive">
                <div className="d-flex justify-content-between align-self-center mt-3">
                    <h1 className="customer-title mb-0">Phân quyền</h1>
                </div>

                <div className="form-group">
                <fieldset className="group mt-3 pt-0">
                    <ul  className="checkbox ml-0">
                        {res && res.map((permission, index) =>
                            <li key={index}>
                                <input 
                                    type="checkbox" 
                                    value={permission.id} 
                                    onChange={handleCheckPermiss} 
                                    id={permission.id} 
                                    checked={permission.is}
                                />
                                <label htmlFor={permission.id}>{permission.name}</label></li> 
                        )}
                        <p className="text-danger mb-0">{validationMsg.permissCheck}</p>
                    </ul>
                </fieldset>
            </div>
            </div>
            <div >
                <Link to="/main/role"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link> 
                {id ? <button type="button" disabled={disable} onClick={updateRole} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createRole} className="btn btn-success mt-5">Lưu</button>}
      
            </div>
        </div>
    )
}

export default RoleEditAndStore;
