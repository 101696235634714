import React, { useState, useEffect } from 'react';
import './Banner.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Button, Modal} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Pagination} from 'antd';
import "antd/dist/antd.css";
import ENDPOINT from '../../ultils/EndPoint';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function Banner() {
    // set const 
    const [listBanner, setListBanner] = useState([]);
    const [listTypeBanner, setListTypeBanner] = useState([]);

    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [idBannerCurr, setIdBannerCurr] = useState('');
    const [nameStoreCurr, setNameStoreCurr] = useState('');

    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(20);

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    const dispatch = useDispatch();

    // call api set list banner
    useEffect(() => {
        let query = '';
        const data = {
            per_page : customersPerPage,
            page: currentPage,
        }
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        let unmounted = false;
        axios.get(`${ENDPOINT.BANNER}` + query, config)
        .then((res) => {
            if (!unmounted) {
                setListBanner(res.data.data.records);
                setNumberPage(res.data.data.total_record)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [customersPerPage, currentPage, dispatch]);


    // call api set list-type banner
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.LIST_BANNER}`, config)
        .then((res) => {
            if (!unmounted) {
                setListTypeBanner(res.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [listBanner, dispatch]);

    // handle delete 
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.BANNER}/${id}`, config)
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Xóa banner thành công !')
                    const remainAll = listBanner.filter((banner) => banner.id !== id)
                    setListBanner(remainAll)
                }
            })
            .catch(error => {
                console.log(error);
            }
        )
    }

    const [sleceted, setSleceted] = useState('')

    const handleSerchBanner = (e) => {
        setSleceted(parseInt(e.target.value))
    }

    return (
        <div className="pl-4 pr-4 mt-3">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        /> 
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Danh sách banner</h1>
            </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
                <Link to ="/main/banners/store"><button type="button" className="btn btn-add"> + Thêm</button></Link>
            </div>
            <div className="input-group col-2 pl-0">
                <select id="selectStatus" name="" onChange={handleSerchBanner} className="form-control custom_select custom_selectProduct"> 
                    <option value="">Loại Banner</option>
                    {listTypeBanner.map((item, key) =>
                        <option index={key} value={item.id}>{item.name}</option>
                    )}
                </select>
            </div>
        </div>
        <div className="category-manager-wapper">
        <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th>Thứ tự sắp xếp</th>
                    <th>Link</th>
                    <th>Loại</th>
                    <th>Hình ảnh</th>
                    <th>Hoạt động</th>
                </tr>
                </thead>
                <tbody row="10">
                {
                    sleceted 
                    ? listBanner ? 
                        listBanner.filter((val) => {
                                if(sleceted === val.type_id ){
                                    return val
                                }
                            })
                        .map((banner, index) => 
                                <tr key={index}>
                                    <th style={{ "width" : "23%"}}>
                                        {banner.id}
                                    </th>
                                    <td style={{ "width" : "20%"}}>
                                        {banner.link}
                                    </td>
                                    <td style={{ "width" : "20%"}}>
                                        {banner.banner_type.name}
                                    </td>
                                    <td >
                                        <img alt="img_banner" src={banner.image} style={{'width':'100px', 'height':'100px'}}></img>
                                    </td>
                                    <td className="d-flex handle_banner" >
                                        <Link to={`/main/banners/${banner.id}`}><i className="mdi mdi-pencil font-size-18"></i></Link> 
                                        <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                            handleShow();
                                            setIdBannerCurr(banner.id)
                                            setNameStoreCurr(banner.banner_type.name)
                                        }} className="mdi mdi-delete font-size-18"></i>
                                    </td>
                                </tr>
                        ) : ''
                   : listBanner ? 
                        listBanner
                        .map((banner, index) => 
                                <tr key={index}>
                                    <th style={{ "width" : "23%"}}>
                                        {banner.id}
                                    </th>
                                    <td style={{ "width" : "20%"}}>
                                        {banner.link}
                                    </td>
                                    <td style={{ "width" : "20%"}}>
                                        {banner.banner_type.name}
                                    </td>
                                    <td >
                                        <img alt="img_banner" src={banner.image} style={{'width':'100px', 'height':'100px'}}></img>
                                    </td>
                                    <td className="d-flex handle_banner" >
                                        <Link to={`/main/banners/${banner.id}`}><i className="mdi mdi-pencil font-size-18"></i></Link> 
                                        <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                            handleShow();
                                            setIdBannerCurr(banner.id)
                                            setNameStoreCurr(banner.banner_type.name)
                                        }} className="mdi mdi-delete font-size-18"></i>
                                    </td>
                                </tr>
                        ) : ''
                }
                   
                </tbody>
            </table>
            {numberPage <= 20 ? '' : 
                <Pagination 
                    onChange={(value) => setcurrentPage(value)}
                    total={numberPage}
                    current={currentPage}
                    showSizeChanger= {false}
                /> 
            }
            </div>
        </div>

        <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Danh sách loại banner</h1>
                </div>
                <div className="table-responsive">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                        <tr>
                            <th style={{ "width" : "20%"}}>Tên loại banner</th>
                            <th>Số lượng tối thiểu(để hiển thị)</th>
                            <th >Số lượng hiện có</th>
                            <th>Trạng thái hiển thị</th>
                        </tr>
                        </thead>
                        <tbody row="10">
                        {listTypeBanner ? listTypeBanner.map((banner, index) => 
                                <tr key={index}>
                                    <th>
                                        {banner.name}
                                    </th>
                                    <td style={{ "width" : "20%"}}>
                                        {banner.max === 0 ? 'Không giới hạn' : banner.max}
                                    </td>
                                    <td style={{ "width" : "20%"}}>
                                        {banner.banner_count}
                                    </td>
                                    <td style={{ "width" : "20%"}}>
                                        {
                                            banner.id === 1 
                                            ? banner.banner_count === 0 ? 'Không' : 'Có' 
                                            : banner.banner_count >= banner.max ? 'Có' : 'Không'
                                        }
                                    </td>
                                </tr>
                        ) : ''}
                        </tbody>
                    </table>
                </div>
            </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Xoá banner</Modal.Title>
            </Modal.Header>
            <Modal.Body>Bạn có chắc chắn muốn xoá banner <span style={{'color':'blue'}}>{nameStoreCurr}</span> không ?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Thoát
                </Button>
                <Button variant="primary" onClick={() => {
                    handleClose();
                    handleDelete(idBannerCurr)
                }}>
                    Xác nhận
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
    )
}

export default Banner