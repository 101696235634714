import * as types from '../constans/ActionType';

export const loginRequire = (payload) => ({
    type: types.LOGIN_REQUEST,
    payload,
});
  
export const loginSuccess = (payload) => ({
    type: types.LOGIN_SUCCESS,
    payload,
});

export const loginFail = (payload) => ({
    type: types.LOGIN_FAIL,
    payload,
});
 
export const logoutRequire = () => ({
    type: types.LOGOUT_REQUEST,
});
  
export const logoutSuccess = (logout) => ({
    type: types.LOGOUT_SUCCESS, 
    payload: logout 
});

export const setNewLogo  = (logoAdmin) => ({
    type: types.LOGO_ADMIN, 
    payload: logoAdmin 
});
  
  
