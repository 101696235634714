import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import ENDPOINT, {CityApiService, DistrictApiService, ShippingFeeApiService} from "../../ultils/EndPoint";
import {Modal} from "react-bootstrap";
import $ from 'jquery';
import {formatCurrency, onNumberOnlyChange, shippingOnlyChange} from "../../helpers/function";

function ShippingDetail() {
    const [showShippingProvince,setShowShippingProvince ] = useState({});
    const [ShippingDistrict,setShippingDistrict ] = useState([]);
    const [listDistrict, setListDistrict] = useState([]);
    const [show, setShow] = useState(false);
    const [showEditShippingProvince, setShowEditShippingProvince] = useState(false);
    const [shippingProvince, setShippingProvince] = useState('');

    const handleClose = () => setShow(false);
    const handleCloseEditProvince = () => setShowEditShippingProvince(false);

    const handleShow = () => setShow(true);
    const handleShowEditProvince = () => setShowEditShippingProvince(true);

    const {id} = useParams();

    useEffect(() => {
        var retval = []
        $(function () {
            $('.idDistricts').each(function () {
                retval.push($(this).attr('id'));
            })
        })
    }, [])

    $(document).ready(function () {
        $('.price_different').on('keyup change', function (e) {
            var difference = parseInt($(this).val())
            if (!difference && e.keyCode !== 189){
                difference = ''
            }

            let price =  parseInt(showShippingProvince.price) + (difference ? difference: 0 )
            if ( difference <= -parseInt(showShippingProvince.price)) {
                price = 0
                $(this).val(-parseInt(showShippingProvince.price))
            }
            $('.'+ $(this).data('refe')).html(price)
        })
    })


    const getShippingProvince = (id) => {
        ShippingFeeApiService.showShippingProvince(id).then(r => {
            if (r.status){
                setShowShippingProvince(r.data)
                setShippingDistrict(r.data.shipping_district)
                setShippingProvince(r.data.price)
            }
        })
    }
    useEffect(() => {
        getShippingProvince(id)
    }, []);

    useEffect(() => {
        CityApiService.getCityById(id).then(r => {
            if (r.status){
                DistrictApiService.getListDistrictByCityId(r.data.viet_nam_zone_id).then(r => {
                    setListDistrict(r.data)
                })
            }
        })
    }, [id])


    const createNewsBtn = () => {
        const dataCreateShippingFeeDistrict = {}
        let districtId = []
        let price = []
        let listPriceDifference = document.querySelectorAll('input.price_different')
        listPriceDifference.forEach(input => {
            let valor =  input?.value;
            if (valor == ""){
                valor = 0
            }
            price.push(parseInt(valor));
        })
        listDistrict.map(function (district, key){
            districtId.push(district.id)
        })

        dataCreateShippingFeeDistrict.district_id =districtId
        dataCreateShippingFeeDistrict.price = price

        ShippingFeeApiService.createShippingDistrict(showShippingProvince.province_id, dataCreateShippingFeeDistrict).then(r => {
            if (r.status){
                setShow(false)
                getShippingProvince(id)
                toast.success('Cập nhật giá vận chuyển quận huyện thành công!')
            }
        })
    }

    const handleSetShippingProvince = (e) => {
        setShippingProvince((e.target.value).replace(/,/g, ""))
    }

    const updateShippingFeeProvince = () => {
        let data = {
            price : parseInt(shippingProvince),
            province_id : showShippingProvince.province_id
        }

        ShippingFeeApiService.updateShippingFeeProvince(showShippingProvince.id, data).then(r => {
            if(r.status){
                setShowEditShippingProvince(false)
                const dataCreateShippingFeeDistrict = {}
                let districtId = []
                let price = []
                if (listDistrict.length){
                    listDistrict.map(function (district, key){
                        districtId.push(district.id)
                    })
                    ShippingDistrict.map(function (shipping, key){
                        if (parseInt(shippingProvince) + parseInt(shipping.difference) < 0){
                            price.push(0)
                        }else{
                            price.push(parseInt(shipping.difference))
                        }
                    })
                    dataCreateShippingFeeDistrict.district_id = districtId
                    dataCreateShippingFeeDistrict.price = price

                    ShippingFeeApiService.createShippingDistrict(showShippingProvince.province_id, dataCreateShippingFeeDistrict).then(r => {
                        if (r.status){
                            toast.success('Cập nhật giá vận chuyển quận huyện thành công !')
                            getShippingProvince(id)
                            return 0;
                        }
                    })
                }
                getShippingProvince(id)
                toast.success('Cập nhật giá vận chuyển tỉnh thành thành công !')
            }else{
                $('.error_price_shipping').html(r.errors.price)
            }
        })
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Chi tiết cấu hình phí vận chuyển</h1>
                </div>
            </div>
            <ul className="list_shipping_manager pl-0 ">
                <li className="d-flex justify-content-between">
                    <div className="grid">
                        <span>{showShippingProvince.province_name}</span>
                        <span>Chi phí vận chuyển: {formatCurrency(showShippingProvince.price ? showShippingProvince.price : 0)}</span>
                    </div>
                    <Link to="#" className="align-self-center" onClick={() => {handleShowEditProvince()}}>Sửa</Link>
                </li>
            </ul>
            {id > 0 ? <div className="tabl-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>STT</th>
                    <th>Quận/Huyện</th>
                    <th><Link to="#" className="align-self-center" onClick={() => {handleShow()}}>Sửa</Link> - Phí vận chuyển</th>
                    </thead>
                    <tbody>
                    {ShippingDistrict != null &&  ShippingDistrict.length
                        ?
                        ShippingDistrict.map((table, key) =>
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{table.district_name}</td>
                                <td>{formatCurrency(table.price ? table.price : 0)}</td>
                            </tr>
                        )
                        : ''}
                    </tbody>
                </table>
            </div> : ''}
            <Link to="/main/shippings-manager"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa phí vận chuyển</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mt-2">
                        <label for="">Phí vận chuyển</label>
                        <input defaultValue={formatCurrency(showShippingProvince.price ? showShippingProvince.price : 0)} disabled={true} type="text" className="form-control"  placeholder="VNĐ" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Chỉnh sửa theo khu vực</label>
                        <table className="table table-borderless">
                            <thead>
                                <th>Quận/Huyện</th>
                                <th>Chênh lệch</th>
                                <th>Phí vận chuyển</th>
                            </thead>
                            <tbody>
                            {listDistrict.map((district, index) =>
                                <tr key={index}>
                                    <td style={{ 'vertical-align': 'middle'}}><span id={district.id} className="idDistricts">{district.name}</span></td>
                                    <td>
                                        <input type="number" data-refe={index + "_price_final"} className={index + "_price_different price_different form-control form_input_district"}
                                               defaultValue={ShippingDistrict.find(item => item.district_id === district.id) ? ShippingDistrict.find(item => item.district_id === district.id).difference : 0 }
                                               onKeyPress={(event) => shippingOnlyChange(event, showShippingProvince.price ? showShippingProvince.price : 0, index)}/>
                                    </td>
                                    <td>
                                        <span style={{padding: "20px"}} className={index + "_price_final"}>
                                        {ShippingDistrict.find(item => item.district_id === district.id) ? formatCurrency(ShippingDistrict.find(item => item.district_id === district.id).price) : formatCurrency(showShippingProvince.price ? showShippingProvince.price : 0)}</span>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button"  className="btn btn-success mt-5" onClick={createNewsBtn} >Lưu</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditShippingProvince} onHide={handleCloseEditProvince}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa phí vận chuyển</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mt-2">
                        <label for="">Phí vận chuyển</label>
                        <input onKeyPress={onNumberOnlyChange} onChange={handleSetShippingProvince} defaultValue={showShippingProvince.price} type="number" min={0} className="form-control"  placeholder="VNĐ" />
                        <span className="error_price_shipping" style={{color : "red", fontSize : "12px"}}></span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button"  className="btn btn-success mt-5" onClick={updateShippingFeeProvince} >Lưu</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export  default ShippingDetail;
