import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import isEmpty from "validator/lib/isEmpty";
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import Logo from '../assets/images/logo.svg';
import banner_logo from '../assets/images/profile-img.png';
import { loginRequire } from '../actions/Auth';

function LoginAdmin() {
    const [stateBtn, setStateBtn] = useState(false);
    const [msg, setmsg] = useState();
    const [email, setUserEmail] = useState(localStorage.getItem('emailUser'));
    const [password, setuserPassword] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    const dataAuthAdmin = useSelector(state => state)    

    useEffect(() => {
        if(dataAuthAdmin.loginAuthReducer.error === 422){
            setmsg('Tài khoản hoặc mật khẩu không đúng.')
        }
    }, [dataAuthAdmin.loginAuthReducer.error])

    function initSettings() {
        $("#password-addon").on('click', function(){
            if($(this).siblings('input').length > 0) {
                $(this).siblings('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
    }

    useEffect(() => {
        initSettings()
    }, [])
 
    // Handle check when input form login 
    const onChangeEmail = event => {
        const value = event.target.value;
        setUserEmail(value)
    }
    const onChangePassword = event => {
        const value = event.target.value;
        setuserPassword(value);
    }
    const handleKeypessEmail = () => {
        validationMsg.email = false
    }
    const handleKeypessPassword = () => {
        validationMsg.password = false  
    }
    const validateAll = () => {
        const msg = {}
        if(email.length <= 0) {
            msg.email = 'Hãy nhập Username'
        }

        if(isEmpty(password)) {
            msg.password = 'Hãy nhập Password'
        }else if(password){
            if(password.length < 6) {
                msg.password = 'Password không được ít hơn 6 kí tự'
            }
        }

        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // submit when all okay
    const onSubmitLogin =  async (e) => {
        e.preventDefault();
        const isValidate = validateAll()
        if(!isValidate) return

        setStateBtn(true)
        setTimeout(() => {
            setStateBtn(false)
        }, 6000);

        const params = {
            username : email,
            password : password,
        }
        dispatch(loginRequire(params));

        setTimeout(() => {
            const token = localStorage.getItem('User-Admin')
            if(token){
                history.replace('/main/dashboard');
            }else{
                history.replace('/');
            }
        }, 1000);
    }

    return (
        <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            <div className="bg-primary bg-soft">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p>Sign in to continue to {process.env.REACT_APP_NAME}.</p>
                                        </div>
                                    </div>
                                    <div className="col-5 align-self-end">
                                        <img src={banner_logo} style={{'height':'100px', 'width':'100%'}} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="auth-logo">
                                    <a href="!#" className="auth-logo-dark"> 
                                        <div className="avatar-md profile-user-wid mb-4">
                                    <div className="avatar-title rounded-circle bg-light">
                                        <img src={Logo} alt="" className="rounded-circle" height="34" />
                                    </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <span style={{'color': 'red'}}>{msg}</span> 
                                    <form className="form-horizontal">
                                        <div className="mb-3">
                                            <label htmlFor="username" className="form-label">Tên đăng nhập</label>
                                            <input
                                                type="text" 
                                                className="form-control" 
                                                id="username"
                                                defaultValue={email}
                                                placeholder="Tên đăng nhập"
                                                onKeyPress={handleKeypessEmail}
                                                onChange={onChangeEmail}
                                            />
                                             <p className="text-danger">{validationMsg.email}</p> 
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Mật khẩu </label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <input 
                                                    type="password" 
                                                    className="form-control"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Mật khẩu"
                                                    onKeyPress={handleKeypessPassword}
                                                    onChange={onChangePassword}
                                                    aria-describedby="password-addon"
                                                />
                                               <button className="btn btn-light" type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                                            </div>
                                            <p className="text-danger">{validationMsg.password}</p> 
                                        </div>
                                        {/* <div className="form-check">
                                            <input className="form-check-input" defaultChecked={checkRemember} onChange={handleCheck} type="checkbox" id="remember_me"/>
                                            <label className="form-check-label" htmlFor="remember_me">
                                                Lưu đăng nhập
                                            </label>
                                        </div> */}
                                        <div className="mt-3 d-grid">
                                            <button 
                                                disabled={stateBtn} 
                                                className="btn btn-primary waves-effect waves-light"
                                                type="submit"
                                                onClick={onSubmitLogin}
                                                >Đăng nhập
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginAdmin;
