import React, {useCallback, useEffect, useState} from 'react'
import ENDPOINT, {warehouseApiService} from '../../ultils/EndPoint';
import {ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {objectToQueryParams} from "../../helpers/function";
import {Pagination} from "antd";
import $ from "jquery";
import axios from "axios";


function WareHouse() {

    const [listWareHouse, setListWareHouse] = useState([]);
    const [customersPerPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [keyword, setFilter] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const [selected, setSelected] = useState('');




    // remove all whitespace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };




    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    const handleInputSearch = (e) => {
        setFilter(removeExtraSpace(e.target.value))
        setcurrentPage(1)
    }
    const selectImport = (e) => {
        setSelected(e.target.value);
        setcurrentPage(1)
    }
    const optimisedChange = debounce(handleInputSearch)

    const handleChangeStartDate = (e) => {
        setStartDate(e.target.value);
        setcurrentPage(1)
    }

    const handleChangeEndDate = (e) => {
        setEndDate(e.target.value);
        setcurrentPage(1)
    }

    const optimisedDate = debounce(handleChangeStartDate, 2000)

    const handleCheckDate = (start, end) => {
        if(start <= end) {
            return true
        }else if(start !== '' && end === '') {
            return true
        }else if(start > end) {
            return false
        }
    }

    const validateAllConfig = useCallback( () => {
        const msg = {}

        if(handleCheckDate(startDate, endDate) !== true){
            msg.startDate = 'Đến ngày phải lớn hơn từ ngày'
        }else {
            msg.startDate = ''
        }

        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }, [startDate, endDate])


    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    const getWareHouse = (query) => {
        warehouseApiService.getListWareHouse(query).then(r => {
            if(r.status) {
                setListWareHouse(r.data.records)
                setTotal(r.data.total_record)
            }
        })
    }

    useEffect(() => {
        const data = {
            per_page: customersPerPage,
            page: currentPage,
            start_date: startDate,
            end_date: endDate,
            keyword: keyword,
            type: selected,
        };
        let query = objectToQueryParams(data)
        getWareHouse(query)
    }, [customersPerPage,currentPage,startDate,endDate,validateAllConfig,keyword,selected ]);

    const resetFilter = () => {
        setFilter('')
        setSelected('')
        setStartDate('')
        setEndDate('')
        $("#searchCategory").val("")
        $("#select_status_warehouse").val("")
        $("#start_date").val("")
        $("#end_date").val("")
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Quản lý kho</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block">
                    <div className="d-flex">
                        <Form.Group className="mr-4 w-50">
                            <input   id="searchCategory" className="form-control" type="search" placeholder="Tên sản phẩm" aria-label="Search" onChange={optimisedChange} style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                            <span className="bx bx-search-alt"></span>
                        </Form.Group>
                        <div className="w-25">
                            <select name="" id="select_status_warehouse" onChange={selectImport} className="form-control pl-3">
                                <option value="">Tất cả</option>
                                <option value="1">Nhập kho</option>
                                <option value="2">Xuất kho</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                        <Form.Group className="d-flex mr-5">
                            <Form.Label className="align-items-center mb-0 pl-0 pr-0 col-3 align-self-center">
                                Từ ngày
                            </Form.Label>
                            <Form.Control type="date" id="start_date" onChange={optimisedDate}></Form.Control>
                        </Form.Group>
                        <div className="grid">
                            <Form.Group className="d-flex">
                                <Form.Label className=" align-items-center mb-0 pl-0 pr-0  col-3 align-self-center">
                                    Đến ngày
                                </Form.Label>
                                <Form.Control type="date" id="end_date" onChange={handleChangeEndDate}></Form.Control>
                                <p className="ml-5 pl-4 text-danger" style={{ "position": "absolute"}}>{validationMsg.startDate}</p>
                            </Form.Group>
                        </div>
                        <Form.Group className="ml-3">
                            <button type='button'  className="btn btn-info btn-reload ml-3" onClick={resetFilter}>
                                <i className="fas fa-sync-alt"></i>
                            </button>
                        </Form.Group>
                    </div>
                </form>
            </div>

            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>STT</th>
                    <th>Ngày</th>
                    <th>Loại</th>
                    <th>Sản phẩm</th>
                    <th>Người thao tác</th>
                    <th>Số lượng</th>
                    </thead>
                    <tbody>
                    {listWareHouse && listWareHouse.map((wareList, index) =>
                        <tr key={index}>
                            <td style={{ "width" : "10%"}}>{wareList.id}</td>
                            <td style={{ "width" : "20%"}}>{wareList.created_at}</td>
                            <td style={{ "width" : "10%"}}>
                                {wareList.type === 1 ?
                                    <span style={{ "color" : "red"}}>Nhập kho</span>
                                    : <span style={{ "color" : 'green'}}>Xuất kho</span>}
                            </td>
                            <td style={{ "width" : "30%"}}>
                                {wareList.product_name}
                            </td>
                            <td>{wareList.user_name}</td>
                            <td>{wareList.quantity}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {total <= 10 ? '' :
                <Pagination
                    onChange={(value) => setcurrentPage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                />
            }
        </div>
    )
}

export default WareHouse
