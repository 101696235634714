import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import LoginAdmin from './pages/LoginAdmin';
import Main from './pages/Main';

function App() {
  const tokenAdmin = localStorage.getItem('User-Admin');

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path='/main' component={Main}/> 
          <Route path='/main/:slug' component={Main}/> 
          <Route path="/" exact component={tokenAdmin ? Main : LoginAdmin} />
          <Route>NOT FOUND</Route> 
        </Switch>
      </Router>
    </div>
  );
}

export default App;
