import './Role.scss';
import React, { useEffect, useState} from 'react'
import ENDPOINT from "../../ultils/EndPoint";
import axios from 'axios';
import {Pagination} from 'antd';
import "antd/dist/antd.css";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function Permission() {
    const [permission, setPermission] = useState([])
    const [total, setTotal] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(10);
    const dispatch = useDispatch();

    useEffect(() => {
        const getTerm = async () => {
            try {
                let query = '';
                const data = {per_page: customersPerPage, page: currentPage};
                Object.keys(data).map((key, index) =>
                    query += (index === 0 ? '?' : "&") + key + '=' + data[key]
                )
                const  res = await axios.get(`${ENDPOINT.PERMISSION}/paginate` + query, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                    }
                })
                setPermission(res.data.data.records)
                setTotal(res.data.data.total_record)
            } catch(error) {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }
        }
        getTerm();
    }, [currentPage,customersPerPage, dispatch ])



    return (
        <div className="brands pl-4 pr-4 mt-3">
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Danh sách phân quyền</h1>
                </div>
            </div>
            <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th>STT</th>
                    <th>Tên phân quyền</th>
                </tr>
                </thead>
                <tbody row="10">
                    {permission?
                        permission.map((item, index) =>
                        <tr key={index}>
                            <th>
                                {item.id}
                            </th>
                            <td>
                                {item.name}
                            </td>
                        </tr>
                    )
                    : ''}
                </tbody>
            </table>
            {total <= 10 ? '' :
                <Pagination
                    onChange={(value) => setcurrentPage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                />
            }
            </div>
        </div>
    )
}

export default Permission;
