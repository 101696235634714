import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Sidebar from '../components/common/Sidebar';
import Header from '../components/common/Header';
import CategoryManager from '../components/category/CategoryManager';
import CategoryEditAndStore from '../components/category/CategoryEditAndStore';
import BrandsManager from '../components/brands/Brands';
import PartnerManager from '../components/partner/PartnerManager';
import PartnerEditStore from '../components/partner/PartnerEditStore';
import BrandsEditAndCreate from '../components/brands/BrandsEditAndCreate';
import Website from '../components/website/Website';
import StoreManager from '../components/website/store-manager/StoreManager';
import StoreEditCreate from '../components/website/store-manager/StoreEditCreate';
import CatalogNews from '../components/catalog/CatalogManager';
import CatalogNewsEditAndStore from '../components/catalog/CatalogNewEditAndStore';
import Usermanager from '../components/users/Usermanager';
import UsermanagerEditAndCreate from '../components/users/UsermanagerEditAndCreate';
import Products from '../components/products/Products';
import ProductCreateAndEdit from '../components/products/ProductCreateAndEdit';
import NewsManager from '../components/news/News';
import NewsEditAndCreate from '../components/news/NewsCreateAndEdit';
import GroupProducts from '../components/group-product/GroupProducts';
import GroupProductDetail from '../components/group-product/GroupProductDetail';
import GroupProductAdd from '../components/group-product/GroupProductAdd';
import Shippings from '../components/shippings/Shippings';
import ShippingsManager from '../components/shippings/ShippingManager';
import ShippingsDetails from '../components/shippings/ShippingDetail';
import StaticPage from '../components/staticpage/StaticPage';
import StaticPageManager from '../components/staticpage/StaticCreateAndEdit';
import Services from '../components/services/Services';
import ServicesManager from '../components/services/ServicesManager';
import WareHouse from '../components/warehouse/WareHouse';
import Banner from '../components/banner/Banner';
import BannerEditStore from '../components/banner/BannerEditStore';
import ListOrders from '../components/list-orders/ListOrders';
import ListOrderDetail from '../components/list-orders/ListOrderDetail';
import Admin from '../components/admin/Admin';
import AdminEditAndStore from '../components/admin/AdminEditAndStore';
import Role from '../components/role-permission/Role';
import RoleEditAndStore from '../components/role-permission/RoleEditAndStore'
import Permission from '../components/role-permission/Permission';
import ListDetail from '../components/deal/ListDeal';
import DetailManager from '../components/deal/DealManager';
import DealProduct from '../components/deal/DealProduct';
import DetailInfoDeal from '../components/deal/DetailInfoDeal';
import Faq from "../components/faq/Faq";
import DetailFaq from "../components/faq/DetailFaq";
import Coupon from '../components/coupon/Coupon';
import CouponUpdateAndStore from '../components/coupon/CouponUpdateAndStore';
import CouponProduct from '../components/coupon/CouponProduct';
import CouponUser from '../components/coupon/CouponUser';
import CouponInfoDetail from '../components/coupon/CouponInfoDetail';
import CreateUser from "../components/users/CreateUser";
import UpdateUser from "../components/users/UpdateUser";
import ListReview from "../components/review/ListReview";
import OrderReviewDetail from "../components/review/OrderReviewDetail";
import TicketManager from '../components/list-orders/TicketManager';
import Prescription from "../components/prescription/Prescription";
import PrescriptionDetail from "../components/prescription/PrescriptionDetail";
import OrderCreate from '../components/list-orders/OrderCreate';
import ListScript from "../components/script/ListScript";
import CreateScript from "../components/script/Create";
import EditScript from "../components/script/Edit";
import ListOrderDraft from '../components/list-orders/ListOrderDraft';
import ListGiftExchangePoint from "../components/gift-exchange-point/List";
import CreateGiftExchangePoint from "../components/gift-exchange-point/Create";
import EditGiftExchangePoint from "../components/gift-exchange-point/Edit";
import Dashboard from '../components/dashboard/Dashboard';

import ListTicket from "../components/list-orders/ListTicket";
import Chat from "../components/chat/index"
import ComponentToPrint from "../components/list-orders/ComponentToPrint";

function Main() {
    const history = useHistory();
    useEffect(() => {
        const token = localStorage.getItem('User-Admin')
        if(!token) {
            history.replace('/')
        }
    })
    return (
        <div>
            <Router>
                <Header />
                <Sidebar/>
                <div className="main-content">
                    <div className="page-content">
                        <Switch>
                            {/* dashboard */}
                            <Route exact path="/main/dashboard" component={Dashboard}/>

                            {/* website */}
                            <Route exact path="/main/website" component={Website} />

                            {/* store */}
                            <Route exact path="/main/store"  component={StoreManager} />
                            <Route exact path="/main/store/create"  component={StoreEditCreate} />
                            <Route exact path="/main/store/edit/:id"  component={StoreEditCreate} />

                            {/* category */}
                            <Route exact path="/main/products/category"  component={CategoryManager} />
                            <Route exact path="/main/products/category/create"  component={CategoryEditAndStore} />
                            <Route exact path="/main/products/category/edit/:id"  component={CategoryEditAndStore} />

                            {/* catalog */}
                            <Route exact path="/main/catalog-news"  component={CatalogNews} />
                            <Route exact path="/main/catalog-news/create"  component={CatalogNewsEditAndStore} />
                            <Route exact path="/main/catalog-news/edit/:id"  component={CatalogNewsEditAndStore} />

                            {/* news */}
                            <Route exact path="/main/news"  component={NewsManager} />
                            <Route exact path="/main/news/create"  component={NewsEditAndCreate} />
                            <Route exact path="/main/news/edit/:id"  component={NewsEditAndCreate} />


                            {/* brands */}
                            <Route exact path="/main/brands"  component={BrandsManager} />
                            <Route exact path="/main/brands/create"  component={BrandsEditAndCreate} />
                            <Route exact path="/main/brands/edit/:id"  component={BrandsEditAndCreate} />
                            
                            {/* partner */}
                            <Route exact path="/main/partner"  component={PartnerManager} />
                            <Route exact path="/main/partner/create"  component={PartnerEditStore} />
                            <Route exact path="/main/partner/edit/:id"  component={PartnerEditStore} />

                            {/* users manager */}
                            <Route exact path="/main/users"  component={Usermanager} />
                            <Route exact path="/main/users/create"  component={CreateUser} />
                            <Route exact path="/main/users/edit/:id"  component={UpdateUser} />
                            <Route exact path="/main/users/:id"  component={UsermanagerEditAndCreate} />

                            {/* product manager */}
                            <Route exact path="/main/products"  component={Products}/>
                            <Route exact path="/main/products/create"  component={ProductCreateAndEdit}/>
                            <Route exact path="/main/products/edit/:id"  component={ProductCreateAndEdit}/>
                            {/* ======>  product group*/}
                            <Route exact path="/main/products/groups"  component={GroupProducts}/>
                            <Route exact path="/main/products/groups/create"  component={GroupProductDetail}/>
                            <Route exact path="/main/products/groups/edit/:id"  component={GroupProductDetail}/>
                            <Route exact path="/main/products-groups/:id"  component={GroupProductAdd}/>

                            {/* ======>  shippinhs*/}
                            <Route exact path="/main/shippings/"  component={Shippings}/>
                            <Route exact path="/main/shippings-manager/"  component={ShippingsManager}/>
                            <Route exact path="/main/shippings-manager/:id"  component={ShippingsDetails}/>


                            {/* ======>  static-page*/}
                            <Route exact path="/main/static-page"  component={StaticPage}/>
                            <Route exact path="/main/static-page/create"  component={StaticPageManager}/>
                            <Route exact path="/main/static-page/edit/:id"  component={StaticPageManager}/>


                            {/* ======>  static-page*/}
                            <Route exact path="/main/services"  component={Services}/>
                            <Route exact path="/main/services/edit/:id"  component={ServicesManager}/>
                            <Route exact path="/main/services/create"  component={ServicesManager}/>

                            {/* ======>  static-page*/}
                            <Route exact path="/main/warehouse"  component={WareHouse}/>

                            {/* ======>  banner*/}
                            <Route exact path="/main/banners"  component={Banner}/>
                            <Route exact path="/main/banners/store"  component={BannerEditStore}/>
                            <Route exact path="/main/banners/:id"  component={BannerEditStore}/>

                            {/* ======>  list-orders*/}
                            <Route exact path="/main/list-orders"  component={ListOrders}/>
                            <Route exact path="/main/list-ticket-delivery"  component={ListTicket}/>
                            <Route exact path="/main/list-orders-draft"  component={ListOrderDraft}/>
                            <Route exact path="/main/list-orders-draft/:id"  component={OrderCreate}/>
                            <Route exact path="/main/list-orders/:id"  component={ListOrderDetail}/>
                            <Route exact path="/main/list-orders/preview/:id"  component={ComponentToPrint}/>
                            <Route exact path="/main/create/order"  component={OrderCreate}/>
                            <Route exact path="/main/ticket/create/:id"  component={TicketManager}/>
                            <Route exact path="/main/ticket/edit/:id"  component={TicketManager}/>

                            {/* ======>  admin*/}
                            <Route exact path="/main/admin"  component={Admin}/>
                            <Route exact path="/main/admin/edit/:id"  component={AdminEditAndStore}/>
                            <Route exact path="/main/admin/store"  component={AdminEditAndStore}/>

                            {/* ======>  role*/}
                            <Route exact path="/main/role"  component={Role}/>
                            <Route exact path="/main/role/store"  component={RoleEditAndStore}/>
                            <Route exact path="/main/role/edit/:id"  component={RoleEditAndStore}/>

                            {/* ======>  permission*/}
                            <Route exact path="/main/permission"  component={Permission}/>

                            {/* ======>  coupon*/}
                            <Route exact path="/main/coupon"  component={Coupon}/>
                            <Route exact path="/main/coupon/store"  component={CouponUpdateAndStore}/>
                            <Route exact path="/main/coupon/edit/:id"  component={CouponUpdateAndStore}/>
                            <Route exact path="/main/coupon-products/:id"  component={CouponProduct}/>
                            <Route exact path="/main/coupon-user/:id"  component={CouponUser}/>
                            <Route exact path="/main/coupon-detail/:id"  component={CouponInfoDetail}/>

                            {/* ======>  deal*/}
                            <Route exact path="/main/deal"  component={ListDetail}/>
                            <Route exact path="/main/deal/create"  component={DetailManager}/>
                            <Route exact path="/main/deal/edit/:id"  component={DetailManager}/>
                            <Route exact path="/main/deal/info/:id"  component={DetailInfoDeal}/>
                            <Route exact path="/main/deal-products/:id"  component={DealProduct}/>

                            { /*======> Faq*/}
                            <Route exact path="/main/faq"  component={Faq}/>
                            <Route exact path="/main/faq/:id"  component={DetailFaq}/>

                            {/*review*/}
                            <Route exact path="/main/order-review"  component={ListReview}/>
                            <Route exact path="/main/order-review/:id"  component={OrderReviewDetail}/>

                            {/*prescript*/}
                            <Route exact path="/main/prescription"  component={Prescription}/>
                            <Route exact path="/main/prescription/:id"  component={PrescriptionDetail}/>

                            {/*script*/}
                            <Route exact path="/main/scripts"  component={ListScript}/>
                            <Route exact path="/main/scripts/create"  component={CreateScript}/>
                            <Route exact path="/main/scripts/:id"  component={EditScript}/>

                            {/*gift exchange point*/}
                            <Route exact path="/main/gift-exchange-point"  component={ListGiftExchangePoint}/>
                            <Route exact path="/main/gift-exchange-point/create"  component={CreateGiftExchangePoint}/>
                            <Route exact path="/main/gift-exchange-point/:id"  component={EditGiftExchangePoint}/>
                            <Route exact path="/main/chat"  component={Chat}/>
                        </Switch>
                    </div>
                </div>
            </Router>
        </div>
    )
}

export default Main
