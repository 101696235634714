import React, {useEffect, useState} from 'react';
import './Dashboard.scss';
import {ApiViaTokenDefinedService} from "../../ultils/EndPoint";
import {objectToQueryParams} from "../../helpers/function";
import {ORDER_SUCCESS, ORDER_UNCONFIRMED} from "../../helpers/constant";
import CountUp from 'react-countup';
import {Link} from "react-router-dom";

function Dashboard() {
    const [countOrderSuccess, setCountOrderSuccess] = useState(0)
    const [countOrderUnConfirmed, setCountOrderUnConfirmed] = useState(0)
    const [countOrderDraft, setCountOrderDraft] = useState(0)
    const [countProduct, setCountProduct] = useState(0)
    const [countPrescription, setCountPrescription] = useState(0)
    const [countUser, setCountUser] = useState(0)
    const [countNews, setCountNews] = useState(0)
    const [productSell, setProductSell] = useState([])
    useEffect(() => {

        //order success
        let dataOrderSuccess = {
            status : ORDER_SUCCESS
        }
        let queryOrderSuccess = objectToQueryParams(dataOrderSuccess)
        ApiViaTokenDefinedService.get("api-client/orders/get-count" + queryOrderSuccess, {}, true).then(r =>{
            if (r.status){
                setCountOrderSuccess(r.data)
            }
        })

        //order success
        let dataOrderUnConfirmed = {
            status : ORDER_UNCONFIRMED
        }
        let queryOrderUnConfirmed = objectToQueryParams(dataOrderUnConfirmed)
        ApiViaTokenDefinedService.get("api-client/orders/get-count" + queryOrderUnConfirmed, {}, true).then(r =>{
            if (r.status){
                setCountOrderUnConfirmed(r.data)
            }
        })

        //order success
        let dataOrderDraft = {
            is_draf : 1
        }
        let queryOrderDraft = objectToQueryParams(dataOrderDraft)
        ApiViaTokenDefinedService.get("api-client/orders/get-count" + queryOrderDraft, {}, true).then(r =>{
            if (r.status){
                setCountOrderDraft(r.data)
            }
        })

        //product
        ApiViaTokenDefinedService.get("api-client/products/get-count", {}, true).then(r =>{
            if (r.status){
                setCountProduct(r.data)
            }
        })

        //prescription
        ApiViaTokenDefinedService.get("api-client/prescription/get-count", {}, true).then(r =>{
            if (r.status){
                setCountPrescription(r.data)
            }
        })

        //news
        ApiViaTokenDefinedService.get("api-client/news/get-count", {}, true).then(r =>{
            if (r.status){
                setCountNews(r.data)
            }
        })

        //user
        ApiViaTokenDefinedService.get("api-client/user/get-count", {}, true).then(r =>{
            if (r.status){
                setCountUser(r.data)
            }
        })


        ApiViaTokenDefinedService.get('api-client/products/no-paginate?order_by=product_sell desc&limit=10', {}, true).then(r => {
            if (r.status){
                setProductSell(r.data)
            }
        })


    }, [])

    return (
<div className="pl-4 pr-4 mt-3">
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Dashboard</h1>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                    <i className="fas fa-cart-arrow-down"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0 ml-2">Đơn hàng mới</h5>
                        </div>
                        <div className="text-muted mt-4">
                            <h4>
                                <CountUp end={parseInt(countOrderUnConfirmed)} duration={2} />
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                    <i className="fas fa-cart-arrow-down"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0 ml-2">Đơn hàng đã hoàn thành</h5>
                        </div>
                        <div className="text-muted mt-4">
                            <h4><CountUp end={parseInt(countOrderSuccess)} duration={2} /></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                    <i className="fas fa-cart-arrow-down"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0 ml-2">Đơn hàng nháp</h5>
                        </div>
                        <div className="text-muted mt-4">
                            <h4><CountUp end={parseInt(countOrderDraft)} duration={2} /></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3 ">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                    <i className="fab fa-shopify"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0 ml-2">Sản phẩm</h5>
                        </div>
                        <div className="text-muted mt-4">
                            <h4><CountUp end={parseInt(countProduct)} duration={2} /></h4>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3 ">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                    <i className="fas fa-hand-holding-medical"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0 ml-2">Đơn thuốc</h5>
                        </div>
                        <div className="text-muted mt-4">
                            <h4><CountUp end={parseInt(countPrescription)} duration={2} /></h4>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3 ">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                    <i className="fas fa-newspaper"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0 ml-2">Tin tức</h5>
                        </div>
                        <div className="text-muted mt-4">
                            <h4><CountUp end={parseInt(countNews)} duration={2} /></h4>

                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3 ">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                    <i className="fas fa-user-cog"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0 ml-2">Người dùng</h5>
                        </div>
                        <div className="text-muted mt-4">
                            <h4><CountUp end={parseInt(countUser)} duration={2} /></h4>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="category-manager-wapper">
        <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th>STT</th>
                    <th>Tên thuốc</th>
                    <th>Số lượng bán</th>
                </tr>
                </thead>
                <tbody row="10">
                {productSell && productSell.length ? productSell.map((product ,key) =>
                    <tr key={key}>
                        <>
                        <td style={{width:'10%'}}>{key + 1}</td>
                        <td style={{width: "60%"}}>
                            <Link to={{pathname: `${process.env.REACT_APP_URL}san-pham/${product.slug}`}} target="_blank">
                            {product.name}
                            </Link>
                        </td>
                        <td>{product.product_sell}</td>
                        </>
                    </tr>
                ) : ''}
                </tbody>
            </table>
            </div>
        </div>

        <div className="operatelog">
                
        </div>
    </div>
    )
}

export default Dashboard
