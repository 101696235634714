import React, {useEffect, useRef, useState} from 'react';
import $ from 'jquery';
import Icon from '../../assets/imageOfCitimed/website_1630895116-icon.png';
import Logo from '../../assets/imageOfCitimed/logo_white.png';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import { logoutSuccess, setNewLogo } from '../../actions/Auth';

function Header() {
    // btn for logout
    const handleForLogout = () => {
        const token =  localStorage.removeItem('User-Admin')
        if(!token) {
            window.location.href = '/';
        }
    }
    const [logoAdmin, setLogoAdmin] = useState('');

    const dispatch = useDispatch();

    const stateLogout = useSelector(state => state.loginAuthReducer)
    const changeLogo = useSelector(state => state.loginAuthReducer.logoAdminChange)

    useEffect(() => {
        if(stateLogout.checkLogout === true){
            toast.error('Bạn đã hết phiên đăng nhập !', {
                id : 1
            })
            setTimeout(() => {
                handleForLogout()
            }, 3000);
        }
    }, [stateLogout.checkLogout])
   
    const idleTimerRef = useRef(null)

    // call api for show
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.WEBSITE}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setLogoAdmin(response.data.data.logo_admin)
                    dispatch(setNewLogo(false))
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch, changeLogo])

    useEffect(() => {
        function initLeftMenuCollapse() {
            $('#vertical-menu-btn').on('click', function (event) {
                event.preventDefault();
                $('body').toggleClass('sidebar-enable');
                if ($(window).width() >= 992) {
                    $('body').toggleClass('vertical-collpsed');
                } else {
                    $('body').removeClass('vertical-collpsed');
                }
            });
        }
        initLeftMenuCollapse();
    })
    const [logoImage, setLogoImage] = useState('');

    // call api for show
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.WEBSITE}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setLogoImage(response.data.data.icon)
                }
            }
        })
        .catch(error => {
          
        }) 
        return () => { unmounted = true };
    }, [])
    
    return (
        <header id="page-topbar">
            <Helmet>
                <link rel="icon" href={logoImage} type="image/x-icon" sizes="16x16 32x32"/>
            </Helmet>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <div className="logo">
                            <span className="logo-lg">
                                <Link to="/main/dashboard">
                                    <img src={logoAdmin} style={{width:'100%'}} alt=''/>
                                </Link>
                            </span>
                            <span className="logo-sm">
                                <Link to="/main/dashboard">
                                    <img src={Icon} alt="" height="22" width="auto"/>
                                </Link>
                            </span>
                        </div>
                    </div>
                    <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                            id="vertical-menu-btn">
                        <i className="fa fa-fw fa-bars"></i>
                    </button>
                    <div onClick={handleForLogout} className="btn btn-sm font-size-16 d-flex justify-content-center align-items-center">
                        <i className="fas fa-sign-out-alt"></i>
                    </div>
                    <div className="font-size-16 d-flex justify-content-center align-items-center">
                        <span>{process.env.REACT_APP_NAME}</span>
                    </div>
                </div>
            </div>
            <IdleTimer
                ref={idleTimerRef}
                timeout={(10 * 1000) * 480}
                onIdle={handleForLogout}
            > </IdleTimer>
        </header>
    )
}
export default Header;
