import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ENDPOINT from '../../ultils/EndPoint';
import { Link, useParams } from 'react-router-dom';
import {Pagination} from "antd";
import { formatCurrency } from '../../helpers/function';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function UsermanagerEditAndCreate() {
    // id user
    const {id} = useParams();
    // set const detail user
    const [imgServer, setImgServer] = useState('');
    const [nameUser, setNameUSer] = useState('');
    const [infoUser,  setInfoUser] = useState([]);
    const [phoneUser, setPhoneUser] = useState('');
    const [socialUser, setSocialUser] = useState([]);
    const [accountCreateDate, setAccountCreateDate] = useState('');
    const [addressUser, setAddressUser] = useState('');
    const [prescriptionofUser, setPrescriptionofUser] = useState([]);
    const [listOrdersofUser, setListOrdersofUser] = useState([]);
    const dispatch = useDispatch();
    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(10);

    // set const prescription for paginate
    const [numberPage1,setNumberPage1] = useState()
    const [currentPage1, setcurrentPage1] = useState(1);
    const [customersPerPage1] = useState(10);
    
     // call api for show
     useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.USER}/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setImgServer(response.data.data.avatar)
                    setNameUSer(response.data.data.name)
                    setPhoneUser(response.data.data.phone)
                    setSocialUser(response.data.data.socials)
                    setAccountCreateDate(response.data.data.created_at)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [id, dispatch])

    useEffect(() => {
        if(id){
            let unmounted = false;
            axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/admin/users/${id}/address`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setInfoUser(response.data.data)
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [id, dispatch])

    useEffect(() => {
        if(id){
            let unmounted = false;
            axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/admin/users/${id}/address/default`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setAddressUser(response.data.data)
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [id, dispatch])
    

    // call api for prescription
    useEffect(() => {
        if(id){
            let query = '';
            const data = {
                per_page : customersPerPage1,
                page: currentPage1,
            }
            Object.keys(data).map((key,index) => 
                query += (index === 0 ? '?' : "&") + key + '=' + data[key]
            )
            let unmounted = false;
            axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/admin/prescription/user/${id}` + query, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setPrescriptionofUser(response.data.data.records)
                        setNumberPage1(response.data.data.total_record)
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [id, customersPerPage1, currentPage1, dispatch])

    // call api for orders
    useEffect(() => {
        if(id){
            let query = '';
            const data = {
                per_page : customersPerPage,
                page: currentPage,
                user_id:id
            }
            Object.keys(data).map((key,index) => 
                query += (index === 0 ? '?' : "&") + key + '=' + data[key]
            )
            let unmounted = false;
            axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/admin/orders` + query, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setListOrdersofUser(response.data.data.records)
                        setNumberPage(response.data.data.total_record)
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [id, customersPerPage, currentPage, dispatch])

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
          
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }

    return (
        <div className="pl-4 pr-4 mt-3">
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Chi tiết người dùng</h1>
            </div>
        </div>
        <div className="d-flex  mt-3">
            <img alt="" style={{'width':'200px', 'height':'200px'}} src={imgServer}></img>
            <ul>
                <li>{nameUser}</li>
                <li>{addressUser !== null ? 'Địa chỉ mặc định: ' + (addressUser ? titleCase(addressUser.address) : '')+ ', ' +( addressUser ? titleCase(addressUser.ward_name) : '') + ', ' + (addressUser ? titleCase(addressUser.district_name) : '')+ ', ' +(addressUser ? titleCase(addressUser.city_name) : '') : ''}</li>
                <li>Số điện thoại: {phoneUser}</li>
                {socialUser !== null ?
                    socialUser.map((net, index) => 
                        <li key={index}>Tài khoản xã hội: {net.provider} - {net.provider_id}</li>
                    )
                : ' '}
                <li>Ngày tạo tài khoản: {accountCreateDate.slice(0,10)}</li>
                {/* <li><button type="button" className="btn btn-danger mt-4">Khoá tài khoản</button></li> */}
            </ul>
        </div>
        <div className="category-manager-wapper">
            <h1 style={{'alignItems':'flex-end', 'justify-content':'unset'}} className="customer-title mb-3">Địa chỉ giao hàng</h1>
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Địa chỉ</th>
                            <th>Xã/Phường</th>
                            <th>Quận/Huyện</th>
                            <th>Tỉnh/Thành phố</th>
                        </tr>
                    </thead>
                    <tbody row="10">
                        {
                            infoUser === null 
                            ?  <tr><td>Không có dữ liệu</td></tr>
                            :
                            infoUser.map((item, index) =>
                                <tr key={index}> 
                                    <td style={{width:'20%'}}>{item.id}</td>
                                    <td style={{width:'20%'}}>{item.address}</td>
                                    <td style={{width:'20%'}}>{item.ward_name}</td>
                                    <td style={{width:'20%'}}>{item.district_name}</td>
                                    <td style={{width:'20%'}}>{item.city_name}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div id="list_orders">
                <h1 style={{'alignItems':'flex-start', 'justify-content':'unset'}}  className="customer-title mb-3 w-100">Danh sách đơn hàng</h1>
                <div className="table-responsive">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Mã đơn hàng</th>
                                <th>Ngày mua</th>
                                <th>Tổng tiền hàng</th>
                            </tr>
                        </thead>
                        <tbody row="10">
                            {
                                listOrdersofUser && listOrdersofUser.map((item, index) =>
                                    <tr key={index}>
                                        <td style={{width:'25%'}}>{item.id}</td>
                                        <td style={{width:'25%'}}>
                                            <Link to={`/main/list-orders/${item.id}`}> 
                                                {item.code}
                                            </Link></td>
                                        <td style={{width:'25%'}}>{(item.created_at).replace('T',' ').slice(0, 11)}</td>
                                        <td style={{width:'25%'}}>{formatCurrency(item.real_price)} đ</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    {numberPage <= 10 ? '' : 
                        <Pagination 
                            onChange={(value) => {
                                document.getElementById('list_orders').scrollIntoView({behavior: 'smooth'});
                                setcurrentPage(value)
                            }}
                            total={numberPage}
                            current={currentPage}
                            showSizeChanger= {false}
                        /> 
                    }
                </div>
            </div>
            <div id="prescription" >
                <h1 style={{'alignItems':'flex-start', 'justify-content':'unset'}}  className="customer-title mb-3 w-100">Đơn thuốc tải lên</h1>
                <div className="table-responsive">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Số điện thoại</th>
                                <th>Hình ảnh</th>
                                <th>Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody row="10">
                        {
                            prescriptionofUser && prescriptionofUser.map((item, index) =>
                                <tr key={index}>
                                    <td style={{width:'5%'}}>{item.id}</td>
                                    <td style={{width:'15%'}}>{item.full_name}</td>
                                    <td style={{width:'25%'}}>{item.phone}</td>
                                    <td style={{width:'25%'}}>
                                        <img alt="" src={item.image} style={{width:'200px', height:'200px', objectFit:'fill'}}></img>
                                    </td>
                                    <td style={{width:'25%'}}>{item.note}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                    {numberPage1 <= 10 ? '' : 
                        <Pagination 
                            onChange={(value) => {
                                document.getElementById('prescription').scrollIntoView({behavior: 'smooth'});
                                setcurrentPage1(value)
                            }}
                            total={numberPage1}
                            current={currentPage1}
                            showSizeChanger= {false}
                        /> 
                    }
                </div>
            </div>
        </div>
    </div>
    )
}

export default UsermanagerEditAndCreate
