import React, { useState, useEffect } from 'react';
import './Website.scss';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import ENDPOINT from '../../ultils/EndPoint';
import $, { trim } from 'jquery';
import isEmail from 'validator/lib/isEmail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from 'react-redux';
import {logoutSuccess, setNewLogo} from '../../actions/Auth';

function Website() {
    // set const
    const [nameWebsite, setNameWebsite] = useState('');
    const [adress, setAdress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [logo, setLogo] = useState('');
    const [icon, setIcon] = useState('');
    const [logoAdmin, setLogoAdmin] = useState('')
    const [titileHeader, setTitileHeader] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKey, setMetaKey] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [facebook, setFacebook] = useState('')
    const dispatch = useDispatch();
    const [updateDelete, setUpdateDelete]= useState(false)
    const [logoServer, setLogoServer] = useState('');
    const [iconServer, setIconServer] = useState('');
    const [logoAdminServer, setLogoAdminServer] = useState('')

    // test adress
    const testAdress = /^\s*\d[\d\s]*$/

    // others
    const [disable, setDisable]= useState(false);

    // set msg
    const [validationMsg, setValidationMsg] = useState({});

    // check phone number or not 
    const isPhoneNumber = new RegExp("^[0-9]*$")

    // call api for show
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.WEBSITE}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setNameWebsite(response.data.data.name);
                    setAdress(response.data.data.address)
                    setEmail(response.data.data.email)
                    setPhone(response.data.data.phone)

                    setFacebook(response.data.data.facebook)

                    setLogo(response.data.data.logo)
                    setIcon(response.data.data.icon)
                    setLogoAdmin(response.data.data.logo_admin)

                    setLogoServer(response.data.data.logo)
                    setIconServer(response.data.data.icon)
                    setLogoAdminServer(response.data.data.logo_admin)

                    $('.image_logo').addClass('image_serve')
                    $('.image_icon').addClass('image_serve')
                    $('.image_logo_admin').addClass('image_serve')

                    setTitileHeader(response.data.data.title_header)
                    setMetaTitle(response.data.data.meta_title)
                    setMetaKey(response.data.data.meta_key)
                    setMetaDesc(response.data.data.meta_desc)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch, updateDelete])

    // handle onChange
    const handleNameWebsite = (e) => {
        setNameWebsite((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMessNameWebsite = () => {
        $('.name_website').removeClass('color_danger')
        validationMsg.nameWebsite = ''
    }

    const handleAdress = (e) => {
        setAdress((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMessAdress = () => {
        $('.adress_partner').removeClass('color_danger')
        validationMsg.adress = ''
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleRemoveEmailMess = () => {
        validationMsg.email = ''
        $('.email_website').removeClass('color_danger')
    }
    const handlePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleRemovePhoneMess = () => {
        validationMsg.phone = ''
        $('.phone_website').removeClass('color_danger')
    }
    const handleHeaderTitle = (e) => {
        setTitileHeader((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveHeaderTitleMess = () => {
        validationMsg.titileHeader = ''
        $('.title_header').removeClass('color_danger')
    }
    const handleMetaTitle = (e) => {
        setMetaTitle((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMetaTitleMess = () => {
        validationMsg.metaTitle = ''
        $('.metaTitle').removeClass('color_danger')
    }
    const handleKey = (e) => {
        setMetaKey((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveKeyMess = () => {
        validationMsg.metaKey = ''
        $('.metaKey').removeClass('color_danger')
    }
    const handleMetaDesc = (e) => {
        setMetaDesc((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMetaDescMess = () => {
        validationMsg.metaDesc = ''
        $('.metaDesc').removeClass('color_danger')
    }
    const handleFacebook = (e) => {
        setFacebook(e.target.value)
    }

    // upload file images
    const onFileLogoChange = (e) => {
        setLogo(e.target.files[0])
        validationMsg.logo = ''
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_logo').addClass('image_icon_partner');
            $('.icon_remove_image').addClass('icon_remove_partner');
        }
        reader.readAsDataURL(e.target.files[0])
    }

    // remove upload image
    const handleRemoveUploadLogo = () => {
        $("#imageUpload1").val("");
        setLogo(logoServer)
        setUpdateDelete(true)
        $("#imagePreview").attr("src", logoServer);
        $('.image_logo').removeClass('image_icon_partner');
        $('.image_logo').removeClass('image_serve')
        $('.icon_remove_image').removeClass('icon_remove_partner');
    }

    // upload file images
    const onFileIconChange = (e) => {
        setIcon(e.target.files[0])
        validationMsg.icon = ''
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview2').attr('src', e.target.result);
            $('#imagePreview2').hide();
            $('#imagePreview2').fadeIn(650);
            $('.image_icon').addClass('image_icon_partner1');
            $('.icon_remove_icon').addClass('icon_remove_partner1');
        }
        reader.readAsDataURL(e.target.files[0])
    }

    // remove upload image
    const handleRemoveUploadIcon = () => {
        $("#imageUpload2").val("");
        setIcon(iconServer)
        setUpdateDelete(true)
        $("#imagePreview2").attr("src", iconServer);
        $('.image_icon').removeClass('image_icon_partner1');
        $('.image_icon').removeClass('image_serve')
        $('.icon_remove_icon').removeClass('icon_remove_partner1');
    }

    // upload file images logo admin
    const onFileLogoAdminChange = (e) => {
        setLogoAdmin(e.target.files[0])
        validationMsg.logoAdmin = ''
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview3').attr('src', e.target.result);
            $('#imagePreview3').hide();
            $('#imagePreview3').fadeIn(650);
            $('.image_logo_admin').addClass('image_icon_partner2');
            $('.icon_remove_logo_admin').addClass('icon_remove_partner2');
        }
        reader.readAsDataURL(e.target.files[0])
    }

    // remove upload images logo admin
    const handleRemoveUploadLogoAdmin = () => {
        $("#imageUpload3").val("");
        setLogoAdmin(logoAdminServer)
        setUpdateDelete(true)
        $("#imagePreview3").attr("src", logoAdminServer);
        $('.image_logo_admin').removeClass('image_icon_partner2');
        $('.image_logo_admin').removeClass('image_serve')
        $('.icon_remove_logo_admin').removeClass('icon_remove_partner2');
    }
    
    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(nameWebsite.length > 50 ){
            msg.nameWebsite = 'Tên website không được nhập quá 50 kí tự'
            $('.name_website').addClass('color_danger')
        }else if( nameWebsite.charAt(0) === '' ){
            msg.nameWebsite = 'Vui lòng nhập tên website'
            $('.name_website').addClass('color_danger')
        }else if(nameWebsite.slice(0, 2) === ' ' ){
            msg.nameWebsite = 'Vui lòng nhập tên website'
            $('.name_website').addClass('color_danger')
        }
       
        if(adress.length > 255 ){
            msg.adress = 'Địa chỉ không được nhập quá 255 kí tự'
            $('.adress_partner').addClass('color_danger')
        }else if( adress.charAt(0) === '' ){
            msg.adress = 'Vui lòng nhập địa chỉ'
            $('.adress_partner').addClass('color_danger')
        }else if(adress.slice(0, 2) === ' ' ){
            msg.adress = 'Vui lòng nhập địa chỉ'
            $('.adress_partner').addClass('color_danger')
        }else if(testAdress.test(adress)){
            msg.adress = 'Địa chỉ không chính xác. Vui lòng nhập lại'
            $('.adress_partner').addClass('color_danger')
        }
        
        if(email.length <= 0 ){
            msg.email = 'Vui lòng nhập Email'
            $('.email_website').addClass('color_danger')
        }else if (!isEmail(email)){
            msg.email = 'Vui lòng nhập đúng định dạng Email'
            $('.email_website').addClass('color_danger')
        }

        if(phone.length <= 0) {
            msg.phone = 'Vui lòng nhập số điện thoại'
            $('.phone_website').addClass('color_danger')
        }else if (!isPhoneNumber.test(phone) || phone.length > 15){
            $('.phone_website').addClass('color_danger')
            msg.phone = 'Số điện thoại tối đa 15 số. Vui lòng nhập lại'
        }

        if(titileHeader.length <= 0){
            msg.titileHeader = 'Vui lòng nhập tiêu đề header'
            $('.title_header').addClass('color_danger')
        }else if( titileHeader.charAt(0) === '' ){
            msg.titileHeader = 'Vui lòng nhập tiêu đề header'
            $('.title_header').addClass('color_danger')
        }else if(titileHeader.slice(0, 2) === ' ' ){
            msg.titileHeader = 'Vui lòng nhập tiêu đề header'
            $('.title_header').addClass('color_danger')
        }

        if(metaTitle.length <= 0){
            msg.metaTitle = 'Vui lòng nhập thẻ tiêu đề'
            $('.metaTitle').addClass('color_danger')
        }else if( metaTitle.charAt(0) === '' ){
            msg.metaTitle = 'Vui lòng nhập thẻ tiêu đề'
            $('.metaTitle').addClass('color_danger')
        }else if(metaTitle.slice(0, 2) === ' ' ){
            msg.metaTitle = 'Vui lòng nhập thẻ tiêu đề'
            $('.metaTitle').addClass('color_danger')
        }

        if(metaKey.length <= 0){
            msg.metaKey = 'Vui lòng nhập thẻ từ khoá'
            $('.metaKey').addClass('color_danger')
        }else if( metaKey.charAt(0) === '' ){
            msg.metaKey = 'Vui lòng nhập thẻ từ khoá'
            $('.metaKey').addClass('color_danger')
        }else if(metaKey.slice(0, 2) === ' ' ){
            msg.metaKey = 'Vui lòng nhập thẻ từ khoá'
            $('.metaKey').addClass('color_danger')
        }
       

        if(metaDesc.length <= 0){
            msg.metaDesc = 'Vui lòng nhập thẻ mô tả'
            $('.metaDesc').addClass('color_danger')
        }else if( metaDesc.charAt(0) === '' ){
            msg.metaDesc = 'Vui lòng nhập thẻ mô tả'
            $('.metaDesc').addClass('color_danger')
        }else if(metaDesc.slice(0, 2) === ' ' ){
            msg.metaDesc = 'Vui lòng nhập thẻ mô tả'
            $('.metaDesc').addClass('color_danger')
        }

        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }
    
    // params for update website
    const updateWebsite = new FormData();
    updateWebsite.append('name', trim(nameWebsite));
    updateWebsite.append('address', trim(adress));
    updateWebsite.append('email', email);
    updateWebsite.append('phone', phone);
    if(logo === logoServer){
 
    }else {
        updateWebsite.append('logo', logo);
    }
    if(icon === iconServer){
 
    }else {
        updateWebsite.append('icon', icon);
    }
    if(logoAdmin === logoAdminServer){
 
    }else {
        updateWebsite.append('logo_admin', logoAdmin);
    }
    updateWebsite.append('title_header', trim(titileHeader));
    updateWebsite.append('meta_title', trim(metaTitle));
    updateWebsite.append('meta_key', trim(metaKey));
    updateWebsite.append('meta_desc', trim(metaDesc));
    updateWebsite.append('facebook', trim(facebook));

    // submit when ok
    const handleUpdateInfoWebsite = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.WEBSITE}`, updateWebsite,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
                if(response.status === 200) {
                    toast.success('Cập nhật thông tin website thành công')
                    dispatch(setNewLogo(true))
                    setTimeout(() => {
                        $('.icon_remove_icon').removeClass('icon_remove_partner1');
                        $('.icon_remove_image').removeClass('icon_remove_partner');
                        $('.icon_remove_logo_admin').removeClass('icon_remove_partner2');
                    }, 1000);
                } 
          })
          .catch(error => {
            
          }
        )
    }
 
    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className="operatelog partner">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Quản lý thông tin website</h1>
            </div>
        </div>
        <Form.Label className="col-2 pl-0 pr-0 ">Tên website</Form.Label>
        <div className="input-group">
            <input type="text" className="form-control name_website" value={nameWebsite} onKeyDown={handleRemoveMessNameWebsite} onChange={handleNameWebsite} aria-label="Username" aria-describedby="basic-addon1"/>
        </div>
        <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.nameWebsite}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Địa chỉ</Form.Label>
        <div className="input-group">
            <input type="text" className="form-control adress_partner" value={adress} onKeyDown={handleRemoveMessAdress} onChange={handleAdress} aria-label="Username" aria-describedby="basic-addon1"/>
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.adress}</p> 
        {/* ====================================================================== */}
        <div className="d-flex ">
            <div className="flex-column col-6 pl-0">
                <Form.Label className="col-2 pl-0 pr-0 ">Email</Form.Label>
                <input type="text" onKeyDown={handleRemoveEmailMess} className="form-control email_website" value={email} onChange={handleEmail} style={{'width':'90%', 'borderRadius':'.25rem'}} aria-label="Username" aria-describedby="basic-addon1"/> 
                <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.email}</p> 
            </div>
            {/* ================================== */}
            <div className="flex-column col-6 pr-0">
                <Form.Label style={{'marginLeft':'10%'}} className="col-2 pl-0 pr-0 ">Phone</Form.Label>
                <div className="input-group flex-column align-items-end">
                    <input type="text" onKeyDown={handleRemovePhoneMess} name = "inputForNumber" className="form-control phone_website" value={phone} onChange={handlePhone} style={{'width':'90%'}} aria-label="Username" aria-describedby="basic-addon1"/>
                </div>
                <p style={{'fontSize': '12px', 'marginLeft':'10%'}} className="text-danger msg_name">{validationMsg.phone}</p> 
            </div>
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name"></p> 
        {/* ====================================================================== */}
        <div className='d-flex'>
            <div className="input-group mb-3">
                <button htmlFor="imageUpload1" style={{'height':'39px'}} type="button" className="btn btn-outline-primary">+ Upload Logo<input className="input_uploadImage" onChange={onFileLogoChange} type='file' id="imageUpload1" accept=".png, .jpg, .jpeg , .svg" /></button>
                {logo ?
                    <div>
                        <img className="image_logo" style={{'marginLeft': '30px','marginTop': '20px', 'marginRight':'30px', 'width':'100px', 'height':'100px'}} alt="" id="imagePreview" src={logo}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} onClick={handleRemoveUploadLogo} className="mdi mdi-delete icon_remove_image"></i>
                    </div>
                : ''}
                <p style={{'fontSize': '12px', 'marginLeft':'10%'}} className="text-danger msg_name"></p> 
            </div>
            <div className="input-group mb-3" style={{'marginLeft':'13%'}}>
                <button htmlFor="imageUpload2" style={{'height':'39px'}} type="button" className="btn btn-outline-primary">+ Upload Icon<input className="input_uploadImage" onChange={onFileIconChange} type='file' id="imageUpload2" accept=".png, .jpg, .jpeg , .svg" /></button>
                {icon ?
                    <div>
                        <img className="image_icon" style={{'marginLeft': '30px','marginTop': '20px', 'marginRight':'30px', 'width':'100px', 'height':'100px'}} alt="" id="imagePreview2" src={icon}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} onClick={handleRemoveUploadIcon} className="mdi mdi-delete icon_remove_icon"></i>
                    </div>
                : ''}
                <p style={{'fontSize': '12px', 'marginLeft':'10%'}} className="text-danger msg_name"></p> 
            </div>
            <div className="input-group mb-3" style={{'marginLeft':'13%'}}>
                <button htmlFor="imageUpload3" style={{'height':'39px'}} type="button" className="btn btn-outline-primary">+ Upload Logo Admin<input className="input_uploadImage" onChange={onFileLogoAdminChange} type='file' id="imageUpload3" accept=".png, .jpg, .jpeg, .svg" /></button>
                {logoAdmin ?
                    <div>
                        <img className="image_logo_admin" style={{'marginLeft': '30px','marginTop': '20px' ,'marginRight':'30px', 'width':'100px', 'height':'100px'}} alt="" id="imagePreview3" src={logoAdmin}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} onClick={handleRemoveUploadLogoAdmin} className="mdi mdi-delete icon_remove_logo_admin"></i>
                    </div>
                : ''}
                <p style={{'fontSize': '12px', 'marginLeft':'10%'}} className="text-danger msg_name"></p> 
            </div>
        </div>
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Facebook</Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control" 
                value={facebook} 
                onChange={handleFacebook} 
                aria-label="Username" 
                aria-describedby="basic-addon1"  
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Tiêu đề header</Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control title_header" 
                value={titileHeader} 
                onChange={handleHeaderTitle} 
                aria-label="Username" 
                aria-describedby="basic-addon1"
                onKeyDown={handleRemoveHeaderTitleMess}
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.titileHeader}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Thẻ tiêu đề</Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control metaTitle" 
                value={metaTitle} 
                onChange={handleMetaTitle} 
                aria-label="Username" 
                aria-describedby="basic-addon1"  
                onKeyDown={handleRemoveMetaTitleMess}    
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Thẻ từ khoá</Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control metaKey" 
                value={metaKey} onChange={handleKey} 
                aria-label="Username" 
                aria-describedby="basic-addon1"
                onKeyDown={handleRemoveKeyMess}
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaKey}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Thẻ mô tả</Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control metaDesc" 
                value={metaDesc} 
                onChange={handleMetaDesc} 
                aria-label="Username" 
                aria-describedby="basic-addon1"
                onKeyDown={handleRemoveMetaDescMess}
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaDesc}</p> 
        {/* ====================================================================== */}
        <div >
            <button type="button" disabled={disable} onClick={handleUpdateInfoWebsite} className="btn btn-success mt-5">Cập nhật</button> 
        </div>
    </div>
    )
}

export default Website
