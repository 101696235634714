import React, {useEffect, useState} from 'react'
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import ENDPOINT from "../../ultils/EndPoint";
import {Link} from "react-router-dom";
import axios from "axios";
import $, {trim} from "jquery";
import {useParams} from "react-router";
import {onNumberOnlyChange} from "../../helpers/function";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function ServicesManager() {

    const {id} = useParams();
    const dispatch = useDispatch();
    const [orderNo, setOrderNo] = useState([]);
    const [nameServiceFirst, setNameServiceFirst] = useState([]);
    const [nameServiceLast, setNameServiceLast] = useState([]);
    const [imageURL, setImageURL] = useState('');
    const [iconServer, setIconServer] = useState('');
    const [metaLink, setMetaLink] = useState('');

    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');


    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');


    // others
    const [disable, setDisable]= useState(false);

    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };


    const handleOrderNo = (e) => {
        setOrderNo(e.target.value);
    }
    const handleRemoveOrder = () => {
        setMsg('')
        validationMsg.orderNo = ''
        $('.order_no').removeClass('danger_border');
    }

    const handleNameServicesFirst = (e) => {
        setNameServiceFirst((e.target.value).replace(/\s\s+/g, ' '))
    }
    const handleRemoveNameServicesFirst = () => {
        setMsg('')
        validationMsg.nameServiceFirst = ''
        $('.name_category').removeClass('danger_border');
    }

    const handleNameServicesLast = (e) => {
        setNameServiceLast((e.target.value).replace(/\s\s+/g, ' '))
    }
    const handleRemoveNameServicesLast = () => {
        setMsg('')
        validationMsg.nameServiceLast = ''
        $('.name_categoryed').removeClass('danger_border');
    }
    const handleLink = (e) => {
        setMetaLink(e.target.value)
    }

    const handleRemoveLink = () => {
        setMsg('')
        validationMsg.metaLink = ''
        $('.link_partner').removeClass('danger_border');
    }

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }


    const validateAll = () => {
        const msg = {};

        if(orderNo.length < 0) {
            msg.orderNo = 'Không được để trống ô thứ tự'
            $('.order_no').addClass('danger_border');
        }else if(orderNo.toString() === '') {
            msg.orderNo = 'Không được để trống ô thứ tự'
            $('.order_no').addClass('danger_border');
        }

        if(imageURL === ''){
            msg.imageURL = "Vui lòng tải lên ảnh"
        }


        if(nameServiceFirst.length > 255) {
            msg.nameServiceFirst = 'Thông tin tên tin tức không được quá 255 kí tự'
            $('.name_category').addClass('danger_border');
        } else if(nameServiceFirst.toString() === '' ||  nameServiceFirst.toString() === ' '){
            msg.nameServiceFirst = 'Vui lòng nhập tên dịch vụ'
            $('.name_category').addClass('danger_border');
        }

        if(nameServiceLast.length > 255) {
            msg.nameServiceLast = 'Thông tin tên tin tức không được quá 255 kí tự'
            $('.name_categoryed').addClass('danger_border');
        } else if(nameServiceLast.toString() === '' || nameServiceLast.toString() === ' '){
            msg.nameServiceLast = 'Vui lòng nhập tên dịch vụ'
            $('.name_categoryed').addClass('danger_border');
        }

        if(metaLink.length > 0 && !validURL(metaLink)){
            msg.metaLink = 'Định dạng link  không đúng'
            $('.link_partner').addClass('color_danger')
        }



        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }


    // call api for edit
    useEffect(()=>{
        if(id) {
            axios.get(`${ENDPOINT.SERVICES}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (response) {
                if(response.status === 200) {
                    setOrderNo(response.data.data.order_no);
                    setNameServiceFirst(response.data.data.title_1);
                    setNameServiceLast(response.data.data.title_2);
                    setImageURL(response.data.data.icon);
                    setIconServer(response.data.data.icon);
                    setMetaLink(response.data.data.link);
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    },[id, dispatch]);

    // Edit
    const updateAdminInfo = new FormData();
    updateAdminInfo.append('order_no', orderNo);
    updateAdminInfo.append('title_1', trim(nameServiceFirst));
    updateAdminInfo.append('title_2', trim(nameServiceLast));
    if(iconServer === imageURL){

    }else {
        updateAdminInfo.append('icon', imageURL);
    }
    if(metaLink === '') {

    }else {
        updateAdminInfo.append('link', metaLink);
    }
    const updateService = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.SERVICES}/${id}`, updateAdminInfo,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    //
                }toast.success('Chỉnh sửa dịch vụ thành công !')
                setTimeout(() => {
                    window.location.href = '/main/services'
                }, 2000);
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        $('.name_category').addClass('danger_border');
                    }
                }
            )
    }

    // Create
    const creatService = new FormData();
    creatService.append('order_no', orderNo);
    creatService.append('title_1', trim(nameServiceFirst));
    creatService.append('title_2', trim(nameServiceLast));
    creatService.append('icon', imageURL);
    creatService.append('link', metaLink);

    const createServicesed = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.SERVICES}`, creatService,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm dịch vụ thành công!')
                    setTimeout(() => {
                        window.location.href = '/main/services'
                    }, 2000);
                }
            })
            .catch(error => {
                    toast.error(error.response.data.error)
                }
            )
    }

    const onFileChange = (e) => {
        setImageURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon1');
            $('.icon_remove_image').addClass('icon_remove_image1');
        }
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }else {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const handleRemoveUpload = () => {
        $("#imageUpload").val("");
        setImageURL(iconServer);
        $("#imagePreview").attr("src", iconServer);
        $('.image_icon').removeClass('image_icon1');
        $('.icon_remove_image').removeClass('icon_remove_image1');
    }


    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa dịch vụ</h1> : <h1 className="customer-title mb-3">Thêm mới dịch vụ</h1>}
                </div>
            </div>
                <Form.Label className="col-2 pl-0 pr-0 ">Số thứ tự<span style={{'color':'red'}}>*</span></Form.Label>
                <input type="number" className="form-control order_no" value={orderNo} onChange={handleOrderNo} onKeyPress={onNumberOnlyChange}
                onKeyDown={handleRemoveOrder} />
                <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.orderNo}</p>
                <Form.Label className="col-2 pl-0 pr-0 ">Tên dịch vụ 1<span style={{'color':'red'}}>*</span></Form.Label>
                <input type="text" className="form-control name_category" value={nameServiceFirst} onChange={handleNameServicesFirst} onKeyDown={handleRemoveNameServicesFirst}/>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.nameServiceFirst}</p>
                <Form.Label className="col-2 pl-0 pr-0 ">Tên dịch vụ 2<span style={{'color':'red'}}>*</span></Form.Label>
                <input type="text" className="form-control name_categoryed" value={nameServiceLast} onChange={handleNameServicesLast} onKeyDown={handleRemoveNameServicesLast}/>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.nameServiceLast}</p>
            <div className="input-group mt-3">
                <button htmlFor="imageUpload" type="button" className="btn btn-add">Hình ảnh<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload" accept=".png, .jpg, .jpeg, .svg" /></button>
                {imageURL ?
                    <div className="w-100 mt-2">
                        <div id="close_btn_wapper">
                            <img className="image_icon" style={{'marginRight':'30px','width':'32px', 'height':'32px'}} alt="" id="imagePreview" src={imageURL}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                        </div>
                    </div>
                    : ''}
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.imageURL}</p>
                <Form.Label className="col-2 pl-0 pr-0 mt-3">Link</Form.Label>
                <input type="text" className="form-control link_partner" value={metaLink} onChange={handleLink} onKeyDown={handleRemoveLink}/>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaLink}</p>
            <div>
                <Link to="/main/services"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                {id ? <button type="button"  className="btn btn-success mt-5" disabled={disable} onClick={updateService}>Cập nhật</button> : <button type="button"  className="btn btn-success mt-5" disabled={disable} onClick={createServicesed}>Lưu</button>}
            </div>
        </div>
    )
}
export default ServicesManager
