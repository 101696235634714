import { 
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGO_ADMIN
} from "../constans/ActionType";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    user: null,
    error: null,
    checkLogout:false,
    logoAdminChange:false,
};

export const loginAuthReducer =  (state = initialState, { type, payload }) => {
    switch (type) {
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
    case LOGIN_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isLoggedIn: true,
          user: payload,
        };
    case LOGIN_FAIL:
        return {
            ...state,
            isLoggedIn: false,
            user: null,
            isLoading: false,
            error: payload,
        };
    case LOGO_ADMIN:
      return {...state, logoAdminChange : payload}
    case LOGOUT_SUCCESS:
      return {...state, checkLogout : payload}
    default:
      return state;
    }
  };
  