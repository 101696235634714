import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router';
import $, { trim } from 'jquery';
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function GroupProductDetail() {
    const history = useHistory();
    // set const 
    const {id} = useParams();
    // set const info 
    const [nameStore, setNameStore] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKey, setMetaKey] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [imageServer, setImageServer] = useState('');
    const dispatch = useDispatch();
    // set for validate
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');

    // others
    const [disable, setDisable]= useState(false);


    // handle all change
    const handleMetaTitle = (e) => {
        setMetaTitle((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMetaTitleMess = () => {
        validationMsg.metaTitle = ''
        $('.metaTitle').removeClass('color_danger')
    }
    const handleKey = (e) => {
        setMetaKey((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveKeyMess = () => {
        validationMsg.metaKey = ''
        $('.metaKey').removeClass('color_danger')
    }
    const handleMetaDesc = (e) => {
        setMetaDesc((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMetaDescMess = () => {
        validationMsg.metaDesc = ''
        $('.metaDesc').removeClass('color_danger')
    }

    const handleChangeName = (e) => {
        setNameStore((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMessName = () => {
        validationMsg.nameStore = '';
        setMsg('')
        $('.name_store').removeClass('color_danger')
    }

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(nameStore.length > 50 ){
            msg.nameStore = 'Thông tin Tên nhóm sản phẩm tối đa 50 kí tự'
            $('.name_store').addClass('color_danger')
        }else if( nameStore.charAt(0) === '' ){
            msg.nameStore = 'Vui lòng nhập tên nhóm sản phẩm'
            $('.name_store').addClass('color_danger')
        }else if(nameStore.slice(0, 2) === ' ' ){
            msg.nameStore = 'Vui lòng nhập tên nhóm sản phẩm'
            $('.name_store').addClass('color_danger')
        }

        if(metaTitle.length <= 0){
            msg.metaTitle = 'Vui lòng nhập thẻ tiêu đề'
            $('.metaTitle').addClass('color_danger')
        }else if( metaTitle.charAt(0) === '' ){
            msg.metaTitle = 'Vui lòng nhập thẻ tiêu đề'
            $('.metaTitle').addClass('color_danger')
        }else if(metaTitle.slice(0, 2) === ' ' ){
            msg.metaTitle = 'Vui lòng nhập thẻ tiêu đề'
            $('.metaTitle').addClass('color_danger')
        }

        if(metaKey.length <= 0){
            msg.metaKey = 'Vui lòng nhập thẻ từ khoá'
            $('.metaKey').addClass('color_danger')
        }else if( metaKey.charAt(0) === '' ){
            msg.metaKey = 'Vui lòng nhập thẻ từ khoá'
            $('.metaKey').addClass('color_danger')
        }else if(metaKey.slice(0, 2) === ' ' ){
            msg.metaKey = 'Vui lòng nhập thẻ từ khoá'
            $('.metaKey').addClass('color_danger')
        }
       

        if(metaDesc.length <= 0){
            msg.metaDesc = 'Vui lòng nhập thẻ mô tả'
            $('.metaDesc').addClass('color_danger')
        }else if( metaDesc.charAt(0) === '' ){
            msg.metaDesc = 'Vui lòng nhập thẻ mô tả'
            $('.metaDesc').addClass('color_danger')
        }else if(metaDesc.slice(0, 2) === ' ' ){
            msg.metaDesc = 'Vui lòng nhập thẻ mô tả'
            $('.metaDesc').addClass('color_danger')
        }

        if(imageURL === '') {
            msg.imageURL = 'Trường hình ảnh là bắt buộc'
        }
        

        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // params for create new group product
    const createGroupsParams = new FormData();
    createGroupsParams.append('name', trim(nameStore));
    createGroupsParams.append('meta_title', trim(metaTitle));
    createGroupsParams.append('meta_key', trim(metaKey));
    createGroupsParams.append('meta_desc', trim(metaDesc));
    createGroupsParams.append('cover', imageURL);

    // create new group product
    const handleCreateAdressStore = ()=> {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.GROUPS_EDIT_CREATE}`, createGroupsParams,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Thêm nhóm sản phẩm thành công')
                setTimeout(() => {
                    history.push('/main/products/groups')
                }, 1000);
            } 
          })
          .catch(error => {
            setMsg('Thông tin Tên nhóm sản phẩm đã tồn tại')
            $('.name_store').addClass('color_danger')
          }
        )
    }

    // show for edit
    useEffect(() => {
        if(id) {
            let unmounted = false;
            axios.get(`${ENDPOINT.GROUPS_EDIT_CREATE}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setNameStore(response.data.data.name)
                        setMetaTitle(response.data.data.meta_title)
                        setMetaKey(response.data.data.meta_key)
                        setMetaDesc(response.data.data.meta_desc)
                        setImageURL(response.data.data.cover)
                        setImageServer(response.data.data.cover)
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [id, dispatch])

    // upload file images
    const onFileChange = (e) => {
        setImageURL(e.target.files[0])
        
        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon_partner_1');
            $('.icon_remove_image').addClass('icon_remove_partner');
        }
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }else {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    // remove upload image
    const handleRemoveUpload = () => {
        $("#imageUpload1").val("");
        setImageURL(imageServer)
        $("#imagePreview").attr("src", imageServer);
        $('.image_icon').removeClass('image_icon_partner_1');
        $('.icon_remove_image').removeClass('icon_remove_partner');
    }

    // params for create new group product
    const updateGroupsParams = new FormData();
    updateGroupsParams.append('name', trim(nameStore));
    updateGroupsParams.append('meta_title', trim(metaTitle));
    updateGroupsParams.append('meta_key', trim(metaKey));
    updateGroupsParams.append('meta_desc', trim(metaDesc));

    if(imageServer === imageURL){

    }else {
        updateGroupsParams.append('cover', imageURL);
    }
 

    // update info
    const updateInforStore = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);
    
        return axios.put(`${ENDPOINT.GROUPS_EDIT_CREATE}/${id}`, updateGroupsParams,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Chỉnh sửa nhóm sản phẩm thành công')
                setTimeout(() => {
                    history.push('/main/products/groups')
                }, 1000);
            } 
          })
          .catch(error => {
            setMsg('Thông tin Tên nhóm sản phẩm đã tồn tại')
            $('.name_store').addClass('color_danger')
          }
        )
    }

    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className="operatelog partner">
            <div className="d-flex justify-content-between align-self-center">
                {id ? <h1 className="customer-title mb-3">Chỉnh sửa nhóm sản phẩm</h1> : <h1 className="customer-title mb-3">Thêm nhóm sản phẩm</h1>} 
            </div>
        </div>
       
        <Form.Label className="col-2 pl-0 pr-0 ">Tên nhóm <span style={{'color':'red'}}>*</span></Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control name_partner name_store" 
                value={nameStore} onChange={handleChangeName} 
                onKeyDown={handleRemoveMessName} 
                aria-label="Username" 
                aria-describedby="basic-addon1"
            />
        </div>
        <p style={{'fontSize': '12px', 'marginTop':'0px', 'marginBottom':'0px'}} className="text-danger msg_name">{msg}</p> 
        <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.nameStore}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Thẻ tiêu đề <span style={{'color':'red'}}>*</span></Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control metaTitle" 
                value={metaTitle} 
                onChange={handleMetaTitle} 
                aria-label="Username" 
                aria-describedby="basic-addon1"  
                onKeyDown={handleRemoveMetaTitleMess}    
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Thẻ từ khoá <span style={{'color':'red'}}>*</span></Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control metaKey" 
                value={metaKey} 
                onChange={handleKey} 
                aria-label="Username" 
                aria-describedby="basic-addon1"
                onKeyDown={handleRemoveKeyMess}
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaKey}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Thẻ mô tả <span style={{'color':'red'}}>*</span></Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control metaDesc" 
                value={metaDesc} 
                onChange={handleMetaDesc} 
                aria-label="Username" 
                aria-describedby="basic-addon1"
                onKeyDown={handleRemoveMetaDescMess}
            />
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaDesc}</p> 
        {/* ====================================================================== */}
        <div className="input-group mb-3 partner_image">
            <button htmlFor="imageUpload1" style={{'width':'136px', 'marginBottom':'30px'}} type="button" className="btn btn-add">+ Upload image<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload1" accept=".png, .jpg, .jpeg" /></button>
            {imageURL ?
                <div  id="close_btn_wapper">
                    <img className="image_icon"
                    style={{'marginRight':'30px','width':'136px', 'height':'136px'}} 
                    alt="" id="imagePreview" src={imageURL}>
                    </img>
                    <i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                </div>
            : ''}
        </div>
        <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.imageURL}</p> 
        {/* ====================================================================== */}
        <div >
            <Link to="/main/products/groups"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link> 
            {id ? <button type="button" onClick={updateInforStore} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" onClick={handleCreateAdressStore} disabled={disable} className="btn btn-success mt-5">Lưu</button>}
        </div>
    </div>
    )
}

export default GroupProductDetail
