import React, {useEffect, useState} from 'react'
import ENDPOINT, {listdeal, ReviewApiService} from "../../ultils/EndPoint";
import {convertDateTime, objectToQueryParams} from "../../helpers/function";
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import {Link} from "react-router-dom";
import trim from "validator/es/lib/trim";
import Switch from "react-switch";
import {Button, Modal} from "react-bootstrap";
import $ from "jquery";

function ListReview() {
    // set const
    const [listOrderReview, setListOrderReview] = useState([]);
    const [customersPerPage] = useState(10)
    const [orderCode, setOrderCode] = useState("")
    const [name, setName] = useState("")
    const [star, setStar] = useState("")
    const [reply, setReply] = useState("")
    const [nameProduct, setNameProduct] = useState("")
    const [rate, setRate]= useState('');
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })
    const [show, setShow] = useState(false);

    const [idReviewSelect, setIdReviewSelect] = useState(0)

    const handleShow = () => setShow(true);

    const handleClose = () => setShow(false);

    // get data 
    const getListOrderReview = (query) => {
        ReviewApiService.getList(query).then(r => {
            if(r.status) {
                setListOrderReview(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }

    const handleChangeRate = (e) => {
        setRate(e.target.value)
    }

    const setCurrentPage = (page) => {
        let data = {}
        data.orderCode = orderCode
        data.name = trim(name)
        data.nameProduct = nameProduct
        data.page = page
        data.star = star
        data.is_reply = reply
        data.product_name = nameProduct
        let query = objectToQueryParams(data)
        getListOrderReview(query)
    }

    useEffect(() => {
        const data = {
            per_page: customersPerPage,
            page: dataPaginate.currentPage,
            order_code: orderCode,
            name: trim(name),
            product_name: nameProduct,
        };
        if (star){
            data.star = star
        }
        if (reply){
            data.is_reply = reply
        }
        let query = objectToQueryParams(data)
        getListOrderReview(query)
    }, [orderCode, name, nameProduct, star, reply, dataPaginate.currentPage]);

    const resetFilter = () => {
        setOrderCode("")
        setName("")
        setNameProduct("")
        setStar("")
        setReply("")
        $("#filter_review").val("");
        $("#filter_name_review").val("");
        $("#filter_nameProduct_review").val("");
        $("#filter_product_name").val("");
        $("#admin_reply").val("");
    }

    function getDataFilter(){
        let data = {}
        data.orderCode = orderCode
        data.name = trim(name)
        data.nameProduct = nameProduct
        data.page = dataPaginate["currentPage"]
        data.star = star
        data.is_reply = reply
        data.product_name = nameProduct
        return objectToQueryParams(data)
    }
    function handleToggleActive(idOrder, idProduct){
        ReviewApiService.toggleActiveReview(idOrder, {
            "product_id" : idProduct
        }).then(r => {
            if(r.status){
                let query = getDataFilter()
                getListOrderReview(query)
            }
        })
    }

    function handleReplyReview(idReview){
        setIdReviewSelect(idReview)
        handleShow()
    }

    function handleSubmitReply(){
        let content = $('#admin_review').val()
        if (!content){
            $('.error_admin_review').html('Vui lòng nhập thông tin')
            return
        }
        ReviewApiService.reply(idReviewSelect, {"description" : content}).then(r => {
            if (r.status){
                toast.success('Trả lời bình luận thành công !')
                let query = getDataFilter()
                getListOrderReview(query)
                handleClose()
            }
        })
    }

    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    const optimisedChange = debounce(handleFilterSearchProduct)

    function handleFilterSearchProduct(e){
        setNameProduct(e.target.value)
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Admin trả lời</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea id="admin_review" name="admin_review" placeholder="" rows="4" cols="60" />
                    <br/>
                    <span className="error_admin_review" style={{fontSize: "12px", color : "red"}}></span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleSubmitReply();
                    }}>
                        Trả lời
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Danh sách đánh giá</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block w-100">

                    <div className="d-flex justify-content-between">
                        <Form.Group className="col-2 pl-0">
                            <input id="filter_review" value={orderCode} onChange={(e) => {setOrderCode(trim(e.target.value))}} id="searchCategory" className="form-control" type="search" placeholder="Mã đơn hàng" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                            <span className="bx bx-search-alt"></span>
                        </Form.Group>
                        <Form.Group className="col-2 pl-0">
                            <input id="filter_name_review" onChange={(e) => {setName(e.target.value)}} className="form-control"  type="search" placeholder="Tên người đánh giá" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                            <span className="bx bx-search-alt"></span>
                        </Form.Group>
                        <Form.Group className="col-2 pl-0">
                            <input id="filter_nameProduct_review"  onChange={optimisedChange} className="form-control"  type="search" placeholder="Tên sản phẩm" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                            <span className="bx bx-search-alt"></span>
                        </Form.Group>
                        <Form.Group className="col-2 pl-0">
                            <select onChange={(e) => setReply(e.target.value)} id="admin_reply" className="form-control">
                                <option value="">Lọc Admin</option>
                                <option value="1">Đã trả lời</option>
                                <option value="0">Chưa trả lời</option>
                            </select>
                        </Form.Group>
                        <Form.Group className="col-2 pl-0">
                            <select onChange={(e) => setStar(e.target.value)} id="filter_product_name" className="form-control">
                                <option value="">Chọn đánh giá</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </Form.Group>
                        <Form.Group>
                            <button type='button'  className="btn btn-info btn-reload" onClick={resetFilter}>
                                <i className="fas fa-sync-alt"></i>
                            </button>
                        </Form.Group>
                    </div>
                </form>
            </div>
            <div className="table-reponsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    </thead>
                    <tbody>
                    {listOrderReview && listOrderReview.map((item,index) =>
                            <>
                            <tr style={{backgroundColor:'rgb(125 125 131)', color:'#fff'}} key={index}>
                                <td>{item.user.name} - CODE: {item.order_code}</td>
                                <td>Thời gian</td>
                                <td>Rate</td>
                                <td>Bình luận</td>
                                <td>Trả lời</td>
                                <td>Hành động</td>
                            </tr>
                            {item.products.length ?
                                <>
                                {
                                    item.products.map((product, index) =>
                                        <tr className="ml-5" style={{background: "#ffffff"}} key={index}>
                                            <td style={{ width : "76%"}}>{product.product_name}</td>
                                            <td style={{ width : "70px "}}>{convertDateTime(item.created_at, 'DD/MM/YYYY')}</td>
                                            <td style={{ width : "50px"}}>{product.star}</td>
                                            <td style={{ minWidth : "140px"}}>{product.description}</td>
                                            <td>{
                                                product.child && product.child.length ? product.child.map((reply, key) =>
                                                    <p style={{ 'minWidth': '200px'}} key={key}>{reply.description}</p>
                                                ) : ''
                                            }</td>
                                            <td className="d-flex justify-content-center align-items-center">
                                                <Switch
                                                    onChange={() => {handleToggleActive(item.order_id, product.product_id)}}
                                                    checked={product.visible}
                                                    className="react-switch"
                                                />
                                                <i onClick={() => {handleReplyReview(product.id)}} style={{marginLeft : "30px", fontSize : "18px"}} className="mdi mdi-pencil"></i>
                                            </td>
                                        </tr>
                                    )
                                }
                                </>
                                : ''}
                        </>

                    )}
                    </tbody>
                </table>
            </div>
            {dataPaginate.totalRecord <= customersPerPage ? '' :
                <Pagination
                    onChange={(value) => setCurrentPage(value)}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    showSizeChanger= {false}
                    defaultPageSize={customersPerPage}
                />
            }
        </div>
    )
}
export default ListReview
