import React, { useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import ENDPOINT from "../../ultils/EndPoint";
import axios from 'axios';
import $ from "jquery";
import './ListOrders.scss';
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {getImageMedia, formatCurrency, finalPrice, sumProp} from '../../helpers/function'
import { useHistory, useParams } from 'react-router';
import {Button, Modal} from "react-bootstrap";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function OrderCreate() {
    // set const 
    const [listUsers, setListUsers] = useState([]);
    const animatedComponents = makeAnimated();
    const [listPaymentMethod, setListPaymentMethod] = useState([]);
    const [userId, setUserId]= useState(''); 
    const [userAdressOfUser, setUserAdressOfUser]= useState(''); 
    const history = useHistory();
    const [district_id, setDistrictId] = useState();
    const [cityId, setCityId] = useState();
    const [wardName, setWardName] = useState();
    const [district_name, setDistrict_name] = useState();
    const [city_name, setCity_name] = useState();
    const [nameUser, setNameUser] = useState();
    const [phoneUser, setPhoneUser] = useState();
    const [addressOfUser, setAddressOfUser] = useState('');
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [priceWidthFeeShip, setPriceWidthFeeShip] = useState();
    const [listAdressUser, setListAdressUser] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [note, setNote]= useState('');
    const {id} = useParams();
    const [dataProductForEdit, setDataProductForEdit] = useState([])
    const [paymentStatus, setPaymentStatus]= useState();
    const [nameCoupon, setNameCoupon] = useState('')
    // only discount
    const [discountFromAdmin, setDiscountFromAdmin] = useState(1);
    const [discountMathodFromAdmin, setDiscountMathodFromAdmin] = useState(0);
    const [percent, setPercent] = useState();
    const [discountForPost, setDiscountForPost] = useState(0);
    const [moneyFromAdmin, setMoneyFromAdmin] = useState('')
    const [moneyOfPercentFromAdmin, setMoneyOfPercentFromAdmin] = useState('');
    const [listCouponValue, setListCouponValue] = useState([]);
    const [valueOfCoupon, setValueOfCoupon] = useState('');
    const [getCodeCoupon, setGetCodeCoupon] = useState('')
    const [getValueCoupon, setGetValueCoupon] = useState('')
    const [getIdCoupon, setGetIdCoupon] = useState('')
    const [getUnitCoupon, setGetUnitCoupon] = useState('')
    const [getMin_order_valueCoupon, setGetMin_order_valueCoupon] = useState('')
    const dispatch = useDispatch();
    
    // set const coupon
    const acceptCoupon =  listCouponValue && listCouponValue.filter(x => x.id === parseInt(valueOfCoupon))

    useEffect(() => {
        if(acceptCoupon && acceptCoupon.length !== 0){
            setGetCodeCoupon(acceptCoupon[0].code)
            setGetIdCoupon(acceptCoupon[0].id)
            setGetUnitCoupon(acceptCoupon[0].unit)
            setGetMin_order_valueCoupon(acceptCoupon[0].min_order_value)
            setGetValueCoupon(acceptCoupon[0].value)
        }
    }, [acceptCoupon])

    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        setDiscountFromAdmin(1)
        setDiscountMathodFromAdmin(0)
        setPercent(0)
        setGetValueCoupon()
        setGetMin_order_valueCoupon()
        setGetUnitCoupon()
        setGetIdCoupon()
        setGetCodeCoupon()
    };
    const handleShow = () => setShow(true);

    // get coupon value for user
    useEffect(() => {
        if(discountFromAdmin === 2) {
            let unmounted = false;
            axios.get(`${ENDPOINT.COUPON}/${userId}/coupon-order`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setListCouponValue(response.data.data);
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [discountFromAdmin, userId, dispatch])
    
    // set const detail adress
    const detailAdress =  listAdressUser.filter(x => x.id === parseInt(userAdressOfUser))

    // set const detail name
    const detailNameofUser =  listUsers.filter(x => x.id === parseInt(userId))

    useEffect(() => {
        if(detailNameofUser.length !== 0){
            setNameUser(detailNameofUser[0].Name)
            setPhoneUser(detailNameofUser[0].Phone)
        }
    }, [detailNameofUser])

    useEffect(() => {
        if(detailAdress.length !== 0){
            setWardName(detailAdress[0].ward_name)
            setCity_name(detailAdress[0].city_name)
            setDistrict_name(detailAdress[0].district_name)
            setDistrictId(detailAdress[0].district_id)
            setCityId(detailAdress[0].city_id)
            setAddressOfUser(detailAdress[0].address)
        }
    }, [detailAdress])

    // get data for edit 
    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT.LIST_ORDERS}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if(response.status === 200) {
                    setPaymentStatus(response.data.data.payment_status) 
                    setUserAdressOfUser(response.data.data.address_id)
                    setPaymentMethod(response.data.data.payment_method_id)
                    setDataProductForEdit(response.data.data.products)
                    if(response.data.data.discount_from_admin){
                        setDiscountForPost(response.data.data.discount_from_admin)
                    }else {
                        if(response.data.data.coupon_unit === 0){
                            setDiscountForPost(response.data.data.coupon_price)
                        }else {
                            setDiscountForPost((response.data.data.coupon_price * response.data.data.product_origin_price)/100)
                        }
                    }
                    setNameCoupon(response.data.data.coupon_code)
                    setNote(response.data.data.note)
                    setUserId(response.data.data.user_id)
                    setOpenTax(response.data.data.is_export)
                    setNameCompany(response.data.data.payment_invoice.name_company)
                    setTax(response.data.data.payment_invoice.tax_code)
                    setAdressCompany(response.data.data.payment_invoice.address_company)
                    setEmail(response.data.data.payment_invoice.email_company)
                    setWardName(response.data.data.ward_name)
                    setDistrict_name(response.data.data.district_name)
                    setCity_name(response.data.data.city_name)
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    }, [id, dispatch])


    let option = []
    if (listProducts && listProducts.length > 0) {
        listProducts.forEach(role => {
        let product = {}
        product.value = role.id
        product.label = role.name
        product.image = role.medias
        product.price = role.price 
        product.discount = role.discount 
        product.deal = role.deal
        product.quantity = 1
        product.real_price = finalPrice(role)
        product.total_real_price = product.real_price * product.quantity
        option.push(product)
      })
    }

    // set data for products
    useEffect(() => {
        if(option.length) {
            var res = dataProductForEdit.map(item => {
                var product = option.find(i => i.value === item.id);
                var productItem = listProducts.find(i => i.id === item.id)
                var quantity = item.quantity
                product.quantity = quantity;
                product.real_price = finalPrice(productItem)
                product.total_real_price = product.real_price * product.quantity
                return product
            })
            setSelectedProduct(res)
        }

    }, [dataProductForEdit, id, listProducts])

    // set const for tax export
    const [openTax, setOpenTax] = useState(false);
    const [nameCompany, setNameCompany] = useState('');
    const [tax, setTax] = useState('');
    const [adressCompany, setAdressCompany] = useState('');
    const [email, setEmail] = useState('');

    // set const validator
    const [validationMsg, setValidationMsg] = useState({});

    // others
    const [disable, setDisable]= useState(false);


   // validate before submit
   const validateAll = () => {
    const msg = {};

        if(userId.length === 0){
            msg.userId = 'Vui lòng chọn người mua';
            $('.userId').addClass('color_danger')
        }

        if(selectedProduct.length === 0){
            msg.selectedProduct = 'Vui lòng chọn sản phẩm';
        }

        if(openTax === true){
            if(nameCompany.length === 0){
                msg.nameCompany = 'Vui lòng nhập tên công ty';
            } 
            if(tax.length === 0){
                msg.tax = 'Vui lòng nhập mã số thuế công ty';
            } 
            if(adressCompany.length === 0){
                msg.adressCompany = 'Vui lòng nhập địa chỉ công ty';
            }
            if(email.length === 0){
                msg.email = 'Vui lòng nhập email công ty';
            }
        }
        
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    let productsForPost = [];
    selectedProduct.map(item =>
        productsForPost.push({
            product_id: item.value,
            quantity: item.quantity,
        })
    )

    // create new 
    var payload = {
        is_draf: true,
        is_export: openTax,
        payment_status: paymentStatus === 1 ? true : false,
        user_id: parseInt(userId),
        products: productsForPost,
        note: note,
        address_id: parseInt(userAdressOfUser),
        payment_method_id: parseInt(paymentMethod),
        address: addressOfUser,
        city_name: city_name,
        district_name: district_name,
        ward_name: wardName,
        phone: phoneUser,
        name: nameUser,
        shipping_fee: priceWidthFeeShip,
        name_company: nameCompany,
        email_company: email,
        address_company: adressCompany,
        tax_code: tax,
        discount_from_admin: getIdCoupon ? 0 : discountForPost,
        coupon_id: getIdCoupon,
    };

    const handleCreateOrderAdmin = ()=> {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.LIST_ORDERS}`, payload ,{
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin'),
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Tạo đơn hàng thành công')
                setTimeout(() => {
                    history.replace('/main/list-orders-draft')
                }, 2000);
            } 
          })
          .catch(error => {
            toast.error(error.response.data.error)
          }
        )
    }

        // update orders  
        var payloadUpdate = {
            is_draf:true,
            is_export: openTax,
            payment_status: paymentStatus === 1 ? true : false,
            user_id: parseInt(userId),
            products: productsForPost,
            note: note,
            address_id: parseInt(userAdressOfUser),
            payment_method_id: parseInt(paymentMethod),
            address: addressOfUser,
            city_name: city_name,
            district_name: district_name,
            ward_name: wardName,
            phone: phoneUser,
            name: nameUser,
            shipping_fee: priceWidthFeeShip,
            name_company: nameCompany,
            email_company: email,
            address_company: adressCompany,
            tax_code: tax,
            discount_from_admin: getIdCoupon ? 0 : discountForPost,
            coupon_id: getIdCoupon,
        };
    
        const handleUpdateOrderAdmin = ()=> {
            const isValidate = validateAll()
            if(!isValidate) return
    
            setDisable(true)
            setTimeout(() => {
                setDisable(false)
            }, 2000);
    
            return axios.put(`${ENDPOINT.LIST_ORDERS}/${id}`, payloadUpdate ,{
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('User-Admin'),
                }
               })
              .then(function (response) {
                if(response.status === 200) {
                    toast.success('Cập nhật đơn hàng thành công')
                    setTimeout(() => {
                        history.replace('/main/list-orders-draft')
                    }, 2000);
                } 
              })
              .catch(error => {
                toast.error(error.response.data.error)
              }
            )
        }

    // handle change
    const handleChaneUserId = (e) => {
        setUserId(e.value)
        if(id){
            setDiscountForPost(0)
            setPriceWidthFeeShip(0)
        }
    }

    const removeMessUserId = () => {
        validationMsg.userId = ''
        $('.userId').removeClass('color_danger')
        $("#myDropDown").attr("size", 0)
    }

    const handleCheckboxTax = (e) => {
        if(e.target.checked){
            setOpenTax(true)
        }else{
            setOpenTax(false)
        }
    }

    const handleChangeNote = (e) => {
        setNote(e.target.value)
    }

    const handleAllAdressId = (e) => {
        setUserAdressOfUser(e.target.value)
    }

    const handleCheckBox = (e) => {
        setPaymentStatus(parseInt(e.target.value));
    }

    const handlePayMenthod = (e) => {
        setPaymentMethod(e.target.value)
    }

    const handleChangeSelected = (e) => {
        setSelectedProduct(e);
    }

    const IncreaseQuantity = (id) => {
        setSelectedProduct(product => 
            product.map((item) =>
                item.value === id ? {...item, quantity: item.quantity + 1, total_real_price: item.real_price * (item.quantity + 1)} : item
            )
        )
        if(id){
            setDiscountForPost(0)
        }
    }

    const DecreaseQuantity = (id) => {
        setSelectedProduct(product => 
            product.map((item) =>
                item.value === id ? {...item, quantity: item.quantity - (item.quantity > 1 ? 1 : 0), total_real_price: item.real_price * (item.quantity - (item.quantity > 1 ? 1 : 0))} : item
            )
        )
        if(id){
            setDiscountForPost(0)
        }
    }

    const handleDeleteProduct = (e) => {
        const remainingOptionProducts = selectedProduct.filter((result) => result.value !== e)
        setSelectedProduct(remainingOptionProducts)
        if(id){
            setDiscountForPost(0)
        }
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleChangeNameCompany = (e) => {
        setNameCompany(e.target.value)
    }

    const handleChangeAdressCompany = (e) => {
        setAdressCompany(e.target.value)
    }

    const handleChangeTax = (e) => {
        setTax(e.target.value)
    }

    const handleOpenModalVoucher = () => {
        handleShow(true)
    }

    const handleDiscountFromAdmin = (e) => {
        setDiscountFromAdmin(parseInt(e.target.value));
    }

    const handleDiscountMethodFromAdmin = (e) => {
        setDiscountMathodFromAdmin(parseInt(e.target.value))
    }

    const handlePercentFromAdmin = (e) => {
        setPercent(e.target.value) 
        setMoneyOfPercentFromAdmin(parseInt((sumProp(selectedProduct, 'total_real_price') * (e.target.value))/100))
    }
    
    const handleMoneyFromAdmin = (e) => {
        setMoneyFromAdmin(parseInt(e.target.value))
    }

    const handleCoupon = (e) => {
        setValueOfCoupon(e.target.value)
    }

    // get list products option
    useEffect(() => {
        let unmounted = false;
        axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/client/products-no-paginate`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setListProducts(response.data.data);
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // get price adress
    useEffect(() => {
        if(cityId && district_id ){
            let unmounted = false;
            axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/client/shipping-fee/province/${cityId}/district/${district_id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setPriceWidthFeeShip(response.data.data || []);
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [district_id, cityId, dispatch])

    // get list user option
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.USER}/get-all`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setListUsers(response.data.data)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // get adress user by id
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.USER}/${userId}/address`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setListAdressUser(response.data.data)
                }
            }
        })
        .catch(error => {
            setListAdressUser([])
        }) 
        return () => { unmounted = true };
    }, [userId])

    // get list payment method
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.PAYMENT_METHOD}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setListPaymentMethod(response.data.data)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // only number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress(
            function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else
                {
                    if (event.keyCode < 48 || event.keyCode > 57 )
                    {
                        event.preventDefault();
                    }
                }
            }
        );
    })

    // set option user for react-select
    let optionUser = []
    if (listUsers && listUsers.length > 0) {
        listUsers.forEach(user => {
        let product = {}
        product.value = user.id
        product.label = user.Name + (user.Phone ? ' - ' + user.Phone : '' ) + (user.Email ? ' - ' + user.Email  : '' )
        optionUser.push(product)
      })
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
          
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }
  
    return (
        <div className="brands pl-4 pr-4 mt-3 ">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center ">
                {
                    id 
                    ? <h1 className="customer-title mb-3 ">Chỉnh sửa đơn hàng nháp</h1>
                    : <h1 className="customer-title mb-3 ">Tạo đơn hàng nháp</h1>
                }
                </div>
            </div>
           <div className="row">
                <div className="col-12 d-flex pl-0 pr-0">
                    <div className="col-5 pl-0">
                        <div className="create_order">
                            <h1>Địa chỉ giao hàng</h1>
                            <div className="input-group pl-0">

                                <Form.Label className="col-12 pl-0 pr-0">Tìm kiếm user <span style={{'color':'red'}}>*</span></Form.Label>
                                <Select
                                    className="col-12 pl-0 pr-0 select_addProduct_group"
                                    style={{zIndex:'1000'}}
                                    onChange={handleChaneUserId}
                                    placeholder = {"Tìm kiếm user"}
                                    options={optionUser}
                                    onClick={removeMessUserId}
                                    components={animatedComponents}
                                    value={optionUser.filter(item => item.value === userId)}
                                >
                                </Select>
                            </div>
                            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.userId}</p> 
                            <div className="input-group pl-0 mt-3">
                                <Form.Label className="col-12 pl-0 pr-0">Địa chỉ của user</Form.Label>
                                <select name="" id="selectBrands" onChange={handleAllAdressId} value={userAdressOfUser} className="form-control custom_select custom_selectProduct " > 
                                    <option value="">Chọn địa chỉ của user</option>
                                    {
                                        listAdressUser.map((brand, index) => 
                                            <option value={brand.id} key={index}>
                                                {titleCase(brand.name)} - {brand.phone} - {titleCase(brand.ward_name)}, {titleCase(brand.district_name)}, {titleCase(brand.city_name)}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                            <h1 className="mt-4">Phương thức thanh toán</h1>
                            <div className="input-group pl-0">
                                <select name="" id="selectBrands" value={paymentMethod} onChange={handlePayMenthod} className="form-control custom_select custom_selectProduct" > 
                                    <option value="">Phương thức thanh toán</option>
                                    {listPaymentMethod.map((brand, index) => <option key={index} value={brand.id}>{brand.name}</option>)}
                                </select>
                            </div>
                            <div className="input-group w-100 mt-3">
                                <Form.Label className="col-12 pl-0 pr-0 mt-2">Ghi chú </Form.Label>
                                <textarea style={{ 'borderRadius': '0.25rem'}} value={note} className="form-control title_des" onChange={handleChangeNote} aria-label="Username" aria-describedby="basic-addon1"></textarea>
                            </div>
                            <h1 className="mt-4">Xác nhận thanh toán</h1>
                            <div onChange={handleCheckBox} className="form-group title_address">
                                <div className="d-flex">
                                    <input value="1" defaultChecked={paymentStatus}  type="radio" name="payment" />
                                    <label className="mb-0 ml-2" htmlFor="checkbox">Đã thanh toán</label>
                                </div>
                                <div  className="d-flex">
                                    <input value="2" defaultChecked={!paymentStatus}  type="radio" name="payment"  />
                                    <label className="mb-0 ml-2" htmlFor="checkbox">Thanh toán sau</label>
                                </div>
                            </div>
                            <h1 className="mt-4">Hoá đơn mua hàng</h1>
                            <div className="form-group title_address">
                                <div className="d-flex">
                                    <input onClick={handleCheckboxTax} type="checkbox" checked={openTax} name="payment" />
                                    <label className="mb-0 ml-2" htmlFor="checkbox">Yêu cầu xuất hoá đơn</label>
                                </div>
                                {
                                    openTax === false
                                    ? ''
                                    : 
                                    <div>
                                        <div className="tax_export mt-3">
                                            <p className="mt-1">THÔNG TIN HÓA ĐƠN:</p>
                                            <ul className="pl-0 d-flex justify-content-between">
                                                <li>Tên công ty <span style={{'color':'red'}}>*</span></li>
                                                <div className="col-8 pr-0"><input type="text" value={nameCompany} className="form-control " onChange={handleChangeNameCompany} placeholder="Nhập tên công ty"/><span className="error_name_company"></span></div>
                                            </ul>
                                        <p style={{'fontSize': '12px', 'marginTop':'0px', marginLeft:'38%'}} className="text-danger ">{validationMsg.nameCompany}</p> 

                                            <ul className="pl-0 d-flex justify-content-between">
                                                <li>Mã số thuế <span style={{'color':'red'}}>*</span></li>
                                                <div className="col-8 pr-0"><input type="text" value={tax} className="form-control " onChange={handleChangeTax} placeholder="Nhập mã số thuế"/><span className="error_name_company"></span></div>
                                            </ul>
                                        <p style={{'fontSize': '12px', 'marginTop':'0px', marginLeft:'38%'}} className="text-danger ">{validationMsg.tax}</p> 

                                            <ul className="pl-0 d-flex justify-content-between">
                                                <li>Địa chỉ công ty <span style={{'color':'red'}}>*</span></li>
                                                <div className="col-8 pr-0"><input type="text" className="form-control " value={adressCompany} onChange={handleChangeAdressCompany} placeholder="Nhập địa chỉ công ty"/><span className="error_name_company"></span></div>
                                            </ul>
                                        <p style={{'fontSize': '12px', 'marginTop':'0px', marginLeft:'38%'}} className="text-danger ">{validationMsg.adressCompany}</p> 

                                            <ul className="pl-0 d-flex justify-content-between">
                                                <li>Email <span style={{'color':'red'}}>*</span></li>
                                                <div className="col-8 pr-0"><input type="text" className="form-control " value={email} onChange={handleChangeEmail} placeholder="Nhập Email"/><span className="error_name_company"></span></div>
                                            </ul>
                                        <p style={{'fontSize': '12px', 'marginTop':'0px', marginLeft:'38%'}} className="text-danger ">{validationMsg.email}</p> 

                                            <div className="note_bill">
                                                <p>Lưu ý:</p>
                                                <ul>
                                                    <li>Lưu ý: Hóa đơn cho sản phẩm của các nhà cung cấp khác {process.env.REACT_APP_NAME} Trading sẽ được xuất trong 14 ngày kể từ thời điểm quý khách nhận hàng.</li>
                                                    <li>Với sản phẩm thuộc nhà cung cấp {process.env.REACT_APP_NAME} Trading, nếu quý khách không điền thông tin hóa đơn, {process.env.REACT_APP_NAME} sẽ xuất hóa đơn điện tử theo thông tin mua hàng.</li>
                                                    <li>Các mặt hàng sau đây trong đơn hàng của bạn không được hỗ trợ xuất hoá đơn</li><li>{process.env.REACT_APP_NAME} từ chối xử lý các yêu cầu phát sinh trong việc kê khai thuế đối với những hóa đơn từ 20 triệu đồng trở lên thanh toán bằng tiền mặt</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-7">
                        <div className="create_order">
                            <h1>Chi tiết đơn hàng</h1>
                            <div className="input-group pl-0 pr-0">
                                <Select
                                    className="col-12 pl-0 pr-0 select_addProduct_group"
                                    style={{zIndex:'1000'}}
                                    isMulti
                                    onChange={handleChangeSelected}
                                    placeholder = {"Sản phẩm"}
                                    options={option}
                                    components={animatedComponents}
                                    closeMenuOnSelect={false}
                                    value={selectedProduct}
                                    controlShouldRenderValue={false}
                                >
                                </Select>
                                <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.selectedProduct}</p> 
                            </div>
                            <div className="mt-3">
                                {
                                    selectedProduct && selectedProduct.map((select, index) => 
                                        <ul key={index} className="d-flex pl-0 justify-content-between">
                                            <li style={{'width':'50px', 'height':'100%'}}>
                                                {
                                                    <img style={{'width':'100%', 'height':'100%', 'objectFit':'fill'}} src={getImageMedia(select.image)} alt=""/>
                                                }
                                            </li>
                                            <ul>
                                                <li style={{fontSize:'10px', marginTop:'10px', width:'200px'}}>{select.label}</li>
                                                <ul className="d-flex pl-0 justify-content-between">
                                                    <li style={{fontSize:'10px', marginTop:'10px'}}>
                                                        {formatCurrency(select.real_price)}
                                                    </li>

                                                    {
                                                        select.real_price < select.price 
                                                        ? 
                                                            <li style={{fontSize:'10px', marginTop:'10px', textDecoration:'line-through'}}>{formatCurrency(select.price)}</li>
                                                        : ''
                                                    }
                                                </ul>
                                            </ul>
                                            <li className="ml-3 mt-3" style={{borderRadius:'8px'}}>
                                                <button style={{background:'none', border:'none'}} type="button"  onClick={() => DecreaseQuantity(select.value)} className="btn-plus">-</button>
                                                {select.quantity}
                                                <button style={{background:'none', border:'none'}} type="button"  onClick={() => IncreaseQuantity(select.value)} className="btn-minus">+</button>
                                            </li>
                                            <li style={{width:'80px'}} className="mt-3 ml-3">{formatCurrency(select.total_real_price)}</li>
                                            <li style={{marginTop:'12px', cursor:'pointer'}} className="ml-3"><i onClick={() => handleDeleteProduct(select.value)} className="mdi mdi-delete font-size-18"></i></li>
                                        </ul>
                                    )
                                }
                            </div>
                            <div className="tax_export mt-3">
                                <ul className="pl-0 d-flex justify-content-between">
                                    <li>Tạm tính</li>
                                    <li>{formatCurrency(sumProp(selectedProduct, 'total_real_price'))}</li>
                                </ul>
                                {/* <ul className="pl-0 d-flex justify-content-between">
                                    <li>Giảm giá sản phẩm</li>
                                    <li>0</li>
                                </ul> */}
                                {
                                    selectedProduct.length !== 0
                                    ?
                                    <ul className="pl-0 d-flex justify-content-between">
                                        <li style={{color:'#0089ff', cursor:'pointer'}} onClick={handleOpenModalVoucher}>Voucher đơn hàng</li>
                                        <li>
                                            {
                                                formatCurrency(discountForPost) 
                                            }
                                            {/* {getCodeCoupon ? ' - ( ' + getCodeCoupon + ' )' : ''} */}
                                        </li>
                                    </ul>
                                    : ''
                                }
                                <ul className="pl-0 d-flex justify-content-between">
                                    <li>Phí vận chuyển</li>
                                    <li>
                                        { (() => {
                                            if (userAdressOfUser.length === 0 || userId.length === 0) {
                                                return 0
                                            } else{
                                                return (
                                                    priceWidthFeeShip ? formatCurrency(priceWidthFeeShip) : 0
                                                )
                                            } 
                                        })()}
                                    </li>
                                </ul>
                                <ul className="pl-0 d-flex justify-content-between">
                                    <li style={{fontWeight:'bold'}}>Giá trị đơn hàng</li>
                                    <li>
                                    {
                                        selectedProduct.length === 0
                                        ? 0
                                        :
                                            (() => {
                                                if (userAdressOfUser.length === 0 || userId.length === 0) {
                                                    return formatCurrency(sumProp(selectedProduct, 'total_real_price') - (discountForPost ? discountForPost : 0 ))
                                                }else{
                                                    return (
                                                        formatCurrency(sumProp(selectedProduct, 'total_real_price') + (priceWidthFeeShip ? priceWidthFeeShip : 0 ) - (discountForPost ? discountForPost : 0 ))
                                                    )
                                                } 
                                            })()
                                    }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                <div>
                <Link to="/main/list-orders-draft"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                {
                    id 
                    ? <button type="button" disabled={disable} onClick={handleUpdateOrderAdmin} className="btn btn-success mt-5">Cập nhật đơn hàng nháp</button>
                    : <button type="button" disabled={disable} onClick={handleCreateOrderAdmin} className="btn btn-success mt-5">Lưu đơn hàng nháp</button>
                }
                
            </div>
           </div>
           <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Giảm giá đơn hàng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <select name="" id="selectBrands" onChange={handleDiscountFromAdmin} className="form-control custom_select custom_selectProduct " > 
                            <option value="1">Admin giảm giá</option>
                            {
                                userId 
                                ? <option value="2">Sử dụng coupon</option>
                                : ''
                            } 
                        </select>
                    </div>
                    {
                        discountFromAdmin === 1 
                        ? 
                            <div>
                                <div className="mt-3">
                                    <select name="" id="selectBrands" onChange={handleDiscountMethodFromAdmin} className="form-control custom_select custom_selectProduct " > 
                                        <option value="0">Tiền</option>
                                        <option value="1">%</option>
                                    </select>
                                </div>
                                {
                                    discountMathodFromAdmin === 0 
                                    ? 
                                        <div className="input-group mt-3">
                                            <input 
                                                type="text" 
                                                className="form-control metaTitle" 
                                                aria-label="Username" 
                                                aria-describedby="basic-addon1"
                                                name="inputForNumber"
                                                onChange={handleMoneyFromAdmin}   
                                            />
                                        </div>
                                    : 
                                    <div>
                                        <Form.Label className="col-12 pl-0 pr-0 mb-0 mt-2">Phần trăm phải nhỏ hơn 99 và lớn hơn 1 <span style={{'color':'red'}}>*</span></Form.Label>
                                        <div className="input-group mt-2">
                                            <input 
                                                type="text" 
                                                onChange={handlePercentFromAdmin}
                                                className="form-control metaTitle percentDiscount" 
                                                aria-label="Username" 
                                                aria-describedby="basic-addon1" 
                                                name="inputForNumber" 
                                            />
                                            <input 
                                                type="text" 
                                                className="form-control metaTitle" 
                                                aria-label="Username" 
                                                aria-describedby="basic-addon1"  
                                                name="inputForNumber" 
                                                disabled
                                                value={formatCurrency((sumProp(selectedProduct, 'total_real_price') * percent)/100)}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        : 
                            <div className="mt-3">
                                <select name="" id="selectBrands" onChange={handleCoupon} className="form-control custom_select custom_selectProduct" > 
                                    <option value=""></option>
                                    {listCouponValue && listCouponValue.map((item, index) =>
                                        parseInt(item.status) === 2
                                        ? <option value={item.id} key={index}>{item.code}</option>
                                        : ''
                                    )}
                                </select>
                            </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Đóng 
                    </Button>
                    <Button variant="primary" onClick={() => {
                        if(discountFromAdmin === 1 ){
                            if(discountMathodFromAdmin === 0){
                                if(moneyFromAdmin.length === 0){
                                    alert('Phải nhập số tiền !')
                                }else{
                                    if(moneyFromAdmin <= sumProp(selectedProduct, 'total_real_price')) {
                                        setDiscountForPost(moneyFromAdmin)
                                    }else {
                                        alert('Số tiền giảm giá phải nhỏ hơn giá tiền tạm tính !')
                                    }
                                }
                            } 
                            if(discountMathodFromAdmin === 1){
                                if(moneyOfPercentFromAdmin.length === 0){
                                    alert('Phải nhập số % !')
                                }else {
                                    if(moneyOfPercentFromAdmin <= sumProp(selectedProduct, 'total_real_price')){
                                        setDiscountForPost(moneyOfPercentFromAdmin)
                                    }else {
                                        alert('Số tiền giảm giá phải nhỏ hơn giá tiền tạm tính !')
                                    }
                                }
                            }
                            
                        }else {
                            if(sumProp(selectedProduct, 'total_real_price') < getMin_order_valueCoupon ){
                                alert(`Bạn không đủ điều kiện để sử dụng COUPON này !. Vì đơn hàng tối thiểu là ${formatCurrency(getMin_order_valueCoupon)} đđ`)
                            }else {
                                if(getUnitCoupon === 0){
                                    setDiscountForPost(getValueCoupon)
                                }else {
                                    setDiscountForPost(((sumProp(selectedProduct, 'total_real_price')) * getValueCoupon)/100)
                                }
                            }
                        }
                        handleClose();
                    }}>
                        Áp dụng
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OrderCreate;


