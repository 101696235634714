import React, {useEffect, useState} from 'react';
import ENDPOINT, {CityApiService, ShippingFeeApiService} from '../../ultils/EndPoint';
import axios from "axios";
import './shipping.scss';
import { Link } from 'react-router-dom'
import {Pagination} from "antd";
import {Button, Modal} from "react-bootstrap";
import {trim} from "jquery";
import {toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import {formatCurrency, objectToQueryParams, onNumberOnlyChange, pluck} from "../../helpers/function";
import CurrencyInput from "react-currency-input-field";


function Shippings() {
    const [listShippingFeeCity, setListShippingFeeCity] = useState([]);
    const [listCity, setListCity] = useState([]);
    const [listCityCanAdd, setListCityCanAdd] = useState([]);
    const [CityId, setCityId] = useState(0);
    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');
    const [prices, setPrice] = useState('');
    const [customersPerPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [handleSelect, setHandleSelect] = useState('');
    const [show, setShow] = useState(false);
    const [showed, setShowed] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClosed = () => setShowed(false);
    const handleShowed = () => setShowed(true);

    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    const handleSelected = (event) => {
        setHandleSelect(event.target.value);
        setcurrentPage(1);
    }


    function handlePrice(value){
        setPrice(value.target.value);
    }

    const selectCIty = (e) => {
        setCityId(e.target.value);
    }

    useEffect(async () => {

        await ShippingFeeApiService.getShippingCityCreated().then(r => {
            if (r.status){
                let arrayCreated = r.data
                CityApiService.getListCity().then(res => {
                    setListCity(res.data)

                    if (res.data.length) {
                        let data = []
                        res.data.forEach(item => {
                            if (!arrayCreated.includes(item.id)){
                                data.push(item)
                            }
                        })

                        setListCityCanAdd(data)
                        setCityId(data[0].id);
                    }
                })
            }
        })


    }, []);


    const getShippingCity = (query) => {
        ShippingFeeApiService.getShippingCity(query).then(r => {
            if (r.status){
                if (!r.data.records.length){
                    const data = {
                        per_page: customersPerPage,
                        page: currentPage - 1,
                        province_id: handleSelect,
                    };
                    let query = objectToQueryParams(data)
                    getShippingCity(query)
                    return
                }
                setListShippingFeeCity(r.data.records)

                setTotal(r.data.total_record)
            }
        })
    }


    useEffect(() => {
        const data = {
            per_page: customersPerPage,
            page: currentPage,
            province_id: handleSelect,
        };

        let query = objectToQueryParams(data)

        getShippingCity(query)

    }, [currentPage, customersPerPage, handleSelect])

    const createNewsBtn = () => {
           let priceFormat = prices.replaceAll(",", "")
           if (priceFormat){
               priceFormat = parseInt(priceFormat)
           }

        const createAddress = new FormData();
        createAddress.append('price', priceFormat);
        createAddress.append('province_id', CityId);
        ShippingFeeApiService.createShippingCity(createAddress).then(r => {
            if (r.status){
                toast.success('Thêm phí vận chuyển tỉnh thành thành công !')
                let query = objectToQueryParams({per_page : customersPerPage, page : currentPage})
                setShow(false)
                getShippingCity(query)
                let dataRemoved = listCityCanAdd.filter((el) => {
                    return el.id !== CityId;
                });

                setListCityCanAdd(dataRemoved)
                setCityId(dataRemoved[0].id)
                setPrice('')
            }else{
                $('.error_ship').html("")
                for (const [key, value] of Object.entries(r.errors)) {
                    $('.error_'+ key).html(value)
                }
            }
        })
    }

    const handleDelete = (id) => {
        ShippingFeeApiService.deleteShippingCity(id).then(r => {
            if (r.status){
                toast.success('Xóa phí vận chuyển thành công !')
                let query = objectToQueryParams({per_page : customersPerPage, page : currentPage})
                setShowed(false)
                getShippingCity(query)
            }
        })
    }


        // only type number
        useEffect(() => {
            $('input[name="input-name"]').keypress(
                function(event)
                {
                    if (event.keyCode === 46 || event.keyCode === 8)
                    {
    
                    }
                    else
                    {
                        if (event.keyCode < 48 || event.keyCode > 57 )
                        {
                            event.preventDefault();
                        }
                    }
                }
            );
        })


    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Cấu hình phí vận chuyển</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between filterr">
                <select name="" id="" className="form-control" onChange={handleSelected}>
                    <option value="">Chọn khu vực vận chuyển</option>
                    {listCity.length && listCity.map(function (city,i){
                            return (
                                <>
                                    <option key={i} value={city.id}>{city.name}</option>
                                </>
                            )
                        })
                        }
                </select>
                <button type="button" className="btn btn btn-add" onClick={() => {handleShow()}} >Thêm khu vực vận chuyển</button>
            </div>
            <ul className="list_shipping_manager pl-0">
                {listShippingFeeCity.length  ? listShippingFeeCity.map((City, index) =>
                    <li key={index} className="d-flex justify-content-between" key={City.province_id}>
                        <div className="grid">
                            <span>{City.province_name}</span>
                            <span>Phí vận chuyển: {formatCurrency(City.price)} đ</span>
                        </div>
                        <div className="d-flex">
                            {City.province_id !== 0 &&
                                <p style={{color:'#1890ff', cursor:'pointer'}} className="mr-2"
                                    onClick={() => {handleShowed();  setIdUserCurr(City.id); setNameUserCurr(City.province_name)}}>Xóa
                                </p>
                            }
                            <Link to={`/main/shippings-manager/${City.province_id}`}>Sửa</Link>
                        </div>
                    </li>
                ) : 'Không có bản ghi nào !'}

            </ul>
            {total <= 10 ? '' :
                <Pagination
                    onChange={(value) => setcurrentPage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                />
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Thêm khu vực vận chuyển</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select name="" className="form-control" id="0" onChange={selectCIty}>
                        {listCityCanAdd.map((city, key) =>
                            <option key={key} value={city.id}>{city.name}</option>
                        )}
                    </select>
                    <span style={{color: "red", fontSize:"12px"}} className="error_ship error_province_id"></span>
                    <div className="form-group mt-2">
                        <label for="">Phí vận chuyển</label>

                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            placeholder="VNĐ"
                            defaultValue={""}
                            allowDecimals={false}
                            decimalsLimit={10}
                            className={`form-control`}
                            onChange={(value, name) => handlePrice(value, name)}
                        />

                        {/*<input type="text" className="form-control" value={prices} onKeyPress={onNumberOnlyChange} onChange={handlePrice} placeholder="VNĐ"/>*/}
                        <span style={{color: "red", fontSize:"12px"}} className="error_ship error_price"></span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button"  className="btn btn-success mt-5" onClick={createNewsBtn}>Lưu</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showed} onHide={handleClosed}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá khu vực vận chuyển</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá khu vực vận chuyển <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosed}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClosed();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Shippings
