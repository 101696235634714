import React, {useEffect, useState} from 'react'
import ENDPOINT, {listdeal} from "../../ultils/EndPoint";
import {objectToQueryParams} from "../../helpers/function";
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import {Link} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import $ from 'jquery'

function ListDeal() {
    // set const
    const [listDeal, setListDeal] = useState([]);
    const [total, setTotal] = useState(0);
    const [customersPerPage] = useState(20);
    const [currentPage, setcurrentPage] = useState(1);
    const [startDate, setStartDate]= useState('');
    const [endDate, setEndDate]= useState('');
    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');
    const [keyword, setKeyWord] = useState('');
    const [updateStatusDeal, setUpdateStatusDeal] = useState(false)

    // set modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // set modal update
    const [showUpdate, setShowUpdate] = useState(false);
    const handleCloseUpdate = (id) => {
        setShowUpdate(false)
        $(`#${id}`).prop('checked', false)
    };
    const handleShowUpdate = () => setShowUpdate(true);

    // remove all whitespace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // set token admin
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // get data 
    const getListDeal = (query) => {
        listdeal.getListDeal(query).then(r => {
            if(r.status) {
                setListDeal(r.data.records)
                setTotal(r.data.total_record)
            }
        })
    }

    useEffect(() => {
        const data = {
            per_page: customersPerPage,
            page: currentPage,
            keyword: keyword,
            start_date: startDate,
            end_date: endDate,
        };
        let query = objectToQueryParams(data)
        getListDeal(query)
        setUpdateStatusDeal(false)
    }, [customersPerPage,currentPage, keyword, startDate, endDate, updateStatusDeal]);

    // delete 
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.DEALLIST}/${id}`, config)
            .then(function (response) {
                toast.success('Xóa deal thành công !')
                const remainList = listDeal.filter(x => x.id !== id)
                setListDeal(remainList)
            })
            .catch(error => {
                alert(error.response.data.error)
                }
            )
    }

    // handle change
    const handleChangeFromDate = (e) => {
        setStartDate(e.target.value)
    }

    const handleChangeToDate = (e) => {
        setEndDate(e.target.value)
    }

    // Handle search debounce
    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    
    const handleInputSearch = (e) => {
        setKeyWord(removeExtraSpace(e.target.value));
    }
    
    // search debounce
    const optimisedChange = debounce(handleInputSearch)

    // prevent enter when search
    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
            e.preventDefault();
            return false;
        }
    });

    // set date curr
    let today = new Date();

    
    let current_datetime = new Date()
    let formatted_date = current_datetime.getFullYear() + "-" 
    + (current_datetime.getMonth() + 1) + "-"
    + (current_datetime.getDate() < 10 ? '0'+ current_datetime.getDate() : current_datetime.getDate()) + " " 
    + (current_datetime.getHours() < 10 ? '0'+ current_datetime.getHours() : current_datetime.getHours()) + ":" 
    + (current_datetime.getMinutes() < 10 ? '0'+ current_datetime.getMinutes() : current_datetime.getMinutes()) + ":" 
    + (current_datetime.getSeconds() < 10 ? '0' + current_datetime.getSeconds() : current_datetime.getSeconds())

    // stop deal 
    const handleStopDeal = (id) => {
        axios.put(`${ENDPOINT.DEALLIST}/update-status/${id}`, config)
            .then(function (response) {
                toast.success('Dừng deal thành công !')
                setUpdateStatusDeal(true)
            })
        .catch(error => {
                alert(error.response.data.error)
            }
        )
    }

    const handleChangeUpdateSatatus = (e) => {
        if(e.target.checked){
            handleShowUpdate(true)
        }
    }
 
    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Danh sách Deal</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block w-100">
                    <div className="d-flex justify-content-between">
                        <Form.Group className="mr-4 w-50">
                            <input   id="searchCategory" className="form-control" onChange={optimisedChange} type="search" placeholder="Tìm kiếm deal" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                            <span className="bx bx-search-alt"></span>
                        </Form.Group>
                        <Link to ="/main/deal/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
                    </div>
                    <div className="d-flex mt-2">
                        <Form.Group className="d-flex mr-5">
                            <Form.Label  className="align-items-center mb-0 pl-0 pr-0 col-3 align-self-center">
                                Từ ngày
                            </Form.Label>
                            <Form.Control onChange={handleChangeFromDate} type="date" id="start_date" ></Form.Control>
                        </Form.Group>
                        <div className="grid">
                            <Form.Group className="d-flex">
                                <Form.Label  className=" align-items-center mb-0 pl-0 pr-0  col-3 align-self-center">
                                    Đến ngày
                                </Form.Label>
                                <Form.Control onChange={handleChangeToDate} type="date" id="end_date" ></Form.Control>

                            </Form.Group>
                        </div>

                    </div>
                </form>
            </div>
            <div className="table-reponsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>ID</th>
                    <th>Tên Deal</th>
                    <th>Trạng thái Deal</th>
                    <th>Thời gian bắt đầu</th>
                    <th>Thời gian kết thúc</th>
                    <th>Dừng deal</th>
                    <th>Hành động</th>
                    </thead>
                    <tbody>
                    {listDeal && listDeal.map((item,index) =>
                        <tr key={index}>
                            <td style={{ "width" : "10%"}}>{item.id}</td>
                            <td style={{ "width" : "20%"}}>{item.name}</td>
                            <td>
                            {(() => {
                                if(formatted_date < item.start_time){
                                    return <span>Sắp diễn ra</span>
                                }else if(item.end_time < formatted_date ){
                                    return <span style={{fontWeight:'bold', color:'red'}}>Đã kết thúc</span>
                                }else if(item.status_deal === false){
                                    return <span style={{color: 'red'}}>Đã dừng</span>
                                }else if(item.start_time < formatted_date){
                                    return <span>Đang diễn ra</span>
                                }
                            })()}
                            </td>
                            <td>{item.start_time}</td>
                            <td>{item.end_time}</td>
                            <td style={{ "width" : "10%"}}>{(() => {
                                if(formatted_date < item.start_time){
                                }else if(item.end_time < formatted_date ){
                                }else if(item.start_time < formatted_date){
                                    return <input onChange={handleChangeUpdateSatatus} id={item.id} onClick={() => setIdUserCurr(item.id)} defaultChecked={item.status_deal === false ? true : false} disabled={item.status_deal === false ? true : false} className="ml-4" type="checkbox"/>
                                }
                            })()} </td>
                            <td>
                                <Link to={`/main/deal-products/${item.id}`}><i className="mdi mdi-plus-circle font-size-18 mr-2"></i></Link> 
                                
                                
                                <Link to={`/main/deal/info/${item.id}`}><i className="mdi mdi-information font-size-18 mr-2"></i></Link> 
                                {
                                    new Date(item.start_time) > today
                                    ?   <Link to={`/main/deal/edit/${item.id}`}>
                                            <i className="mdi mdi-pencil font-size-18"></i>
                                        </Link> 
                                    :   <Link style={{'cursor':'default'}} to="#">
                                            <i className="mdi mr-3 font-size-18"></i>
                                        </Link> 
                                }
                                {(() => {
                                if(formatted_date < item.start_time){
                                    return (
                                        <Link  onClick={() => {handleShow();  setIdUserCurr(item.id); setNameUserCurr(item.name)}} to="#" className="text-danger">
                                            <i className="mdi mdi-delete font-size-18"></i>
                                        </Link>
                                    )
                                }else if(item.end_time < formatted_date ){
                                    return (<Link style={{'cursor':'default'}} to="#">
                                            <i className="mdi mr-3 font-size-18"></i>
                                        </Link> )
                                }else if(item.start_time < formatted_date){
                                    return (<Link style={{'cursor':'default'}} to="#">
                                            <i className="mdi mr-3 font-size-18"></i>
                                        </Link> )
                                }
                                })()}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {total <= 20 ? '' :
                <Pagination
                    onChange={(value) => setcurrentPage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                />
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá deal</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá deal <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUpdate} onHide={() => handleCloseUpdate(idUserCurr)}>
                <Modal.Header closeButton>
                    <Modal.Title>Dừng deal</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn dừng deal <span style={{'color':'blue'}}>{}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCloseUpdate(idUserCurr)}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleStopDeal(idUserCurr);
                        handleCloseUpdate();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default ListDeal
