import React, {useEffect, useState} from 'react'
import Form from "react-bootstrap/Form";
import $ from "jquery"
import trim from "validator/es/lib/trim";
import {toast, ToastContainer} from "react-toastify";
import Link from "@material-ui/core/Link";
import CurrencyInput from 'react-currency-input-field';
import {handlePasteOnlyNumber, onNumberOnlyChange} from "../../helpers/function";
import {TYPE_GIFT_EXCHANGE_POINT} from "../../helpers/constant";
import {ProductApiService} from "../../ultils/EndPoint";
import {useHistory} from "react-router-dom";


function CreateGiftExchangePoint() {
    const [name, setName] = useState("");
    const [point, setPoint] = useState("");
    const [price, setPrice] = useState(0);
    const [imageURL, setImageURL] = useState("");
    const [qty, setQty] = useState("");
    const [msg1, setMsg1] = useState('')
    const history = useHistory();
    const zero = 0
    const onFileChange = (e) => {
        setImageURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('.image_icon').attr('src', e.target.result);
            $('.image_icon').hide();
            $('.image_icon').fadeIn(650);
        }

        reader.readAsDataURL(e.target.files[0])
        setMsg1('')
    }
    const [check, setCheck] = useState(1);
    const [status, setStatus] = useState(true)

    const handleCheckShow = () => {
        setCheck(1)
        setStatus(true)
    }
    const handleCheckHide = () => {
        setCheck(0)
        setStatus(false)
    }

    const handleCreateProduct = () => {
        let pointData = parseInt(point)
        let qtyData = parseInt(qty)
        if (name){
            $('.error_name').text("")
        }else{
            $('.error_name').html("Không được để trống")
        }

        if (imageURL){
            $('.error_image').text("")
        }else{
            $('.error_image').html("Không được để trống")
        }

        if (pointData){
            $('.error_point').html("")
        }else{
            $('.error_point').html("Không được để trống")
        }

        if (price == zero || price){
            $('.error_price').html("")
        }else{
            $('.error_price').html("Không được để trống")
        }

        if (qtyData){
            $('.error_qty').html("")
        }else{
            $('.error_qty').html("Không được để trống")
        }

        if (name && imageURL && pointData && (price || price == 0 ) && qtyData){
            let formData = new FormData()
            formData.append('name', name)
            formData.append('type_id', TYPE_GIFT_EXCHANGE_POINT)
            formData.append('point', pointData)
            formData.append('price', price)
            formData.append('product_stock', qtyData)
            formData.append('image', imageURL)
            formData.append('status', check);
            ProductApiService.create(formData).then(r => {
                if (r.status){
                    toast.success("Thêm mới quà đồi điểm thành công")
                    setTimeout(() => {
                        history.push('/main/gift-exchange-point')
                    }, 2000);
                }else{
                    alert(r.error)
                }
            })
        }
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Thêm quà tặng đổi điểm</h1>
                </div>
            </div>
            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Tên <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group input-script" style={{marginBottom : "20px"}}>
                <input style={{width: "100%"}} onChange={(e) => setName(trim(e.target.value))} placeholder="Nhập tên quà tặng đổi điểm" type="text" className="form-control name_category" aria-label="Username" aria-describedby="basic-addon1"/>
                <p style={{color: "red", fontSize: "12px"}} className="error_name"/>
            </div>

            <Form.Label className="col-2 pl-0 pr-0 ">Ảnh <span style={{"color": " red"}}>*</span></Form.Label>
            <br/>
            <button onClick={() => $('.input_uploadImage').click()} htmlFor="imageUpload1" style={{'width':'136px', 'marginBottom':'30px'}} type="button" className="btn btn-add">+ Upload image</button>
            <input className="input_uploadImage" hidden={true} onChange={onFileChange} type='file' id="imageUpload1" accept=".png, .jpg, .jpeg" />
            <p style={{color: "red", fontSize: "12px"}} className="error_image"/>
            {imageURL ?
                <div  id="close_btn_wapper">
                    <img className="image_icon"
                         style={{'marginRight':'30px','width':'150px', 'height':'150px'}}
                         alt="" id="imagePreview" src={imageURL}>
                    </img>
                </div>
                : ''}

            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Số điểm <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group input-script" style={{marginBottom : "20px"}}>
                <input defaultValue={point} onPaste={handlePasteOnlyNumber} onKeyPress={onNumberOnlyChange} style={{width: "100%"}} onChange={(e) => setPoint(parseInt(e.target.value))}  placeholder="Nhập số điểm" type="text" className="form-control name_category" aria-label="Username" aria-describedby="basic-addon1"/>
                <p style={{color: "red", fontSize: "12px"}} className="error_point"/>
            </div>


            <Form.Label className="col-2 pl-0 pr-0 ">Số tiền vật phẩm </Form.Label>
            <div className="input-group input-script" style={{marginBottom : "20px"}}>
                <CurrencyInput
                    id="input-example"
                    name="input-name"
                    placeholder="Nhập số "
                    defaultValue={0}
                    decimalsLimit={2}
                    className="form-control"
                    onValueChange={(value, name) => setPrice(value)}
                />
            </div>
            <p style={{color: "red", fontSize: "12px"}} className="error_price"/>



            <Form.Label className="col-2 pl-0 pr-0 ">Số lượng<span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group input-script" style={{marginBottom : "20px"}}>
                <input defaultValue={qty} onPaste={handlePasteOnlyNumber} onKeyPress={onNumberOnlyChange} style={{width: "100%"}} onChange={(e) => setQty(parseInt(e.target.value))}  placeholder="Nhập số lượng" type="text" className="form-control name_category" aria-label="Username" aria-describedby="basic-addon1"/>
                <p style={{color: "red", fontSize: "12px"}} className="error_qty"/>
            </div>
            <Form.Label className="col-2 pl-0 pr-0 ">Trạng thái</Form.Label>
            {/* <div className="d-flex align-items-center">
                <input type="checkbox" className="mr-2" checked={checkboxStatus} onChange={handleCheckStatus}></input><span>{checkboxStatus === false ? "Ẩn" : " Hiện"}</span>
            </div> */}
            <div className="d-flex">
                <div className="custom-control custom-radio mr-3">
                    <input type="radio" id="customRadio1" checked={status === true ? true : false} onChange={handleCheckShow} name="customRadio" className="custom-control-input"/>
                    <label className="custom-control-label" style={{'paddingTop':'3px', 'cursor':'pointer'}} htmlFor="customRadio1">Hiển thị</label>
                </div>
                <div className="custom-control custom-radio">
                    <input type="radio" id="customRadio2" checked={status === false ? true : false} onChange={handleCheckHide} name="customRadio" className="custom-control-input"/>
                    <label className="custom-control-label" style={{'paddingTop':'3px', 'cursor':'pointer'}} htmlFor="customRadio2">Ẩn</label>
                </div>
            </div>
            <div >
                <button onClick={() => {history.push('/main/gift-exchange-point')}} type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button>
                <a onClick={handleCreateProduct} type="button" className="btn btn-success mt-5">Lưu</a>
            </div>
        </div>

    )
}
export default CreateGiftExchangePoint