import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import './Banner.scss'
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function BannerEditStore() {
    const {id} = useParams();
  
    const [link, setLink] = useState('');
    const dispatch = useDispatch();
    const [iconServer, setIconServer] = useState('');
    const [bannerServer, setbannerServer] = useState('');

    const [imageURL, setImageURL] = useState('');
    const [bannerURL, setbannerURL] = useState('');

    const [orderNo, setOrderNo] = useState('');
    const [listTypeBanner, setListTypeBanner] = useState([]);
    const [selectedOption, setSelectedOption] = useState(1)

    const [disable, setDisable]= useState(false);
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');

    // test positive integer
    const positive =  /^\+?(0|[1-9]\d*)$/

    // function check url
    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // set const
    const handleSlectListType = (e) => {
        setSelectedOption(e.target.value)
    }

    const handleNumberOrder = (e) => {
        setOrderNo(e.target.value)
    }
    const handleRemoveNumberOrder = () => {
        validationMsg.orderNo = ''
        $('.titile_category').removeClass('danger_border')
    }

    const handleLink = (e) => {
        setLink(e.target.value)
    }
    const handleRemoveLink = () => {
        validationMsg.link = ''
        $('.key_category').removeClass('danger_border')
    }

    // get data 
    useEffect(()=>{
        if(id) {
            axios.get(`${ENDPOINT.BANNER}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (response) {
                if(response.status === 200) {
                    setOrderNo(response.data.data.order_number)
                    setLink(response.data.data.link)
                    setImageURL(response.data.data.image)
                    setIconServer(response.data.data.image)
                    setSelectedOption(response.data.data.type_id)
                    setbannerURL(response.data.data.image_mobile)
                    setbannerServer(response.data.data.image_mobile)
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    },[id, dispatch]);

    // call api set list-type banner
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.LIST_BANNER}`, config)
        .then((res) => {
            if (!unmounted) {
                setListTypeBanner(res.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch]);
       

    // set validate all 
    const validateAll = () => {
        const msg = {};

        if(imageURL === ''){
            msg.imageURL = "Vui lòng tải lên ảnh banner"
        }

        if(orderNo <= 0) {
            msg.orderNo = 'Vui lòng nhập số thứ tự'
            $('.titile_category').addClass('danger_border');
        }else if(!positive.test(orderNo)){
            msg.orderNo = 'Vui lòng không nhập kí tự đặc biệt'
            $('.titile_category').addClass('danger_border');
        }else if(orderNo.length > 10){
            msg.orderNo = 'Thứ tự chỉ bao gồm 10 kí tự số và lớn hơn 0'
            $('.titile_category').addClass('danger_border');
        }

        if(link && !validURL(link)){
            msg.link = 'Link không đúng định dạng'
            $('.key_category').addClass('danger_border');
        }
        
        // if(link.length <= 0) {
        //     msg.link = 'Vui lòng nhập link'
        //     $('.key_category').addClass('danger_border');
        // }else if(!validURL(link)){
        //     msg.link = 'Link không đúng định dạng'
        //     $('.key_category').addClass('danger_border');
        // }


        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    const createBanner = new FormData();
    createBanner.append('link', link);
    createBanner.append('order_number', orderNo);
    createBanner.append('image', imageURL);
    createBanner.append('image_mobile', bannerURL);
    createBanner.append('type_id', selectedOption);

    const createBrands = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.BANNER}`, createBanner,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm mới banner thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/banners'
                    }, 2000);
                }
            })
            .catch(error => {
                    toast.error(error.response.data.error)
                }
            )

    }

    const updateBanner = new FormData();
    updateBanner.append('link', link);
    updateBanner.append('order_number', orderNo);
    if(iconServer === imageURL){

    }else {
        updateBanner.append('image', imageURL);
    }
    updateBanner.append('type_id', selectedOption);
    if(bannerServer === bannerURL){

    }else {
        updateBanner.append('image_mobile', bannerURL);
    }

    const updateBannerButton = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.BANNER}/${id}`, updateBanner,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Chỉnh sửa banner thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/banners'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Thông tin Tên thương hiệu đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )

    }

    const handleRemoveUpload = () => {
        $("#imageUpload").val("");
        setImageURL(iconServer);
        $("#imagePreview").attr("src", iconServer);
        $('.image_icon').removeClass('image_icon1');
        $('.icon_remove_image').removeClass('icon_remove_image1');
    }

    const onFileChange = (e) => {
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }

        setImageURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon1');
            $('.icon_remove_image').addClass('icon_remove_image1');
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const handleRemoveUploadBanner = () => {
        $("#imageUploadBanner").val("");
        setbannerURL(bannerServer)
        $("#imagePreviewBanner").attr("src", bannerServer);
        $('.image_icon').removeClass('image_icon2');
        $('.icon_remove_image').removeClass('icon_remove_image2');
    }

    const onFileChangeBanner = (e) => {
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }
        setbannerURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreviewBanner').attr('src', e.target.result);
            $('#imagePreviewBanner').hide();
            $('#imagePreviewBanner').fadeIn(650);
            $('.image_icon').addClass('image_icon2');
            $('.icon_remove_image').addClass('icon_remove_image2');
        }

        reader.readAsDataURL(e.target.files[0])
    }

    $('input[name="inputForNumber"]').keypress(
        function(event)
        {
            if (event.keyCode === 46 || event.keyCode === 8)
            {

            }
            else
            {
                if (event.keyCode < 48 || event.keyCode > 57 )
                {
                    event.preventDefault();
                }
            }
        }
    );

    return (
        <div className="pl-4 pr-4 mt-3 brands_form">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa banner</h1> : <h1 className="customer-title mb-3">Thêm mới banner</h1>}
                </div>
            </div>
            <div className="mb-3">
                <Form.Label className="col-2 pl-0 pr-0 ">Hình ảnh(PC) <span style={{"color": " red"}}>*</span></Form.Label>
                <div className="form-group upload_img">
                <button htmlFor="imageUpload" type="button" className="btn btn-add">+ Upload<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" /></button>
                {imageURL ?
                    <div>
                        <div id="close_btn_wapper">
                            <img 
                            className="image_icon" 
                            style={{'marginRight':'30px'}} 
                            alt="" 
                            id="imagePreview" 
                            src={imageURL}></img>
                            <i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                        </div>
                    </div>
                    : ''}
                </div>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.imageURL}</p>
            <div className="mb-3">
                <Form.Label className="col-2 pl-0 pr-0 ">Hình ảnh mobile(nếu có)</Form.Label>
                <div className="form-group upload_img">
                    <button htmlFor="imageUpload" type="button" className="btn btn-add">+ Upload<input className="input_uploadImage" onChange={onFileChangeBanner} type='file' id="imageUploadBanner" accept=".png, .jpg, .jpeg" /></button>
                    {bannerURL ?
                        <div>
                            <div id="close_btn_wapper">
                                <img 
                                className="image_icon" 
                                style={{'marginRight':'30px'}} 
                                alt="" 
                                id="imagePreviewBanner" 
                                src={bannerURL}>
                                </img>
                                <i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUploadBanner} className="mdi mdi-delete icon_remove_image"></i>
                            </div>
                        </div>
                        : ''}
                </div>
            </div>
            <div className="d-flex align-items-center mt-3">
                <div className="input-group col-12 pl-0 pr-0">
                    <select id="selectCate" style={id ? {cursor:'not-allowed'} : {}} name="" disabled={id ? true : false} onChange={handleSlectListType} value={selectedOption} className="form-control custom_select custom_selectProduct "> 
                        {listTypeBanner.map((banner, index) =>
                            <option key={index} value={banner.id}>{banner.name}</option>
                        )}
                    </select>
                </div>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name"></p>
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Số thứ tự <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" name="inputForNumber" className="form-control titile_category" value={orderNo} onKeyDown={handleRemoveNumberOrder} onChange={handleNumberOrder} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.orderNo}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Link</Form.Label>
            <div className="input-group">
                <input type="text" className="form-control key_category" value={link} onKeyDown={handleRemoveLink} onChange={handleLink} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.link}</p>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{msg}</p>
            <div >
                <Link to="/main/banners"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                 {id ? <button type="button" disabled={disable} onClick={updateBannerButton} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createBrands} className="btn btn-success mt-5">Lưu</button>}
                {/*<button type="button" disabled={disable} onClick={createBrands} className="btn btn-success mt-5">Lưu</button>*/}
            </div>
           
        </div>
    )
}

export default BannerEditStore
