import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const VERSION = 'api/v1/';
const API_URL = BASE_URL + VERSION;

const ENDPOINT = {
    // auth

    LOGIN_AUTH: BASE_URL + VERSION + 'admin/login',

    // category
    LIST_CATEGORY: BASE_URL + VERSION + 'admin/products/category',
    OPTION_CATEGORY: BASE_URL + VERSION + 'admin/products/category/option',

    // band
    BRAND: BASE_URL + VERSION + 'admin/products/brand',
    BRAND_NO_PAGINATE: BASE_URL + VERSION + 'admin/products/brand/no-paginate',

    //partner
    PARTNER: BASE_URL + VERSION + 'admin/partner',

    //website
    WEBSITE: BASE_URL + VERSION + 'admin/website',

    // store
    STORE: BASE_URL + VERSION + 'admin/stories',

    // catalog news
    CATALOG: BASE_URL + VERSION + 'admin/news/catalogs',
    OPTION_CATALOG: BASE_URL + VERSION + 'admin/news/catalogs/option',

    // news
    NEWS : BASE_URL + VERSION + 'admin/news',

    // manager user
    USER: BASE_URL + VERSION + 'admin/users',

    // product
    PRODUCT: BASE_URL + VERSION + 'admin/products',
    TYPE_LIST: BASE_URL + VERSION + 'admin/products/type',
    TYPE_UNIT: BASE_URL + VERSION + 'admin/products/unit',
    //---------> group
    GROUPS_PAGINATE: BASE_URL + VERSION + 'admin/products/groups/paginate',
    GROUPS_EDIT_CREATE: BASE_URL + VERSION + 'admin/products/groups',
    //---------> group-product
    // end point used inside commponents


    SHIPPINGS : BASE_URL + VERSION + 'admin/products/shippings',
    SHIPPINGS_PROVINCE : BASE_URL + VERSION + 'admin/shipping/province',
    DETAIL_SHIPPING : BASE_URL + VERSION + 'admin/shipping/district',

    //---------> city

    CITY : BASE_URL + VERSION + 'cities',
    DISTRICT : BASE_URL + VERSION + 'districts',


    //---------> static-page
    STATICPAGE : BASE_URL + VERSION + 'admin/static-page',

    //---------> services
    SERVICES : BASE_URL + VERSION + 'admin/services',

    WAREHOUSE:  BASE_URL + VERSION + 'admin/products/import-export',

    //---------> banner
    BANNER: BASE_URL + VERSION + 'admin/banners',
    LIST_BANNER: BASE_URL + VERSION + 'admin/banner-type',

    //---------> list orders
    LIST_ORDERS: BASE_URL + VERSION + 'admin/orders',

    //---------> admin
    ADMIN: BASE_URL + VERSION + 'admins',
    //---------> list orders
    DEALLIST : BASE_URL + VERSION + 'admin/products/deals',

    //---------> role and permission
    ROLE: BASE_URL + VERSION + 'admin/roles',
    PERMISSION: BASE_URL + VERSION + 'admin/permissions',

    //---------> coupon
    COUPON: BASE_URL + VERSION + 'admin/coupons',

    //---------> payment method
    PAYMENT_METHOD: BASE_URL + VERSION + 'client/payments/payment-method',

}

export default ENDPOINT

export const ApiViaTokenDefinedService = {
    setHeader() {
        if (process.browser) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + process.env.REACT_APP_API_CLIENT_TOKEN ;
        }
    },

    get(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.get(API_URL + url, {
            params: params
        })
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                return error.response.data
            })
    },
}

export const ApiService = {

    setFormData() {
        axios.defaults.headers.common[
            "Content-Type"
            ] = "multipart/form-data";
    },

    setHeader() {
        if (process.browser) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem('User-Admin') ;
        }
    },

    get(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.get(API_URL + url, {
            params: params
        })
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                return error.response.data
            })
    },

    post(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.post(API_URL + url, params)
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                return error.response.data
            })
    },

    put(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.put(API_URL + url, params)
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                return error.response.data
            })
    },


    delete(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.delete(API_URL + url, {params: params})
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                return error.response.data
            })
    },
     deleteBulk(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return axios.delete(API_URL + url, {
          data: params
        })
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                return error.response.data
            })
    },

    async getRequest(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return await axios.get(API_URL + url, {params: params})
            .then((res) => {
                return res.data.data
            })
            .catch((error) => {
                return error.response.data
            })
    },

    async postRequest(url, params = {}, isToken = false) {
        if (isToken) {
            this.setHeader();
        }
        return await axios.post(API_URL + url, params)
            .then((res) => {
                return res.data.data
            })
            .catch((error) => {
                return error.response.data
            })
    },
}

const SHIPPING_FEE = 'admin/shipping/'
export const ShippingFeeApiService = {
    getShippingCity(query = ''){
        return  ApiService.get(SHIPPING_FEE + 'province' + query, {}, true)
    },

    getShippingCityCreated(){
        return  ApiService.get('admin/shipping-fee/list-created', {}, true)
    },

    getShippingDistrict(idProvince){
        return  ApiService.get(SHIPPING_FEE + 'district/' + idProvince, true)
    },

    createShippingCity(data = {}){
        return  ApiService.post(SHIPPING_FEE + 'province', data, true)
    },

    deleteShippingCity(id) {
        return ApiService.delete(SHIPPING_FEE + 'province/' + id, {}, true)
    },

    showShippingProvince(provinceId){
        return ApiService.get(SHIPPING_FEE + 'district/' + provinceId, {}, true)
    },

    createShippingDistrict(provinceId , data = {}){
        return ApiService.post(SHIPPING_FEE + 'district/' + provinceId, data, true)
    },

    updateShippingFeeProvince(shippingProvinceId, data = {}){
        return ApiService.put(SHIPPING_FEE + 'province/' + shippingProvinceId, data, true)
    }
}


const CITIES = 'cities'
export const CityApiService = {
    getListCity(){
        return ApiService.get(CITIES)
    },
    getCityById(id){
        return ApiService.get(CITIES + '/' + id)
    }
}

const DISTRICT = 'districts'
export const DistrictApiService = {
    getListDistrictByCityId(cityId){
        return ApiService.get(DISTRICT + '?city_id=' + cityId)
    }
}

const WARD = 'wards'
export const WardApiService = {
    getListWardByDistrictId(districtId){
        return ApiService.get(WARD + '?district_id=' + districtId)
    }
}

const USER = 'admins/users'
export const UserApiService = {
    create(data = {}){
        return ApiService.post(USER, data, true)
    },

    update(id ,data = {}){
        return ApiService.post(USER + '/' + id, data, true)
    },

    show(id){
        return ApiService.get('admin/users/' + id, {}, true)
    },

    getListAddress(id){
        return ApiService.get('admin/users/' + id + '/address', {}, true)
    }
}


const WAREHOUSE = 'admin/products/import-export'
export const warehouseApiService = {
    getListWareHouse(query){
        return ApiService.get(WAREHOUSE + query, {}, true)
    },

    createWareHouse(query){
        return ApiService.post(WAREHOUSE + query, {}, true)
    }
}
const PRODUCT_GROUP = 'admin/products/groups/'
export const ProductGroupApiService = {
    deleteProduct(groupId, params ={}) {
        return ApiService.deleteBulk(PRODUCT_GROUP + `${groupId}/delete`, params, true)
    },
    setFeature(id, params = {}) {
        return ApiService.put(PRODUCT_GROUP + `${id}/set-feature`, params , true)
    }
}

const REVIEW = 'admin/reviews/'
export const ReviewApiService = {
    getList(query) {
        return ApiService.get(REVIEW + 'product-order' + query, {}, true)
    },
    getListReviewProduct(orderId){
        return ApiService.get(REVIEW + 'product-order/' +orderId, {}, true)
    },
    toggleActiveReview(orderId,  data = {}){
        return ApiService.put(REVIEW + 'product-order/' +orderId, data, true)
    },
    reply(reviewId,  data = {}){
        return ApiService.post(REVIEW + 'product-order/reply/' +reviewId, data, true)
    }

}

const DEAL = 'admin/products/deals/'
export const listdeal = {
    getListDeal(query){
        return ApiService.get(DEAL + query, {}, true)
    },
    createDeal(query) {
        return ApiService.post(DEAL + query, {}, true)
    },
    deleteDealProduct(groupId, params ={}) {
        return ApiService.deleteBulk(DEAL + `${groupId}/delete`, params, true)
    }
}

const COUPONLIST = 'admin/coupons'
export const listcoupon = {
    getListCoupon(query){
        return ApiService.get(COUPONLIST + query, {}, true)
    },
    createCoupon(query) {
        return ApiService.post(COUPONLIST + query, {}, true)
    },
    deleteCouponProduct(groupId, params) {
        return ApiService.deleteBulk(COUPONLIST + `/${groupId}/remove-product`, params, true)
    }
}

const FAQ = 'admin/faq'
export const FaqService = {
    getList(params = {}) {
        return ApiService.get(FAQ, params, true)
    },
    toggleFaq(id) {
        return ApiService.put(FAQ + `/${id}/toggle-active` , {} , true)
    },
    deleteFaq(id) {
        return ApiService.delete(FAQ + `/${id}`, {}, true)
    },
    listAnswer(id, params = {}) {
        return ApiService.get(FAQ + `/${id}/answer`, params, true)
    },
    toggleAnswer(id) {
        return ApiService.put(FAQ + `/answer/${id}/toggle-active` , {} , true)
    },
    deleteAnswer(id) {
        return ApiService.delete(FAQ + `/answer/${id}`, {}, true)
    },
    answer(id, data) {
        return ApiService.post(FAQ + `/${id}/answer`, data, true)
    }
}

const PRESCRIPTION = 'admin/prescription'
export const PrescriptionApiService = {
    getList(query = {}){
        return ApiService.get(PRESCRIPTION + query, {}, true)
    },
    show(id) {
        return ApiService.get(PRESCRIPTION + '/' + id, {}, true)
    },
}

const SCRIPT = 'admin/scripts'
export const ScriptApiService = {
    getList(query = {}){
        return ApiService.get(SCRIPT + query, {}, true)
    },
    show(id) {
        return ApiService.get(SCRIPT + '/' + id, {}, true)
    },

    delete(id) {
        return ApiService.delete(SCRIPT + '/' + id, {}, true)
    },

    getListNoPaginate(){
        return ApiService.get(SCRIPT + '-no-paginate', {}, true)
    },

    create(data = {}){
        return ApiService.post(SCRIPT, data, true)
    },

    setFirst(id){
        return ApiService.put(SCRIPT + '/' + id + '/set-first', {}, true)
    },

    update(id, data = {}){
        return ApiService.put(SCRIPT + '/' + id, data, true)
    }
}

const ORDER = 'admin/orders'
export const OrderApiService = {
    create(data = {}){
        return ApiService.post(ORDER, data, true)
    },

    getListTicket(query = ''){
        return ApiService.get(ORDER + '/ticket-delivery' + query, {}, true)
    },

    updateStatusTicket(idTicket, data = {}){
        return ApiService.put(ORDER + '/' + idTicket + "/ticket-delivery", data, true)
    },

    getCount(query){
        return ApiService.get('api-client/orders/get-count' + query, {}, true)
    }

}

const PRODUCT = 'admin/products'
export const ProductApiService = {
    getList(query = {}){
        return ApiService.get(PRODUCT + query, {}, true)
    },
    show(id) {
        return ApiService.get(PRODUCT + '/' + id, {}, true)
    },

    create(data = {}){
        return ApiService.post(PRODUCT, data, true)
    },

    update(id, data = {}){
        return ApiService.put(PRODUCT + '/' + id, data, true)
    }
}
const AUTH = 'admin/me'
export const AuthApiService = {
    me(){
        return ApiService.get(AUTH, {}, true)
    },
}

const CHAT = 'admin/list-room/admin'
export const ChatApiService = {
    getListRoom(query = ''){
        return ApiService.get(CHAT + query, {}, true)
    },
}



const API_NHN_URL = process.env.REACT_APP_URL_NHN
export const ApiNHNService = {
    setHeader() {
        if (process.browser) {
            axios.defaults.headers.common["token"] =  process.env.REACT_APP_TOKEN_NHN;
        }
    },

    get(url, params = {}, isToken = false) {

        if (isToken) {
            this.setHeader();
        }
        return axios.get(API_NHN_URL + url, {
            params: params
        })
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                return error.response.data
            })
    }
}
