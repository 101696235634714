import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router';
import ENDPOINT, {ApiNHNService, DistrictApiService, WardApiService} from '../../ultils/EndPoint';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { getImageMedia } from '../../helpers/function';
import Form from "react-bootstrap/Form";
import {Link} from 'react-router-dom';
import $ from 'jquery'
import {DELIVERY_FAST} from "../../helpers/constant";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function TicketManager() {
    // set const 
    const [listProduct, setListProduct] = useState([])
    const [weight, setWeight] = useState('');
    const [method_id, setMethod_id] = useState('');
    const [allowSeenProduct, setAllowSeenProduct] = useState(0);
    const [note, setNote] = useState('');
    const {id} = useParams();
    const history = useHistory();
    const [provinceSelect, setProvinceSelect] = useState(0);
    const [districtSelect, setDistrictSelect] = useState(0);
    const [wardSelect, setWardSelect] = useState(0);
    const [shopIdSelect, setShopIdSelect] = useState(0);
    const [listShop, setListShop] = useState([]);
    const dispatch = useDispatch();

    // others
    const [disable, setDisable]= useState(false);

    // set validator
    const [validationMsg, setValidationMsg] = useState({});
    
    // get data 
    useEffect(()=>{
    if(id) {
        axios.get(`${ENDPOINT.LIST_ORDERS}/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setListProduct(response.data.data)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
    }
    },[id, dispatch]);

    $(document).ready(function (){

        $(document).on("change","#selectShop",function() {
            if ($(this).val()){
                setShopIdSelect($(this).val())
            }else{
                setShopIdSelect(0)
            }
        });

        $(document).on("change","#selectProvince",function() {
            if ($(this).val()){
                setProvinceSelect($(this).val())
            }else{
                setProvinceSelect(0)
            }
        });

        $(document).on("change","#selectDistrict",function() {
            if ($(this).val()){
                setDistrictSelect($(this).val())
            }else{
                setDistrictSelect(0)
            }
        });

        $(document).on("change","#selectWard",function() {
            if ($(this).val()){
                setWardSelect($(this).val())
            }else{
                setWardSelect(0)
            }
        });
    })

    useEffect(() =>{
        setDistrictSelect(0)
        setWardSelect(0)

        if (provinceSelect > 0) {
            ApiNHNService.get("shiip/public-api/master-data/district?province_id=" + provinceSelect, {}, true).then(r => {
                if (r.code == 200){
                    let listOptionDistrict = ''
                    let districts = r.data
                    districts.map((district, key) => {
                        listOptionDistrict += `<option value=${district.DistrictID} key=${key}>${district.DistrictName}</option>`
                    })
                    $('#selectDistrict').html('<option value="">Chọn huyện</option>' + listOptionDistrict)
                    $('#selectWard').html('<option value="">Chọn xã</option>')
                }
            })
        }else{
            $('#selectDistrict').html('<option value="">Chọn huyện</option>')
            $('#selectWard').html('<option value="">Chọn xã</option>')
        }
    }, [provinceSelect])

    useEffect(() =>{
        setWardSelect(0)
        if (districtSelect > 0) {
            ApiNHNService.get("shiip/public-api/master-data/ward?district_id=" + districtSelect, {}, true).then(r => {
                if (r.code == 200){
                    let listOptionWard = ''
                    let wards = r.data
                    wards.map((ward, key) => {
                        listOptionWard += `<option value=${ward.WardCode} key=${key}>${ward.WardName}</option>`
                    })
                    $('#selectWard').html('<option value="">Chọn xã</option>' + listOptionWard)
                }
            })
        }else{
            $('#selectWard').html('<option value="">Chọn xã</option>')
        }
    }, [districtSelect])



    // handle Change
    const handleMethod = async (e) => {
        setMethod_id(e.target.value)
        let listOptionShops = ''
        let listOptionProvinces = ''
        if (e.target.value == DELIVERY_FAST) {
            await ApiNHNService.get("shiip/public-api/v2/shop/all", {}, true).then(r => {
                if (r.code == 200) {
                    setListShop(r.data.shops)
                    let shops = r.data.shops
                    shops.map((shop, key) => {
                        listOptionShops += `<option value=${shop._id} key=${key}>${shop.name}</option>`
                    })
                }
            })

            await ApiNHNService.get("shiip/public-api/master-data/province", {}, true).then(r => {
                if (r.code == 200) {
                    let provinces = r.data
                    provinces.map((province, key) => {
                        listOptionProvinces += `<option value=${province.ProvinceID} key=${key}>${province.ProvinceName}</option>`
                    })
                }
            })

            $('.content_delivery_fast').html(
                `<div style="margin-top: 10px" class="row">
                    <div class="input-groups col-6 grid" style="display: grid">
                        <select id="selectShop" name="" class="form-control custom_select custom_selectProduct method_id" style="width: 100%" >
                            <option value="">Chọn cửa hàng</option>
                            ${listOptionShops}
                        </select>
                        <p style={{'fontSize': '12px'}} class="text-danger error_shop msg_name mb-0"></p>
                    </div>
                    <div class="input-groups col-6  pl-0" style="display: grid">
                        <select id="selectProvince" name="" class="form-control custom_select custom_selectProduct method_id" style="width: 100%" >
                            <option value="">Chọn tỉnh thành phố</option>
                            ${listOptionProvinces}
                        </select>
                        <p style={{'fontSize': '12px'}} class="text-danger error_province msg_name mb-0"></p>
                    </div>
                </div>
                <div style="margin-top: 10px" class="d-flex justify-content-between align-items-center">
                    <div class="input-groups col-6 pl-0">
                        <select id="selectDistrict" name="" class="form-control custom_select custom_selectProduct method_id" style="width: 100%" >
                            <option value="">Chọn huyện</option>
                        </select>
                        <p style={{'fontSize': '12px'}} class="text-danger error_district msg_name mb-0"></p>
                    </div>
                    <div class="input-groups col-6  pl-0">
                        <select id="selectWard" name="" class="form-control custom_select custom_selectProduct method_id" style="width: 100%" >
                            <option value="">Chọn xã</option>
                        </select>
                        <p style={{'fontSize': '12px'}} class="text-danger error_ward msg_name mb-0"></p>
                    </div>
                </div>
                `
            )
        } else {
            $('.content_delivery_fast').html("")
        }
    }

    const removeMessMethodId = () => {
        validationMsg.method_id = ''
        $('.method_id').removeClass('danger_border');
    }

    const handleAllowSeen = (e) => {
        setAllowSeenProduct(e.target.value)
    }
    
    const handleWeight = (e) => {
        setWeight(e.target.value)
    }

    const handleNote = (e) => {
        setNote(e.target.value)
    }

// validate before submit
const validateAll = () => {
    const msg = {};

    if(method_id.length === 0){
        msg.method_id = 'Vui lòng chọn phương thức giao hàng'
        $('.method_id').addClass('danger_border');
    }

    if (method_id == DELIVERY_FAST){
        if (!shopIdSelect){
            msg.error_shop = 'Vui lòng chọn trường này'
        }else{
            $('.error_shop').html("")
        }

        if (!provinceSelect){
            msg.error_province = 'Vui lòng chọn trường này'
        }else{
            $('.error_province').html("")
        }

        if (!districtSelect){
            msg.error_district = 'Vui lòng chọn trường này'
        }else{
            $('.error_district').html("")
        }

        if (!wardSelect){
            msg.error_ward = 'Vui lòng chọn trường này'
        }else{
            $('.error_ward').html("")
        }
    }

    setValidationMsg(msg)
    if(Object.keys(msg).length > 0){
        // Object.keys(msg).map(function(key) {
        //     $('.' + key).html(msg[key])
        // });
        return false
    }else{
        return true
    }
  
}

 const createTicket = () => {
     const isValidate = validateAll()
     if(!isValidate) return

     setDisable(true)
     setTimeout(() => {
         setDisable(false)
     }, 2000);


     //set data
     const createTicketForm = new FormData();
     createTicketForm.append('method_id', method_id)
     createTicketForm.append('note', note)
     createTicketForm.append('weight', weight)
     createTicketForm.append('allow_seen_product', allowSeenProduct)
     createTicketForm.append('cod', listProduct.payment_status === false ? listProduct.real_price : 0)

     if (method_id == DELIVERY_FAST){
         createTicketForm.append("shop_id", parseInt(shopIdSelect))
         createTicketForm.append("district_id", parseInt(districtSelect))
         createTicketForm.append("ward_code", parseInt(wardSelect))

         let shop = listShop.find(shop => {
             return shop._id == shopIdSelect
         })
         createTicketForm.append("shop_address", shop ? shop.address : '')
     }

     return axios.post(`${ENDPOINT.LIST_ORDERS}/${id}/ticket-delivery`, createTicketForm,  {
         headers: {
             Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
         }
     })
         .then(function (response) {
             if(response.status === 200) {
                toast.success('Thêm mới phiếu giao hàng thành công!')
                setTimeout(() => {
                   history.replace(`/main/list-orders/${id}`)
                }, 2000);
             }
         })
         .catch(error => {
               
            }
         )

 }

// only type number
useEffect(() => {
    $('input[name="inputForNumber"]').keypress(
        function(event)
        {
            if (event.keyCode === 46 || event.keyCode === 8)
            {

            }
            else
            {
                if (event.keyCode < 48 || event.keyCode > 57 )
                {
                    event.preventDefault();
                }
            }
        }
    );
})

    return (
        <div className="pl-4 pr-4 mt-3">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center mb-3">
                <h1 className="customer-title mb-0">Tạo phiếu  giao  hàng</h1>
            </div>
        </div>
        <h1 className="customer-title justify-content-start">Sản phẩm</h1>
        <div className="category-manager-wapper mt-0">
            <div className="table-responsive group_product">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <tr>
                        <th>STT</th>
                        <th>Tên sản phẩm</th>
                        <th>Ảnh</th>
                        <th>Số lượng</th>
                    </tr>
                    </thead>
                    <tbody row="10">
                    {listProduct.products && listProduct.products.map((productDetail, index) =>
                        <tr key={index}>
                            <th>
                                {productDetail.id}
                            </th>
                            <td>
                                {productDetail.name}
                            </td>
                            <td style={{'width':'300px', 'height':'100%'}}>
                                {
                                    <img style={{'width':'100%', 'height':'100%', 'objectFit':'fill'}} src={getImageMedia(productDetail.image)} alt=""/>
                                }
                            </td>
                            <td>
                                {productDetail.quantity}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
        <h1 className="customer-title justify-content-start">Địa chỉ giao hàng</h1>
        <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="list_orderboxDetail">
                <p>Người nhận: {listProduct.name}</p>
                <p>Địa chỉ:  {listProduct.address}</p>
                <p>Tỉnh/Thành phố: {listProduct.city_name}</p>
                <p>Quận/Huyện: {listProduct.district_name}</p>
                <p>Xã/Phường/Thị Trấn: {listProduct.ward_name}</p>
            </div>
        </div>
        <p style={{'fontSize': '12px'}} className="text-danger error_method_id msg_name mb-0 mt-3">{validationMsg.method_id}</p>
        <div className="d-flex justify-content-between align-items-center">
            <div className="input-group col-6 pl-0">
                <select id="selectStatusPayment" name="" onChange={handleMethod} onClick={removeMessMethodId} className="form-control custom_select custom_selectProduct method_id" >
                    <option value="">Phương thức giao hàng</option>
                    <option value="10">Khác</option>
                    <option value="1">Giao hàng nhanh</option>
                    <option value="2">Vn Post</option>
                </select>
            </div>
            <div className="input-group col-6 pl-0  pr-0">
                <select name="" id="deliveryStatus"  onChange={handleAllowSeen} className="form-control custom_select custom_selectProduct" > 
                    <option value="1">Cho xem hàng</option>
                    <option value="2">Không cho xem hàng</option>
                </select>
            </div>
        </div>
        <div className="content_delivery_fast">

        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="input-group col-6 pl-0">
                <Form.Label className="col-2 pl-0 pr-0 mt-2">COD</Form.Label>
                <input disabled={true} value={listProduct.payment_status === false ?  new Intl.NumberFormat('it-IT').format(listProduct.real_price) + ' VNĐ' : ''} className="form-control"></input>
            </div>
            <div className="input-group col-6 pl-0  pr-0">
                <Form.Label className="col-3 pl-0 pr-0 mt-2">Cân nặng (gram)</Form.Label>
               <input className="form-control" onChange={handleWeight} name="inputForNumber"></input>
            </div>
        </div>
        <div className="listDetail_footer">
            <textarea  className="w-100" onChange={handleNote} placeholder={'Ghi chú :'} style={{height:'200px', padding:'10px'}}></textarea>
        </div>
        <div >
            <Link to={`/main/list-orders/${id}`}><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại đơn hàng</button></Link>
            <button disabled={disable} type="button" onClick={createTicket} className="btn btn-info mt-5 mr-4">Tạo phiếu giao hàng</button>
        </div>
    </div>
    )
}

export default TicketManager
