import React, {useEffect, useState} from 'react'
import ENDPOINT, {listdeal, PrescriptionApiService, ReviewApiService} from "../../ultils/EndPoint";
import {convertDateTime, objectToQueryParams} from "../../helpers/function";
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import {Link} from "react-router-dom";
import trim from "validator/es/lib/trim";

function ListPrescription() {
    // set const
    const [listPrescription, setListPrescription] = useState([]);
    const [customersPerPage] = useState(10)

    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })

    // get data 
    const getListPrescription = (query) => {
        PrescriptionApiService.getList(query).then(r => {
            if(r.status) {
                setListPrescription(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }

    const setCurrentPage = (page) => {
        let data = {}
        data.page = page
        data.per_page = customersPerPage
        let query = objectToQueryParams(data)
        getListPrescription(query)
    }

    useEffect(() => {
        const data = {
            per_page: customersPerPage,
            page: dataPaginate.currentPage,
        };
        let query = objectToQueryParams(data)
        getListPrescription(query)
    }, []);

    return (
        <div className="pl-4 pr-4 mt-3">
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Danh sách đơn thuốc</h1>
                </div>
            </div>
            <div className="table-reponsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>STT</th>
                    <th>Tên</th>
                    <th>SDT</th>
                    <th>Email</th>
                    <th>Địa chỉ</th>
                    <th>Ngày đăng</th>
                    <th>Ảnh đơn thuốc</th>
                    <th>Hành động</th>
                    </thead>
                    <tbody>
                    {listPrescription && listPrescription.map((item,index) =>
                        <tr key={index}>
                            <td style={{ "width" : "5%", paddingLeft:'10px'}}>{index + 1}</td>
                            <td style={{ "width" : "15%"}}>{item.full_name}</td>
                            <td style={{ "width" : "10%"}}>{item.phone}</td>
                            <td style={{ "width" : "10%"}}>{item.email}</td>
                            <td style={{ "width" : "35%"}}>{item.address + " " + item.city_name + " " + item.district_name + " "+ item.ward_name}</td>
                            <td>{convertDateTime(item.created_at, 'DD/MM/YYYY')}</td>
                            <td>
                                <img style={{width:"140px", height:"140px", paddingTop:'10px', paddingBottom:'10px'}} src={item.image} alt={item.full_name}/>
                            </td>
                            <td>
                                <Link to={`/main/prescription/${item.id}`}>
                                    <i className="mdi mdi-pencil font-size-18"></i>
                                </Link>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {dataPaginate.totalRecord <= customersPerPage ? '' :
                <Pagination
                    onChange={(value) => setCurrentPage(value)}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    showSizeChanger= {false}
                    defaultPageSize={customersPerPage}
                />
            }
        </div>
    )
}
export default ListPrescription
