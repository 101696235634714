import React, { useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form';
import ENDPOINT from "../../ultils/EndPoint";
import axios from 'axios';
import "antd/dist/antd.css";
import $, { trim } from "jquery";
import {Link, useHistory, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function AdminEditAndStore() {
    // set const list permiss
    const [permissionList, setPermissionList] = useState([]);
    const [permissCheck, setPermissCheck] = useState([]);
    const [checkSuper, setCheckSuper] = useState();

    // set msg
    const [validationMsg, setValidationMsg] = useState({});
    const [msgName, setMsgName] = useState('');
    const [msgEmail, setMsgEmail] = useState('');
    // set id
    const {id} = useParams();
    // others
    const [disable, setDisable]= useState(false);
    const history = useHistory();

    // set const
    const [nameRole, setNameRole] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVetify, setPasswordVetify] = useState('');
 
    // handle change name 
    const handleNameRole = (e) => {
        setNameRole(e.target.value)
    }

    const handleRemoveMess = () => {
        validationMsg.nameRole = ''
        $('.name_store').removeClass('color_danger')
    }

    const handleUserName = (e) => {
        setUserName(e.target.value)
    }

    const handleRemoveMessUserName = () => {
        validationMsg.userName = ''
        setMsgName('')
        $('.userName').removeClass('color_danger')
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleRemoveMessEmail = () => {
        validationMsg.email = ''
        setMsgEmail('')
        $('.email').removeClass('color_danger')
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleRemoveMessPassword = () => {
        validationMsg.password = ''
        $('.password').removeClass('color_danger')
    }

    const handlePasswordVetify = (e) => {
        setPasswordVetify(e.target.value)
    }

    const handleRemoveMessPassVetify = () => {
        validationMsg.passwordVetify = ''
        $('.passwordVetify').removeClass('color_danger')
    }

    const dispatch = useDispatch();


    // handle change permission id
    const handleCheckPermiss = (e) => {
        validationMsg.permissCheck = ''
        if(e.target.checked) {
            setPermissCheck([...permissCheck, parseInt(e.target.value)])
        }else {
            setPermissCheck(permissCheck.filter(item => item !== parseInt(e.target.value)))
        }
    }
    
    // get permission ids
    useEffect(() => {
        axios.get(`${ENDPOINT.ROLE}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setPermissionList(response.data.data);
            }
        })
        .catch(error =>{                
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        })
    }, [dispatch])

    // get data role
    useEffect(() => {
        if(id) {
            let unmounted = false;
            axios.get(`${ENDPOINT.ADMIN}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setNameRole(response.data.data.name || []);
                        setEmail(response.data.data.email || []);
                        setCheckSuper(response.data.data.is_supperadmin);
                        setUserName(response.data.data.username)
                        setPermissCheck(response.data.data.roles.map(x => x.id))
                    }
                }
            })
            .catch(error =>{                
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            })
            return () => { unmounted = true };
        }
    }, [id, dispatch])

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(nameRole.length > 255 ){
            msg.nameRole = 'Họ và tên không dài quá 255 kí tự'
        }else if( nameRole.charAt(0) === '' ){
            msg.nameRole = 'Vui lòng nhập họ và tên'
            $('.name_store').addClass('color_danger')
        }else if(nameRole.slice(0, 2) === ' ' ){
            msg.nameRole = 'Vui lòng nhập họ và tên'
            $('.name_store').addClass('color_danger')
        }

        if(userName.length > 255 ){
            msg.userName = 'Tên đăng nhập không dài quá 255 kí tự'
        }else if( userName.charAt(0) === '' ){
            msg.userName = 'Vui lòng nhập tên đăng nhập'
            $('.userName').addClass('color_danger')
        }else if(userName.slice(0, 2) === ' ' ){
            msg.userName = 'Vui lòng nhập tên đăng nhập'
            $('.userName').addClass('color_danger')
        }

        if(permissCheck.length === 0 ){
            msg.permissCheck = 'Vui lòng chọn 1 vai trò'
        }

        if(email.length <= 0 ){
            msg.email = 'Vui lòng nhập Email'
            $('.email').addClass('color_danger')
        }else if (!isEmail(email)){
            msg.email = 'Vui lòng nhập đúng định dạng Email'
            $('.email').addClass('color_danger')
        }

        if(id){
            if(password.length > 0){
                if(password.length < 6 || password.length > 30){
                    $('.password').addClass('color_danger')
                    msg.password = "Mật khẩu phải nằm trong khoảng từ 6 đến 30 kí tự"
                }
        
                if(passwordVetify !== password) {
                    $('.passwordVetify').addClass('color_danger')
                    msg.passwordVetify = "Mật khẩu nhập lại không trùng khớp"
                }
            }
        }else {
            if(password.length === 0) {
                msg.password = "Vui lòng nhập mật khẩu mới"
                $('.password').addClass('color_danger')
            }else if(password.length < 6 || password.length > 30){
                $('.password').addClass('color_danger')
                msg.password = "Mật khẩu phải nằm trong khoảng từ 6 đến 30 kí tự"
            }
    
            if(passwordVetify.length === 0) {
                msg.passwordVetify = "Vui lòng nhập lại mật khẩu"
                $('.passwordVetify').addClass('color_danger')
            }else if(passwordVetify !== password) {
                $('.passwordVetify').addClass('color_danger')
                msg.passwordVetify = "Mật khẩu nhập lại không trùng khớp"
            }
        }

     
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // form data for update 
    const updateNewRole = new FormData();
    updateNewRole.append('name', trim(nameRole));
    updateNewRole.append('role_ids', permissCheck)
    updateNewRole.append('username', trim(userName));
    updateNewRole.append('email', trim(email));
    updateNewRole.append('password', password);
    updateNewRole.append('password_confirm', passwordVetify);
    // update Role
    const updateRole = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.ADMIN}/${id}`, updateNewRole,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Cập nhật admin thành công !')
                setTimeout(() => {
                    history.push('/main/admin')
                }, 2000);
            }
          })
          .catch(error => {
            if(error.response.data.errors.email === "Email đã tồn tại!") {
                setMsgEmail('Email đã tồn tại')
            } 
            if(error.response.data.errors.username === "Username đã tồn tại!"){
                setMsgName('Username đã tồn tại')
            }
          }
        )
    }

    // form data for create 
    const createNewRole = new FormData();
    createNewRole.append('name', trim(nameRole));
    createNewRole.append('role_ids', permissCheck)
    createNewRole.append('username', trim(userName));
    createNewRole.append('email', trim(email));
    if(password.length > 0){
        createNewRole.append('password', password);
        createNewRole.append('password_confirm', passwordVetify);
    }
    // create role
    const createRole = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.ADMIN}`, createNewRole,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Thêm admin mới thành công !')
                setTimeout(() => {
                    history.push('/main/admin')
                }, 2000);
            }
        
          })
          .catch(errors => {
            if(errors.response.data.errors.email === "Email đã tồn tại!") {
                setMsgEmail('Email đã tồn tại')
            } 
            if(errors.response.data.errors.username === "Username đã tồn tại!"){
                setMsgName('Username đã tồn tại')
            }
          }
        )
    }
    // handle checked
    const res = permissionList.map(x => ({...x,is:permissCheck.includes(x.id)}))

    // handle password show/hide
    function initSettings() {
        $("#change_password-1").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
        $("#change_password-2").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
    }
    useEffect(() => {
        initSettings()
    })

    return (
        <div className="brands pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa admin</h1> : <h1 className="customer-title mb-3">Thêm mới admin</h1>} 
                </div>
            </div>
            <Form.Label className="col-2 pl-0 pr-0">Họ và tên <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control name_partner name_store" 
                    value={nameRole}
                    onChange={handleNameRole} 
                    onKeyDown={handleRemoveMess} 
                    aria-label="Username" 
                    aria-describedby="basic-addon1"
                />
            </div>
            <p className="text-danger mb-0">{validationMsg.nameRole}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Tên đăng nhập <span style={{'color':'red'}}>*</span> </Form.Label>
                <div className="input-group">
                <input 
                    type="text" 
                    className="form-control name_partner userName" 
                    value={userName}
                    onChange={handleUserName} 
                    onKeyDown={handleRemoveMessUserName} 
                    aria-label="Username" 
                    aria-describedby="basic-addon1"
                />
            </div>
            <p className="text-danger mb-0">{validationMsg.userName}</p>
            <p className="text-danger mb-0">{msgName}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Email <span style={{'color':'red'}}>*</span> </Form.Label>
                <div className="input-group">
                <input 
                    type="text" 
                    className="form-control name_partner email" 
                    value={email}
                    onChange={handleEmail} 
                    onKeyDown={handleRemoveMessEmail} 
                    aria-label="Username" 
                    autoComplete="off"
                    aria-describedby="basic-addon1"
                />
            </div>
            <p className="text-danger mb-0">{validationMsg.email}</p>
            <p className="text-danger mb-0">{msgEmail}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Mật khẩu {id ? '' : <span style={{'color':'red'}}>*</span>}  </Form.Label>
                <div className="input-group auth-pass-inputgroup changePass-input">
                    <input 
                        type="password" 
                        className="form-control name_partner password" 
                        value={password}
                        autoComplete="off"
                        onChange={handlePassword} 
                        onKeyDown={handleRemoveMessPassword} 
                        aria-label="Username" 
                        aria-describedby="basic-addon1"
                    />
                    <button className="btn btn-light" type="button" id="change_password-1"><i className="mdi mdi-eye-outline"></i></button>
                </div>
            <p className="text-danger mb-0">{validationMsg.password}</p>
                <Form.Label className="col-2 pl-0 pr-0 ">Nhập lại mật khẩu {id ? '' : <span style={{'color':'red'}}>*</span>} </Form.Label>
                <div className="input-group auth-pass-inputgroup changePass-input">
                    <input 
                        type="password" 
                        className="form-control name_partner passwordVetify" 
                        value={passwordVetify}
                        autoComplete="off"
                        onChange={handlePasswordVetify} 
                        onKeyDown={handleRemoveMessPassVetify} 
                        aria-label="Username" 
                        aria-describedby="basic-addon1"
                    />
                    <button className="btn btn-light" type="button" id="change_password-2"><i className="mdi mdi-eye-outline"></i></button>
                </div>
            <p className="text-danger mb-0">{validationMsg.passwordVetify}</p>
            <div className="table-responsive">
            {
                checkSuper === true
                ? 
                    ''
                :
                <div>
                    <div className="d-flex justify-content-between align-self-center mt-3">
                        <h1 className="customer-title mb-0">Vai trò</h1>
                    </div>
                    <div className="form-group">
                        <fieldset className="group mt-3 pt-0">
                            <ul  className="checkbox ml-0">
                                {res && res.map((permission, index) =>
                                    <li key={index}>
                                        <input 
                                            type="checkbox" 
                                            value={permission.id} 
                                            onChange={handleCheckPermiss} 
                                            id={permission.id} 
                                            checked={permission.is}
                                        />
                                        <label htmlFor={permission.id}>{permission.name}</label></li> 
                                )}
                                <p className="text-danger mb-0">{validationMsg.permissCheck}</p>
                            </ul>
                        </fieldset>
                    </div>
                </div>    
            }
            </div>
            <div >
                <Link to="/main/admin"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link> 
                {id ? <button type="button" disabled={disable} onClick={updateRole} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createRole} className="btn btn-success mt-5">Lưu</button>}
      
            </div>
        </div>
    )
}

export default AdminEditAndStore;
