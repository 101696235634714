import React, {useEffect, useState} from 'react'
import {ProductApiService} from "../../ultils/EndPoint";
import {convertDateTime, formatCurrency, objectToQueryParams} from "../../helpers/function";
import {Pagination} from "antd";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import trim from "validator/es/lib/trim";

function ListGiftExchangePoint() {
    // set const
    const [listProductGift, setListProductGift] = useState([]);
    const [customersPerPage] = useState(10)
    const [keyword, setKeyword] = useState("")
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })

    const GIFT_EXCHANGE_POINT = 1

    // get data
    const getListProductGift = (query) => {
        ProductApiService.getList(query).then(r => {
            if(r.status) {
                setListProductGift(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }

    function setQueryFilter(data) {
        data.per_page = customersPerPage
        data.type_id = GIFT_EXCHANGE_POINT
        data.keyword = keyword
        return objectToQueryParams(data)
    }

    const setCurrentPage = (page) => {
        let data = {}
        data.page = page
        let query = setQueryFilter(data)
        getListProductGift(query)
    }

    useEffect(() => {
        let data = {}
        let query = setQueryFilter(data)
        getListProductGift(query)
    }, [keyword]);

    return (
        <div className="pl-4 pr-4 mt-3">
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Danh sách quà đổi điểm</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block w-100">
                    <div className="d-flex justify-content-between">
                        <Form.Group className="mr-4 w-50">
                            <input onChange={(e) => {setKeyword(trim(e.target.value))}} id="search-product" className="form-control" type="search" placeholder="Tìm kiếm quà đổi điểm" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                            <span className="bx bx-search-alt"></span>
                        </Form.Group>
                        <Link to ="/main/gift-exchange-point/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
                    </div>
                </form>
            </div>
            <div className="table-reponsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>STT</th>
                    <th>Tên</th>
                    <th>Điểm</th>
                    <th>Giá (VNĐ)</th>
                    <th>Hình ảnh</th>
                    <th>Số lượng</th>
                    <th>Trạng thái</th>
                    <th>Hành động</th>
                    </thead>
                    <tbody>
                    {listProductGift && listProductGift.map((item,index) =>
                        <tr key={index}>
                            <td style={{ "width" : "10%"}}>{index + 1}</td>
                            <td style={{ "width" : "30%"}}>{item.name}</td>
                            <td>{item.point}</td>
                            <td>{formatCurrency(item.price)}</td>
                            <td><img width={180} height={180} src={item.medias[0].image} alt="image-product"/></td>
                            <td>{item.product_stock}</td>
                            <td>{item.status === 0 ? "Ẩn" : "Hiện"}</td>
                            <td>
                                <Link to={`/main/gift-exchange-point/${item.id}`}>
                                    <i className="mdi mdi-pencil font-size-18"></i>
                                </Link>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {dataPaginate.totalRecord <= customersPerPage ? '' :
                <Pagination
                    onChange={(value) => setCurrentPage(value)}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    showSizeChanger= {false}
                    defaultPageSize={customersPerPage}
                />
            }
        </div>
    )
}
export default ListGiftExchangePoint
