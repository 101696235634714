import axios from 'axios';
import ENDPOINT from './EndPoint';

export const signIn = (user) => {
    return axios.post(`${ENDPOINT.LOGIN_AUTH}`, user)
      .then(function (response) {
          return response.data
      })
      .catch((error) => Promise.reject(error))
} 

