import React, {useEffect, useState} from 'react'
import ENDPOINT, {listdeal, ReviewApiService, ScriptApiService} from "../../ultils/EndPoint";
import {convertDateTime, objectToQueryParams} from "../../helpers/function";
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import {Link} from "react-router-dom";
import trim from "validator/es/lib/trim";
import {Button, Modal} from "react-bootstrap";

function ListScript() {
    // set const
    const [listScript, setListScript] = useState([]);
    const [customersPerPage] = useState(10)
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })

    const [idSelected, setIdSelected] = useState(0)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // get data 
    const getListScript = (query) => {
        ScriptApiService.getList(query).then(r => {
            if(r.status) {
                setListScript(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }

    const setCurrentPage = (page) => {
        let data = {}
        data.page = page
        data.per_page = customersPerPage
        let query = objectToQueryParams(data)
        getListScript(query)
    }

    useEffect(() => {
        const data = {
            per_page: customersPerPage,
        };
        let query = objectToQueryParams(data)
        getListScript(query)
    }, []);

    function deleteScript(id){
        ScriptApiService.delete(id).then(r => {
            if (r.status){
                toast.success('Xóa câu hỏi tự động thành công!');
                const data = {
                    per_page: customersPerPage,
                };
                let query = objectToQueryParams(data)
                getListScript(query)
            }
        })
    }
    function setFirst(id){
        ScriptApiService.setFirst(id).then(r => {
            if (r.status){
                toast.success('Thay đổi câu hỏi tự động thành công!');
                const data = {
                    per_page: customersPerPage,
                };
                let query = objectToQueryParams(data)
                getListScript(query)
            }
        })
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Danh sách câu hỏi tự động</h1>
                </div>
            </div>
            <form  className="pt-3">
                <div className="d-flex justify-content-between">
                    <Link to="/main/scripts/create">
                        <button type="button" className="btn btn-add"> + Thêm</button>
                    </Link>
                </div>
            </form>
            <div className="table-reponsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>STT</th>
                    <th>Tên tự động</th>
                    <th>Bắt đầu chat</th>
                    <th>Lựa chọn</th>
                    <th>Hành động</th>
                    </thead>
                    <tbody>
                    {listScript && listScript.map((item,index) =>
                        <tr key={index}>
                            <td style={{ "width" : "5%", paddingLeft:'10px'}}>{index + 1}</td>
                            <td style={{ "width" : "20%"}}>{item.name}</td>
                            <td style={{ "width" : "13%"}}>{item.is_first ?  <i className="text-success mdi mdi-check font-size-18" />:  <i className="text-danger mdi mdi-cancel font-size-18" />}</td>

                            <td>
                                {item.script_options.length ? item.script_options.map((v, key) =>
                                    <b>{v.script_option.name + (key === item.script_options.length - 1 ? "" : " , ")}</b>
                                ) : ''}
                            </td>
                            <td style={{ "width" : "10%"}}>
                                 <Link to={`/main/scripts/${item.id}`}>
                                    <i className="mdi mdi-pencil font-size-18" />
                                 </Link>
                                {
                                    item.is_first ? '' : <span><i onClick={() => {setIdSelected(item.id); handleShow()}} style={{cursor: "pointer"}} className="text-danger mdi mdi-delete font-size-18"/> <i onClick={() => {setFirst(item.id)}} style={{cursor: "pointer"}} className="text-info mdi mdi-arrow-up font-size-18"/></span>
                                }
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {dataPaginate.totalRecord <= customersPerPage ? '' :
                <Pagination
                    onChange={(value) => setCurrentPage(value)}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    showSizeChanger= {false}
                    defaultPageSize={customersPerPage}
                />
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá thương hiệu</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá câu hỏi tự động này không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        deleteScript(idSelected);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default ListScript
