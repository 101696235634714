import React, {useEffect, useState} from 'react'
import ENDPOINT from "../../ultils/EndPoint";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {formatCurrency} from "../../helpers/function";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function DetailInfoDeal() {
    // set const
    const [listDeal, setListDeal] = useState([]);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const {id} = useParams();

    // call api for edit
    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT.DEALLIST}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (r) {
                if(r.status === 200) {
                    setListDeal(r.data.data)
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    }, [id, dispatch])

    // get data list
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.DEALLIST}/${id}/added`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setData(response.data.data)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [id, dispatch])

    return (
        <div className="pl-4 pr-4 mt-3">
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Thông tin deal</h1>
                </div>
            </div>

            <div style={{fontFamily:'none'}} className="table-dealInfo mt-3 mb-4">
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Tên deal</li>
                    <li>{listDeal.name}</li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Thời gian diễn ra</li>
                    <li>Từ <span style={{'fontWeight':'bold'}}>{listDeal.start_time}</span> đến <span style={{'fontWeight':'bold'}}>{listDeal.end_time}</span></li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Ghi chú</li>
                    <li>{listDeal.note}</li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Hình ảnh </li>
                    <li><img alt="" src={listDeal.banner} style={{'width':'fit-content', 'height':'100px', 'objectFit':'fill'}}></img></li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Giảm</li>
                    <li>{new Intl.NumberFormat('it-IT').format(listDeal.value)} {listDeal.type === 1 ? 'VNĐ' : '%'}</li>
                </ul>
            </div>
            
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Thông tin sản phẩm</h1>
                </div>
            </div>
            <div className="pl-0 pr-0 mt-3">        
            <div className="category-manager-wapper mt-0">
                <div className="table-responsive">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Tên sản phẩm</th>
                                <th>Danh mục</th>
                                <th>Thương hiệu</th>
                                <th>Giá bán(VNĐ)</th>
                                <th>Quy cách</th>
                                <th>Sản xuất</th>
                                <th>Hình ảnh</th>
                                <th>Trạng thái</th>
                                <th>Loại thuốc</th>
                            </tr>
                        </thead>
                        <tbody row="10">
                        {data ? 
                        data
                        .map((product, index) => 
                                <tr key={index}>
                                    <th style={{'fontWeight': 'normal'}}>
                                        {product.id}
                                    </th>
                                    <th>
                                        {product.name}
                                    </th>
                                    <th>
                                        {product.category.name}
                                    </th>
                                    <th>
                                        {product.brand.name}
                                    </th>
                                    <th>
                                        { formatCurrency(product.discount)}
                                    </th>
                                    <th>
                                        {product.capacity}
                                    </th>
                                    <th>
                                        {product.made_in}
                                    </th>
                                    <th>
                                        {product.medias !== null 
                                        ?
                                        product.medias.map((check, index) => 
                                            check.collection_name === 'single' ? <img key={index} alt="" style={{'width':'100px', 'height':'100px'}} src={check.image} /> : ''
                                        )
                                        : 
                                        ''}
                                    </th>
                                    <th>
                                        {product.status === 0 ? 'Hết hàng' : 'Đang bán'}
                                    </th>
                                    <th>
                                        {product.type !== null ? product.type.name : ''}
                                    </th>
                                </tr>
                        ) : ''}
                        </tbody>
                    </table>
                    {/* {numberPage <= 10 ? '' : 
                        <Pagination 
                            onChange={(value) => setcurrentPage(value)}
                            total={numberPage}
                            current={currentPage}
                            showSizeChanger= {false}
                        /> 
                    } */}
                    </div>
                </div>
            </div>

            <div>
                <Link to="/main/deal"><button type="button" className="btn btn-secondary">Quay lại</button></Link> 
            </div>
        </div>
    )
}
export default DetailInfoDeal
