import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ENDPOINT, {listcoupon} from '../../ultils/EndPoint';
import $ from 'jquery';
import {Button, Modal} from "react-bootstrap";
import Select from 'react-select';
import {Link} from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import {Pagination} from "antd";
import {formatCurrency} from '../../helpers/function';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function CouponProduct() {
  // set const 
  const [data, setData] = useState([]);
  const animatedComponents = makeAnimated();
  const [dataSelect, setDataSelect] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [multiDelete, setMultiDelete] = useState([]);
  const [changeDataCheck, setChangeDataCheck] = useState(false);
  const dispatch = useDispatch();
  const [productsPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false)

  let option = []
  if (dataSelect && dataSelect.length > 0) {
    dataSelect.forEach(role => {
      let roleDate = {}
      roleDate.value = role.id
      roleDate.label = role.name
      option.push(roleDate)
    })
  }
  const {id} = useParams();

  // set for modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAll, setShowAll] = useState(false);
  const handleCloseAll = () => setShowAll(false);
  const handleShowAll = () => setShowAll(true);
  const [idProduct, setIdProduct] = useState('');
  const [nameProductCurr, setNameProductCurr] = useState('');
  const [dataCheckPro, setDataCheckPro] = useState({})
    // set constans admin token
  const admin_token = localStorage.getItem('User-Admin')
  // get data list
  useEffect(() => {
      let unmounted = false;
      let query = '';
      const data = {
        per_page: productsPerPage,
        page: currentPage
      };
      Object.keys(data).map((key, index) =>
          query += (index === 0 ? '?' : "&") + key + '=' + data[key]
      )
      axios.get(`${ENDPOINT.COUPON}/${id}`+ query, {
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
          }
      })
      .then(async function (response) {
          if (!unmounted) {
              if (response.status === 200) {
                  let products = response.data.data.products.records
                  let boxers = dataCheckPro
                  if (!boxers['page_' + currentPage]) {
                      boxers['page_' + currentPage] = []
                      products.map((product) => {
                          boxers['page_' + currentPage].push(false)
                      })
                  }
                  setDataCheckPro(boxers)
                  // await setChecked()
                  setData(response.data.data.products.records)
                  setTotal(response.data.data.products.total_record)

                  if($('input.selectedId').not(':checked').length){
                      $('#selectall').prop('checked', false);
                      setIsCheckAll(false)
                  }else{
                      $('#selectall').prop('checked', true);
                      setIsCheckAll(true)
                  }
              }
          }
      })
      .catch(error => {
        console.log(error)
    }) 
      return () => { unmounted = true };
  }, [id, productsPerPage, currentPage, dispatch])

    const setChecked = () => {
            // dataCheckPro['page_' + currentPage].map((proCheck, index) => {
            //         $(document).ready(function () {
            //          if (proCheck) {
            //              $(`.selected-pro-${currentPage}-${index}`).prop("checked", true)
            //          } else {
            //              if ($(`.selected-pro-${currentPage}-${index}`).is(':checked')) {
            //                  $(`.selected-pro-${currentPage}-${index}`).prop("checked", false)
            //              }
            //          }
            //         });
            // })
    }
  // get data option
  useEffect(() => {
    let unmounted = false;
    axios.get(`${ENDPOINT.COUPON}/${id}/available-products`, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
        }
    })
    .then(function (response) {
        if (!unmounted) {
            if(response.status === 200) {
              setDataSelect(response.data.data);
              setChangeDataCheck(false)
            }
        }
    })
    .catch(error => {
      if(error.response.status === 401){
          dispatch(logoutSuccess(true))
      }
  }) 
    return () => { unmounted = true };
  }, [id, changeDataCheck, dispatch])

  // post new
  const ids = []
  selectedProduct && selectedProduct.map(select => 
    ids.push(select.value)
  )

  // params for create product
  const createProducts = new FormData();
  createProducts.append('product_ids',"["+ ids + ']')

  const addNewProductInGroup = () => {
    return axios.post(`${ENDPOINT.COUPON}/add-products/${id}`, createProducts,  {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
      }
    })
    .then(function (response) {
      if(response.status === 200) {
        toast.success('Thêm sản phẩm vào mã giảm giá thành công')
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } 
    })
    .catch(error => {
      
    }
  )
  }
  
  // set checked
  useEffect(() =>{
    $(function () {
        $('.footer').hide();
        $(document).ready(function () {
            $('#selectall').click(function () {
                $('.selectedId').prop('checked', this.checked)
                let checked = $(this).is(":checked")
                dataCheckPro['page_' + currentPage].forEach((input, index ) => {
                    dataCheckPro['page_' + currentPage][index] = $(this).is(":checked")
                })
                setDataCheckPro(dataCheckPro)
                // setChecked()
            });
            $('.selectedId').change(function () {
                var check = ($('.selectedId').filter(":checked").length === $('.selectedId').length);
                $('#selectall').prop("checked", check);
                let index = $(this).attr('index')
                dataCheckPro['page_' + currentPage][index] = $(this).is(":checked")
                setDataCheckPro(dataCheckPro)
                // setChecked()
            });
        });
    })
  })


  // delete one item 
  const deleteFormData = new FormData();
  deleteFormData.append('model_ids', '[' + idProduct + ']')

  const handleDelete = () => {
    axios.delete(`${ENDPOINT.COUPON}/${id}/remove-product`,{
      headers: { Authorization: 'Bearer ' +  admin_token },
      data: deleteFormData
    })
      .then(function (response) {
          if(response.status === 200) {
              clearCheckedCheckbox()
              toast.success('Xóa sản phẩm thành công !')
              const remainingpartner = data.filter((result) => result.id !== idProduct)
              setData(remainingpartner)
              setChangeDataCheck(true)
          }
      })
      .catch(error => {
          console.log(error);
      }
    )
  }

  const addAllProduct = () => {
    handleShowAll()
  }

  const handleAddAll = () => {
    let addAllProduct = dataSelect.map(item => {
      return item.id
    })
    const allProduct = new FormData();
    allProduct.append('product_ids',"["+ addAllProduct + ']')
    return axios.post(`${ENDPOINT.COUPON}/add-products/${id}`, allProduct,  {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
      }
    })
      .then(function (response) {
        if(response.status === 200) {
          toast.success('Thêm sản phẩm vào mã giảm giá thành công')
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch(error => {

        })
  }

    function handleChangeIdProduct(e){
        let index = multiDelete.indexOf(parseInt(e))
        if (index > -1){
            multiDelete.splice(index, 1);
        }else{
            multiDelete.push(parseInt(e))
        }
        setMultiDelete(multiDelete)
    }

  // handleMulti delete
  const changeCheckBoxAll = (e) => {
    if($('#selectall').is(":checked")){

        data.forEach(e => {
            if (!multiDelete.includes(e.id)){
                 multiDelete.push(parseInt(e.id))
            }
        })

    }else if(!$('#selectall').is(":checked") && !$('.selectedId').is(":checked")){
      // remove id product from multiDelete
        data.map(a => {
            let index = multiDelete.indexOf(parseInt(a.id))
            if (index > -1){
                multiDelete.splice(index, 1);
            }
        })

        setMultiDelete(multiDelete)

    }else {
      setMultiDelete([])
    }
  }

  // delete one item 
  let dataReq = {}
  dataReq.model_ids = `[${multiDelete}]`

  
  // delete multi
  const deleteMultiProduct= async () => {
      // let checkboxes = document.querySelectorAll('input[type="checkbox"].selectedId:checked')
      // let productIds = [];
      let data = {}
      data.model_ids = `[${multiDelete}]`
      if (!multiDelete.length){
          alert('Chưa chọn sản phẩm nào!')
          return
      }

      listcoupon.deleteCouponProduct(id, data).then(function (response) {
              if(response.status) {
                  toast.success('Xóa sản phẩm thành công !')
                  setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
          })
          .catch(error => {

          })
  }
  // handle change select product
  const handleChangeSelected = (e) => {
    setSelectedProduct(e);
  }

  function clearCheckedCheckbox(){
      $('input[type="checkbox"]:checked').each(function (){
          $(this).prop('checked', false);
      })
      setMultiDelete([])
  }

  function handleChangePage(value){
      setcurrentPage(value)
  }
  return (
    <div className="pl-4 pr-4 mt-3">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Thêm sản phẩm vào mã giảm giá</h1>
            </div>
        </div>
        <div className="d-flex align-items-center mt-3">
            <div className="input-group col-8 pl-0">
                <Select
                  className="col-12 pl-0 pr-0 select_addProduct_group"
                  isMulti
                  onChange={handleChangeSelected}
                  placeholder = {"Sản phẩm áp dụng"}
                  options={option}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                >
                </Select>
            </div>
            <div className="ml-3">
                <button type="button" onClick={addNewProductInGroup} className="btn btn-add"> + Thêm</button>
            </div>
          </div>
          <div className="d-flex">
            <div className="mt-3 mb-0 mr-2">
              <button type="button" onClick={addAllProduct} className="btn btn-success font-size-14"> Thêm tất cả sản phẩm</button>
            </div>
             <div className="mt-3 mb-0">
                <button type="button" onClick={deleteMultiProduct} className="btn btn-danger font-size-14"> Xoá đã chọn</button>
            </div>
          </div>
        <div className="category-manager-wapper mt-0">
        <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th className="title_address">
                        <div className="d-flex">
                           <input
                                type="checkbox"
                                name="payment"
                                // onChange={handleCheckedDeleteAll}
                                onChange={changeCheckBoxAll}
                                id="selectall"
                                 />


                            <label htmlFor="checkbox"></label>
                        </div>
                    </th>
                    <th>ID</th>
                    <th>Tên sản phẩm</th>
                    <th>Danh mục</th>
                    <th>Thương hiệu</th>
                    <th>Giá bán(VNĐ)</th>
                    <th>Hình ảnh</th>
                    <th style={{'width':'100px'}}>Hành động</th>
                </tr>
                </thead>
                <tbody row="10">
                  {data ? 
                  data
                  .map((product, index) => 
                        <tr key={index}>
                            <th className="title_address">
                                <div className="d-flex">
                                    {
                                        multiDelete.includes(product.id) ? <input
                                            type="checkbox"
                                            name="payment"
                                            id={`${product.id} selected-pro-${currentPage}-${index}`}
                                            product_id={product.id}
                                            index={index}
                                            className={`selectedId selected-pro-${currentPage}-${index}`}
                                            value={product.id}
                                            onChange={(e) => handleChangeIdProduct(e.target.value)}
                                            checked={multiDelete.includes(product.id)}
                                        /> : <input
                                            type="checkbox"
                                            name="payment"
                                            id={`${product.id} selected-pro-${currentPage}-${index}`}
                                            product_id={product.id}
                                            index={index}
                                            className={`selectedId selected-pro-${currentPage}-${index}`}
                                            value={product.id}
                                            onChange={(e) => handleChangeIdProduct(e.target.value)}/>
                                    }

                                    <label htmlFor="checkbox"></label>
                                </div>
                            </th>
                            <th style={{'fontWeight': 'normal'}}>
                                {product.id}
                            </th>
                            <th>
                              <Link to={{pathname: `${process.env.REACT_APP_URL}san-pham/${product.slug}`}} target="_blank">
                                  {product.name}
                              </Link>
                            </th>
                            <th>
                                {product.category_name}
                            </th>
                            <th>
                                {product.brand_name}
                            </th>
                            <th>
                              <span>{formatCurrency(product.price)}</span>
                            </th>
                            <th>
                                {product.image !== null 
                                ?
                                  product.image.map((check, index) => 
                                      check.collection_name === 'single' ? <img key={index} alt="" style={{'width':'100px', 'height':'100px'}} src={check.image} /> : ''
                                  )
                                : 
                                ''}
                            </th>
                            <td style={{'width':'100px'}}>
                                <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                    handleShow();
                                    setIdProduct(product.id)
                                    setNameProductCurr(product.name)
                                }} className="mdi mdi-delete font-size-18"></i>
                            </td>
                        </tr>
                  ) : ''}
                </tbody>
            </table>
            {total <= 10 ? '' : 
                <Pagination 
                    onChange={(value) =>  handleChangePage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                /> 
            }
            
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá sản phẩm khỏi nhóm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá sản phẩm <span style={{'color':'blue'}}>{nameProductCurr}</span> này ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleClose();
                        handleDelete(idProduct)
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
       <Modal show={showAll} onHide={handleCloseAll}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm sản phẩm vào coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc chắn muốn thêm tất cả sản phẩm vào coupon này không?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAll}>
            Thoát
          </Button>
          <Button variant="primary" onClick={() => {
            handleCloseAll();
            handleAddAll()
          }}>
            Xác nhận
          </Button>
        </Modal.Footer>
      </Modal>
          <div>
              <Link to="/main/coupon"><button type="button" className="btn btn-secondary">Quay lại</button></Link>
          </div>
    </div>
    );
}

export default CouponProduct
