import React, {useEffect, useState} from 'react'
import ENDPOINT from '../../ultils/EndPoint';
import {ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import $ from "jquery";
import axios from "axios";
import './ListOrders.scss';
import { formatDate, formatCurrency, formatDateTime } from '../../helpers/function';
import {listMethodDelivery, listMethodPayment, listOrderStatus, listStatusDelivery} from "../../helpers/constant";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function ListOrders() {
    // set const 
    const [listOrders, setListOrders] = useState([]);
    const [keyword, setFilter] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('')
    const [paymentDelivery, setPaymentDelivery] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [status, setStatus] = useState('')
    const dispatch = useDispatch();

    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(10);

    // set Orthers
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // call api set list 
    useEffect(() => {
        let query = '';
        const data = {
            per_page : customersPerPage,
            page: currentPage,
            keyword: keyword,
            from_date: fromDate,
            to_date: toDate,
            payment_method_id:paymentMethod,
            delivery_status:paymentDelivery,
            payment_status: paymentStatus,
            status: status,
            is_draf: 0
        }
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        let unmounted = false;
        axios.get(`${ENDPOINT.LIST_ORDERS}` + query, config)
        .then((res) => {
            if (!unmounted) {
                setListOrders(res.data.data.records);
                setNumberPage(res.data.data.total_record)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [
        customersPerPage, 
        currentPage, 
        keyword, 
        fromDate,  
        toDate, 
        paymentStatus, 
        paymentMethod,
        paymentDelivery,
        status,
        dispatch
    ]);

    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    const handleInputSearch = (e) => {
        setFilter(removeExtraSpace(e.target.value))
        setcurrentPage(1)
    }

    const optimisedChange = debounce(handleInputSearch)

    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    const handleChangeFromDate = (e) => {
        setcurrentPage(1)
        setFromDate(e.target.value)
    }

    const handleChangeToDate = (e) => {
        setcurrentPage(1)
        setToDate(e.target.value)
    }

    const handleChangePaymentStatus = (e) => {
        setcurrentPage(1)
        setPaymentStatus(e.target.value);
    }

    const handleChangePaymentDelivery = (e) => {
        setcurrentPage(1)
        setPaymentDelivery(e.target.value);
    }

    const handleChangePaymentMethod = (e) => {
        setcurrentPage(1)
        setPaymentMethod(e.target.value);
    }

    const handleChangeStatus = (e) => {
        setcurrentPage(1)
        setStatus(e.target.value);
    }

    // restart all
    const handleRestart = () => {
        setcurrentPage(1)
        setFilter('')
        setFromDate('')
        setToDate('')
        setPaymentStatus('')
        setPaymentDelivery('')
        setPaymentMethod('')
        setStatus('')
        $('#searchCategory').val('')
        $('#start_date').val('')
        $('#end_date').val('')
        $('#selectStatusPayment').val('')
        $('#deliveryStatus').val('')
        $('#paymentMethod').val('')
        $('#selectTypeList').val('')
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = ( Data , fileName) => {
        const ws = XLSX.utils.json_to_sheet(Data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    var listExportExcel = [];
    // call api for all list 
    const getAllDataNoPaginate = () => {
    let query = '';
    const data = {
        per_page : numberPage,
        keyword: keyword,
        from_date: fromDate,
        to_date: toDate,
        payment_method_id:paymentMethod,
        delivery_status:paymentDelivery,
        payment_status: paymentStatus,
        status: status,
        is_draf: 0
    }
    Object.keys(data).map((key,index) => 
        query += (index === 0 ? '?' : "&") + key + '=' + data[key]
    )
    axios.get(`${ENDPOINT.LIST_ORDERS}` + query, config)
    .then((res) => {
        res.data.data.records && res.data.data.records.map(item => {
            listExportExcel.push({
                'STT': item.id,
                'CODE': item.code,
                'Trạng thái đơn hàng': listOrderStatus.find(status => {
                    if(status.id === item.status){
                        return status
                    }
                }).name,
                'Phương thức thanh toán': item.payment_method.name,
                'Trạng thái vận chuyển': listStatusDelivery.find(status => {
                    if(status.id === item.delivery_status){
                        return status
                    }
                }).name,
                'Phương thức vận chuyển': listMethodDelivery.find(method => {
                    if(method.id === item.delivery_method_id){
                        return method
                    }
                }).name,
                'Trạng thái thanh toán': item.payment_status === false ? 'Chưa thanh toán' : 'Đã thanh toán',
                'Số điện thoại': item.phone,
                'Tên': item.name,
                'Ngày tạo': formatDateTime(item.created_at),
                'Giá sản phẩm (VNĐ)': formatCurrency(item.real_price),
                'Ghi chú': item.note,
            })
        })
        exportToCSV(listExportExcel,'Danh sách đơn hàng')
    })
    .catch(error => {
        if(error.response.status === 401){
            dispatch(logoutSuccess(true))
        }
    }) 
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="flex-column d-sm-flex justify-content-end align-items-start pb-0">
                    <h1 className="customer-title mb-3">Quản lý đơn hàng</h1>
                    <div className="d-flex">
                        {/* <Exportfile Data={listExportExcel} fileName={'Danh sách đơn hàng'}/> */}
                        <button type="button" onClick={() => getAllDataNoPaginate()} className="btn btn-success btn-export d-flex ">
                            <i className="mdi mdi-download d-block font-size-16"></i>Export
                        </button>
                        <button type='button'  className="btn btn-info btn-reload ml-3" onClick={handleRestart}>
                            <i className="fas fa-sync-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <div className="d-flex ">
                            <Form.Group className="mr-4 w-20">
                                <input 
                                    id="searchCategory" 
                                    className="form-control" 
                                    type="search" 
                                    placeholder="Tìm kiếm" 
                                    aria-label="Search" 
                                    onChange={optimisedChange} 
                                    style={{ background: "#fff", border: '1px solid #ced4da'}}
                                    />
                                <span className="bx bx-search-alt"></span>
                            </Form.Group>
                            <div className="d-flex">
                            <Form.Group className="d-flex mr-5">
                                <Form.Label className="align-items-center mb-0 pl-0 pr-0 col-3 align-self-center">
                                    Từ ngày
                                </Form.Label>
                                <Form.Control onChange={handleChangeFromDate} type="date" id="start_date" ></Form.Control>
                            </Form.Group>
                            <div className="grid">
                                <Form.Group className="d-flex">
                                    <Form.Label className=" align-items-center mb-0 pl-0 pr-0  col-3 align-self-center">
                                        Đến ngày
                                    </Form.Label>
                                    <Form.Control onChange={handleChangeToDate} type="date" id="end_date" ></Form.Control>
                                    {/*<p className="ml-5 pl-4 text-danger" style={{ "position": "absolute"}}>{validationMsg.startDate}</p>*/}
                                </Form.Group>
                            </div>
                            <div>
                                <Link style={{'marginLeft':'30px'}} to ="/main/create/order"><button type="button" className="btn btn-add">Tạo đơn hàng nháp</button></Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="input-group col-3 pl-0">
                    <select id="selectStatusPayment" name="" onChange={handleChangePaymentStatus} className="form-control custom_select custom_selectProduct" > 
                        <option value="">Trạng thái thanh toán</option>
                        <option value="1">Đã thanh toán</option>
                        <option value="0">Chưa thanh toán</option>
                    </select>
                </div>
                <div className="input-group col-3 pl-0 ">
                    <select name="" id="deliveryStatus" onChange={handleChangePaymentDelivery} className="form-control custom_select custom_selectProduct" > 
                        <option value="">Trạng thái giao hàng</option>
                        {
                            listStatusDelivery.map((status, index) =>
                                <option key={index} value={status.id}>{status.name}</option>
                            )
                        }
                    </select>
                </div>
                <div className="input-group col-3 pl-0 ">
                    <select name="" id="paymentMethod" onChange={handleChangePaymentMethod} className="form-control custom_select custom_selectProduct " > 
                        <option value="">Phương thức thanh toán</option>
                        {listMethodPayment.map(item => {
                            return <option value={item.id}>{item.name}</option>
                        })}
                    </select>
                </div>
                <div className="input-group col-3 pl-0 pr-0">
                    <select name="" id="selectTypeList" onChange={handleChangeStatus} className="form-control custom_select custom_selectProduct" > 
                        <option value="">Trạng thái đơn hàng</option>
                        {listOrderStatus.map(item => {
                            return <option value={item.id}>{item.name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div>
                <div className="table-responsive group_product">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                        <tr>
                            <th>Mã đơn hàng</th>
                            <th>Nguời mua hàng</th>
                            <th>Ngày mua</th>
                            <th>Giá trị đơn hàng(VNĐ)</th>
                            <th>Phương thức thanh toán</th>
                            <th>Phương thức giao hàng</th>
                            <th>Trạng thái giao hàng</th>
                            <th>Trạng thái thanh toán</th>
                            <th>Trạng thái đơn hàng</th>
                        </tr>
                        </thead>
                        <tbody row="10">
                        {listOrders ? listOrders.map((listOrder, index) => 
                                <tr key={index}>
                                    <th>
                                        <Link to={`/main/list-orders/${listOrder.id}`}> 
                                            {listOrder.code}
                                        </Link>
                                    </th>
                                    <td style={{width:'150px'}}>
                                        {listOrder.name}
                                    </td>
                                    <td style={{width:'120px'}}>
                                        {formatDate(listOrder.created_at)}
                                    </td>
                                    <td style={{width:'120px'}}>
                                        {formatCurrency(listOrder.real_price)}
                                    </td>
                                    <td>
                                        {listOrder.payment_method.name}
                                    </td>
                                    <td>
                                        {listMethodDelivery.find(method => {
                                            return method.id == listOrder.delivery_method_id
                                        }).name}
                                    </td>
                                    <td>
                                        {listStatusDelivery.find(status => {
                                            return status.id == listOrder.delivery_status
                                        }).name}
                                    </td>
                                    <td>
                                        {
                                           listOrder.payment_status === false
                                           ? 'Chưa thanh toán'
                                           : 'Đã thanh toán'
                                        }
                                    </td>
                                    <td>
                                        {listOrderStatus.find(status => {
                                                return status.id == listOrder.status
                                            }).name}
                                    </td>
                                </tr>
                        ) : ''}
                        </tbody>
                    </table>
                    {numberPage <= 10 ? '' : 
                        <Pagination 
                            onChange={(value) => setcurrentPage(value)}
                            total={numberPage}
                            current={currentPage}
                            showSizeChanger= {false}
                        /> 
                    }
                </div>
            </div>
        </div>
    )
}

export default ListOrders
