import React, {useEffect, useRef, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import './ListOrders.scss';
import {formatCurrency} from '../../helpers/function';
import {COD, listMethodDelivery, listStatusDelivery} from "../../helpers/constant";
import {useReactToPrint} from "react-to-print";
import axios from "axios";
import ENDPOINT from "../../ultils/EndPoint";
import {useParams} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function ComponentToPrint() {
  const [listOrderDetail, setListOrderDetail] = useState([]);
  const {id} = useParams();
  const dispatch = useDispatch();
  useEffect(()=>{
    if(id) {
      axios.get(`${ENDPOINT.LIST_ORDERS}/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
        }
      })
      .then(function (response) {
        if(response.status === 200) {
          setListOrderDetail(response.data.data)
        }
      })
      .catch(error => {
        if(error.response.status === 401){
            dispatch(logoutSuccess(true))
        }
    }) 
    }
  },[id, dispatch]);
  function getTotalPointFinal(){
    let point = 0
    listOrderDetail.products && listOrderDetail.products.map(product => {
      if (product.point > 0) {
        point += product.point
      }
    })
    return point
  }
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <div className="pl-4 pr-4 mt-3">
        <button type="button" onClick={handlePrint} className="btn btn-info">In đơn hàng</button>
      </div>
      <div className="pl-4 pr-4 mt-3" ref={componentRef}>
        <div className="top mb-5" style={{'textAlign': 'center'}}>
          <h3>Đơn hàng: {listOrderDetail.code}</h3>
        </div>
        <div className="d-flex align-self-center">
          <h1 className="customer-title mb-0">Thông tin sản phẩm</h1>
        </div>
        <div>
          <div className="category-manager-wapper">
            <div className="table-responsive group_product">
              <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên sản phẩm</th>
                  <th>Số lượng</th>
                  <th>Giá trị(VNĐ)</th>
                  <th>Giảm giá</th>
                  <th>Thành tiền</th>
                </tr>
                </thead>
                <tbody row="10">
                {listOrderDetail.products && listOrderDetail.products.map((productDetail, index) =>
                  <tr key={index}>
                    <th>
                      {productDetail.id}
                    </th>
                    <td>
                      {productDetail.name}
                    </td>
                    <td>
                      {productDetail.quantity}
                    </td>
                    <td>
                      {productDetail.product_point ? formatCurrency(productDetail.product_point) + ' điểm' : formatCurrency(productDetail.product_price)}
                    </td>
                    <td>
                      {formatCurrency(productDetail.coupon_value)} {productDetail.coupon_code ? <span style={{fontWeight:'bold'}}>{'(' + productDetail.coupon_code + ')'}</span>  : ''}
                    </td>
                    <td>
                      {productDetail.point ? formatCurrency(productDetail.point) + ' điểm' : (formatCurrency(productDetail.real_price))}
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
            <div className="col pl-0 ">

            </div>
            <div className="col pr-0" style={{'textAlign' : 'right'}}>
              <div className="list_orderboxDetail">
                <p>Tổng giá trị đơn hàng: {new Intl.NumberFormat('it-IT').format(listOrderDetail.product_origin_price ? listOrderDetail.product_origin_price : listOrderDetail.origin_price + listOrderDetail.coupon_product_value )} đ</p>
                <p>Giảm giá: {listOrderDetail.coupon_unit === 1 ?  new Intl.NumberFormat('it-IT').format(listOrderDetail.origin_price / listOrderDetail.coupon_price) : new Intl.NumberFormat('it-IT').format(listOrderDetail.coupon_price)} đ</p>
                <p>Giảm giá sản phẩm: {new Intl.NumberFormat('it-IT').format(listOrderDetail.coupon_product_value)} đ</p>
                <p>Phí vận chuyển: {new Intl.NumberFormat('it-IT').format(listOrderDetail.shipping_fee)} đ</p>
                <p>Số tiền thanh toán: {listOrderDetail.real_price ? new Intl.NumberFormat('it-IT').format(listOrderDetail.real_price) + ' đ' : ''}  {getTotalPointFinal() ? formatCurrency(getTotalPointFinal()) + ' điểm' : ''}</p>
              </div>
            </div>
          </div>
          <div className="operatelog">
            <div className="d-flex align-self-center">
              <h1 className="customer-title mb-0">Thông tin giao hàng</h1>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="col pl-0 ">
              <div className="list_orderboxDetail">
                <p>Người mua: {listOrderDetail.name}</p>
                <p>Phương thức thanh toán: {listOrderDetail.payment_method && listOrderDetail.payment_method.name}</p>
                <p>Trạng thái thanh toán: {
                  listOrderDetail.payment_status === false ? 'Chưa thanh toán' : 'Đã thanh toán'
                }</p>
                <p>Ghi chú: {listOrderDetail.note}</p>
              </div>
            </div>
            <div className="col pr-0">
              <div className="list_orderboxDetail">
                <p>Người nhận: {listOrderDetail.name}</p>
                <p>Địa chỉ:  {listOrderDetail.address}</p>
                <p>Tỉnh/Thành phố: {listOrderDetail.city_name}</p>
                <p>Quận/Huyện: {listOrderDetail.district_name}</p>
                <p>Xã/Phường/Thị Trấn: {listOrderDetail.ward_name}</p>
              </div>
            </div>
          </div>
          {
            listOrderDetail.ticket !== 0
              ?
              <div>
                <div className="operatelog mt-5">
                  <div className="d-flex align-self-center">
                    <h1 className="customer-title mb-0 mr-3">Phiếu giao hàng</h1>
                  </div>
                </div>
                {
                  (listOrderDetail.ticket && listOrderDetail.ticket.id !== 0)
                    ? <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="col pl-0 ">
                        <div className="list_orderboxDetail">
                          <p>Trạng thái:
                            {
                              " " + listStatusDelivery.find(status => {
                                return status.id == listOrderDetail.ticket.order.delivery_status
                              }).name
                            }
                          </p>
                          <p>Phương thức vận chuyển:
                            {
                              " " + listMethodDelivery.find(method => {
                                return method.id == listOrderDetail.ticket.method_id
                              }).name
                            }
                          </p>
                          <p>Mã vận đơn: {listOrderDetail.ticket.code}</p>

                          {
                            listOrderDetail.payment_method_id === COD ? <p>COD: {formatCurrency(listOrderDetail.real_price) + ' đ'} </p>   : ''
                          }
                        </div>
                      </div>
                    </div>
                    : ''

                }
              </div>
              : ''
          }
        </div>
      </div>
    </div>
  )
}

export default ComponentToPrint
