export const DEFAULT_TYPE = 1

export const TYPE_GIFT_EXCHANGE_POINT = 1

export const DELIVERY_FAST = 1

export const COD = 1

//delivery
export const DELIVERY_SUCCESS = 2
export const DELIVERY_FAIL = 3
export const DELIVERING = 1
export const WAITING_UP = 0

//order

export const ORDER_SUCCESS = 2
export const ORDER_UNCONFIRMED = 1

export const listMethodDelivery = [
    {
      id : 0,
      name : ""
    },
    {
        id : 1,
        name : "Giao hàng nhanh"
    },
    {
        id : 2,
        name : "Viettel Post"
    },
    {
        id :3,
        name : "VN Post"
    },
    {
        id :10,
        name : "Khác"
    }
]

export const listStatusDelivery = [
    {
        id : WAITING_UP,
        name : "Chờ lấy hàng"
    },
    {
        id : DELIVERING,
        name : "Đang giao hàng"
    },
    {
        id : DELIVERY_SUCCESS,
        name : "Thành công"
    },
    {
        id : DELIVERY_FAIL,
        name : "Thất bại"
    },
]

export const listMethodPayment = [
    {
        id : COD,
        name : "Thanh toán khi nhận hàng"
    },
    {
        id : 2,
        name : "Thanh toán qua ví điện tử VNPAY"
    },
    {
        id :3,
        name : "Thanh toán qua ví điện tử MOMO"
    },
    {
        id :4,
        name : "Thanh toán qua VISA"
    }
]

export const listOrderStatus = [
        {id : 0, name : "Chưa xác nhận"},
        {id : 1, name : "Đã xác nhận"},
        {id : 2, name : "Đã hoàn thành"},
        {id : 3, name : "Hủy bởi khách hàng"},
        {id : 4, name : "Hủy bởi admin"},
]

export const EventJoinChannel = 1
export const EventMessage     = 2