import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ENDPOINT, {ProductGroupApiService} from '../../ultils/EndPoint';
import {Button, Modal} from "react-bootstrap";
import { Link } from 'react-router-dom';
import {Pagination} from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "antd/dist/antd.css";
import './GroupProduct.scss';
import $ from 'jquery';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function GroupProducts() {
    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [idPartnerCurr, setIdPartnerCurr] = useState('');
    const [namePartnerCurr, setNamePartnerCurr] = useState('');
    const dispatch = useDispatch();
    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [keyword, setFilter] = useState('');
    const [customersPerPage] = useState(10);

    // set const 
    const [listGroups, setListGroups] = useState([]);
    const [statusFeature, setStatusFeature] = useState([])
    const [updateCheck, setUpdateCheck] = useState(false)

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    //  call api serve list 
    useEffect(() => {
        let query = '';
        const data = {per_page : customersPerPage, keyword: keyword ,page: currentPage};
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )

        let unmounted = false;
        axios.get(`${ENDPOINT.GROUPS_PAGINATE}` + query, config)
        .then((res) => {
            if (!unmounted) {
                setListGroups(res.data.data.records);
                setNumberPage(res.data.data.total_record)
                setUpdateCheck(false)
                let arrStatus = []
                if (res.data.data.records && res.data.data.records.length) {
                    res.data.data.records.map((item, index) => {
                        arrStatus.push(item.feature)
                    })
                    setStatusFeature(arrStatus)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [customersPerPage, currentPage, keyword, dispatch, updateCheck]);

    // handle delete 
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.GROUPS_EDIT_CREATE}/${id}`, config)
        .then(function (response) {
            if(response.status === 200) {
                toast.success('Xóa nhóm sản phẩm thành công !')
                const remainingpartner = listGroups.filter((result) => result.id !== id)
                setListGroups(remainingpartner)
                setUpdateCheck(true)
            }
        })
        .catch(error => {
            console.log(error);
        }
      )
    }

    // Handle search debounce
    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }
    
    const handleInputSearch = (e) => {
        setFilter(removeExtraSpace(e.target.value))
        setcurrentPage(1)
    }
        
    const optimisedChange = debounce(handleInputSearch)

    async function handleChange(index) {
        setUpdateCheck(true)
        let checkboxes = document.querySelectorAll('input[type="checkbox"].checkbox-status')
        let data = []
        checkboxes.forEach((input, i) => {
            let status = input.getAttribute('status') === 'true'
            if (i === index) {
                status = !status
            }
            data.push(status)
        })
        setStatusFeature(data)
    }
    async function toggleActive(index, item) {
        let res = await ProductGroupApiService.setFeature(item.id)
    }

    // prevent enter when search
    $('#searchPartner').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
          e.preventDefault();
          return false;
        }
    });

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Danh sách nhóm sản phẩm</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <Link to ="/main/products/groups/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
                </div>
                <div>
                    <form className="form-inline position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input className="form-control" id="searchPartner" type="search" onChange={optimisedChange} placeholder="Nhóm sản phẩm" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                        <span className="bx bx-search-alt"></span>
                    </form>
                </div>
            </div>
            <div className="category-manager-wapper">
                <div className="table-responsive group_product">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                        <tr>
                            <th>STT</th>
                            <th>Tên</th>
                            <th>Số lượng sản phẩm</th>
                            <th>Hình ảnh</th>
                            <th>Feature</th>
                            <th>Hành động</th>
                        </tr>
                        </thead>
                        <tbody row="10">
                        {listGroups ? listGroups.map((group, index) => 
                                <tr key={index}>
                                    <th>
                                        {group.id}
                                    </th>
                                    <td >{group.name}</td>
                                    <td style={{width:'10%'}}>
                                        {group.product_count}
                                    </td>
                                    <td >
                                        <img style={{'width':'100px', 'height':'80px'}} src={group.cover} alt=""/>
                                    </td>
                                    <td>
                                        <div className="custom-control custom-switch" style={{marginLeft : -40 + 'px'}}>
                                            <input 
                                                type="checkbox" 
                                                checked={statusFeature[index] || false}
                                                onChange={() => handleChange(index)}
                                                onClick={() => toggleActive(index, group)}
                                                className="custom-control-input checkbox-status"
                                                id={"customSwitch" + index}
                                                status={statusFeature[index] ? statusFeature[index].toString() : 'false'}/>
                                            <label className="custom-control-label" htmlFor={"customSwitch" + index}></label>
                                        </div>
                                    </td>
                                    <td>
                                        <Link to={`/main/products-groups/${group.id}`}><i className="mdi mdi-plus-circle font-size-18 mr-3"></i></Link> 
                                        <Link to={`/main/products/groups/edit/${group.id}`}><i className="mdi mdi-pencil font-size-18 mr-3"></i></Link> 
                                        <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                            handleShow();
                                            setIdPartnerCurr(group.id)
                                            setNamePartnerCurr(group.name)
                                        }} className="mdi mdi-delete font-size-18"></i>
                                    </td>
                                </tr>
                        ) : ''}
                        </tbody>
                    </table>
                    {numberPage <= 10 ? '' : 
                    <Pagination 
                        onChange={(value) => setcurrentPage(value)}
                        total={numberPage}
                        current={currentPage}
                        showSizeChanger= {false}
                        /> 
                    }
                    </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá nhóm sản phẩm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá nhóm sản phẩm <span style={{'color':'blue'}}>{namePartnerCurr}</span> này ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleClose();
                        handleDelete(idPartnerCurr)
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default GroupProducts
