import React, {useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom";
import {FaqService} from "../../ultils/EndPoint";
import {toast, ToastContainer} from "react-toastify";
import {Pagination} from "antd";
import {Button, Modal} from "react-bootstrap";
export default function DetailFaq() {
    const [answers , setAnswers] = useState([])
    const {id} = useParams();
    const [total, setTotal] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const [showPopAnswer, setShowPopAnswer] = useState(false);
    const [filter, setFilter] = useState({
        user_name : ''
    })
    const [statusAnswer, setStatusAnswer] = useState([])
    const [idUserCurr, setIdUserCurr] = useState('');
    const [customersPerPage] = useState(10);
    const [nameUserCurr, setNameUserCurr] = useState('');
    const [answer, setAnswer] = useState('')
    const [extra, setExtra] = useState({})

     useEffect(async () => {
        let data = filter
        data.per_page = customersPerPage
        data.page = currentPage
        await getListAnswer(data)
    }, [currentPage, filter])

    async function getListAnswer(params) {
        let res = await FaqService.listAnswer(id, params)

        if (res.status) {
            let arrStatus = []
            if (res.data.records && res.data.records.length) {
                res.data.records.map((item, index) => {
                    arrStatus.push(item.active)
                })
            }
            setStatusAnswer(arrStatus)
            setAnswers(res.data.records)
            setTotal(res.data.total_record)
            setExtra(res.data.extra_data)
        }
    }

    const handleInputSearch = (e, key) => {
        setFilter({
            user_name : key === 'user_name' ? e.target.value : filter.user_name,
        })
        setcurrentPage(1)
    }
    async function handleChange(index) {
        let checkboxes = document.querySelectorAll('input[type="checkbox"].checkbox-status')
        let data = []
        checkboxes.forEach((input, i) => {
            let status = input.getAttribute('status') === 'true'
            if (i === index) {
                status = !status
            }
            data.push(status)
        })
        setStatusAnswer(data)
    }
    async function toggleActive(index, item) {
        let res = await FaqService.toggleAnswer(item.id)
        if (res.status) {
            getListAnswer(filter)
        }
    }
    const handleDelete = async (id) => {
        let res = await FaqService.deleteAnswer(id)
        if (res.status) {
            toast.success("Xoá câu trả lời thành công!")
            let data = filter
            data.per_page = customersPerPage
            data.page = currentPage
            getListAnswer(data)
        }
    }
    async function answerStore() {
        let res = await FaqService.answer(id, {content : answer})
        if (res.status) {
            toast.success("Trả lời thành công!")
            let data = filter
            data.per_page = customersPerPage
            data.page = currentPage
            getListAnswer(data)
        }
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClosePopAnswer = () => setShowPopAnswer(false);
    const handleShowPopAnswer = () => setShowPopAnswer(true);
    return (
        <div className="brands pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Câu hỏi : {extra.question}</h1>
                </div>
            </div>
            <form className="pt-3">
                <div className="d-flex justify-content-between">
                    <div className="form-inline w-25 position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input className="w-100 form-control" value={filter.user_name} onChange={e => handleInputSearch(e, 'user_name')}
                               type="search" placeholder="Tên người hỏi " aria-label="Search"
                               style={{background: "#fff"}} />
                        <span className="bx bx-search-alt"></span>
                    </div>
                     <div className="form-inline w-25 position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <button type="button" className="btn btn-add" onClick={() => setShowPopAnswer(true)}>Trả lời</button>
                    </div>
                </div>
            </form>
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <tr>
                        <th>STT</th>
                        <th>Tên </th>
                        <th>Số điện thoại</th>
                        <th>Câu trả lời</th>
                        <th>Hành động</th>
                        <th>Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {answers && answers.length ?
                        answers.map((item, index) =>
                            <tr key={index}>
                                <th>
                                    {index + 1}
                                </th>
                                <td style={{width : '15%'}}>{item.user.name}</td>
                                <td style={{width : '15%'}}>{item.phone ? item.phone : item.user.phone}</td>
                                <td>{item.content}</td>
                                <td>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" checked={statusAnswer[index] || false} onChange={() => handleChange(index)} onClick={() => toggleActive(index, item)}
                                        className="custom-control-input checkbox-status" id={"customSwitch" + index} status={statusAnswer[index]? statusAnswer[index].toString() : 'false'}/>
                                        <label className="custom-control-label" htmlFor={"customSwitch" + index}></label>
                                    </div>
                                </td>
                                <td>
                                    <Link onClick={() => {
                                        handleShow();
                                        setIdUserCurr(item.id);
                                        setNameUserCurr(item.name)
                                    }} to="#" className="text-danger">
                                        <i className="mdi mdi-delete font-size-18"></i>
                                    </Link>
                                </td>
                            </tr>
                        )
                        : ''}
                    </tbody>
                </table>
                {total <= customersPerPage ? '' :
                    <Pagination
                        onChange={(value) => setcurrentPage(value)}
                        total={total}
                        current={currentPage}
                        showSizeChanger={false}
                    />
                }
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá câu hỏi </Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá câu hỏi này không <span
                    style={{'color': 'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPopAnswer} onHide={handleClosePopAnswer}>
                <Modal.Header closeButton>
                    <Modal.Title>Admin trả lời </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <textarea className="form-control" value={answer} onChange={(event => setAnswer(event.target.value))} rows="3"/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        answerStore();
                        handleClosePopAnswer();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}