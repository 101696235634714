import { createStore , applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import rootSaga from '../sagas/Saga';

const sagaMiddleWare = createSagaMiddleware()

const middleware = [sagaMiddleWare];
const store = createStore(rootReducer, applyMiddleware(...middleware));

sagaMiddleWare.run(rootSaga)

export default store