import React, {useEffect, useState} from 'react'
import ENDPOINT from "../../ultils/EndPoint";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {formatCurrency} from "../../helpers/function";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';
import {Pagination} from 'antd';
import "antd/dist/antd.css";
import {formatDate} from '../../helpers/function';

function CouponInfoDetail() {
    // set const
    const [listDeal, setListDeal] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const {id} = useParams();
    const dispatch = useDispatch();

    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(10);

    // set const for paginate
    const [numberPageUser,setNumberPageUser] = useState()
    const [currentPageUser, setCurrentPageUser] = useState(1);
    const [customersPerPageUser] = useState(10);

  // get data list
  useEffect(() => {
    let query = '';
    const data = {
        per_page : customersPerPage,
        page: currentPage,
    }
    Object.keys(data).map((key,index) => 
        query += (index === 0 ? '?' : "&") + key + '=' + data[key]
    )
    let unmounted = false;
    axios.get(`${ENDPOINT.COUPON}/${id}`+ query, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
        }
    })
    .then(function (response) {
        if (!unmounted) {
            if(response.status === 200) {
                setListDeal(response.data.data || [])
                setListProducts(response.data.data.products.records || [])
                setListUser(response.data.data.users.records || [])
                setNumberPage(response.data.data.products.total_record || [])
            }
        }
    })
    .catch(error => {
        if(error.response.status === 401){
            dispatch(logoutSuccess(true))
        }
    }) 
    return () => { unmounted = true };
}, [id, dispatch, currentPage, customersPerPage])

    // get data list
    useEffect(() => {
        let query = '';
        const data = {
            per_page : customersPerPageUser,
            page: currentPageUser,
        }
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        let unmounted = false;
        axios.get(`${ENDPOINT.COUPON}/${id}`+ query, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setListUser(response.data.data.users.records || [])
                    setNumberPageUser(response.data.data.users.total_record || [])
                }
            }
        })
        .catch(error => {
            
        }) 
        return () => { unmounted = true };
    }, [id, customersPerPageUser, currentPageUser])

    return (
        <div className="pl-4 pr-4 mt-3">
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Thông tin mã giảm giá</h1>
                </div>
            </div>

            <div style={{fontFamily:'none'}} id="product_top" className="table-dealInfo mt-3 mb-4">
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Tên deal</li>
                    <li>{listDeal.code}</li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Thời gian diễn ra</li>
                    {/* .replace('T', ' ').replace('+07:00', ' ') */}
                    <li>Từ <span style={{'fontWeight':'bold'}}>{formatDate(listDeal.start_time)}</span> đến <span style={{'fontWeight':'bold'}}>{formatDate(listDeal.end_time)}</span></li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Ghi chú</li>
                    <li>{listDeal.note}</li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Số người được sử dụng</li>
                    <li>{listDeal.number_of_use}</li>
                </ul>
                <ul className="d-flex pl-0">
                    <li style={{fontSize:'16px'}}>Giảm</li>
                    <li>{new Intl.NumberFormat('it-IT').format(listDeal.value)} {listDeal.uint === 0 ? 'VNĐ' : '%'}</li>
                </ul>
                {
                    listDeal && listDeal.type === 1
                    ?   <ul className="d-flex pl-0">
                            <li style={{fontSize:'16px'}}>Giá trị đơn hàng tối thiểu</li>
                            <li>{new Intl.NumberFormat('it-IT').format(listDeal.min_order_value ? listDeal.min_order_value : '')} VNĐ </li>
                        </ul>
                    : ''
                }
              
            </div>
            
            {
                listDeal && listDeal.type === 2
                ? 
                    <div>
                        <div  className="operatelog">
                            <div className="d-flex justify-content-between align-self-center">
                                <h1 className="customer-title mb-0">Thông tin sản phẩm được áp dụng</h1>
                            </div>
                        </div>
                        <div className="pl-0 pr-0 mt-3">        
                        <div className="category-manager-wapper mt-0">
                            <div className="table-responsive">
                                <table className="table project-list-table table-nowrap align-middle table-borderless">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Tên sản phẩm</th>
                                            <th>Danh mục</th>
                                            <th>Thương hiệu</th>
                                            <th>Giá bán(VNĐ)</th>
                                            <th>Hình ảnh</th>
                                        </tr>
                                    </thead>
                                    <tbody row="10">
                                    {listProducts ? 
                                    listProducts
                                    .map((product, index) => 
                                            <tr key={index}>
                                                <th style={{'fontWeight': 'normal'}}>
                                                    {product.id}
                                                </th>
                                                <th>
                                                    {product.name}
                                                </th>
                                                <th>
                                                    {product.category_name}
                                                </th>
                                                <th>
                                                    {product.brand_name}
                                                </th>
                                                <th>
                                                  { formatCurrency(product.price)}
                                                </th>
                                                <th>
                                                    {product.image !== null 
                                                    ?
                                                    product.image.map((check, index) => 
                                                        check.collection_name === 'single' ? <img key={index} alt="" style={{'width':'100px', 'height':'100px'}} src={check.image} /> : ''
                                                    )
                                                    : 
                                                    ''}
                                                </th>
                                            </tr>
                                    ) : ''}
                                    </tbody>
                                </table>
                                {numberPage <= 10 ? '' : 
                                    <Pagination 
                                        onChange={(value) =>{
                                            document.getElementById('product_top').scrollIntoView({behavior: 'smooth'});
                                            setcurrentPage(value)
                                        }}
                                        total={numberPage}
                                        current={currentPage}
                                        showSizeChanger= {false}
                                    /> 
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                : ""
            }

            <div>

                    <div id="user_top" className="operatelog">
                        <div className="d-flex justify-content-between align-self-center">
                            <h1 className="customer-title mb-0">Thông tin người dùng được áp dụng</h1>
                        </div>
                    </div>
                    <div className="pl-0 pr-0 mt-3">        
                    <div  className="category-manager-wapper mt-0">
                        <div className="table-responsive">
                            <table className="table project-list-table table-nowrap align-middle table-borderless">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Code</th>
                                        <th>Tên người dùng</th>
                                        <th>Số điện thoại</th>
                                        <th>Hình ảnh</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody row="10">
                                {listUser ? 
                                listUser
                                .map((product, index) => 
                                        <tr key={index}>
                                            <th style={{'fontWeight': 'normal'}}>
                                                {product.ID}
                                            </th>
                                            <th>
                                                {product.Code}
                                            </th>
                                            <th>
                                                {product.Name}
                                            </th>
                                            <th>
                                                {product.Phone}
                                            </th>
                                            <th>
                                            <img key={index} alt="" style={{'width':'100px', 'height':'100px'}} src={product.Avatar} /> 
                                            </th>
                                            <th>
                                                {product.Email}
                                            </th>
                                        </tr>
                                ) : ''}
                                </tbody>
                            </table>
                            {numberPageUser <= 10 ? '' : 
                                <Pagination 
                                    onChange={(value) => {
                                        document.getElementById('user_top').scrollIntoView({behavior: 'smooth'});
                                        setCurrentPageUser(value)
                                    }}
                                    total={numberPageUser}
                                    current={currentPageUser}
                                    showSizeChanger= {false}
                                /> 
                            }
                            </div>
                        </div>
                    </div>
                </div>
            <div>
                <Link to="/main/coupon"><button type="button" className="btn btn-secondary">Quay lại</button></Link> 
            </div>
        </div>
    )
}
export default CouponInfoDetail
