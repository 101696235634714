import React, {useEffect, useState} from 'react';
import './chat.scss';
import Picker from 'emoji-picker-react';
import $, {trim} from 'jquery'
import logo_default from '../../../src/assets/images/avatar-default.jpg';
import moment from "moment";
import {EventJoinChannel} from "../../helpers/constant";
import {convertEncodeToEmoji, decodeHtml, formatDateTime, toBase64} from "../../helpers/function";

export default function Index() {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [listRooms, setListRooms] = useState([])

  function getMessageAdmin(message) {
    let content = message.content
    if (message.files && message.files.length){
      message.files.forEach(file => {
        content += "<br/><img width='100' height='100' src='" + file + "' alt='image-chat'/>"
      })
    }
    return `
        <li class="right">
          <div class="conversation-list">

            <div class="ctext-wrap">
              <div class="conversation-name">Admin</div>
              <p>
                ${content}
              </p>

              <p class="chat-time mb-0"><i
                class="bx bx-time-five align-middle me-1"></i> ${formatDateTime(message.time)}
              </p>
            </div>
          </div>
        </li>
        `
  }


  function getMessageCustomer(message) {
    let content = message.content
    if (message.files && message.files.length){
      message.files.forEach(file => {
        content += "<br/><img width='100' height='100' src='" + file + "' alt='image-chat'/>"
      })
    }
    return `
       <li>
        <div class="conversation-list">

          <div class="ctext-wrap">
            <div class="conversation-name">${localStorage.getItem('user-chat')}</div>
            <p>
              ${content}
            </p>
            <p class="chat-time mb-0"><i
              class="bx bx-time-five align-middle me-1"></i> ${formatDateTime(message.time)}
            </p>
          </div>

        </div>
      </li>
    `
  }

  function getChat(message) {
    let data = ''
    if (message.model_id === "admin") {
      data = getMessageAdmin(message)
    } else {
      data = getMessageCustomer(message)
    }
    return data
  }

  function scrollBottom() {
    $('.simplebar-content-chat-wrapper').animate({scrollTop: 9999999999});
  }

  function selectUserChat(user_id, username){
    setEmptyImage()
    removePreview()
    $('.content-chat-user').html("")
    $('.card-chat').css("display", "block")
    localStorage.setItem("user-id-chat", user_id)
    localStorage.setItem("user-chat", username)
    let roomSelect = listRooms.find(room => {
      return room.user_id == user_id
    })
    let listChat = roomSelect.chat.length && roomSelect.chat.map(chat => {
      return getChat(chat)
    })
    $('.content-chat-user').append(listChat)
    scrollBottom()
  }

  function resetRoomSelect(){
    localStorage.removeItem("user-id-chat")
    localStorage.removeItem("user-chat")
  }

  function setEmptyImage(){
    $('#input-data-image').data('value', "")
    $('#input-data-image').data('mytype', "")
    $('#input-data-image').data('filename', "")
  }

  function removePreview(){
    $('#upload-file').val("")
    $('.previewFile').remove()
  }


  async function handleUploadImage(e) {
    $('.previewFile').remove()
    $('.contain-preview').append(
        `<div style="position: relative" class="mt-3 previewFile d-flex justify-content-end">
                   <button class="close-preview">x</button>
                    <img id="file-preview" alt="preview-file" width=${100} height=${100}/>
                </div>`
    )
    let file = e.target.files[0]
    let base64 = await toBase64(file)
    let extra = base64.split(/^(data:(.*?);base64,)/)
    $('#input-data-image').data('value', extra[3])
    $('#input-data-image').data('mytype', extra[2])
    $('#input-data-image').data('filename', file.name)

    $('#file-preview').attr("src", URL.createObjectURL(file));
  }

  function connect(){
    let ws = new WebSocket(process.env.REACT_APP_DOMAIN_CHAT + 'ws-admin/admin' + '?authorization=1')
    ws.onopen = async () => {
      console.log("connected")
      $(document).ready(function (){
        //send message

        setInterval(function(){
          let data = {}
          data.event = 1
          ws.send(JSON.stringify(data))
        }, 50000);

        $('#send-chat').click(function(){
          let message = convertEncodeToEmoji($(".chat-input").val())
          if (!trim(message) && !$('#input-data-image').data('value')) {
            // $('.chat-input').addClass("border-error")
          } else {
            // $('.chat-input').removeClass("border-error")
            let data = {}
            data.script_id = 0
            data.model_id = "admin"
            data.content = message
            data.chat_with = "user-admin"
            data.user_id = localStorage.getItem("user-id-chat")
            data.file = [
              {
                mine_type : $('#input-data-image').data('mytype'),
                value : $('#input-data-image').data('value'),
                file_name : $('#input-data-image').data('filename'),
              }
            ]

            sendMessage(data, ws)
            $('.chat-input').val("")
            removePreview()
            setEmptyImage()
          }
        })

        $('.chat-input').keyup(function (e) {
          if (e.keyCode == 13) {
            // $(this).trigger("enterKey");
            $('#send-chat').click()
          }
        });

        $('.btn-upload-file').click(function (){
          $('#upload-file').click()
        })


        function isOpen(ws) { return ws.readyState === ws.OPEN }

        function sendMessage(data, ws) {
          if (!isOpen(ws)){
            return
          }
          ws.send(JSON.stringify(data))
        }


        $(document).on('click', '.close-preview', function () {
          removePreview()
          setEmptyImage()
        })

      })
    }

    ws.onmessage = async evt => {
      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data)
      console.log(message)
      if (message) {
        if (message.event === EventJoinChannel) {
          // nếu readload thì event = 1
          resetRoomSelect()
          $('.content-chat-user').html("")
          $('.card-chat').css("display", "none")
        } else {
          if ($('#room_' + message.last_room).length) {
            // nếu đã từng chat với admin
            let roomSelect = message.room.find(room => {
              return room.id == message.last_room
            })
            if (roomSelect.user_id == localStorage.getItem("user-id-chat")){
              $('.content-chat-user').append(getChat(roomSelect.last_message))
              scrollBottom()
            }
          }
        }

        setListRooms(message.room)
      }
    }

    ws.onclose = () => {
      connect()
      console.log('disconnect')
    }
  }

  useEffect(() => {
    connect()
  }, [])

  const onEmojiClick = (event, emojiObject) => {
    // setOpenEmoji(false)
    setChosenEmoji(emojiObject);
    $('.chat-input').val($('.chat-input').val() + emojiObject.emoji)

    // $('.chat-input-section').removeClass('show-emoji')
  };

  const openEmojiClick = () => {
    if (!openEmoji) {
      setOpenEmoji(true)
      $('.chat-input-section').addClass('show-emoji')
    } else {
      setOpenEmoji(false)
      $('.chat-input-section').removeClass('show-emoji')
    }
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Chat</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="#">{process.env.REACT_APP_NAME}</a></li>
                <li className="breadcrumb-item active">Chat</li>
              </ol>
            </div>

          </div>
        </div>
      </div>
      <div className="d-lg-flex">
        <div className="chat-leftsidebar col-lg-4">
          <div>
            <div className="py-4 border-bottom">
              <div className="media">
                <div className="align-self-center mr-3">
                  <img src="https://ui-avatars.com/api/?size=128&background=E64D23&color=fff&name=a"
                       className="avatar-xs rounded-circle" alt="" />
                </div>
                <div className="media-body">
                  <h5 className="font-size-15 mt-0 mb-1"> Admin</h5>
                  <p className="text-muted mb-0"><i className="mdi mdi-circle text-success align-middle mr-1"></i> Hoạt động
                  </p>
                </div>
              </div>
            </div>
            <div className="search-box chat-search-box py-4">
              <div className="position-relative">
                <input type="text" className="form-control" placeholder="Search..." />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
            <div className="chat-leftsidebar-nav">
              <div className="tab-content py-4">
                <div className="tab-pane show active">
                  <div>
                    <h5 className="font-size-14 mb-3">Gần đây</h5>
                    <ul className="list-unstyled chat-list" data-simplebar="init" style={{"maxHeight": "410px"}}>
                      <div className="simplebar-wrapper">
                        <div className="simplebar-height-auto-observer-wrapper">
                          <div className="simplebar-height-auto-observer">
                          </div>
                        </div>
                        <div className="simplebar-mask">
                          <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                              <div className="simplebar-content">
                                {listRooms && listRooms.map((room, key) =>
                                    <li id={"room_" + room.id} onClick={() => {selectUserChat(room.user_id, room.user ? room.user.name: 'Người dùng ẩn danh')}} key={key} className={room.user_id == localStorage.getItem("user-id-chat") ? "active" : ''}>
                                      <a href="#">
                                        <div className="media">
                                          <div className="align-self-center mr-3">
                                            <i className="mdi mdi-circle text-success font-size-10"></i>
                                          </div>
                                          <div className="align-self-center mr-3">
                                            <img src={room.user ? room.user.avatar : logo_default}
                                                 className="rounded-circle avatar-xs" alt={room.user ? room.user.name : 'Người dùng ẩn danh'}/>
                                          </div>

                                          <div className="media-body overflow-hidden">
                                            <h5 className="text-truncate font-size-14 mb-1">{room.user ? room.user.name : 'Người dùng ẩn danh'}</h5>
                                            <p className="message-last-chat text-truncate mb-0">{decodeHtml(room.last_message.content)}</p>
                                          </div>
                                          <div className="time-last-chatfont-size-11">{moment(room.last_message.time).fromNow()}</div>
                                        </div>
                                      </a>
                                    </li>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simplebar-placeholder">

                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 user-chat">
          <input type="text" id="input-data-image" style={{display:"none"}} data-value="" data-mytype="" data-filename=""/>
          {
           <div style={{display: "none"}} className="card card-chat">
             <div className="contain-preview">

             </div>
              <div className="p-4 border-bottom">
                <div className="row">
                  <div className="col-md-4 col-9">
                    <h5 className="font-size-15 mb-1">{localStorage.getItem('user-chat')}</h5>
                    <p className="text-muted mb-0"><i className="mdi mdi-circle text-success align-middle me-1"></i> Hoạt động</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="chat-conversation p-3">
                  <ul className="list-unstyled mb-0" data-simplebar="init" style={{"maxHeight": "486px"}}>
                    <div className="simplebar-wrapper">
                      <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer">
                        </div>
                      </div>
                      <div className="simplebar-mask">
                        <div className="simplebar-offset">
                          <div className="simplebar-content-wrapper simplebar-content-chat-wrapper">
                            <div className="simplebar-content content-chat-user">

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simplebar-placeholder" style={{"width": "auto", "height": "639px"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-horizontal" style={{"visibility": "hidden"}}>
                      <div className="simplebar-scrollbar" style={{"transform": "translate3d(0px, 0px, 0px)", "display": "none"}}></div>
                    </div>
                    <div className="simplebar-track simplebar-vertical" style={{"visibility": "hidden"}}>
                      <div className="simplebar-scrollbar"
                           style={{"transform": "translate3d(0px, 0px, 0px)", "display": "block", "height" : "369px"}}></div>
                    </div>
                  </ul>
                </div>
                <div className="p-3 chat-input-section">
                  <div className="row">
                    <div className="col">
                      <div className="position-relative">
                        <input type="text" className="form-control chat-input" placeholder="Enter Message..." />
                        <div className="chat-input-links" id="tooltip-container">
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <a title="Emoji" onClick={openEmojiClick}><i className="mdi mdi-emoticon-happy-outline"></i></a>
                            </li>
                            <li className="list-inline-item">
                              <input onChange={(e) => {
                                handleUploadImage(e)
                              } } accept="image/*" style={{display : "none"}} type="file" id="upload-file"/>
                              <a className="button btn-upload-file" title="Add Files"><i className="mdi mdi-file-document-outline"></i></a>
                            </li>
                          </ul>
                        </div>
                        {openEmoji ?
                            <div className="emoji-popup">
                              <Picker onEmojiClick={onEmojiClick} />
                            </div>
                            :  null}
                      </div>
                    </div>
                    <div className="col-auto">
                      <button id="send-chat" type="submit"
                              className="btn btn-primary btn-rounded chat-send w-md waves-effect waves-light"><span
                          className="d-none d-sm-inline-block me-2">Gửi</span> <i className="mdi mdi-send"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </div>
  )
}