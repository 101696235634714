import './Role.scss';
import React, { useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import ENDPOINT from "../../ultils/EndPoint";
import axios from 'axios';
// import './brands.scss';
import {Pagination} from 'antd';
import "antd/dist/antd.css";
import {Button, Modal} from "react-bootstrap";
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function Role() {
    const [roles, setRoles] = useState([])
    const [total, setTotal] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const [keyword, setFilter] = useState('');
    const [idUserCurr, setIdUserCurr] = useState('');
    const [customersPerPage] = useState(10);
    const [nameUserCurr, setNameUserCurr] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    useEffect(() => {
        const getTerm = async () => {
            try {
                let query = '';
                const data = {per_page: customersPerPage, keyword: keyword, page: currentPage};
                Object.keys(data).map((key, index) =>
                    query += (index === 0 ? '?' : "&") + key + '=' + data[key]
                )
                const  res = await axios.get(`${ENDPOINT.ROLE}/paginate` + query, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                    }
                })
                setRoles(res.data.data.records)
                setTotal(res.data.data.total_record)
            } catch(error) {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }
        }
        getTerm();
    }, [currentPage,customersPerPage, keyword, dispatch ])

    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }


    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.ROLE}/${id}`, config)
            .then(function (response) {
                toast.success('Xóa vai trò thành công !')
                const remainListRole = roles.filter((result) => result.id !== id)
                setRoles(remainListRole)
            })
            .catch(error => {

                }
            )
    }

    const handleInputSearch = (e) => {
        setFilter(removeExtraSpace(e.target.value))
        setcurrentPage(1)
    }

    const optimisedChange = debounce(handleInputSearch)

    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    return (
        <div className="brands pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Danh sách role</h1>
                </div>
            </div>
            <form  className="pt-3">
                <div className="d-flex justify-content-between mb-3">
                    <div className="form-inline w-25 position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input className="w-100 form-control" id="searchCategory" onChange={optimisedChange} type="search"  placeholder="Tìm kiếm vai trò" aria-label="Search" style={{ background: "#fff" }}/>
                        <span className="bx bx-search-alt"></span>
                    </div>
                    <Link to="/main/role/store">
                        <button type="button" className="btn btn-add"> + Thêm</button>
                    </Link>
                </div>
            </form>
            <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th>STT</th>
                    <th>Vai trò</th>
                    <th>Phân quyền</th>
                    <th>Hành động</th>
                </tr>
                </thead>
                <tbody row="10">
                    {roles?
                        roles.map((item, index) =>
                        <tr key={index}>
                            <th style={{'width':'100px'}}>
                                {item.id}
                            </th>
                            <td style={{'width':'150px'}}>
                                {item.name}
                            </td>
                            <td style={{'width':'200px'}}>
                                <div className="list_permission">
                                {
                                    item.permissions !== null &&  item.permissions.map((permiss, index) => 
                                            <p className="mb-0 mt-0" key={index}>{permiss.name} </p>
                                      
                                    )
                                }
                                </div>
                            </td>
                            <td style={{'width':'50px'}}>
                            {
                                item.id === 1 
                                ? ''
                                : 
                                <div>
                                    <Link to={`/main/role/edit/${item.id}`}>
                                        <i className="mdi mdi-pencil font-size-18"></i>
                                    </Link>
                                    <Link  onClick={() => {handleShow();  setIdUserCurr(item.id); setNameUserCurr(item.name)}} to="#" className="text-danger">
                                        <i className="mdi mdi-delete font-size-18"></i>
                                    </Link>
                                </div>
                            }
                            </td>
                        </tr>
                    )
                    : ''}
                </tbody>
            </table>
            {total <= 10 ? '' :
                <Pagination
                    onChange={(value) => setcurrentPage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                />
            }
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá vai trò</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá vai trò <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Role;
