import React, {useEffect, useState} from 'react'
import ENDPOINT, {OrderApiService} from '../../ultils/EndPoint';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import './ListOrders.scss';
import {getImageMedia, formatCurrency} from '../../helpers/function';
import {Link} from 'react-router-dom';
import {COD, DELIVERY_SUCCESS, listMethodDelivery, listMethodPayment, listStatusDelivery} from "../../helpers/constant";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function ListOrderDetail() {
    // set const 
    const [listOrderDetail, setListOrderDetail] = useState([]);
    const [activeUpdate, setActiveUpdate] = useState(false);
    const [noteUpdata, setNotUpdate] = useState([]);
    const [selected, setSelected] = useState([]);
    const {id} = useParams();
    const history = useHistory();
    const [upadte, setUpdate] = useState(false)
    const [statusDelivery, setStatusDelivery] = useState("")
    const dispatch = useDispatch();
    // get data 
    useEffect(()=>{
    if(id) {
        axios.get(`${ENDPOINT.LIST_ORDERS}/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setListOrderDetail(response.data.data)
                setStatusDelivery(response.data.data.delivery_status)
                setNotUpdate(response.data.data.note)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
    }
    },[id, statusDelivery, dispatch]);
    function getTotalPointFinal(){
        let point = 0
        listOrderDetail.products && listOrderDetail.products.map(product => {
            if (product.point > 0) {
                point += product.point
            }
        })
        return point
    }

    // handle change
    const handleChangeNote = (e) => {
        setNotUpdate(e.target.value)
    }

    const handleSelected = (e) => {
        setSelected(e.target.value)
    }

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 

    const updateNoteFormData = new FormData();
    if(listOrderDetail.status === 1){
        if(selected.length === 0){
            updateNoteFormData.append('status', 1)
        }else {
            updateNoteFormData.append('status', selected)
        }
    }else {
        updateNoteFormData.append('status', selected)
    }
    if(selected === '2'){
        updateNoteFormData.append('delivery_status', 2)
    }else if(selected === '4'){
        updateNoteFormData.append('delivery_status', 0)
    }
    updateNoteFormData.append('note', noteUpdata)

    const updateNote = () => {
        axios.post(`${ENDPOINT.LIST_ORDERS}/${id}/update-status`, updateNoteFormData,{
            headers: { Authorization: 'Bearer ' +  admin_token }
          })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Update đơn hàng thành công !')
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
            })
            .catch(error => {
                toast.error(error.response.data.error)
            }
          )
    }

    function handleChangeStatusDelivery(ticketId, status){
            OrderApiService.updateStatusTicket(ticketId, {delivery_status : parseInt(status), note : ""}).then(r => {
                if (r.status){
                    toast.success('Thay đổi trạng thái phiếu giao hàng !')
                    setStatusDelivery(parseInt(status))
                }
            })
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <Link to={'/main/list-orders/preview/' + id} className="btn btn-info mb-3">In phiếu giao hàng</Link>
                </div>
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Chi tiết đơn hàng : {listOrderDetail.code}</h1>
                </div>
            </div>
            <div className="category-manager-wapper">
                <div className="table-responsive group_product">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                        <tr>
                            <th>STT</th>
                            <th>Tên sản phẩm</th>
                            <th>Ảnh</th>
                            <th>Số lượng</th>
                            <th>Giá trị(VNĐ/ điểm)</th>
                            <th>Giảm giá</th>
                            <th>Thành tiền</th> 
                        </tr>
                        </thead>
                        <tbody row="10">
                        {listOrderDetail.products && listOrderDetail.products.map((productDetail, index) =>
                            <tr key={index}>
                                <th>
                                    {productDetail.id}
                                </th>
                                <td style={{width:'35%'}}>
                                    {productDetail.name}
                                </td>
                                <td >
                                    {
                                        <img style={{'width':'100%', 'objectFit':'fill'}} src={getImageMedia(productDetail.image)} alt=""/>
                                    }
                                </td>
                                <td style={{width:'10%'}}>
                                    {productDetail.quantity}
                                </td>
                                <td style={{width:'16%'}}>
                                    {productDetail.product_point ? formatCurrency(productDetail.product_point) + ' điểm' : formatCurrency(productDetail.product_price)}
                                </td>
                                <td style={{width:'10%'}}>
                                    {formatCurrency(productDetail.coupon_value)} {productDetail.coupon_code ? <span style={{fontWeight:'bold'}}>{'(' + productDetail.coupon_code + ')'}</span>  : ''}
                                </td>
                                <td style={{width:'10%'}}> 
                                    {productDetail.point ? formatCurrency(productDetail.point) + ' điểm' : (formatCurrency(productDetail.real_price))}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                <div className="col pl-0 ">
                   
                </div>
                <div className="col pr-0">
                    <div className="list_orderboxDetail">
                        {/* <p>Tổng giá trị đơn hàng: {formatCurrency(listOrderDetail.product_origin_price ? listOrderDetail.product_origin_price : listOrderDetail.origin_price + listOrderDetail.coupon_product_value )} VNĐ</p> */}
                        <p>Tổng giá trị đơn hàng: {formatCurrency(listOrderDetail.origin_price + listOrderDetail.coupon_product_value)} VNĐ</p>
                        <p>Giảm giá: {listOrderDetail.coupon_unit === 1 ?  formatCurrency(listOrderDetail.origin_price / listOrderDetail.coupon_price) : formatCurrency(listOrderDetail.coupon_price)} VNĐ</p>
                        <p>Giảm giá sản phẩm: {formatCurrency(listOrderDetail.coupon_product_value)} VNĐ</p>
                        <p>Phí vận chuyển: {formatCurrency(listOrderDetail.shipping_fee)} VNĐ</p>
                        <p>Số tiền thanh toán: {listOrderDetail.real_price ? formatCurrency(listOrderDetail.real_price) + ' VNĐ' : ''}  {getTotalPointFinal() ? formatCurrency(getTotalPointFinal()) + ' điểm' : ''}</p>
                    </div>
                </div>
            </div>
            <div className="operatelog">
                <div className="d-flex align-self-center">
                    <h1 className="customer-title mb-0">Trạng thái đơn hàng : 
                        {(() => {
                            if (listOrderDetail.status === 0) {
                            return (
                                <span>Chưa xác nhận</span>
                            )
                            } else if (listOrderDetail.status === 1) {
                            return (
                                <span>Đã xác nhận</span>
                            )
                            } else if (listOrderDetail.status === 2){
                                return (
                                    <span>Đã hoàn thành</span>
                                )
                            } else if (listOrderDetail.status === 3){
                                return (
                                    <span>Huỷ bởi khách hàng</span>
                                )
                            } else if (listOrderDetail.status === 4){
                                return (
                                    <span>Huỷ bởi admin</span>
                                )
                            }
                        })()}
                    </h1>
                    {(() => {
                        if (listOrderDetail.status === 0) {
                        return (
                            <button type="button" onClick={() => setActiveUpdate(!activeUpdate)} className="btn btn-info ml-4"> Cập nhật đơn hàng</button>
                        )
                        } else if (listOrderDetail.status === 1) {
                        return (
                            <button type="button" onClick={() => setActiveUpdate(!activeUpdate)} className="btn btn-info ml-4"> Cập nhật đơn hàng</button>
                        )
                        } else if (listOrderDetail.status === 2){
                            return (
                                <span></span>
                            )
                        } else if (listOrderDetail.status === 3){
                            return (
                                <span></span>
                            )
                        } else if (listOrderDetail.status === 4){
                            return (
                                <span></span>
                            )
                        }
                    })()}
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="col pl-0 ">
                    <div className="list_orderboxDetail">
                        <p>Người mua: {listOrderDetail.name}</p>
                        <p>Phương thức thanh toán: {listOrderDetail.payment_method && listOrderDetail.payment_method.name}</p>
                        <p>Trạng thái thanh toán: {
                            listOrderDetail.payment_status === false ? 'Chưa thanh toán' : 'Đã thanh toán' 
                        }</p>
                        <p>Ghi chú: {listOrderDetail.note}</p>
                    </div>
                </div>
                <div className="col pr-0">
                    <div className="list_orderboxDetail">
                        <p>Người nhận: {listOrderDetail.name}</p>
                        <p>Số điện thoại: {listOrderDetail.phone}</p>
                        <p>Địa chỉ:  {listOrderDetail.address}</p>
                        <p>Tỉnh/Thành phố: {listOrderDetail.city_name}</p>
                        <p>Quận/Huyện: {listOrderDetail.district_name}</p>
                        <p>Xã/Phường/Thị Trấn: {listOrderDetail.ward_name}</p>
                    </div>
                </div>
            </div>
            {
                activeUpdate === true 
                ? <div className="listDetail_footer">
                        <textarea onChange={handleChangeNote} value={noteUpdata} className="w-100" placeholder={'Ghi chú :'} style={{height:'200px', padding:'10px'}}></textarea>
                        <div className="d-flex mt-4 w-100 justify-content-between">
                            <div className="input-group col-2 pl-0 pr-0">
                                <select name="" onChange={handleSelected} id="selectTypeList" className="form-control custom_select custom_selectProduct " > 
                                    <option value="">Trạng thái</option>
                                    {
                                        listOrderDetail.status === 1 
                                        ? ''
                                        : <option value="1">Xác nhận</option>
                                    }
                                    <option value="2">Hoàn thành</option>
                                    <option value="4">Huỷ đơn</option>
                                </select>
                            </div>
                            <div className="col pr-0">
                                <button type="button" onClick={updateNote} className="btn btn-success"> Lưu</button>
                            </div>
                        </div>
                    </div>

                : ''
            }
            {
                listOrderDetail.ticket !== 0
                ? 
                <div>
                    <div className="operatelog mt-5">
                        <div className="d-flex align-self-center">
                            <h1 className="customer-title mb-0 mr-3">Phiếu giao hàng</h1> 
                            {
                                (listOrderDetail.ticket && listOrderDetail.ticket.id !== 0) || listOrderDetail.status === 0  || listOrderDetail.status === 2 || listOrderDetail.status === 3 || listOrderDetail.status === 4
                                ? ''
                                : <Link to={`/main/ticket/create/${id}`}><button type="button" className="btn btn-success mr-3"> Tạo phiếu giao hàng</button> </Link> 
                            }
                        </div>
                    </div>
                        {
                            (listOrderDetail.ticket && listOrderDetail.ticket.id !== 0)
                            ? <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="col pl-0 ">
                                    <div className="list_orderboxDetail">
                                        <p>Trạng thái:
                                            {
                                                " " + listStatusDelivery.find(status => {
                                                    return status.id == listOrderDetail.ticket.order.delivery_status
                                                }).name
                                            }
                                        </p>
                                        <p>Phương thức vận chuyển: 
                                            {
                                                " " + listMethodDelivery.find(method => {
                                                    return method.id == listOrderDetail.ticket.method_id
                                                }).name
                                            }
                                        </p>
                                        <p>Mã vận đơn: {listOrderDetail.ticket.code}</p>

                                        {
                                            listOrderDetail.payment_method_id === COD ? <p>COD: {formatCurrency(listOrderDetail.real_price) + ' VNĐ'} </p>   : ''
                                        }
                                    </div>
                                </div>
                                <div className="col pr-0">
                                    {
                                        statusDelivery == DELIVERY_SUCCESS ? '' :
                                        <div style={{width: "40%"}} className="input-group pl-0">
                                            <select onChange={(e) => {handleChangeStatusDelivery(listOrderDetail.ticket.id, e.target.value)}}  value={statusDelivery} name="" id="selectBrands" className="form-control custom_select custom_selectProduct" >
                                                {/*<option value="">Trạng thái vận chuyển</option>*/}
                                                {listStatusDelivery.map((status, index) => <option key={index} value={status.id}>{status.name}</option>)}
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                            : ''
                                
                        }
                </div>
                : ''
            }
            <div >
                <Link to="/main/list-orders"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
            </div>
        </div>
    )
}

export default ListOrderDetail
