import React, { useState, useEffect } from 'react';
import '../Website.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ENDPOINT from '../../../ultils/EndPoint';
import {Button, Modal} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Pagination} from 'antd';
import "antd/dist/antd.css";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../../actions/Auth';

function StoreManager() {
    // set const 
    const [listStore, setListStore] = useState([]);
 
    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [idStoreCurr, setIdStoreCurr] = useState('');
    const [nameStoreCurr, setNameStoreCurr] = useState('');
    const dispatch = useDispatch();

    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(10);

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // call api set list store
    useEffect(() => {
        let query = '';
        const data = {per_page : customersPerPage ,page: currentPage};
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )

        let unmounted = false;
        axios.get(`${ENDPOINT.STORE}` + query, config)
        .then((res) => {
            if (!unmounted) {
                setListStore(res.data.data.records);
                setNumberPage(res.data.data.total_record)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [customersPerPage, currentPage, dispatch]);

    // handle delete 
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.STORE}/${id}`, config)
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Xóa cửa hàng thành công !')
                    const remainAll = listStore.filter((store) => store.id !== id)
                    setListStore(remainAll)
                }
            })
            .catch(error => {
                
            }
        )
    }

    return (
        <div className="pl-4 pr-4 mt-3">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Danh sách địa chỉ cửa hàng</h1>
            </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
                <Link to ="/main/store/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
            </div>

        </div>
        <div className="category-manager-wapper">
        <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Tên cửa hàng</th>
                    <th>Địa chỉ</th>
                    <th>Số điện thoại</th>
                    <th>Hành động</th>
                </tr>
                </thead>
                <tbody row="10">
                   {listStore ? listStore.map((store, index) => 
                        <tr key={index}>
                            <th>
                                {store.id}
                            </th>
                            <th>
                                {store.name}
                            </th>
                            <td >
                                {store.address}
                            </td>
                            <td >
                                {store.phone}
                            </td>
                            <td className="d-flex action-store">
                                <Link to={`/main/store/edit/${store.id}`}><i className="mdi mdi-pencil font-size-18"></i></Link> 
                                <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                    handleShow();
                                    setIdStoreCurr(store.id)
                                    setNameStoreCurr(store.name)
                                }} className="mdi mdi-delete font-size-18"></i>
                            </td>
                        </tr>
                   ) : ''}
                </tbody>
            </table>
            {numberPage <= 10 ? '' : 
                <Pagination 
                    onChange={(value) => setcurrentPage(value)}
                    total={numberPage}
                    current={currentPage}
                    showSizeChanger= {false}
                /> 
            }
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Xoá cửa hàng</Modal.Title>
            </Modal.Header>
            <Modal.Body>Bạn có chắc chắn muốn xoá cửa hàng <span style={{'color':'blue'}}>{nameStoreCurr}</span> không ?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Thoát
                </Button>
                <Button variant="primary" onClick={() => {
                    handleClose();
                    handleDelete(idStoreCurr)
                }}>
                    Xác nhận
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
    )
}

export default StoreManager
