import React, {useEffect, useState} from 'react'
import {toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../ultils/EndPoint";
import {Button, Modal} from "react-bootstrap";
import {Pagination} from "antd";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function Services() {

    const [listServices, setListServices] = useState([]);
    const [customersPerPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [total, setTotal] = useState();
    const dispatch = useDispatch();
    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.SERVICES}/${id}`, config)
            .then(function (response) {
                toast.success('Xóa dịch vụ thành công !')
                setTimeout(() => {
                    window.location.href = '/main/services'
                }, 2000);
            })
            .catch(error => {
                    toast.error(error.response.data.error)
                }
            )
    }

    useEffect(() => {
        // const token = localStorage.getItem('User-Admin')
        const getTerm = async () => {
            try {
                let query = '';
                const data = {
                    per_page: customersPerPage,
                    page: currentPage,
                };
                Object.keys(data).map((key, index) =>
                    query += (index === 0 ? '?' : "&") + key + '=' + data[key]
                )
                const  res = await axios.get(`${ENDPOINT.SERVICES + query}`, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                    }
                })
                setListServices(res.data.data)
                setTotal(res.data.data.total_record)
            } catch(error) {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }
        }
        getTerm();
    }, [currentPage, customersPerPage, dispatch])

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="d-flex justify-content-between">
                <div className="operatelog">
                    <div className="d-flex justify-content-between align-self-center">
                        <h1 className="customer-title mb-3">Danh sách dịch vụ</h1>
                    </div>
                </div>
                <Link to ="/main/services/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
            </div>
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>Thứ tự sắp xếp</th>
                    <th>Tên dịch vụ 1</th>
                    <th>Tên dịch vụ 2</th>
                    <th>Link</th>
                    <th>Hình ảnh</th>
                    <th>Hành động</th>
                    </thead>
                    <tbody>
                    {listServices.map((tableService, index) =>
                        <tr key={index}>
                            <td style={{ "width": "15%"}}>{tableService.order_no}</td>
                            <td style={{ "width": "30%"}}>{tableService.title_1}</td>
                            <td style={{ "width": "15%"}}>{tableService.title_2}</td>
                            <td style={{ "width": "15%"}}>{tableService.link}</td>
                            <td><img style={{ "width" : "60%"}} src={tableService.icon} alt=""/></td>
                            <td style={{ 'width': '10%'}}>
                                <Link to={`/main/services/edit/${tableService.id}`}>
                                    <i className="mdi mdi-pencil font-size-18"></i>
                                </Link>
                                <Link  onClick={() => {handleShow();  setIdUserCurr(tableService.id); setNameUserCurr(tableService.title_1)}} to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18"></i>
                                </Link>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá dịch vụ</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá dịch vụ <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default Services
