import React, {useEffect, useState} from 'react'
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../ultils/EndPoint";
import $, {trim} from "jquery";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function CouponUpdateAndStore() {
    // set id 
    const {id} = useParams();
    const dispatch = useDispatch();
    // set const
    const [nameCoupon, setNameDeal] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [typeDeal, setTypeDeal] = useState(0);
    const [noteDeal, setNoteDeal] = useState('');
    const [selectTypeCoupon, setSelectTypeCoupon] = useState(1);
    const [numberOfPerson, setNumberOfPerson] = useState('');

    const [priceCoupon, setPriceCoupon] = useState('');
    const [price, setPrice] = useState('')

    // set validation
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');
    const [msgTime, setMsgTime] = useState('');
    const [msgTime2, setMsgTime2] = useState('');

    // test positive integer
    const positive =  /^\+?(0|[1-9]\d*)$/

    // others
    const [disable, setDisable]= useState(false);

    let today = new Date();

    // validate before submit
    const validateAll = () => {
        const msg = {};

        // validate name
        if(nameCoupon.length > 20) {
            msg.nameCoupon = 'Tên mã giảm giá tối đa 20 kí tự'
            $('.nameCoupon').addClass('danger_border');
        }else if( nameCoupon.charAt(0) === '' ){
            msg.nameCoupon = 'Vui lòng nhập tên mã giảm giá'
            $('.nameCoupon').addClass('danger_border');
        }else if(nameCoupon.slice(0, 2) === ' '){
            msg.nameCoupon = 'Vui lòng nhập tên giảm giá'
            $('.nameCoupon').addClass('danger_border');
        }
        // validate time
        if(startDate.length === 0) {
            msg.startDate = 'Vui lòng chọn thời gian bắt đầu'
            $('.startDate').addClass('danger_border');
        }else {
            if(!id){
                if(new Date(startDate) < today){
                    msg.startDate = 'Thời gian bắt đầu có hiệu lực phải là thời gian sau thời điểm hiện tại'
                    $('.startDate').addClass('danger_border');
                }
            }else {
                if(new Date(startDate) < today){
                    msg.startDate = 'Thời gian bắt đầu có hiệu lực phải là thời gian sau thời điểm hiện tại'
                    $('.startDate').addClass('danger_border');
                }
            }
        }
        
        if(endDate.length === 0) {
            msg.endDate = 'Vui lòng chọn thời gian kết thúc'
            $('.endDate').addClass('danger_border');
        }

        if(new Date(startDate) > new Date(endDate)){
            msg.endDate = 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu'
            $('.endDate').addClass('danger_border');
        }

        if((((new Date(endDate) - new Date(startDate))/1000)/60) <= 30){
            msg.endDate = 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu 30 phút'
            $('.endDate').addClass('danger_border');
        }

        // validate note
        if(noteDeal.length > 0){
            if(noteDeal.length > 255){
                msg.noteDeal = 'Ghi chú tối đa 255 kí tự'
                $('.noteDeal').addClass('danger_border');
            }
        }

        // validate number of use
        if(numberOfPerson.length === 0){
            msg.numberOfPerson = 'Vui lòng nhập sô lượt sử dụng'
            $('.numberOfPerson').addClass('danger_border');
        }

        if(selectTypeCoupon === 1){
            if(priceCoupon.length === 0){
                msg.priceCoupon = 'Vui lòng nhập giá trị đơn hàng tối thiểu'
                $('.priceCoupon').addClass('danger_border');
            }
            if(typeDeal === 0){
                if(price.length === 0){
                    msg.price = 'Vui lòng nhập giá trị mã giảm giá'
                    $('.price').addClass('danger_border');
                }else if(price < 1){
                    msg.price = 'Giá trị mã giảm giá lớn hơn 0 Đ'
                    $('.price').addClass('danger_border');
                }else if(!positive.test(price)){
                    msg.price = 'Vui lòng không nhập kí tự đặc biệt'
                    $('.price').addClass('danger_border');
                }else if(parseInt(price) >= parseInt(priceCoupon) ){
                    msg.priceCoupon = 'Giá trị đơn hàng tối thiểu phải lớn hơn giá trị mã giảm giá'
                    $('.priceCoupon').addClass('danger_border'); 
                }else if(parseInt(price) < parseInt(priceCoupon)){
                    $('.priceCoupon').removeClass('danger_border'); 
                }
            }else{
                if(price.length === 0){
                    msg.price = 'Vui lòng nhập giá trị deal'
                    $('.price').addClass('danger_border');
                }else if(price > 99){
                    msg.price = 'Giá trị % không lớn hơn 99'
                    $('.price').addClass('danger_border');
                }else if(price < 1){
                    msg.price = 'Giá trị % lớn hơn 0 '
                    $('.price').addClass('danger_border');
                }else if(!positive.test(price)){
                    msg.price = 'Vui lòng không nhập kí tự đặc biệt'
                    $('.price').addClass('danger_border');
                }
            }
        }else {
            if(price.length === 0){
                msg.price = 'Vui lòng nhập giá trị mã giảm giá'
                $('.price').addClass('danger_border');
            }
        }

        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // handle change all
    const handleNameDeal = (e) => {
        setNameDeal(e.target.value);
    }

    const removeMessNameDeal = () => {
        validationMsg.nameCoupon = '';
        $('.nameCoupon').removeClass('danger_border')
        setMsg('')
    }

    const handleStartDate = (e) => {
        setStartDate((e.target.value).replace(/T/g, " "));
    }
    const removeDangerStartDate = (e) => {
        $('.startDate').removeClass('danger_border');
        validationMsg.startDate = '';
        setMsgTime('');
        setMsgTime2('');
    }
    const handleEndDate = (e) => {
        setEndDate((e.target.value).replace(/T/g, " "))
    }
    const removeDangerEndtDate = () => {
        $('.endDate').removeClass('danger_border');
        validationMsg.endDate = '';
    }

    const handleNoteDeal = (e) => {
        setNoteDeal(e.target.value)
    }
    const removeMessNotDeal = () => {
        validationMsg.noteDeal = '';
        $('.noteDeal').removeClass('danger_border')
    }

    const handleSelectTypeCoupon = (e) => {
        setSelectTypeCoupon(parseInt(e.target.value))
    }

    const handlePersons = (e) => {
        setNumberOfPerson(e.target.value)
    }

    const removeMessPersons = () => {
        $('.numberOfPerson').removeClass('danger_border')
        validationMsg.numberOfPerson = '';
    }

    const handlePrice = (e) => {
        setPrice(e.target.value)
    }

    const removeMessPriceReal = () => {
        validationMsg.price = '';
        $('.price').removeClass('danger_border')
    }

    const handlePriceCoupon = (e) => {
        setPriceCoupon(e.target.value)
    }

    const removeMessPriceCoupon = () => {
        validationMsg.priceCoupon = '';
        $('.priceCoupon').removeClass('danger_border')
    }

    const handleSelectType = (e) => {
        setTypeDeal(parseInt(e.target.value))
    }

    // call api for edit
    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT.COUPON}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (r) {
                if(r.status === 200) {
                    setNameDeal(r.data.data.code)
                    setStartDate(r.data.data.start_time)
                    setEndDate(r.data.data.end_time)
                    setTypeDeal(r.data.data.uint)
                    setPriceCoupon(r.data.data.min_order_value)
                    setPrice(r.data.data.value)
                    setNoteDeal(r.data.data.note)
                    setNumberOfPerson(r.data.data.number_of_use)
                    setSelectTypeCoupon(r.data.data.type)
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    }, [id, dispatch])

    // edit deal
    const updateCoupon = new FormData();
    updateCoupon.append('code', trim(nameCoupon));
    updateCoupon.append('start_time', startDate.length === 16 ? (startDate).replace(/T/g, " ").replace('+07:00','').concat('', ':00') : (startDate).replace(/T/g, " ").replace('+07:00',''))
    updateCoupon.append('end_time', endDate.length === 16 ? (endDate).replace(/T/g, " ").replace('+07:00','').concat('', ':00') : (endDate).replace(/T/g, " ").replace('+07:00',''))
    updateCoupon.append('type', selectTypeCoupon);
    if(selectTypeCoupon === 1){
        if(typeDeal === 1 ){
            updateCoupon.append('unit', 1);
        }else {
            updateCoupon.append('unit', 0);
        }
    }else{
        updateCoupon.append('unit', 0);
    }
    updateCoupon.append('min_order_value', priceCoupon);
    updateCoupon.append('note', noteDeal);
    updateCoupon.append('number_of_use', numberOfPerson);
    updateCoupon.append('value', price);


    const updateDeal = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.COUPON}/${id}`, updateCoupon,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Chỉnh sửa mã giảm giá thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/coupon'
                    }, 2000);
                }
            })
            .catch(error => {
                if(error.response.data.errors && error.response.data.errors.start_time === "Thời gian kết thúc phải lớn hơn thời gian bắt đầu ít nhất 30 phút "){
                    setMsgTime('Thời gian kết thúc phải lớn hơn thời gian bắt đầu ít nhất 30 phút ')
                    $('.startDate').addClass('danger_border');
                }else if(error.response.data.errors && error.response.data.errors.start_time === "Thời gian bắt đầu phải lớn hơn thời gian hiện tại 30 phút"){
                    setMsgTime2('Thời gian bắt đầu phải lớn hơn thời gian hiện tại 30 phút')
                    $('.startDate').addClass('danger_border');
                }else {
                    setMsg('Tên mã giảm giá đã tồn tại')
                    $('.nameCoupon').addClass('danger_border');
                }
                }
            )

    }

    // create deal
    const createCoupon = new FormData();
    createCoupon.append('code', trim(nameCoupon));
    createCoupon.append('start_time', startDate.length === 16 ? (startDate).replace(/T/g, " ").concat('', ':00') : (startDate).replace(/T/g, " "));
    createCoupon.append('end_time', endDate.length === 16 ? (endDate).replace(/T/g, " ").concat('', ':00') : (endDate).replace(/T/g, " "));
    createCoupon.append('type', selectTypeCoupon);
    if(selectTypeCoupon === 1){
        if(typeDeal === 1){
            createCoupon.append('unit', 1);
        }else {
            createCoupon.append('unit', 0);
        }
    }else{
        createCoupon.append('unit', 0);
    }
    createCoupon.append('min_order_value', priceCoupon);
    createCoupon.append('note', noteDeal);
    createCoupon.append('number_of_use', numberOfPerson);
    createCoupon.append('value', price);

    const createDeal = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.COUPON}`, createCoupon,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm mới mã giảm giá thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/coupon'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.response.data.errors && error.response.data.errors.start_time === "Thời gian kết thúc phải lớn hơn thời gian bắt đầu ít nhất 30 phút "){
                        setMsgTime('Thời gian kết thúc phải lớn hơn thời gian bắt đầu ít nhất 30 phút ')
                        $('.startDate').addClass('danger_border');
                    }else if(error.response.data.errors && error.response.data.errors.start_time === "Thời gian bắt đầu phải lớn hơn thời gian hiện tại 30 phút"){
                        setMsgTime2('Thời gian bắt đầu phải lớn hơn thời gian hiện tại 30 phút')
                        $('.startDate').addClass('danger_border');
                    }else {
                        setMsg('Tên mã giảm giá đã tồn tại')
                        $('.nameCoupon').addClass('danger_border');
                    }
                }
            )

    }

    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress(
            function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else
                {
                    if (event.keyCode < 48 || event.keyCode > 57 )
                    {
                        event.preventDefault();
                    }
                }
            }
        );
    })

    useEffect(() => {
        $("#mask1").each((i,ele)=>{
            let clone=$(ele).clone(false)
            clone.attr("type","text")
            let ele1=$(ele)
            clone.val(Number(ele1.val()).toLocaleString("en"))
            $(ele).after(clone)
            $(ele).hide()
            clone.mouseenter(()=>{
                ele1.show()
                clone.hide()
            })
            setInterval(()=>{
                let newv=Number(ele1.val()).toLocaleString("en")
                if(clone.val()!== newv){
                    clone.val(newv)
                }
            },10)

            $(ele).mouseleave(()=>{
                $(clone).show()
                $(ele1).hide()
            })
        })
        $("#mask2").each((i,ele)=>{
            let clone=$(ele).clone(false)
            clone.attr("type","text")
            let ele1=$(ele)
            clone.val(Number(ele1.val()).toLocaleString("en"))
            $(ele).after(clone)
            $(ele).hide()
            clone.mouseenter(()=>{
                ele1.show()
                clone.hide()
            })
            setInterval(()=>{
                let newv=Number(ele1.val()).toLocaleString("en")
                if(clone.val()!== newv){
                    clone.val(newv)
                }
            },10)

            $(ele).mouseleave(()=>{
                $(clone).show()
                $(ele1).hide()
            })
        })
    }, [])
    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa mã giảm giá</h1> : <h1 className="customer-title mb-3">Thêm mới mã giảm giá</h1>}
                </div>
            </div>
            {/* ====================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Tên mã giảm giá <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control nameCoupon" value={nameCoupon} onChange={handleNameDeal} onKeyDown={removeMessNameDeal}/>
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.nameCoupon}</p>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{msg}</p>
            {/* ====================================== */}
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Ngày bắt đầu <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group" >
                <input 
                    type="datetime-local" 
                    step="1"
                    className="form-control startDate" 
                    value={startDate.replace(/ /g, "T").replace('+07:00','') || ''} 
                    onChange={handleStartDate} 
                    onClick={removeDangerStartDate}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.startDate}</p>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{msgTime}</p>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{msgTime2}</p>
            {/* ====================================== */}
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Ngày kết thúc <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input 
                    type="datetime-local" 
                    step="1"
                    className="form-control endDate" 
                    value={endDate.replace(/ /g, "T").replace('+07:00','') || ''} 
                    onChange={handleEndDate}
                    onClick={removeDangerEndtDate}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.endDate}</p>
            {/* ====================================== */}
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Áp dụng cho <span style={{'color':'red'}}>*</span></Form.Label>
            <select name="" id="" className='form-control selectTypeCoupon' disabled={id ? true : false} value={selectTypeCoupon} onChange={handleSelectTypeCoupon}>
                <option value="1">Đơn hàng</option>
                <option value="2">Sản phẩm</option>
            </select>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.selectTypeCoupon}</p>
            {/* ====================================== */}
            {
                selectTypeCoupon === 1 
                ? 
                <div>
                <Form.Label className="col-2 pl-0 pr-0 mt-2">Loại mã giảm giá <span style={{'color':'red'}}>*</span></Form.Label>
                    <select name="" id="" className='form-control typeDeal' value={typeDeal} onChange={handleSelectType}>
                        <option value="0">Tiền</option>
                        <option value="1">%</option>
                    </select>
                    <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.typeDeal}</p>
                     </div>
                 : ''
            }
            {/* ====================================== */}
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Giá trị mã giảm giá <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" name="inputForNumber" id="mask2" className="form-control price" value={price} onChange={handlePrice} onKeyDown={removeMessPriceReal}/>
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.price}</p>
            {/* ====================================== */}
            {
                selectTypeCoupon === 1 
                ? 
                    <div>
                        <Form.Label className="col-4 pl-0 pr-0 mt-2">Giá trị đơn hàng tối thiểu áp dụng <span style={{'color':'red'}}>*</span></Form.Label>
                        <div className="input-group">
                            <input type="text" name="inputForNumber" id="mask1" className="form-control priceCoupon" value={priceCoupon} onChange={handlePriceCoupon} onKeyDown={removeMessPriceCoupon}/>
                        </div>
                        <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.priceCoupon}</p>
                    </div>
                : ''
            }
            {/* ====================================== */}
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Số lượt 1 người sử dụng <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" name="inputForNumber"
                className="form-control numberOfPerson" value={numberOfPerson} onChange={handlePersons} onKeyDown={removeMessPersons}/>
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.numberOfPerson}</p>
            {/* ====================================== */}
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Ghi chú</Form.Label>
            <textarea name="" id="" cols="30" rows="4" onKeyDown={removeMessNotDeal} className="form-control" value={noteDeal} onChange={handleNoteDeal}></textarea>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.noteDeal}</p>
            {/* ====================================== */}
            <div>
                <Link to="/main/coupon"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                {
                    id 
                    ? <button type="button" disabled={disable} onClick={updateDeal} className="btn btn-success mt-5">Cập nhật</button> 
                    : <button type="button" disabled={disable} onClick={createDeal}  className="btn btn-success mt-5">Lưu</button>
                }
            </div>
        </div>
    )
}
export default CouponUpdateAndStore
