import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ENDPOINT from '../../ultils/EndPoint';
import $ from 'jquery';
import {Button, Modal} from "react-bootstrap";
import Select from 'react-select';
import {Link} from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import {Pagination} from "antd";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function CouponUser() {
  // set const 
  const [data, setData] = useState([]);
  const animatedComponents = makeAnimated();
  const [dataSelect, setDataSelect] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  const dispatch = useDispatch();
  const [usersPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState();

  let option = []
  if (dataSelect && dataSelect.length > 0) {
    dataSelect.forEach(role => {
      let roleDate = {}
      roleDate.value = role.ID
      roleDate.label = role.Name + ' - ' + (role.Phone ? role.Phone : role.Email)
      option.push(roleDate)
    })
  }
  const {id} = useParams();

  // set for modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get data list
  useEffect(() => {
      let unmounted = false;
      let query = '';
      const data = {
        per_page: usersPerPage,
        page: currentPage
      };
      Object.keys(data).map((key, index) =>
          query += (index === 0 ? '?' : "&") + key + '=' + data[key]
      )
      axios.get(`${ENDPOINT.COUPON}/${id}` + query, {
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
          }
      })
      .then(function (response) {
          if (!unmounted) {
              if(response.status === 200) {
                setData(response.data.data.users.records)
                setTotal(response.data.data.users.total_record)
              }
          }
      })
      .catch(error => {
        if(error.response.status === 401){
            dispatch(logoutSuccess(true))
        }
    }) 
      return () => { unmounted = true };
  }, [id, usersPerPage, currentPage, dispatch])

  // get data option
  useEffect(() => {
    let unmounted = false;

    axios.get(`${ENDPOINT.COUPON}/${id}/available-users`, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
        }
    })
    .then(function (response) {
        if (!unmounted) {
            if(response.status === 200) {
              setDataSelect(response.data.data);
            }
        }
    })
    .catch(error => {
      if(error.response.status === 401){
          dispatch(logoutSuccess(true))
      }
  }) 
    return () => { unmounted = true };
  }, [id, dispatch])

  // post new
  const ids = []
  selectedProduct && selectedProduct.map(select => 
    ids.push(select.value)
  )

  // params for create product
  const userAddCoupons= new FormData();
  userAddCoupons.append('user_ids', '[' + ids + ']')


  const addNewProductInGroup = () => {
    if (ids.length == 0) {
      return ;
    }
    return axios.post(`${ENDPOINT.COUPON}/add-users/${id}`, userAddCoupons,  {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
      }
    })
    .then(function (response) {
      if(response.status === 200) {
        toast.success('Thêm user vào mã giảm giá thành công')
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } 
    })
    .catch(error => {
      if(error.response.status === 401){
        dispatch(logoutSuccess(true))
      }
    }
  )}
  
  // set checked
  useEffect(() =>{
    $(function () {
        $('.footer').hide();
        $(document).ready(function () {
            $('#selectall').click(function () {
              $('.selectedId').prop('checked', this.checked)
            });
            $('.selectedId').change(function () {
                var check = ($('.selectedId').filter(":checked").length === $('.selectedId').length);
                $('#selectall').prop("checked", check);
            });
        });
    })
  })

  const handleAddAllUser = () => {
    let allIds = dataSelect.map(item => {
      return item.ID
    })
    const allUser= new FormData();
    allUser.append('user_ids', '[' + allIds + ']')
    return axios.post(`${ENDPOINT.COUPON}/add-users/${id}`, allUser,  {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
      }
    })
      .then(function (response) {
        if(response.status === 200) {
          toast.success('Thêm user vào mã giảm giá thành công')
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch(error => {
          if(error.response.status === 401){
            dispatch(logoutSuccess(true))
          }
        }
      )
  }

  // delete multi
  const addAllUser= async () => {
      handleShow()
  }
  
  // handle change select product
  const handleChangeSelected = (e) => {
    setSelectedProduct(e);
  }

 
  return (
    <div className="pl-4 pr-4 mt-3">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Thêm người dùng sử dụng mã giảm giá</h1>
            </div>
        </div>
        <div className="d-flex align-items-center mt-3">
            <div className="input-group col-8 pl-0">
                <Select
                  className="col-12 pl-0 pr-0 select_addProduct_group"
                  isMulti
                  onChange={handleChangeSelected}
                  placeholder = {"Người dùng"}
                  options={option}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                >
                </Select>
            </div>
            <div className="ml-3">
                <button type="button" onClick={addNewProductInGroup} className="btn btn-add"> + Thêm</button>
            </div>
          </div>
          <div className="d-flex">
             <div className="mt-3 mb-0">
                <button type="button" onClick={addAllUser} className="btn btn-success font-size-14"> Thêm tất cả người dùng</button>
            </div>
          </div>
        <div className="mt-4">
        <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Tên người dùng</th>
                    <th>Số điện thoại</th>
                    <th>Email</th>
                    <th>Ngày sinh</th>
                    <th>Hình ảnh</th>
                </tr>
                </thead>
                <tbody row="10">
                  {data ? 
                  data
                  .map((product, index) => 
                        <tr key={index}>
                            <th>
                                {product.ID}
                            </th>
                            <th>
                                {product.Name}
                            </th>
                            <th>
                                {product.Phone}
                            </th>
                            <th>
                                {product.Email}
                            </th>
                            <th>
                                {product.Birthday}
                            </th>
                            <th>
                                <img key={index} alt="" style={{'width':'100px', 'height':'100px'}} src={product.Avatar} />
                            </th>
                        </tr>
                  ) : ''}
                </tbody>
            </table>
            {total <= 10 ? '' : 
                <Pagination 
                    onChange={(value) => setcurrentPage(value)}
                    total={total}
                    current={currentPage}
                    showSizeChanger= {false}
                /> 
            }
            
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Thêm người dùng vào coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn thêm tất cả người dùng có thể sử dụng coupon này không?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleClose();
                        handleAddAllUser();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        <div>
            <Link to="/main/coupon"><button type="button" className="btn btn-secondary">Quay lại</button></Link> 
        </div>
    </div>
    );
}

export default CouponUser
