import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ENDPOINT from '../../ultils/EndPoint';
import './Category.scss';
import {Button, Modal} from "react-bootstrap";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function CategoryManager() {
    // set const 
    const [listCategory, setCategory] = useState([]);
    const dispatch = useDispatch();
    
    var allCategory = [];
    listCategory && listCategory.map(cate => 
        {allCategory.push({
            name: cate.name, 
            id: cate.id,
           
        })
            cate.childs !== null && cate.childs.map(cateChild => { 
                    allCategory.push({
                        name: cateChild.name, 
                        id: cateChild.id,
                        
                    })
                    cateChild.Child !== null && cateChild.Child.map(cateChildLast => 
                        allCategory.push({
                            name: cateChildLast.name, 
                            id: cateChildLast.id,
                        })
                    )
                }
            )
        }
    )
    // set const keyword
    const [keyWord, setKeyWord] = useState('');
    const [show, setShow] = useState(false);
    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // remove all whitespace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // call api set list category
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.LIST_CATEGORY}`, config)
        .then((res) => {
            if (!unmounted) {
                setCategory(res.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch]);

    // delete category
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.LIST_CATEGORY}/${id}`, config)
          .then(function (response) {
            toast.success('Xóa danh mục sản phẩm thành công !')
            setTimeout(() => {
                window.location.reload();
            }, 2000);
          })
          .catch(error => {
                alert(error.response.data.error)
          }
        )
    }

    // Handle search debounce
    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }
    
    const handleInputSearch = (e) => {
        setKeyWord(removeExtraSpace(e.target.value));
    }
  
    // search debounce
    const optimisedChange = debounce(handleInputSearch)

    // prevent enter when search
    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
            e.preventDefault();
            return false;
        }
    });

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Danh sách danh mục sản phẩm</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <Link to ="/main/products/category/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
                </div>
                <div>
                    <form className="form-inline position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input onChange={optimisedChange} className="form-control" id="searchCategory" type="search" placeholder="Tìm kiếm danh mục" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                        <span className="bx bx-search-alt"></span>
                    </form>
                </div>
            </div>
            <div className="category-manager-wapper">
            { keyWord === '' ? 
            listCategory && listCategory.map((cate, index) => 
                <div key={index}>
                    <p className="parent">
                        <span style={{whiteSpace: 'pre-wrap'}}>{cate.name}</span> 
                        <p style={{'display':'flex', 'marginBottom':'0px'}}>
                            <Link to={`/main/products/category/edit/${cate.id}`}><i className="mdi mdi-pencil font-size-18 mr-2"></i></Link> 
                            <i id={cate.id} onClick={() => {handleShow(); setIdUserCurr(cate.id); setNameUserCurr(cate.name)}} className="mdi mdi-delete font-size-18"></i>
                        </p>
                    </p>
                    {cate.childs !== null ? 
                        cate.childs
                        .sort((a,b) => a.order_no - b.order_no)
                        .map((cateChild, index) =>   
                            <div key={index}>
                                <p id={cateChild.id} className="child">
                                    <span style={{whiteSpace: 'pre-wrap'}}>{cateChild.name}</span>     
                                    <p style={{'display':'flex', 'marginBottom':'0px'}}>
                                        <Link to={`/main/products/category/edit/${cateChild.id}`}><i className="mdi mdi-pencil font-size-18 mr-2"></i></Link> 
                                        <i id={cateChild.id} onClick={() => {handleShow();  setIdUserCurr(cateChild.id); setNameUserCurr(cateChild.name)}} className="mdi mdi-delete font-size-18"></i>
                                    </p>
                                </p>
                            {cateChild.Child !== null ? 
                                cateChild.Child
                                .sort((a,b) => a.order_no - b.order_no)
                                .map((grandChild, index) => 
                                    <div key={index}>
                                        <p id={grandChild.id} className="grandchild">
                                            <span style={{whiteSpace: 'pre-wrap'}}>{grandChild.name}</span>     
                                            <p style={{'display':'flex', 'marginBottom':'0px'}}>
                                                <Link to={`/main/products/category/edit/${grandChild.id}`}><i className="mdi mdi-pencil font-size-18 mr-2"></i></Link> 
                                                <i id={grandChild.id} onClick={() => {handleShow();  setIdUserCurr(grandChild.id); setNameUserCurr(grandChild.name)}} className="mdi mdi-delete font-size-18"></i>
                                            </p>
                                        </p>   
                                    </div>
                                )
                            : ''}
                            </div>
                    ) 
                    : ''}
                </div>
            ) 
            
            :  allCategory && allCategory.filter((val) => {
                    if(removeExtraSpace(val.name).toLowerCase().includes(keyWord.toLowerCase())){
                        return val
                    }
                })
                .map((valueSearch, index) => 
                    <div className="category-manager-wapper">
                        <p key={index} className="parent">
                            <span style={{whiteSpace: 'pre-wrap'}}>{valueSearch.name}</span> 
                            <p style={{'display':'flex', 'marginBottom':'0px'}}>
                                <Link to={`/main/products/category/edit/${valueSearch.id}`}><i className="mdi mdi-pencil font-size-18 mr-2"></i></Link> 
                                <i id={valueSearch.id} onClick={() => {handleShow(); setIdUserCurr(valueSearch.id); setNameUserCurr(valueSearch.name)}} className="mdi mdi-delete font-size-18"></i>
                            </p>
                        </p>
                    </div>
                )  
            }
                
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá danh mục sản phẩm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá danh mục <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CategoryManager
