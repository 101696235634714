import React, {useEffect, useState} from 'react'
import ENDPOINT, {ApiService, OrderApiService} from '../../ultils/EndPoint';
import {ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Pagination} from "antd";
import $ from "jquery";
import axios from "axios";
import './ListOrders.scss';
import {formatDate, formatCurrency, Exportfile, objectToQueryParams} from '../../helpers/function';
import {listMethodDelivery, listStatusDelivery} from "../../helpers/constant";
import {debounce} from "@material-ui/core";
import trim from "validator/es/lib/trim";

function ListTicket() {
    // set const 
    const [listTicket, setListTicket] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [statusDelivery, setStatusDelivery] = useState("")

    // set const for paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [customersPerPage] = useState(10);
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })


    // call api set list banner

    function getQueryFilter(){
        let data = {}
        data.per_page = customersPerPage
        data.page = currentPage
        data.keyword = keyword
        data.from_date = fromDate
        data.to_date = toDate
        data.keyword = keyword
        data.delivery_status = statusDelivery
        return objectToQueryParams(data)
    }

    useEffect(() => {
        let query = '';

        query = getQueryFilter()

        OrderApiService.getListTicket(query).then(r => {
            if (r.status){
                setListTicket(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }, [
        customersPerPage, 
        currentPage, 
        keyword, 
        fromDate,  
        toDate, 
        statusDelivery,
    ]);


    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="flex-column d-sm-flex justify-content-end align-items-start pb-0">
                    <h1 className="customer-title mb-3">Quản lý phiếu giao hàng</h1>
                    {/*<div className="d-flex">*/}
                    {/*    <Exportfile Data={listTicket} fileName={'Danh sách phiếu giao hàng'}/>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <div className="d-flex ">
                            <Form.Group className="mr-4 w-20">
                                <input 
                                    id="searchCategory" 
                                    className="form-control" 
                                    type="search" 
                                    placeholder="Tìm kiếm" 
                                    aria-label="Search" 
                                    onChange={(e) => setKeyword(trim(e.target.value))}
                                    style={{ background: "#fff", border: '1px solid #ced4da'}}
                                    />
                                <span className="bx bx-search-alt"></span>
                            </Form.Group>
                            <div className="d-flex">
                            <Form.Group className="d-flex mr-5">
                                <Form.Label className="align-items-center mb-0 pl-0 pr-0 col-3 align-self-center">
                                    Từ ngày
                                </Form.Label>
                                <Form.Control onChange={(e) => {setFromDate(e.target.value)}} type="date" id="start_date" ></Form.Control>
                            </Form.Group>
                            <div className="grid mr-5">
                                <Form.Group className="d-flex">
                                    <Form.Label className=" align-items-center mb-0 pl-0 pr-0  col-3 align-self-center">
                                        Đến ngày
                                    </Form.Label>
                                    <Form.Control onChange={(e) => {setToDate(e.target.value)}} type="date" id="end_date" ></Form.Control>
                                    {/*<p className="ml-5 pl-4 text-danger" style={{ "position": "absolute"}}>{validationMsg.startDate}</p>*/}
                                </Form.Group>
                            </div>
                            <div className="grid">
                                <select name="" id="selectTypeList" onChange={(e) => {setStatusDelivery(e.target.value); setCurrentPage(1)}} className="form-control custom_select custom_selectProduct" >
                                    <option value="">Tất cả</option>
                                    {
                                        listStatusDelivery.map((status, key) => {
                                            return <option key={key} value={status.id}>{status.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="category-manager-wapper">
                <div className="table-responsive group_product">
                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                        <tr>
                            <th>Mã giao hàng</th>
                            <th>Mã đơn hàng</th>
                            <th>Ngày tạo</th>
                            <th>Giá trị đơn hàng(VNĐ)</th>
                            <th>Phương thức vận chuyển</th>
                            <th>Trạng thái vận chuyển</th>
                            <th>Phương thức/trạng thái thanh toán</th>
                        </tr>
                        </thead>
                        <tbody row="10">
                        {listTicket ? listTicket.map((ticket, index) =>
                                <tr key={index}>
                                    <td style={{width:'10%'}}>
                                        <Link to={`/main/list-orders/${ticket.order_id}`}>
                                            {ticket.code}
                                        </Link>
                                    </td>
                                    <td style={{width:'10%'}}>
                                        {ticket.order.code}
                                    </td>
                                    <td style={{width:'10%'}}>
                                        {formatDate(ticket.created_at)}
                                    </td>
                                    <td style={{width:'17%'}}>
                                        {formatCurrency(ticket.order.origin_price)}
                                    </td>
                                    <td style={{width:'17%'}}>
                                        {listMethodDelivery.find(method => {
                                            return method.id == ticket.method_id
                                        }).name}
                                    </td>
                                    <td style={{width:'17%'}}>
                                        {listStatusDelivery.find(status => {
                                            return status.id == ticket.order.delivery_status
                                        }).name}
                                    </td>
                                    <td style={{width:'41%'}}>
                                        {ticket.order.payment_method.name} / {ticket.order.payment_status === false
                                        ? 'Chưa thanh toán'
                                        : 'Đã thanh toán'
                                    }
                                    </td>
                                </tr>
                        ) : ''}
                        </tbody>
                    </table>
                    {dataPaginate.totalRecord <= customersPerPage ? '' :
                        <Pagination
                            onChange={(value) => setCurrentPage(value)}
                            total={dataPaginate.totalRecord}
                            current={dataPaginate.currentPage}
                            showSizeChanger= {false}
                            defaultPageSize={customersPerPage}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ListTicket
