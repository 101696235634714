import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ENDPOINT from '../../ultils/EndPoint';
import '../category/Category.scss';
import {Button, Modal} from "react-bootstrap";
import { Link } from 'react-router-dom';
import $ from "jquery";
import {toast, ToastContainer} from "react-toastify";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function CategoryManager() {
    // set const
    const [listCategory, setCategory] = useState([]);
    // set const keyword
    const [keyWord, setKeyWord] = useState('');
    const [show, setShow] = useState(false);
    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    // remove all whitespace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // call api set list category
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.CATALOG}`, config)
        .then((res) => {
            if (!unmounted) {
                setCategory(res.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch]);

    // delete category
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.CATALOG}/${id}`, config)
            .then(function (response) {
                toast.success('Xóa danh mục tin tức thành công !')
                setTimeout(() => {
                    window.location.href = '/main/catalog-news'
                }, 2000);
            })
            .catch(error => {
                    alert(error.response.data.error)
                }
            )
    }

    // Handle search debounce
    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    const handleInputSearch = (e) => {
        setKeyWord(removeExtraSpace(e.target.value));
    }

    // search debounce
    const optimisedChange = debounce(handleInputSearch)

    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Danh sách danh mục tin tức</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <Link to ="/main/catalog-news/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
                </div>
                <div className="w-50">
                    <form className="form-inline position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input onChange={optimisedChange}  id="searchCategory" className="form-control" type="search" placeholder="Tìm kiếm tên danh mục tin tức" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                        <span className="bx bx-search-alt"></span>
                    </form>
                </div>
            </div>
            <div className="category-manager-wapper">
                {listCategory && listCategory.filter((val) => {
                    if(keyWord === ''){
                        return val
                    }else if(val.name.toLowerCase().includes(keyWord.toLowerCase())){
                        return val
                    }
                }).map((cata, index) =>
                    <div key={index}>
                        <p className="parent">
                            {cata.name}
                            <p style={{'display':'flex', 'marginBottom':'0px'}}>
                                <Link to={`/main/catalog-news/edit/${cata.id}`}><i className="mdi mdi-pencil font-size-18 mr-2"></i></Link>
                                <i id={cata.id} onClick={() => {handleShow(); setIdUserCurr(cata.id); setNameUserCurr(cata.name)}} className="mdi mdi-delete font-size-18"></i>
                            </p>
                        </p>
                        {cata.child !== null ?
                            cata.child
                            .sort((a,b) => a.order_no - b.order_no)
                            .map((cataChilds, index) =>
                            <div key={index}>
                                <p id={cataChilds.id} className="child">
                                    {cataChilds.name}
                                    <p style={{'display':'flex', 'marginBottom':'0px'}}>
                                        <Link to={`/main/catalog-news/edit/${cataChilds.id}`}><i className="mdi mdi-pencil font-size-18 mr-2"></i></Link>
                                        <i id={cataChilds.id} onClick={() => {handleShow();  setIdUserCurr(cataChilds.id); setNameUserCurr(cataChilds.name)}} className="mdi mdi-delete font-size-18"></i>
                                    </p>
                                </p>
                                {/* {cataChilds.childs !== null ?
                                    cataChilds.childs.map((grandChild,index) =>
                                        <div key={index}>
                                            <p id={grandChild.id} className="grandchild">{grandChild.name}
                                                <p style={{'display':'flex', 'marginBottom':'0px'}}>
                                                    <Link to={`/main/catalog-news/edit/${grandChild.id}`}><i className="mdi mdi-pencil font-size-18 mr-2"></i></Link>
                                                    <i id={grandChild.id} onClick={() => {handleShow();  setIdUserCurr(grandChild.id); setNameUserCurr(grandChild.name)}} className="mdi mdi-delete font-size-18"></i>
                                                </p>
                                            </p>
                                        </div>
                                    )
                                : '' } */}
                            </div>
                        )
                        : ''}
                    </div>
                )}

            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá tin tức</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá tin tức <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CategoryManager
