import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import $, { trim } from 'jquery';
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function PartnerEditStore() {
    const history = useHistory();
    // set const
    const [namePartner, setNamePartner] = useState('');
    const [link, setLink] = useState('');
    const [imageURL, setImageURL] = useState('');
    const dispatch = useDispatch();
    // set msg
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');

    // others
    const [disable, setDisable]= useState(false);

    // set id
    const {id} = useParams();


    // call api show detail if have id
    useEffect(() => {
       if(id) {
        axios.get(`${ENDPOINT.PARTNER}/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setNamePartner(response.data.data.name)
                setLink(response.data.data.url)
                setImageURL(response.data.data.image)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
       }
    }, [id, dispatch])

    // update info partner
    const updateInforPartner = new FormData();
    updateInforPartner.append('name', trim(namePartner));
    updateInforPartner.append('url', link);
    updateInforPartner.append('image', imageURL);
    
    // submit when evething fine
    const updatePartner = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.PARTNER}/${id}`, updateInforPartner,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
            })
            .then(function (response) {
            if(response.status === 200) {
                toast.success('Chỉnh sửa đối tác thành công !')
                setTimeout(() => {
                    history.push('/main/partner')
                }, 2000);
            } 
            })
            .catch(error => {
                if(error.request.status === 422) {
                    setMsg('Thông tin tên danh mục đã tồn tại')
                    $('.name_partner').addClass('color_danger')
                }
            }
        )

    }

    // handle change
    const handleNamePartner = (e) => {
        setNamePartner((e.target.value).replace(/\s\s+/g, ''))
    }
    const removeErrMessName = () => [
        validationMsg.namePartner = '',
        setMsg(''),
        $('.name_partner').removeClass('color_danger')
    ]

    const handleLink = (e) => {
        setLink(e.target.value)
    }
    const removeErrLink= () => [
        validationMsg.link = '',
        $('.link_partner').removeClass('color_danger')
    ]

    // function check url
    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(namePartner.length <= 0) {
            msg.namePartner = 'Vui lòng nhập tên đối tác'
            $('.name_partner').addClass('color_danger')
        }else if(namePartner.length > 255) {
            msg.namePartner = 'Tên đối tác không dài quá 255 ký tự'
            $('.name_partner').addClass('color_danger')
        }else if( namePartner.charAt(0) && namePartner.charAt(1) === '' ) {
            msg.namePartner = 'Vui lòng nhập tên đối tác'
            $('.name_partner').addClass('color_danger')
        }

        if(link.length > 0 && !validURL(link)){
            msg.link = 'Định dạng link  không đúng'
            $('.link_partner').addClass('color_danger')
        }

        if(imageURL === '') {
            msg.imageURL = 'Trường hình ảnh là bắt buộc'
        }
        
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // params for create partner
    const createPartnerParams = new FormData();
    createPartnerParams.append('name', trim(namePartner));
    createPartnerParams.append('url', link);
    createPartnerParams.append('image', imageURL);

    // submit when evething fine
    const createPartner = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.PARTNER}`, createPartnerParams,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Thêm đối tác thành công !')
                setTimeout(() => {
                    history.push('/main/partner')
                }, 2000);
            } 
          })
          .catch(error => {
            if(error.request.status === 422) {
                setMsg('Thông tin tên đối tác đã tồn tại')
                $('.name_partner').addClass('color_danger')
            }
          }
        )

    }

    // upload file images
    const onFileChange = (e) => {
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }

        setImageURL(e.target.files[0])
        
        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon_partner_1');
            $('.icon_remove_image').addClass('icon_remove_partner');
        }

        reader.readAsDataURL(e.target.files[0])
    }

    // remove upload image
    const handleRemoveUpload = () => {
        $("#imageUpload1").val("");
        setImageURL('')
        $("#imagePreview").attr("src", "");
        $('.image_icon').removeClass('image_icon_partner_1');
        $('.icon_remove_image').removeClass('icon_remove_partner');
    }

    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog partner">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa đối tác</h1> : <h1 className="customer-title mb-3">Thêm mới đối tác</h1>} 
                </div>
            </div>
           
            <Form.Label className="col-2 pl-0 pr-0 ">Tên đối tác <span style={{'color':'red'}}>*</span> </Form.Label>
            <div className="input-group">
                <input type="text" className="form-control name_partner" value={namePartner} onKeyDown={removeErrMessName} onChange={handleNamePartner} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px', 'marginBottom':'0px'}} className="text-danger msg_name">{validationMsg.namePartner}</p> 
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{msg}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Link</Form.Label>
            <div className="input-group">
                <input type="text" className="form-control link_partner" value={link} onKeyDown={removeErrLink} onChange={handleLink} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.link}</p> 
            {/* ====================================================================== */}
            <div className="input-group mb-3 partner_image">
                <button htmlFor="imageUpload1" style={{'width':'136px', 'marginBottom':'30px'}} type="button" className="btn btn-add">+ Upload image<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload1" accept=".png, .jpg, .jpeg" /></button>
                {imageURL ?
                    <div style={{'width':'200px', 'height':'200px'}} id="close_btn_wapper">
                        <img className="image_icon" style={{'marginRight':'30px','width':'100%'}} alt="" id="imagePreview" src={imageURL}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                    </div>
                : ''}
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.imageURL}</p> 
            <div >
                <Link to="/main/partner"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link> 
                {id ? <button type="button" disabled={disable} onClick={updatePartner} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createPartner} className="btn btn-success mt-5">Lưu</button>}
            </div>
        </div>
    )
}

export default PartnerEditStore
