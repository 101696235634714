import React, {useEffect, useState} from 'react'
import Form from "react-bootstrap/Form";
import $ from "jquery"
import {ScriptApiService} from "../../ultils/EndPoint";
import {Link, useHistory} from "react-router-dom";
import trim from "validator/es/lib/trim";
import {toast, ToastContainer} from "react-toastify";

function CreateScript() {
    // set const
    const [listScript, setListScript] = useState([]);
    const [nameScript, setNameScript] = useState("");
    const [contentScript, setContentScript] = useState("");
    const history = useHistory();

    useEffect(() => {
        ScriptApiService.getListNoPaginate().then(r => {
            if (r.status){
                setListScript(r.data)
            }
        })
    }, [])
    // get data

    useEffect(() => {
        $(document).ready(function (){
            $(document).on("click",".delete-script-option",function() {
               $(this).parent('.row-script-option').remove()
            });

            $('.plus-option').click(function (){
                if (listScript.length){
                    let html = ''
                    listScript.map((item, key) =>
                        html += `<option value=${item.id} key=${key}>${item.name}</option>`
                    )
                    $('.content_option_script').append(
                        `<div class="row row-script-option" style="margin-top: 20px">
                            <div class="col-md-4">
                                <input placeholder="Nhệp tên kịch bản liên quan" type="text" class="form-control name_option_script" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>

                            <div class="col-md-4">
                                <select class="form-control option_script_id" name="option_script_id">
                                    ${html}
                                </select>
                            </div>

                            <div class="col-md-4 delete-script-option">
                                <i class="text-dark mdi mdi-delete font-size-20"></i>
                            </div>
                    </div>`
                    )
                }

            })

        })
    }, [listScript]);

    const handleCreateScript = () => {
        if (nameScript){
            $('.error_name_script').text("")
        }else{
            $('.error_name_script').html("Không được để trống")
        }

        if (contentScript){
            $('.error_content_script').html("")
        }else{
            $('.error_content_script').html("Không được để trống")
        }

        let arrayNameOption = []
        let listNameOption = document.querySelectorAll('input.name_option_script')
        listNameOption.forEach(input => {
            let valor =  input?.value;
            arrayNameOption.push(valor);
        })
        let isHasEmpty = arrayNameOption.includes("") && arrayNameOption.length > 0

        if (isHasEmpty){
            $('.error_option_script').html("Hãy điền đầy đủ thông tin")
            return
        }else{
            $('.error_option_script').html("")
        }

        let arrayIdOption = []
        let listIdIOption = document.querySelectorAll('select.option_script_id')
        listIdIOption.forEach(input => {
            let valor =  input?.value;
            if (valor == ""){
                valor = 0
            }
            arrayIdOption.push(parseInt(valor));
        })

        if (nameScript && contentScript && !isHasEmpty){
            let data = {
                name : nameScript,
                content : contentScript,
                name_options : arrayNameOption,
                script_option_ids : arrayIdOption
            }

            ScriptApiService.create(data).then(r => {
                if (r.status){
                    toast.success("Tạo mới câu hỏi tự động thành công")
                    setTimeout(() => {
                        history.push('/main/scripts')
                    }, 2000);
                }
            })
        }
    }


    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Thêm câu hỏi câu hỏi tự động</h1>
                </div>
            </div>
            <br/>
            <Form.Label className="col-2 pl-0 pr-0 ">Kịch bản chào <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group input-script" style={{marginBottom : "20px"}}>
                <input style={{width: "100%"}} onChange={(e) => setNameScript(trim(e.target.value))} placeholder="Nhập tên kịch bản" type="text" className="form-control name_category" aria-label="Username" aria-describedby="basic-addon1"/>
                <p style={{color: "red", fontSize: "12px"}} className="error_name_script"/>
            </div>


            <Form.Label className="col-2 pl-0 pr-0 ">Nội dung <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group input-script" style={{marginBottom : "20px"}}>
                <input style={{width: "100%"}} onChange={(e) => setContentScript(trim(e.target.value))}  placeholder="Nhập nội dung kịch bản" type="text" className="form-control name_category" aria-label="Username" aria-describedby="basic-addon1"/>
                <p style={{color: "red", fontSize: "12px"}} className="error_content_script"/>
            </div>


            <Form.Label className="col-2 pl-0 pr-0 "><strong style={{fontSize: "20px"}}>Thêm lựa chọn <i style={{marginLeft: "40px", fontSize : "40px", cursor: "pointer"}} className="text-dark mdi mdi-plus-box plus-option" /></strong></Form.Label>
            <div className="content_option_script">
                <p style={{color: "red", fontSize: "12px"}} className="error_option_script"/>
            </div>

            <div >
                <Link to="/main/scripts"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                <a onClick={handleCreateScript} type="button" className="btn btn-success mt-5">Lưu</a>
            </div>
        </div>

    )
}
export default CreateScript