import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from "moment";

export function objectToQueryParams(queryParams) {
    return queryParams ?
        Object.entries(queryParams).reduce((acc, [key, val], index) => {
            const sign = index === 0 ? '?' : '&';
            acc += `${sign}${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
            return acc;
        }, '')
        : '';
}

export function validateNegativeInteger(e, value) {
    if (!/^(\-(\d*))$/.test(value)){
        e.preventDefault()
    }
}

export const formatCurrency = (value) => {
    let floatValue = parseFloat(value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return floatValue.substring(0, floatValue.length - 2);
};

export const onNumberOnlyChange = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    if (!isValid) {
        event.preventDefault();
        return;
    }
};

export const shippingOnlyChange = (event, provincePrice, index) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    let value = document.getElementsByClassName(`${index}_price_different`)[0].value
    let valCurrent = parseInt(`${value}${keyValue}`)

    if (keyCode === 46 || keyCode === 101 || (value.toString().length && !isValid)) {
        event.preventDefault();
        return;
    }
    if (valCurrent <= -provincePrice) {
        document.getElementsByClassName(`${index}_price_different`)[0].value = -provincePrice
        event.preventDefault();
        return;
    }
};

export const handlePasteOnlyNumber = e => {
    if (!/[0-9]+/.test(e.clipboardData.getData('text'))) {
        e.preventDefault();
    }
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
    return vnf_regex.test(phone);
}

// format date from  api
export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day,month,year].join('-');
}

// export file
export const Exportfile = ({Data, fileName}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    const exportToCSV = ( Data , fileName) => {
        const ws = XLSX.utils.json_to_sheet(Data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <button type="button" onClick={() => exportToCSV(Data,fileName)} className="btn btn-success btn-export d-flex ">
            <i className="mdi mdi-download d-block font-size-16"></i>Export
        </button>
    )
}

export const getImageMedia = (medias) => {
    let child = medias.find(item => item.collection_name === 'single');
    if (child) {
      return child.image
    }
    return null;
  }


export const convertDateTime = (time, format) => {

    return moment(time).format(format)
}

export function finalPrice(product) {
    let discountDeal = product.deal && product.deal.type === 1 ? (product.discount - product.deal.value) : ((product.deal && product.deal.type === 2) ? Math.round(product.discount * (1 - product.deal.value/100)) : product.discount)
    return discountDeal > 0 ? discountDeal : 0;
  }

export function sumProp(items, prop) {
if (items == null || items === '') {
    return 0
}
return items.reduce(function (a, b) {
    return b[prop] == null ? a : a + b[prop]
}, 0)
}

export const pluck = (objs, key) => {
    var sol = [];
    for(var i in objs){
        if(objs[i].hasOwnProperty(key)){
            sol.push(objs[i][key]);
        }
    }
    return sol;
}

// const debounce = (func) => {
//     let timer;
//     return function (...args){
//         const context = this;
//         if(timer) clearTimeout(timer)
//         timer = setTimeout(() => {
//             timer = null
//             func.apply(context, args)
//         }, 1000)
//     }
// }

export const formatDateTime = (time) => {

    return moment(time).format( 'HH:mm DD-MM-YYYY')
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export const convertEncodeToEmoji = (string) =>{
    return string.replace(/\p{Emoji}/ug, (m, idx) =>
        "&#x" +m.codePointAt(0).toString(16)
    )
}

export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}