import React, {useEffect, useState} from 'react'
import {PrescriptionApiService, ReviewApiService} from "../../ultils/EndPoint";
import {toast, ToastContainer} from "react-toastify";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import $ from "jquery";
import Switch from "react-switch";
import {Button, Modal} from "react-bootstrap";

function PrescriptionDetail() {
    const [prescriptionDetail, setPrescriptionDetail] = useState({});
    const {id} = useParams()

    function getPrescriptionDetail(id){
        PrescriptionApiService.show(id).then(r => {
            if(r.status) {
                setPrescriptionDetail(r.data)
            }
        })
    }
    useEffect(() => {
        getPrescriptionDetail(id)
    }, [id]);

    return (
        <div className="pl-4 pr-4 mt-3">
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Chi tiết đơn thuốc</h1>
                </div>
            </div>
            <div className="infoOrderReview" style={{marginTop : "80px"}}>
               <div className="row">
                   <div className="col-md-4">
                       <strong>Ảnh đơn thuốc</strong>
                       <br/>
                       <img src={prescriptionDetail.image} alt={prescriptionDetail.full_name} width="300px" height="300px"/>
                   </div>
                   <div className="col-md-8" style={{fontSize : "16px"}}>
                       <p></p>
                       <p><strong>Họ và tên: </strong>{prescriptionDetail.full_name}</p>
                       <p><strong>Số điện thoại: </strong>{prescriptionDetail.phone}</p>
                       <p><strong>Mã đơn thuốc :</strong>{prescriptionDetail.id}</p>
                       <p><strong>Địa chỉ: </strong>{prescriptionDetail.address + " , " + prescriptionDetail.city_name + " , " + prescriptionDetail.district_name + " , "+ prescriptionDetail.ward_name}</p>
                   </div>
               </div>
            </div>
        </div>
    )
}
export default PrescriptionDetail
