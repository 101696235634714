import React, {useEffect, useState} from 'react';
import ENDPOINT from '../../ultils/EndPoint';
import axios from "axios";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';
import Switch from "react-switch";

function Shippings() {
    const [listShipping, setListShippings] = useState([]);
    const [checked, setChecked] = useState(false);
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    const dispatch = useDispatch();

    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };


    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.SHIPPINGS}`, config)
        .then((res) => {
            if (!unmounted) {
                setListShippings(res.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch]);

    return (
        <div className="pl-4 pr-4 mt-3">
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Quản lý vận chuyển</h1>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <th>STT</th>
                        <th>Tên đơn vị giao hàng</th>
                        <th>Trạng thái</th>
                    </thead>
                    <tbody>
                    {listShipping.map((shipping, index) =>
                        <tr key={index}>
                            <td>{shipping.id}</td>
                            <td>{shipping.name}</td>
                            <td>
                                <label>

                                    {/* <Switch
                                        onChange={handleChange}
                                        checked={checked}
                                        className="react-switch"
                                    /> */}

                                    <Switch
                                        onChange={handleChange}
                                        checked={checked}
                                        className="react-switch"
                                    />

                                </label>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Shippings
