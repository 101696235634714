import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { trim } from 'jquery';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function BrandsEditAndCreate() {
    const {id} = useParams();
    const [nameBrands, setnameBrands ] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKey, setMetaKey] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [des, setdes] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [bannerURL, setbannerURL] = useState('');
    const [iconServer, setIconServer] = useState('');
    const [bannerServer, setbannerServer] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');
    const [disable, setDisable]= useState(false);
    const dispatch = useDispatch();

    const handleNameBrands = (e) => {
        // setnameBrands((e.target.value).replace(/\s\s+/g, ' '))
        setnameBrands(e.target.value)
    }

    const handleRemoveNameBrands = () => {
        validationMsg.nameBrands = '';
        setMsg('')
        $('.name_category').removeClass('danger_border');
    }

    const handleMetaTitle = (e) => {
        setMetaTitle(e.target.value)
    }
    const handleRemoveTitle = () => {
        validationMsg.metaTitle = ''
        $('.titile_category').removeClass('danger_border')
    }

    const handleMetaKey = (e) => {
        setMetaKey(e.target.value)
    }
    const handleRemoveKey = () => {
        validationMsg.metaKey = ''
        $('.key_category').removeClass('danger_border')
    }

    const handleMetaDescription = (e) => {
        setMetaDescription(e.target.value)
    }
    const handleRemoveDesc = () => {
        validationMsg.metaDescription = ''
        $('.desc_category').removeClass('danger_border')
    }

    const handleDes = (e) => {
        setdes(e.target.value)
    }

    const handleRemoveDes = () => {
        validationMsg.des = ''
        $('.title_des').removeClass('danger_border')
    }


    useEffect(()=>{
        if(id) {
            axios.get(`${ENDPOINT.BRAND}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (response) {
                if(response.status === 200) {
                    setnameBrands(response.data.data.name)
                    setMetaTitle(response.data.data.meta_title)
                    setMetaKey(response.data.data.meta_key)
                    setMetaDescription(response.data.data.meta_desc)
                    setdes(response.data.data.description)
                    setImageURL(response.data.data.image)
                    setbannerURL(response.data.data.banner)
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    },[id]);


    // set validate all 
    const validateAll = () => {
        const msg = {};

        if(nameBrands.length > 50) {
            msg.nameBrands = 'Thông tin Tên thương hiệu tối đa 50 kí tự'
            $('.name_category').addClass('danger_border');
        }else if(nameBrands.charAt(0) === '') {
            msg.nameBrands = 'Vui lòng nhập tên thương hiệu'
            $('.name_category').addClass('danger_border');
        }else if(nameBrands.slice(0,2) === ' ') {
            msg.nameBrands = 'Vui lòng nhập tên thương hiệu'
            $('.name_category').addClass('danger_border');
        }

        if(metaDescription.length <= 0) {
            msg.metaDescription = 'Vui lòng nhập tên mô tả'
            $('.desc_category').addClass('danger_border');
        }

        if(imageURL === ''){
            msg.imageURL = "Vui lòng tải lên ảnh đại diện"
        }

        if(bannerURL === ''){
            msg.bannerURL = "Vui lòng tải lên ảnh banner"
        }
        if(metaTitle.length <= 0) {
            msg.metaTitle = 'Vui lòng nhập tên tiêu đề'
            $('.titile_category').addClass('danger_border');
        }

        if(metaKey.length <= 0) {
            msg.metaKey = 'Vui lòng nhập tên từ khoá'
            $('.key_category').addClass('danger_border');
        }
        if(des.length <= 0) {
            msg.des = 'Vui lòng nhập tên mô tả'
            $('.title_des').addClass('danger_border');
        }


        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    const updateAdmin = new FormData();
    updateAdmin.append('name', trim(nameBrands));
    updateAdmin.append('meta_key', metaKey);
    updateAdmin.append('meta_title', metaTitle);
    updateAdmin.append('meta_desc', metaDescription);
    updateAdmin.append('description', des);
    updateAdmin.append('image', imageURL);
    updateAdmin.append('banner', bannerURL);

    const createBrands = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.BRAND}`, updateAdmin,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm thương hiệu thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/brands'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Thông tin Tên thương hiệu đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )

    }

    const updateAdminInfo = new FormData();
    updateAdminInfo.append('name', trim(nameBrands));
    updateAdminInfo.append('meta_key', metaKey);
    updateAdminInfo.append('meta_title', metaTitle);
    updateAdminInfo.append('meta_desc', metaDescription);
    updateAdminInfo.append('image', imageURL);
    updateAdminInfo.append('banner', bannerURL);
    updateAdminInfo.append('description',des);

    const updateBrands = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.BRAND}/${id}`, updateAdminInfo,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Chỉnh sửa thương hiệu thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/brands'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Thông tin Tên thương hiệu đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )

    }

    const handleRemoveUpload = () => {
        $("#imageUpload").val("");
        setImageURL(iconServer);
        $("#imagePreview").attr("src", iconServer);
        $('.image_icon').removeClass('image_icon1');
        $('.icon_remove_image').removeClass('icon_remove_image1');
    }

    const onFileChange = (e) => {
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }
        setImageURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon1');
            $('.icon_remove_image').addClass('icon_remove_image1');
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const handleRemoveUploadBanner = () => {
        $("#imageUploadBanner").val("");
        setbannerURL(bannerServer)
        $("#imagePreviewBanner").attr("src", bannerServer);
        $('.image_icon').removeClass('image_icon1');
        $('.icon_remove_image').removeClass('icon_remove_image2');
    }

    const onFileChangeBanner = (e) => {
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }
        setbannerURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreviewBanner').attr('src', e.target.result);
            $('#imagePreviewBanner').hide();
            $('#imagePreviewBanner').fadeIn(650);
            $('.image_icon').addClass('image_icon1');
            $('.icon_remove_image').addClass('icon_remove_image2');
        }

        reader.readAsDataURL(e.target.files[0])
    }

    return (
        <div className="pl-4 pr-4 mt-3 brands_form">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa thương hiệu</h1> : <h1 className="customer-title mb-3">Thêm mới thương hiệu</h1>}
                </div>
            </div>
    
            <Form.Label className="col-2 pl-0 pr-0 ">Tên thương hiệu <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control name_category" value={nameBrands} onKeyDown={handleRemoveNameBrands} onChange={handleNameBrands} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px', 'marginBottom':'0px'}} className="text-danger msg_name">{validationMsg.nameBrands}</p>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{msg}</p>
            <div className="mb-3">
                <Form.Label className="col-7 pl-0 pr-0 ">Avatar thương hiệu <span style={{"color": " red"}}>*</span> ( Upload ảnh kích thước 210*210 để có avatar thương hiệu đẹp )</Form.Label>
                <div className="form-group upload_img">
                <button htmlFor="imageUpload" type="button" className="btn btn-add">+ Upload<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" /></button>
                {imageURL ?
                    <div>
                        <div id="close_btn_wapper">
                            <img className="image_icon" style={{'marginRight':'30px'}} alt="" id="imagePreview" src={imageURL}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                        </div>
                    </div>
                    : ''}
                </div>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.imageURL}</p>
            <div className="mb-3">
                <Form.Label className="col-2 pl-0 pr-0 ">Ảnh banner thương hiệu <span style={{"color": " red"}}>*</span></Form.Label>
                <div className="form-group upload_img">
                    <button htmlFor="imageUpload" type="button" className="btn btn-add">+ Upload<input className="input_uploadImage" onChange={onFileChangeBanner} type='file' id="imageUploadBanner" accept=".png, .jpg, .jpeg" /></button>
                    {bannerURL ?
                        <div>
                            <div id="close_btn_wapper">
                                <img className="image_icon" style={{'marginRight':'30px'}} alt="" id="imagePreviewBanner" src={bannerURL}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUploadBanner} className="mdi mdi-delete icon_remove_image"></i>
                            </div>
                        </div>
                        : ''}
                </div>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.bannerURL}</p>
            <div className="input-group w-100">
                <Form.Label className="col-12 pl-0 pr-0 mt-2">Mô tả <span style={{"color": " red"}}>*</span></Form.Label>
                <textarea style={{ 'borderRadius': '0.25rem'}} className="form-control title_des" value={des} onKeyDown={handleRemoveDes} onChange={handleDes} aria-label="Username" aria-describedby="basic-addon1"></textarea>
                <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name w-100">{validationMsg.des}</p>
            </div>
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Thẻ tiêu đề <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control titile_category" value={metaTitle} onKeyDown={handleRemoveTitle} onChange={handleMetaTitle} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ từ khoá <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control key_category" value={metaKey} onKeyDown={handleRemoveKey} onChange={handleMetaKey} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaKey}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ mô tả <span style={{"color": " red"}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text"  className="form-control desc_category" value={metaDescription} onKeyDown={handleRemoveDesc} onChange={handleMetaDescription} aria-label="Username" aria-describedby="basic-addon1" />
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaDescription}</p>
            <div >
                <Link to="/main/brands"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                 {id ? <button type="button" disabled={disable} onClick={updateBrands} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createBrands} className="btn btn-success mt-5">Lưu</button>}
                {/*<button type="button" disabled={disable} onClick={createBrands} className="btn btn-success mt-5">Lưu</button>*/}
            </div>
        </div>
    )
}

export default BrandsEditAndCreate
