import React, {useEffect, useState} from 'react'
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import ENDPOINT, {FaqService} from "../../ultils/EndPoint";
import {toast, ToastContainer} from "react-toastify";
import $, { trim } from "jquery";
import {Pagination} from "antd";
import {Button, Modal} from "react-bootstrap";
import Switch from "react-switch";

export default function Faq() {
    const [questions, setQuestions] = useState([])
    const history = useHistory();
    const [total, setTotal] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const [filter, setFilter] = useState({
        user_name : '',
        product_name : ''
    })
    const [idUserCurr, setIdUserCurr] = useState('');
    const [customersPerPage] = useState(10);
    const [nameUserCurr, setNameUserCurr] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [statusFaq, setStatusFaq] = useState([])

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/  +/).join('');

    useEffect(async () => {
        let data = filter
        data.per_page = customersPerPage
        data.page = currentPage
        await getListFaq(data)
    }, [currentPage, filter])

    async function getListFaq(data) {
        let res = await FaqService.getList(data)
        if (res.status) {
            let arrStatus = []
            if (res.data.records && res.data.records.length) {
                res.data.records.map((item, index) => {
                    arrStatus.push(item.active)
                })
            }
            setStatusFaq(arrStatus)
            setQuestions(res.data.records)
            setTotal(res.data.total_record)
        }
    }

    const handleDelete = async (id) => {
        let res = await FaqService.deleteFaq(id)
        if (res.status) {
            toast.success("Xoá câu hỏi thành công!")
            let data = filter
            data.per_page = customersPerPage
            data.page = currentPage
            getListFaq(data)
        }
    }

    const handleInputSearch = (e, key) => {
        setFilter({
            user_name : key === 'user_name' ? ((e.target.value).replace(/  +/g, ' ').charAt(0)) === ' ' ? ((e.target.value).replace(/  +/g, ' ').charAt(0)).replace(' ', '') : (e.target.value).replace(/  +/g, ' ') : filter.user_name ,
            product_name : key === 'product_name' ? ((e.target.value).replace(/  +/g, ' ').charAt(0)) === ' ' ? ((e.target.value).replace(/  +/g, ' ').charAt(0)).replace(' ', '') : (e.target.value).replace(/  +/g, ' ') : filter.product_name
        })
        setcurrentPage(1)
    }

    async function handleChange(index) {
        let checkboxes = document.querySelectorAll('input[type="checkbox"].checkbox-status')
        let data = []
        checkboxes.forEach((input, i) => {
            let status = input.getAttribute('status') === 'true'
            if (i === index) {
                status = !status
            }
            data.push(status)
        })
        setStatusFaq(data)
    }
    async function toggleActive(index, item) {
        let res = await FaqService.toggleFaq(item.id)
        if (res.status) {
            getListFaq(filter)
        }
    }
    return (
        <div className="brands pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Quản lý FAQ </h1>
                </div>
            </div>
            <form className="pt-3">
                <div className="d-flex justify-content-between">
                    <div className="form-inline w-25 position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input className="w-100 form-control" value={filter.user_name} onChange={e => handleInputSearch(e, 'user_name')}
                               type="search" placeholder="Tên người hỏi " aria-label="Search"
                               style={{background: "#fff"}} />
                        <span className="bx bx-search-alt"></span>
                    </div>
                     <div className="form-inline w-25 position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input className="w-100 form-control" value={filter.product_name} onChange={e => handleInputSearch(e, 'product_name')}
                               type="search" placeholder="Tên sản phẩm " aria-label="Search"
                               style={{background: "#fff"}}/>
                        <span className="bx bx-search-alt"></span>
                    </div>
                </div>
            </form>
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <tr>
                        <th>STT</th>
                        <th>Tên </th>
                        <th>Số điện thoại</th>
                        <th>Sản phẩm</th>
                        <th>Câu hỏi</th>
                        <th>Admin trả lời</th>
                        <th>Hành động</th>
                        <th>Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {questions && questions.length ?
                        questions.map((item, index) =>
                            <tr key={index}>
                                <th>
                                    {index + 1}
                                </th>
                                <td style={{width : '15%'}}>{item.user.name}</td>
                                <td style={{width : '15%'}}>{item.phone ? item.phone : item.user.phone}</td>
                                <td>{item.model 
                                ?
                                <Link to={{pathname: `${process.env.REACT_APP_URL}san-pham/${item.model.slug}`}} target="_blank">
                                    {item.model.name}
                                </Link>  
                                : ""}</td>
                                <td>{item.content}</td>
                                <td style={{width:'12%'}}>
                                    {item.faq_answers && item.faq_answers.find(x => x.user_id === 1) ? 'Đã trả lời' : 'Chưa trả lời'}
                                </td>
                                <td>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" checked={statusFaq[index] || false} onChange={() => handleChange(index)} onClick={() => toggleActive(index, item)}
                                        className="custom-control-input checkbox-status" id={"customSwitch" + index} status={statusFaq[index]? statusFaq[index].toString() : 'false'}/>
                                        <label className="custom-control-label" htmlFor={"customSwitch" + index}></label>
                                    </div>
                                </td>
                                <td>
                                    <Link onClick={() => {
                                        handleShow();
                                        setIdUserCurr(item.id);
                                        setNameUserCurr(item.name)
                                    }} to="#" className="text-danger">
                                        <i className="mdi mdi-delete font-size-18"></i>
                                    </Link>
                                    <Link to={`/main/faq/${item.id}`}><i
                                        className="mdi mdi-information font-size-18 mr-2"></i></Link>
                                </td>
                            </tr>
                        )
                        : ''}
                    </tbody>
                </table>
                {total <= customersPerPage ? '' :
                    <Pagination
                        onChange={(value) => setcurrentPage(value)}
                        total={total}
                        current={currentPage}
                        showSizeChanger={false}
                        defaultPageSize={customersPerPage}
                    />
                }
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá câu hỏi </Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá câu hỏi này không <span
                    style={{'color': 'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}