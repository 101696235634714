import React, {useEffect, useState} from 'react'
import {toast, ToastContainer} from "react-toastify";
import Form from "react-bootstrap/Form";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../ultils/EndPoint";
import $, {trim} from "jquery";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function DealManager() {
    // set id 
    const {id} = useParams();

    // set const
    const [nameDeal, setNameDeal] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [typeDeal, setTypeDeal] = useState('');
    const [activeDeal, setActiveDeal] = useState('1');
    const [noteDeal, setNoteDeal] = useState('');
    const dispatch = useDispatch();

    const [iconServer, setIconServer] = useState('');
    const [priceDeal, setPriceDeal] = useState('');

    // set validation
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');
    const [msgTime, setMsgTime] = useState('');

    // test positive integer
    const positive =  /^\+?(0|[1-9]\d*)$/

    // others
    const [disable, setDisable]= useState(false);

    let today = new Date();

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(nameDeal.length > 255) {
            msg.nameDeal = 'Tên deal tối đa 255 kí tự'
            $('.nameDeal').addClass('danger_border');
        }else if( nameDeal.charAt(0) === '' ){
            msg.nameDeal = 'Vui lòng nhập tên deal'
            $('.nameDeal').addClass('danger_border');
        }else if(nameDeal.slice(0, 2) === ' '){
            msg.nameDeal = 'Vui lòng nhập tên deal'
            $('.nameDeal').addClass('danger_border');
        }
        
        if(imageURL === ''){
            msg.imageURL = "Vui lòng tải lên ảnh feature"
        }

        if(startDate.length === 0) {
            msg.startDate = 'Vui lòng chọn thời gian bắt đầu'
            $('.startDate').addClass('danger_border');
        }else {
            if(!id){
                if(new Date(startDate)  < today){
                    msg.startDate = 'Thời gian bắt đầu có hiệu lực phải là thời gian sau thời điểm hiện tại'
                    $('.startDate').addClass('danger_border');
                }
            }else {
                if(new Date(startDate) < today){
                    msg.startDate = 'Thời gian bắt đầu có hiệu lực phải là thời gian sau thời điểm hiện tại'
                    $('.startDate').addClass('danger_border');
                }
            }
        }
        

        if(endDate.length === 0) {
            msg.endDate = 'Vui lòng chọn thời gian kết thúc'
            $('.endDate').addClass('danger_border');
        }

        if(new Date(startDate) > new Date(endDate)){
            msg.endDate = 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu'
            $('.endDate').addClass('danger_border');
        }else if((new Date(endDate) - new Date(startDate))/ 60000 < 30){
            msg.endDate = 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu ít nhất 30 phút'
            $('.endDate').addClass('danger_border');
        }

        if(typeDeal === ''){
            msg.typeDeal = 'Vui lòng chọn loại deal'
            $('.typeDeal').addClass('danger_border');
        }else {
            $('.typeDeal').removeClass('danger_border');
            if(typeDeal === '1'){
                if(priceDeal.length === 0){
                    msg.priceDeal = 'Vui lòng nhập giá deal'
                    $('.priceDeal').addClass('danger_border');
                }else if(priceDeal < 1){
                    msg.priceDeal = 'Giá deal lớn hơn 0'
                    $('.priceDeal').addClass('danger_border');
                }else if(!positive.test(priceDeal)){
                    msg.priceDeal = 'Vui lòng không nhập kí tự đặc biệt'
                    $('.priceDeal').addClass('danger_border');
                }
            }else if(typeDeal === '2') {
                if(priceDeal.length === 0){
                    msg.priceDeal = 'Vui lòng nhập giá deal'
                    $('.priceDeal').addClass('danger_border');
                }else if(priceDeal > 100){
                    msg.priceDeal = 'Giá trị % không lớn hơn 100'
                    $('.priceDeal').addClass('danger_border');
                }else if(priceDeal < 1){
                    msg.priceDeal = 'Giá trị % lớn hơn 0 '
                    $('.priceDeal').addClass('danger_border');
                }else if(!positive.test(priceDeal)){
                    msg.priceDeal = 'Vui lòng không nhập kí tự đặc biệt'
                    $('.priceDeal').addClass('danger_border');
                }
            }
        }

        if(noteDeal.length > 0){
            if(noteDeal.length > 255){
                msg.noteDeal = 'Ghi chú tối đa 255 kí tự'
                $('.noteDeal').addClass('danger_border');
            }
        }

        if(imageURL.length === 0){
            msg.imageURL = 'Vui lòng tải ảnh'
            $('.imageURL').addClass('danger_border');
        }

    
        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // handle change all
    const handleNameDeal = (e) => {
        setNameDeal(e.target.value);
    }

    const removeMessNameDeal = () => {
        validationMsg.nameDeal = '';
        $('.nameDeal').removeClass('danger_border')
        setMsg('')
    }

    const handleStartDate = (e) => {
        setStartDate((e.target.value).replace(/T/g, " "));
    }
    const removeDangerStartDate = (e) => {
        $('.startDate').removeClass('danger_border');
    }
    const handleEndDate = (e) => {
        setEndDate((e.target.value).replace(/T/g, " "))
    }
    const removeDangerEndtDate = () => {
        $('.endDate').removeClass('danger_border');
    }

    const handleNoteDeal = (e) => {
        setNoteDeal(e.target.value)
    }
    const removeMessNotDeal = () => {
        validationMsg.noteDeal = '';
        $('.noteDeal').removeClass('danger_border')
    }

    const handlePriceDeal = (e) => {
        setPriceDeal(e.target.value)
    }

    const removeMessPrice = () => {
        validationMsg.priceDeal = '';
        $('.priceDeal').removeClass('danger_border')
    }

    const handleSelectType = (e) => {
        setTypeDeal(e.target.value)
    }


    const onFileChange = (e) => {
        setImageURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon1');
            $('.icon_remove_image').addClass('icon_remove_image1');
        }
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }else {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const handleRemoveUpload = () => {
        $("#imageUpload").val("");
        setImageURL(iconServer);
        $("#imagePreview").attr("src", iconServer);
        $('.image_icon').removeClass('image_icon1');
        $('.icon_remove_image').removeClass('icon_remove_image1');
    }



    // call api for edit
    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT.DEALLIST}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (r) {
                if(r.status === 200) {
                    setNameDeal(r.data.data.name)
                    setStartDate(r.data.data.start_time)
                    setEndDate(r.data.data.end_time)
                    setTypeDeal(r.data.data.type)
                    setPriceDeal(r.data.data.value)
                    setActiveDeal(r.data.data.active)
                    setNoteDeal(r.data.data.note)
                    setImageURL(r.data.data.banner)
                    setIconServer(r.data.data.banner)
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    }, [id, dispatch])

    // edit deal
    const updateDealed = new FormData();
    updateDealed.append('name', trim(nameDeal));
    updateDealed.append('start_time', startDate.replace(/T/g, " "));
    updateDealed.append('end_time', endDate.replace(/T/g, " "));
    updateDealed.append('type', typeDeal);
    updateDealed.append('value', priceDeal);
    updateDealed.append('note', noteDeal);
    updateDealed.append('active', activeDeal);
    if(iconServer === imageURL){

    }else {
        updateDealed.append('banner', imageURL);
    }

    const updateDeal = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.DEALLIST}/${id}`, updateDealed,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                toast.success('Chỉnh sửa deal thành công !')
                setTimeout(() => {
                    window.location.href = '/main/deal'
                }, 2000);
            }
        })
        .catch(error => {
                if(error.response.data.errors.name === "Tên Deal đã tồn tại") {
                    setMsg('Tên Deal đã tồn tại')
                    $('.nameCoupon').addClass('danger_border');
                }
            }
        )
    }

    // create deal
    const createDealed = new FormData();
    createDealed.append('name', trim(nameDeal));
    createDealed.append('start_time', (startDate).replace(/T/g, " "));
    createDealed.append('end_time', (endDate).replace(/T/g, " "));
    createDealed.append('type', typeDeal);
    createDealed.append('value', priceDeal);
    createDealed.append('note', noteDeal);
    createDealed.append('active', activeDeal);
    if(iconServer === imageURL){

    }else {
        createDealed.append('banner', imageURL);
    }

    const createDeal = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.DEALLIST}`, createDealed,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm mới deal thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/deal'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.response.data.errors.name === "Tên Deal đã tồn tại") {
                        setMsg('Tên Deal đã tồn tại')
                        $('.nameCoupon').addClass('danger_border');
                    }
                }
            )

    }

    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress(
            function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else
                {
                    if (event.keyCode < 48 || event.keyCode > 57 )
                    {
                        event.preventDefault();
                    }
                }
            }
        );
    })

    useEffect(() => {
        $(".mask").each((i,ele)=>{
            let clone=$(ele).clone(false)
            clone.attr("type","text")
            let ele1=$(ele)
            clone.val(Number(ele1.val()).toLocaleString("en"))
            $(ele).after(clone)
            $(ele).hide()
            clone.mouseenter(()=>{
                ele1.show()
                clone.hide()
            })
            setInterval(()=>{
                let newv=Number(ele1.val()).toLocaleString("en")
                if(clone.val()!== newv){
                    clone.val(newv)
                }
            },10)
    
            $(ele).mouseleave(()=>{
                $(clone).show()
                $(ele1).hide()
            })
        })
    }, [])

    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa deal</h1> : <h1 className="customer-title mb-3">Thêm mới deal</h1>}
                </div>
            </div>
            <Form.Label className="col-2 pl-0 pr-0 ">Tên deal <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control nameDeal" value={nameDeal} onChange={handleNameDeal} onKeyDown={removeMessNameDeal}/>
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.nameDeal}</p>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{msg}</p>
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Ngày bắt đầu <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group" >
                <input 
                    type="datetime-local" 
                    step="1"
                    id="dateInput"
                    className="form-control startDate" 
                    value={startDate.replace(/ /g, "T") || ''} 
                    onChange={handleStartDate} 
                    onClick={removeDangerStartDate}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.startDate}</p>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{msgTime}</p>
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Ngày kết thúc <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input 
                    type="datetime-local" 
                    step="1"
                    className="form-control endDate" 
                    value={endDate.replace(/ /g, "T") || ''} 
                    onChange={handleEndDate}
                    onClick={removeDangerEndtDate}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.endDate}</p>
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Loại deal <span style={{'color':'red'}}>*</span></Form.Label>
            <select name="" id="" className='form-control typeDeal' value={typeDeal} onChange={handleSelectType}>
                <option value=""></option>
                <option value="1">Tiền</option>
                <option value="2">%</option>
            </select>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.typeDeal}</p>
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Giảm <span style={{'color':'red'}}>*</span></Form.Label>
                <input type="text" className="form-control priceDeal mask" name="inputForNumber" value={priceDeal} onKeyDown={removeMessPrice} onChange={handlePriceDeal}/>
                <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.priceDeal}</p>
            <Form.Label className="col-2 pl-0 pr-0 mt-2">Ghi chú</Form.Label>
            <textarea name="" id="" cols="30" rows="4" onKeyDown={removeMessNotDeal} className="form-control" value={noteDeal} onChange={handleNoteDeal}></textarea>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-0">{validationMsg.noteDeal}</p>
            <div className="input-group mt-3">
                <button htmlFor="imageUpload" type="button" className="btn btn-add">Hình ảnh<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" /></button>
                {imageURL ?
                    <div className="w-100 mt-2">
                        <div id="close_btn_wapper">
                            <img className="image_icon" style={{'marginRight':'30px','width':'150px', 'height':'150px'}} alt="" id="imagePreview" src={imageURL}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                        </div>
                    </div>
                    : ''}
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name mb-0 mt-2">{validationMsg.imageURL}</p>
            <div>
                <Link to="/main/deal"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                {
                    id 
                    ? <button type="button" disabled={disable} onClick={updateDeal} className="btn btn-success mt-5">Cập nhật</button> 
                    : <button type="button" disabled={disable} onClick={createDeal}  className="btn btn-success mt-5">Lưu</button>
                }
            </div>
        </div>
    )
}
export default DealManager
