import React, {useEffect, useState} from 'react'
import {ReviewApiService} from "../../ultils/EndPoint";
import {toast, ToastContainer} from "react-toastify";
import {useParams} from "react-router";
import $ from "jquery";
import Switch from "react-switch";
import {Button, Modal} from "react-bootstrap";

function OrderReviewDetail() {
    // set const
    const [listReviewProduct, setListReviewProduct] = useState([]);
    const {id} = useParams()
    const [idReviewSelect, setIdReviewSelect] = useState(0)

    // set modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function getListReviewProduct(id){
        ReviewApiService.getListReviewProduct(id).then(r => {
            if(r.status) {
                setListReviewProduct(r.data)
            }
        })
    }
    useEffect(() => {
        getListReviewProduct(id)
    }, [id]);

    function handleToggleActive(idProduct){
        let data = {
            "product_id" : idProduct
        }

        ReviewApiService.toggleActiveReview(id, data).then(r => {
            if(r.status){
                getListReviewProduct(id)
            }
        })
    }

    function handleReplyReview(idReview){
        setIdReviewSelect(idReview)
        handleShow()
    }

    function handleSubmitReply(){
        let content = $('#admin_review').val()
        if (!content){
            $('.error_admin_review').html('Vui lòng nhập thông tin')
            return
        }
        ReviewApiService.reply(idReviewSelect, {"description" : content}).then(r => {
            if (r.status){
                toast.success('Trả lời bình luận thành công !')
                getListReviewProduct(id)
                handleClose()
            }
        })
    }
    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Chi tiết đánh giá</h1>
                </div>
            </div>
            <div className="infoOrderReview" style={{marginTop : "20px"}}>
                <p><strong>Mã đơn hàng</strong> : {listReviewProduct.length ? listReviewProduct[0].order_code : ''}</p>
                <p><strong>Người đánh giá</strong> : {listReviewProduct.length ? listReviewProduct[0].user.name : ''}</p>
                <p><strong>Sản phẩm</strong> : {listReviewProduct.length}</p>
            </div>
            <div className="table-reponsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                    <th>STT</th>
                    <th>Tên sản phẩm</th>
                    <th>Rate</th>
                    <th>Bình luận</th>
                    <th>Trả lời</th>
                    <th>Hành động</th>
                    </thead>
                    <tbody>
                    {listReviewProduct && listReviewProduct.map((item,index) =>
                        <tr key={index}>
                            <td style={{ "width" : "10%"}}>{index + 1}</td>
                            <td style={{ "width" : "30%"}}>{item.products.name}</td>
                            <td>{item.star}</td>
                            <td>{item.description}</td>
                            <td>
                                {
                                    item.reply && item.reply.map((v, i) => {
                                        return (
                                            <p key={i}>{v.description}</p>
                                        )
                                    })
                                }
                            </td>
                            <td>
                                <Switch
                                    onChange={() => {handleToggleActive(item.products.id)}}
                                    checked={item.visible}
                                    className="react-switch"
                                />
                                <i onClick={() => {handleReplyReview(item.id)}} style={{marginLeft : "30px", fontSize : "30px"}} className="mdi mdi-pencil"></i>

                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Admin trả lời</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea id="admin_review" name="admin_review" placeholder="" rows="4" cols="60" />
                    <br/>
                    <span className="error_admin_review" style={{fontSize: "12px", color : "red"}}></span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleSubmitReply();
                    }}>
                        Trả lời
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default OrderReviewDetail
