import React, { useState, useEffect } from 'react';
import './Products.scss';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import ENDPOINT from '../../ultils/EndPoint';
import $, { trim } from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams } from 'react-router';
import {Link} from 'react-router-dom';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from '../toolbar/QuillToolbar';
import EditorToolbar1, { modules1, formats1 } from '../toolbar/QuillToolbar1';
import EditorToolbar2, { modules2, formats2 } from '../toolbar/QuillToolbar2';
import EditorToolbar3, { modules3, formats3 } from '../toolbar/QuillToolbar3';
import "react-quill/dist/quill.snow.css";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function ProductCreateAndEdit() {
    // set const 
    const [nameProduct, setNameProduct] = useState('');
    const [barCode, setBarCode] = useState('');
    const [sku, setSku] = useState('');
    const [typeList, setTypeList] = useState([]);
    const [typeSelected, setTypeSelected] = useState('2');
    const [category, setCategory] = useState([]);
    var allCategory = [];
    const dispatch = useDispatch();
    category && category.map(cate => 
        {allCategory.push({
            name: cate.name, 
            id: cate.id,
            checkOrdin: 1
        })
            cate.childs !== null && cate.childs.map(cateChild => { 
                    allCategory.push({
                        name: cateChild.name, 
                        id: cateChild.id,
                        checkOrdin: 2
                    })
                    cateChild.Child !== null && cateChild.Child.map(cateChildLast => 
                        allCategory.push({
                            name: cateChildLast.name, 
                            id: cateChildLast.id,
                            checkOrdin: 3
                        })
                    )
                }
            )
        }
    )
    const [categorySelected, setCategorySelected] = useState('');
    const [brands, setBrands] = useState([]);
    const [brandSelected, setbrandSelected] = useState('');
    const [typeUnit, setTypeUnit] = useState([]);
    const [typeUnitSelected, setTypeUnitSelected] = useState('');
    const [capacity, setCapacity] = useState('');
    const [priceProduct, setPriceProduct] = useState('');
    const [discount, setDiscount] = useState('');
    const [weight, setWeight] = useState('');
    const [productsInStock, setProductsInStock] = useState('');
    const [madeIn, setMadeIn] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [commonInfo, setCommonInfo] = useState([]);
    const [components, setComponents] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const [usages, setUsages] = useState([]);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKey, setMetaKey] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [statusProduct, setStatusProduct] = useState('1');
 
    const [imageFirst, setImageFirst] = useState([]);
    const [singleImage, setSingleImage] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [multiImageUpload, setMultiImage] = useState([]);

    const [selectMultiImage, setSelectMultiImage] = useState([]);
    const [multiImageUpdate, setMultiImageUpdate] = useState([]);

    const [deleteId, setDeleteId] = useState([]);
    const [deleteIdCheck, setDeleteIdCheck] = useState([]);

    // set history
    const history = useHistory();

    // get id 
    const {id} = useParams();

    // test made in
    const testMadeIn = /^\s*\d[\d\s]*$/

    // test positive integer
    const positive =  /^\+?(0|[1-9]\d*)$/

    // validate mess
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');
    const [msg1, setMsg1] = useState('')

    // others
    const [disable, setDisable]= useState(false);
    
    // handle all
    const handleNameProduct = (e) => {
        setNameProduct((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessNameProduct = () => {
        $('.name_product').removeClass('product_warning')
        validationMsg.nameProduct = ''
        setMsg('')
    }
   
    const handleBarCode = (e) => {
        setBarCode((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessBarCode = () => {
        $('.barCode').removeClass('product_warning')
        validationMsg.barCode = ''
    }
    
    const handleSku = (e) => {
        setSku((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessSku = () => {
        $('.sku').removeClass('product_warning')
        validationMsg.sku = ''
    }

    const handleTypeList = (e) => {
        setTypeSelected(e.target.value)
    }

    const handleCategory = (e) => {
        setCategorySelected(e.target.value)
    }
    const RemoveMessCategory = () => {
        $('.categorySelected').removeClass('product_warning')
        validationMsg.categorySelected = ''
    }

    const handleBrands = (e) => {
        setbrandSelected(e.target.value)
    }
    const RemoveMessBrands = () => {
        $('.brands').removeClass('product_warning')
        validationMsg.brandSelected = ''
    }

    const handleTypeUnit = (e) => {
        setTypeUnitSelected(e.target.value)
    }
    const RemoveMessTypeUnit = () => {
        $('.unit').removeClass('product_warning')
        validationMsg.typeUnitSelected = ''
    }

    const handleCapacity = (e) => {
        setCapacity((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessCapacity = () => {
        $('.capacity').removeClass('product_warning')
        validationMsg.capacity = ''
    }

    const handlePriceProduct = (e) => {
        setPriceProduct(e.target.value)
    }
    const RemoveMessPriceProduct = () => {
        $('.price_product').removeClass('product_warning')
        validationMsg.priceProduct = ''
    }

    const handleDiscount = (e) => {
        setDiscount(e.target.value)
    }
    const RemoveMessDiscount = () => {
        validationMsg.discount = ''
        $('.discount').removeClass('product_warning')
    }

    const handleWeight = (e) => {
        setWeight(e.target.value)
    }
    const RemoveMessWeight = () => {
        $('.weight').removeClass('product_warning')
        validationMsg.weight = ''
    }

    const handleProductsInStock = (e) => {
        setProductsInStock(e.target.value)
    }
    const RemoveMessProductsInStock = () => {
        $('.productsInStock').removeClass('product_warning')
        validationMsg.productsInStock = ''
    }

    const handleMadeIn = (e) => {
        setMadeIn((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessMadeIn = () => {
        $('.madeIn').removeClass('product_warning')
        validationMsg.madeIn = ''
    }

    const handleShortDesc= (e) => {
        setShortDesc((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessShortDesc = () => {
        $('.shortDesc').removeClass('product_warning')
        validationMsg.shortDesc = ''
    }

    const handleCommonInfo= (event) => {
        setCommonInfo(event)
    }

    const handleComponents= (event) => {
        setComponents(event)
    }

    const handleBenefits= (event) => {
        setBenefits(event)
    }

    const handleUsages= (event) => {
        setUsages(event)
    }

    const handleMetaTitle= (e) => {
        setMetaTitle((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessMetaTitle = () => {
        $('.metaTitle').removeClass('product_warning')
        validationMsg.metaTitle = ''
    }

    const handleMetaKey= (e) => {
        setMetaKey((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessMetaKey = () => {
        $('.metaKey').removeClass('product_warning')
        validationMsg.metaKey = ''
    }

    const handleMetaDesc= (e) => {
        setMetaDesc((e.target.value).replace(/\s\s+/g, ''))
    }
    const RemoveMessMetaDesc = () => {
        $('.metaDesc').removeClass('product_warning')
        validationMsg.metaDesc = ''
    }

    const handleStatusProduct= (e) => {
        setStatusProduct(e.target.value)
    }

    // call api type list 
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.TYPE_LIST}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setTypeList(response.data.data.slice(1,));
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // call api category 
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.LIST_CATEGORY}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setCategory(response.data.data);
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // call api brands
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.BRAND_NO_PAGINATE}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setBrands(response.data.data)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // call api type unit
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.TYPE_UNIT}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setTypeUnit(response.data.data)
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        })  
        return () => { unmounted = true };
    }, [dispatch])

    // call api for edit
    useEffect(() => {
        if(id) {
            let unmounted = false;
            axios.get(`${ENDPOINT.PRODUCT}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setNameProduct(response.data.data.name)
                        setBarCode(response.data.data.bar_code)
                        setSku(response.data.data.sku)
                        setTypeSelected(response.data.data.type_id)
                        setCategorySelected(response.data.data.category_id)
                        setbrandSelected(response.data.data.brand_id)
                        setTypeUnitSelected(response.data.data.unit_id)
                        setCapacity(response.data.data.capacity)
                        setPriceProduct(response.data.data.price)
                        setDiscount(response.data.data.discount)
                        setWeight(response.data.data.weight)
                        setProductsInStock(response.data.data.product_stock)
                        setMadeIn(response.data.data.made_in)
                        setShortDesc(response.data.data.intro_short)
                        
                        setCommonInfo(response.data.data.description)
                        setComponents(response.data.data.components)
                        setBenefits(response.data.data.benefits)
                        setUsages(response.data.data.usages)
    
                        setMetaTitle(response.data.data.meta_title)
                        setMetaKey(response.data.data.meta_key)
                        setMetaDesc(response.data.data.meta_desc)
                        setStatusProduct(response.data.data.status)

                        if(response.data.data.medias) {
                            response.data.data.medias.map(check => 
                                check.collection_name === 'single' ? setImageFirst(check) : ''
                            )
                        }

                        if(response.data.data.medias) {
                            response.data.data.medias.map(check => 
                                check.collection_name === 'single' ? setSingleImage(check) : ''
                            )
                        }
                                             
                        setMultiImage(response.data.data.medias.filter(image => image.collection_name === 'multi'))

                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            })  
            return () => { unmounted = true };
        }
    }, [id, dispatch])
    
    // only type for price
    useEffect(() => {
        $('input[name="inputForPrice"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    // upload file images
    const onFileChange = (e) => {
        setImageFirst(e.target.files[0])

        var reader = new FileReader();

        if(imageFirst && imageFirst.name !== singleImage.image){
            setDeleteId([singleImage.id])
        }

        reader.onload = function(e) {
            $('#imagePreviewProduct').attr('src', e.target.result);
            $('#imagePreviewProduct').hide();
            $('#imagePreviewProduct').fadeIn(650);
            $('.image_productMain').addClass('image_MainProduct');
            $('.icon_remove_image').addClass('icon_remove_image_product');
        }

        if (e.target.files[0] && e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return setImageFirst([])
        }else {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    // remove upload image
    const handleRemoveUpload = () => {
        $("#imageUploadProduct").val("");
        setImageFirst(singleImage.image);
        $("#imagePreviewProduct").attr("src", singleImage.image);
        $('.image_productMain').removeClass('image_MainProduct');
        $('.icon_remove_image').removeClass('icon_remove_image_product');
        var setAganinDelete = deleteId.filter(deleteId => deleteId !== singleImage.id)
        setDeleteId(setAganinDelete)
    }

    // upload file images multi
    const handleImageChange = (e) => {
        if (e.target.files) {
            setMultiImage([...multiImageUpload, ...e.target.files])
            const filesArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );

            if (e.target.files && e.target.files[0].size > 5e6) {
                alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            }else {
                setSelectedFiles((prevImages) => prevImages.concat(filesArray));
                Array.from(e.target.files).map(
                    (file) => URL.revokeObjectURL(file) 
                );
            }
        }
    };

    useEffect(() => {
        multiImageUpload.map(item => {
            if(item.size >= 5000000){
                var setImageOverSize = multiImageUpload.filter(multiImageUpload => multiImageUpload.name !== item.name)
                setMultiImage(setImageOverSize)
            }
        })
    }, [multiImageUpload])


    const renderPhotos = (source) => {
        if(!id) {
            return source.map((photo, index) => {
                return <div key={index} id={index}>
                        <img src={photo} alt="" key={photo} />
                        <i style={{'cursor':'pointer', 'fontSize': '18px'}} onClick={() => handleRemoveImage(index)} className="mdi mdi-delete remove_multi_image"></i>
                    </div>
            });
        }else {
            return source.map((photo, index) => {
                return <div key={index} id={photo.id}>
                        <img src={photo.image} alt="" key={photo} />
                        <i style={{'cursor':'pointer', 'fontSize': '18px'}} onClick={() => handleRemoveImage(photo.id)} className="mdi mdi-delete remove_multi_image"></i>
                    </div>
            });
        }
    };

    // handle Remove Image 
    const handleRemoveImage = (id) => {
        $(`#${id}`).remove()
        var setImageAgain = multiImageUpload.filter((image, index) => index !== id)
        setDeleteId([...deleteId, id])
        setDeleteIdCheck([...deleteIdCheck, id])
        setMultiImage(setImageAgain)
    }

    //handle multi image when update
    const handleImageChangeUpdate = (e) => {
        if (e.target.files) {
            setMultiImageUpdate([...multiImageUpdate, ...e.target.files])
            const filesArrayMulti = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );
            
            if (e.target.files && e.target.files[0].size > 5e6) {
                alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            }else {
                setSelectMultiImage((prevImages) => prevImages.concat(filesArrayMulti));
                Array.from(e.target.files).map(
                    (file) => URL.revokeObjectURL(file) 
                );
            }
        }
    }

    useEffect(() => {
        multiImageUpdate.map(item => {
            if(item.size >= 5000000){
                var setImageOverSizeUpdate = multiImageUpdate.filter(multiImageUpdate => multiImageUpdate.name !== item.name)
                setMultiImageUpdate(setImageOverSizeUpdate)
            }
        })
    }, [multiImageUpdate])

    const renderMultiImage = (source) => {
        if(id) {
            return source.map((photo, index) => {
                return <div key={index} id={index}>
                        <img src={photo} alt="" key={photo} />
                        <i style={{'cursor':'pointer', 'fontSize': '18px'}} onClick={() => handleRemovemultiImage(index)} className="mdi mdi-delete remove_multi_image"></i>
                    </div>
            });
        }
    }

    // handle Remove Image 
    const handleRemovemultiImage = (id) => {
        $(`#${id}`).remove()
        var setImageAgain = multiImageUpdate.filter((image, index) => index !== id)
        setMultiImageUpdate(setImageAgain)
    }


    // validate before submit
    const validateAll = () => {
        const msg = {};
        
        if(nameProduct.length > 255 ){
            msg.nameProduct = 'Tên sản phẩm không dài quá 255 ký tự'
            $('.name_product').addClass('product_warning')
        }else if( nameProduct.charAt(0) === '' ){
            msg.nameProduct = 'Vui lòng nhập vào trường tên sản phẩm '
            $('.name_product').addClass('product_warning')
        }else if(nameProduct.slice(0, 2) === ' ' ){
            msg.nameProduct = 'Vui lòng nhập vào trường tên sản phẩm '
            $('.name_product').addClass('product_warning')
        }

        if(barCode.length > 0){
            if(barCode.length > 50 ){
                msg.barCode = 'Mã vạch sản phẩm không dài quá 50 ký tự'
                $('.barCode').addClass('product_warning')
            }
        }

        if(sku.length > 0){
            if(sku.length > 50 ){
                msg.sku = 'Mã SKU không dài quá 50 ký tự'
                $('.sku').addClass('product_warning')
            }
        }
        
        if(categorySelected.length === 0 ){
            msg.categorySelected = 'Vui lòng chọn danh mục'
            $('.categorySelected').addClass('product_warning')
        }

        if(typeUnitSelected.length === 0 ){
            msg.typeUnitSelected = 'Vui lòng chọn đơn vị'
            $('.unit').addClass('product_warning')
        }

        if(capacity.length > 50 ){
            msg.capacity = 'Quy cách không dài quá 50 ký tự'
            $('.capacity').addClass('product_warning')
        }else if( capacity.charAt(0) === '' ){
            msg.capacity = 'Vui lòng nhập vào trường quy cách '
            $('.capacity').addClass('product_warning')
        }else if(capacity.slice(0, 2) === ' ' ){
            msg.capacity = 'Vui lòng nhập vào trường quy cách '
            $('.capacity').addClass('product_warning')
        }

        if(parseInt(typeSelected) === 2){
            if(priceProduct.length <= 0){
                msg.priceProduct = 'Vui lòng nhập giá tiền'
                $('.price_product').addClass('product_warning')
            }else if( priceProduct.length > 15){
                msg.priceProduct = 'Thông tin giá sản phẩm phải là số nguyên dương và bao gồm tối đa 15 kí tự số'
                $('.price_product').addClass('product_warning')
            }else if(priceProduct < 1
                || !positive.test(priceProduct) 
                || priceProduct[0] === '0'){
                msg.priceProduct = 'Thông tin giá sản phẩm phải là số nguyên dương và bao gồm tối đa 15 kí tự số';
                $('.price_product').addClass('product_warning')
            }

            if(productsInStock.length <= 0){
                msg.productsInStock = 'Vui lòng nhập trường số lượng sản phẩm'
                $('.productsInStock').addClass('product_warning')
            }else if(productsInStock < 1
                || !positive.test(productsInStock) 
                || productsInStock[0] === '0'){
                msg.productsInStock = 'Số lượng sản phẩm phải lớn hơn 0 và phải là số. Vui lòng nhập lại';
                $('.productsInStock').addClass('product_warning')
            }

            if(discount <= 0 ){
                msg.discount = 'Giá bán không được nhỏ hơn hoặc bằng 0'
                $('.discount').addClass('product_warning')
            }else if(parseInt(discount) > parseInt(priceProduct)){
                msg.discount = 'Giá bán không được lớn hơn giá sản phẩm'
                $('.discount').addClass('product_warning')
            }
    
        }else if(parseInt(typeSelected) === 3){
            if(priceProduct.length > 0){
                if( priceProduct.length > 15){
                    msg.priceProduct = 'Thông tin giá sản phẩm phải là số nguyên dương và bao gồm tối đa 15 kí tự số'
                    $('.price_product').addClass('product_warning')
                }else if(priceProduct < 1
                    || !positive.test(priceProduct) 
                    || priceProduct[0] === '0'){
                    msg.priceProduct = 'Thông tin giá sản phẩm phải là số nguyên dương và bao gồm tối đa 15 kí tự số';
                    $('.price_product').addClass('product_warning')
                }
            }

            if(productsInStock.length > 0){
                if(productsInStock < 1
                    || !positive.test(productsInStock) 
                    || productsInStock[0] === '0'){
                    msg.productsInStock = 'Số lượng sản phẩm phải lớn hơn 0 và phải là số. Vui lòng nhập lại';
                    $('.productsInStock').addClass('product_warning')
                }
            }
        }

       
        if(weight.length > 0){
            if(weight <= 0) {
                msg.weight = 'Cân nặng phải lớn hơn 0. Vui lòng nhập lại'
                $('.weight').addClass('product_warning')
            }
        }

        if(madeIn.length > 0){
            if(madeIn.length > 255 ){
                msg.madeIn = 'Nơi sản xuất không dài quá 255 kí tự'
                $('.madeIn').addClass('product_warning')
            }else if(testMadeIn.test(madeIn)){
                msg.madeIn = 'Nơi sản xuất không chính xác '
                $('.madeIn').addClass('product_warning')
            }
        }

        // if(discount.length > 0){
            // if(discount <= 0 ){
            //     msg.discount = 'Giá bán không được nhỏ hơn hoặc bằng 0'
            //     $('.discount').addClass('product_warning')
            // }else if(parseInt(discount) > parseInt(priceProduct)){
            //     msg.discount = 'Giá bán không được lớn hơn giá sản phẩm'
            //     $('.discount').addClass('product_warning')
            // }
        // }
        

        // if(shortDesc.length > 0){
        //     if(shortDesc.length > 255 ){
        //         msg.shortDesc = 'Mô tả không dài quá 255 kí tự'
        //         $('.shortDesc').addClass('product_warning')
        //     }
        // }

        if(imageFirst === undefined){
            msg.imageFirst = "Vui lòng tải lên hình ảnh"
        }else if(imageFirst && imageFirst.length === 0){
            msg.imageFirst = "Vui lòng tải lên hình ảnh"
        }
        

        if(!id){
            if(multiImageUpload.length > 9) {
                msg.imageSecond = "Chỉ được phép tải lên tối đa 9 ảnh. Dung lượng dưới 5Mb"
            }
        }else{
            if(multiImageUpdate.length > 9) {
                msg.selectMultiImage = "Chỉ được phép tải lên tối đa 9 ảnh. Dung lượng dưới 5Mb"
            }else if( multiImageUpdate.length > (9 - (multiImageUpload.length - deleteIdCheck.length))) {
                msg.selectMultiImage = "1 Sản phẩm chỉ có tối đa 9 ảnh. Dung lượng dưới 5Mb. Xin vui lòng xoá ảnh cũ để cập nhật ảnh mới"
            }
        } 
       
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // params for create product
    const createProducts= new FormData();
    createProducts.append('name', trim(nameProduct));
    createProducts.append('bar_code', trim(barCode));
    createProducts.append('sku', trim(sku));
    createProducts.append('type_id', typeSelected);
    createProducts.append('category_id', categorySelected);
    createProducts.append('brand_id', brandSelected);
    createProducts.append('unit_id', typeUnitSelected);
    createProducts.append('capacity', capacity);
    createProducts.append('price', priceProduct);
    createProducts.append('discount', discount);
    createProducts.append('weight', weight);
    createProducts.append('product_stock', productsInStock);
    createProducts.append('made_in', trim(madeIn));
    createProducts.append('intro_short', trim(shortDesc));
    createProducts.append('description', commonInfo);
    createProducts.append('components', components);
    createProducts.append('benefits', benefits);
    createProducts.append('usages', usages);
    createProducts.append('meta_title', trim(metaTitle));
    createProducts.append('meta_key', trim(metaKey));
    createProducts.append('meta_desc', trim(metaDesc));
    createProducts.append('image', imageFirst);
    createProducts.append('status', statusProduct);
    multiImageUpload.map(multi =>
        createProducts.append('images[]', multi)
    )

    // create when oki
    const createProduct = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.PRODUCT}`, createProducts,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Thêm sản phẩm mới thành công')
                setTimeout(() => {
                    history.push('/main/products')
                }, 1000);
            } 
          })
          .catch(error => {
            setMsg('Tên sản phẩm đã được sử dụng')
            $('.name_product').addClass('product_warning')
          }
        )
    }

    // params for create product
    const paramsUpdateProducts= new FormData();
    paramsUpdateProducts.append('name', trim(nameProduct));
    paramsUpdateProducts.append('bar_code', trim(barCode));
    paramsUpdateProducts.append('sku', trim(sku));
    paramsUpdateProducts.append('type_id', typeSelected);
    paramsUpdateProducts.append('category_id', categorySelected);
    paramsUpdateProducts.append('brand_id', brandSelected);
    paramsUpdateProducts.append('unit_id', typeUnitSelected);
    paramsUpdateProducts.append('capacity', capacity);
    paramsUpdateProducts.append('price', priceProduct);
    paramsUpdateProducts.append('discount', discount);
    paramsUpdateProducts.append('weight', weight);
    paramsUpdateProducts.append('product_stock', productsInStock);
    paramsUpdateProducts.append('made_in', trim(madeIn));
    paramsUpdateProducts.append('intro_short', trim(shortDesc));
    paramsUpdateProducts.append('description', commonInfo);
    paramsUpdateProducts.append('components', components);
    paramsUpdateProducts.append('benefits', benefits);
    paramsUpdateProducts.append('usages', usages);
    paramsUpdateProducts.append('meta_title', trim(metaTitle));
    paramsUpdateProducts.append('meta_key', trim(metaKey));
    paramsUpdateProducts.append('meta_desc', trim(metaDesc));
    paramsUpdateProducts.append('image', imageFirst);

    if(id){
        paramsUpdateProducts.append('media_ids', deleteId.join(" "));
    }

    paramsUpdateProducts.append('status', statusProduct);

    multiImageUpdate.map(multi =>
        paramsUpdateProducts.append('images[]', multi)
    )

    // update when oki
    const updateProduct = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);
        
        return axios.put(`${ENDPOINT.PRODUCT}/${id}`, paramsUpdateProducts,  {
            headers: {
            Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
            })
        .then(function (response) {
            if(response.status === 200) {
                toast.success('Chỉnh sửa sản phẩm thành công')
                setTimeout(() => {
                    history.push('/main/products')
                }, 1000);
            } 
        })
        .catch(error => {
                setMsg('Tên sản phẩm đã được sử dụng')
                $('.name_product').addClass('product_warning')
            }
        )

    }

    useEffect(() => {
        $(".mask").each((i,ele)=>{
            let clone=$(ele).clone(false)
            clone.attr("type","text")
            let ele1=$(ele)
            clone.val(Number(ele1.val()).toLocaleString("en"))
            $(ele).after(clone)
            $(ele).hide()
            clone.mouseenter(()=>{
                ele1.show()
                clone.hide()
            })
            setInterval(()=>{
                let newv=Number(ele1.val()).toLocaleString("en")
                if(clone.val()!== newv){
                    clone.val(newv)
                }
            },10)
    
            $(ele).mouseleave(()=>{
                $(clone).show()
                $(ele1).hide()
            })
        })
    }, [])

    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog partner">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">{id ? 'Sửa sản phẩm' : 'Tạo sản phẩm'}</h1>
                </div>
            </div>
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Tên sản phẩm <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control name_product" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                    onChange={handleNameProduct}
                    onKeyDown={RemoveMessNameProduct}
                    value={nameProduct}
                />
            </div>
            <p style={{'fontSize': '12px', 'marginBottom':'0px'}} className="text-danger msg_name">{validationMsg.nameProduct}</p> 
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{msg}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Mã vạch sản phẩm</Form.Label> 
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control barCode" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1"
                    onChange={handleBarCode} 
                    onKeyDown={RemoveMessBarCode}
                    value={barCode}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.barCode}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Mã sku</Form.Label>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control sku" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1"
                    onChange={handleSku} 
                    onKeyDown={RemoveMessSku}
                    value={sku}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.sku}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Loại sản phẩm <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group mb-3">
                <select name="" className="form-control custom_select custom_selectProduct" value={typeSelected} onChange={handleTypeList} > 
                    {typeList.map((type_list, index) => 
                        <option key={index} value={type_list.id}>{type_list.name}</option>
                    )}
                </select>
            </div>
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Trạng thái sản phẩm <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group mb-3">
                <select name="" className="form-control custom_select custom_selectProduct" value={statusProduct} onChange={handleStatusProduct}> 
                   <option value="1">Đang bán </option>
                   <option value="0">Ngưng bán</option>
                </select>
            </div>
            {/* ====================================================================== */}
            <div className="d-flex">
                <div className="col-6 pl-0">
                    <Form.Label className="col-4 pl-0" > Danh mục <span style={{'color':'red'}}>*</span></Form.Label>
                    <div className="input-group">
                        <select name="" value={categorySelected} className="form-control custom_select categorySelected custom_selectProduct" onChange={handleCategory} onMouseDown={RemoveMessCategory}> 
                            <option value=""></option>
                            {allCategory.map((cate, index) => 
                                <option key={index} value={cate.id}>
                                   { (() => {
                                        if (cate.checkOrdin === 1) {
                                            return (
                                                cate.name
                                            )
                                        } else if (cate.checkOrdin === 2) {
                                            return (
                                                `${'\u00A0\u00A0\u00A0'}` + cate.name
                                            )
                                        } else {
                                            return (
                                                `${'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}` + cate.name
                                            )
                                        }
                                    })()}
                                </option>
                            )}
                        </select>
                    </div>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.categorySelected}</p> 
                </div>
                <div className="col-6 pl-0 pr-0">
                    <Form.Label className="col-4 pl-0 ">Thương hiệu</Form.Label>
                    <div className="input-group">
                        <select name="" value={brandSelected} className="form-control custom_select custom_selectProduct brands" onChange={handleBrands} onMouseDown={RemoveMessBrands}> 
                            <option value="">Không có thương hiệu</option>
                            {brands.map((brand, index) => <option key={index} value={brand.id}>{brand.name}</option>)}
                        </select>
                    </div>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.brandSelected}</p> 
                </div>
            </div>
            {/* ====================================================================== */}
            <div className="d-flex">
                <div className="col-6 pl-0">
                    <Form.Label className="col-4 pl-0" >Đơn vị <span style={{'color':'red'}}>*</span></Form.Label>
                    <div className="input-group">
                        <select name="" value={typeUnitSelected} className="form-control custom_select custom_selectProduct unit" onChange={handleTypeUnit} onMouseDown={RemoveMessTypeUnit}> 
                            <option value=""></option>
                            {typeUnit.map((unit, index) => <option key={index} value={unit.id}>{unit.name}</option>)}
                        </select>
                    </div>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.typeUnitSelected}</p> 
                </div>
                <div className="col-6 pl-0 pr-0">
                    <Form.Label className="col-4 pl-0 ">Quy cách <span style={{'color':'red'}}>*</span></Form.Label>
                    <div className="input-group">
                        <input 
                            value={capacity}
                            type="text" 
                            className="form-control capacity" 
                            aria-label="Username" 
                            aria-describedby="basic-addon1" 
                            onChange={handleCapacity}
                            onMouseDown={RemoveMessCapacity}
                        />
                    </div>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.capacity}</p>
                </div>
            </div>
            {/* ====================================================================== */}
            <div className="d-flex">
                <div className="col-6 pl-0">
                    <Form.Label className="col-4 pl-0" >Giá Marketing (VNĐ) <span style={{'color':'red'}}>*</span></Form.Label>
                    <div className="input-group">
                        <input 
                            id="myInput1"
                            value={priceProduct}
                            type="text" 
                            name="inputForPrice"
                            className="form-control price_product mask" 
                            aria-label="Username" 
                            aria-describedby="basic-addon1"
                            onChange={handlePriceProduct} 
                            onKeyDown={RemoveMessPriceProduct}
                            autoComplete="off"
                        />
                    </div>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.priceProduct}</p>
                </div>
                <div className="col-6 pl-0 pr-0">
                    <Form.Label className="col-5 pl-0 ">Giá bán (VNĐ) <span style={{'color':'red'}}>*</span></Form.Label>
                    <div className="input-group">
                        <input 
                            value={discount}
                            type="text" 
                            name="inputForPrice"
                            className="form-control discount mask" 
                            aria-label="Username" 
                            aria-describedby="basic-addon1" 
                            onChange={handleDiscount}
                            autoComplete="off"
                            onKeyDown={RemoveMessDiscount}
                        />
                    </div>
                    <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.discount}</p> 
                </div>
            </div>
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Cân nặng (gram)</Form.Label>
            <div className="input-group">
                <input 
                    value={weight}
                    type="text" 
                    name="inputForPrice"
                    className="form-control weight" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                    onChange={handleWeight}
                    onKeyDown={RemoveMessWeight}
                    autoComplete="off"
                    id="myInput2"
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.weight}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 "> Số lượng sản phẩm <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input 
                    value={productsInStock}
                    type="text" 
                    name="inputForPrice"
                    className="form-control productsInStock" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                    onChange={handleProductsInStock}
                    onKeyDown={RemoveMessProductsInStock}
                    autoComplete="off"
                    id="myInput3"
                    disabled={id ? true : false}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.productsInStock}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Sản xuất</Form.Label>
            <div className="input-group">
                <input 
                    value={madeIn}
                    type="text" 
                    className="form-control madeIn" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                    onChange={handleMadeIn}
                    onKeyDown={RemoveMessMadeIn}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.madeIn}</p> 
            {/* ====================================================================== */}
            <div className="input-group w-100">
                <Form.Label className="col-12 pl-0 pr-0 mt-2">Mô tả ngắn</Form.Label>
                <textarea 
                    value={shortDesc}
                    style={{ 'borderRadius': '0.25rem'}} 
                    className="form-control shortDesc" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1"
                    onChange={handleShortDesc}
                    onKeyDown={RemoveMessShortDesc}
                >
                </textarea>
                <p style={{'fontSize': '12px'}} className="text-danger msg_name w-100">{validationMsg.shortDesc}</p>
            </div>
            {/* ====================================================================== */}
            <div className="input-group w-100">
                <Form.Label className="col-12 pl-0 pr-0 mt-2">Thông tin chung</Form.Label>
                <EditorToolbar/>
                <ReactQuill
                    modules={modules}
                    formats={formats}
                    onChange={handleCommonInfo}
                    value={commonInfo || ''}
                />
                <p style={{'fontSize': '12px'}} className="text-danger msg_name w-100"></p>
            </div>
            {/* ====================================================================== */}
            <div className="input-group w-100">
                <Form.Label className="col-12 pl-0 pr-0 mt-2">Thành phần</Form.Label>
                <EditorToolbar1/>
                <ReactQuill
                    modules={modules1}
                    formats={formats1}
                    onChange={handleComponents}
                    value={components || ''}
                />
                <p style={{'fontSize': '12px'}} className="text-danger msg_name w-100"></p>
            </div>
            {/* ====================================================================== */}
            <div className="input-group w-100">
                <Form.Label className="col-12 pl-0 pr-0 mt-2">Công dụng</Form.Label>
                <EditorToolbar2 />
                <ReactQuill
                    modules={modules2}
                    formats={formats2}
                    onChange={handleBenefits}
                    value={benefits}
                />
                <p style={{'fontSize': '12px'}} className="text-danger msg_name w-100">{validationMsg.benefits}</p>
            </div>
            {/* ====================================================================== */}
            <div className="input-group ">
                <Form.Label className="col-12 pl-0 pr-0 mt-2">Cách dùng</Form.Label>
                <EditorToolbar3 />
                <ReactQuill
                    modules={modules3}
                    formats={formats3}
                    onChange={handleUsages}
                    value={usages}
                />
                <p style={{'fontSize': '12px'}} className="text-danger msg_name w-100">{validationMsg.usages}</p>
            </div>
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ tiêu đề</Form.Label>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control metaTitle" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                    onChange={handleMetaTitle}
                    onKeyDown={RemoveMessMetaTitle}
                    value={metaTitle}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ từ khoá</Form.Label>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control metaKey" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                    onChange={handleMetaKey}
                    onKeyDown={RemoveMessMetaKey}
                    value={metaKey}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaKey}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ mô tả</Form.Label>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control metaDesc" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                    onChange={handleMetaDesc}
                    onKeyDown={RemoveMessMetaDesc}
                    value={metaDesc}
                />
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.metaDesc}</p> 
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Hình ảnh đại diện <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group mb-3">
            <button htmlFor="imageUploadProduct" type="button" className="btn btn-add">+ Upload image<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUploadProduct" accept=".png, .jpg, .jpeg" /></button>
                {imageFirst && imageFirst.length !== 0 ?
                    <div id="close_btn_wapper">
                        <img 
                            className="image_productMain"
                            src={imageFirst.image} 
                            style={{'marginLeft': '30px', 'marginRight':'30px'}}
                            alt="" 
                            id="imagePreviewProduct" >
                        </img>
                        <i style={{'cursor':'pointer', 'fontSize': '0px'}} onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                    </div>
                : ''}
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.imageFirst}</p>
            {/* ====================================================================== */}
            {id ? <Form.Label className="col-6 pl-0 pr-0 mb-0">Hình ảnh về sản phẩm : <span style={{'fontWeight':'bold'}}>Số lượng ảnh của sản phẩm là {(multiImageUpload.length - deleteIdCheck.length)}/9 ảnh</span></Form.Label> : <Form.Label className="col-4 pl-0 pr-0 ">Hình ảnh khác (Dung lượng tối đa 5Mb) </Form.Label>} 
            <div className="container mt-3 w-100 pl-0 pr-0 ">
                <div className="card shadow-sm w-100">
                    <div className="card-header d-flex justify-content-between">
                    {!id ?  <label htmlFor="gallery-photo-add" type="button" className="btn btn-add custom_uploadMulti">+ Upload multi image</label> : ''}
                        <input type="file" className="d-none" id="gallery-photo-add" accept=".png, .jpg, .jpeg" multiple onChange={handleImageChange} />
                    </div>
                    <div className="d-flex flex-wrap result justify-content-start gallery card-body-images" id="container">
                        {
                            id ? renderPhotos(multiImageUpload) : renderPhotos(selectedFiles)
                        }
                    </div>
                </div>
            </div>
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.imageSecond}</p> 
            {/* ====================================================================== */}
            {id ?
                <div>
                    <Form.Label className="col-6 pl-0 pr-0 mb-0">Hình ảnh khác: <span style={{'fontWeight':'bold'}}>Số lượng ảnh được phép tải lên là {9 - (multiImageUpload.length - deleteIdCheck.length)}/9 ảnh</span></Form.Label>
                    <div className="container mt-3 w-100 pl-0 pr-0 ">
                        <div className="card shadow-sm w-100">
                            <div className="card-header d-flex justify-content-between">
                      
                                <label htmlFor="gallery-photo-add1" type="button" className="btn btn-add custom_uploadMulti">+ Upload multi image</label>
                                <input type="file" className="d-none" id="gallery-photo-add1" accept=".png, .jpg, .jpeg" multiple onChange={handleImageChangeUpdate} />
                            </div>
                            <div className="d-flex flex-wrap result justify-content-start gallery card-body-images" id="container">
                                {renderMultiImage(selectMultiImage)}
                            </div>
                        </div>
                    </div>
                    <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.selectMultiImage}</p> 
                </div>
            : ''}
            <p style={{'fontSize': '12px'}} className="text-danger msg_name">{msg1}</p> 
            {/* ====================================================================== */}
            <div >
                <Link to="/main/products"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link> 
                {id ? <button type="button" disabled={disable} onClick={updateProduct} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createProduct}  className="btn btn-success mt-5">Lưu</button>}
            </div>
        </div>
    )
}

export default ProductCreateAndEdit
