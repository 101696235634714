import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ENDPOINT from '../../ultils/EndPoint';
import './Partner.scss';
import {Button, Modal} from "react-bootstrap";
import { Link } from 'react-router-dom';
import {Pagination} from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "antd/dist/antd.css";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';
import $ from 'jquery';

function PartnerManager() {
    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [idPartnerCurr, setIdPartnerCurr] = useState('');
    const [namePartnerCurr, setNamePartnerCurr] = useState('');
    const dispatch = useDispatch();
    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [keyword, setFilter] = useState('');
    const [customersPerPage] = useState(10);

    // set const 
    const [listPartner, setListPartner] = useState([]);

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    //  call api serve list 
    useEffect(() => {
        let query = '';
        const data = {per_page : customersPerPage, keyword: keyword ,page: currentPage};
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )

        let unmounted = false;
        axios.get(`${ENDPOINT.PARTNER}` + query, config)
        .then((res) => {
            if (!unmounted) {
                setListPartner(res.data.data.records);
                setNumberPage(res.data.data.total_record)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [customersPerPage, currentPage, keyword, dispatch]);

    // handle delete 
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.PARTNER}/${id}`, config)
        .then(function (response) {
            if(response.status === 200) {
                toast.success('Xóa đối tác thành công !')
                const remainingpartner = listPartner.filter((result) => result.id !== id)
                setListPartner(remainingpartner)
            }
        })
        .catch(error => {
            console.log(error);
        }
      )
    }

    // Handle search debounce
    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }
    
    const handleInputSearch = (e) => {
        setFilter(removeExtraSpace(e.target.value))
        setcurrentPage(1)
    }
        
    const optimisedChange = debounce(handleInputSearch)

    // prevent enter when search
    $('#searchPartner').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
          e.preventDefault();
          return false;
        }
    });

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-3">Danh sách đối tác</h1>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <Link to ="/main/partner/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
                </div>
                <div>
                    <form className="form-inline position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <input className="form-control" id="searchPartner" type="search" onChange={optimisedChange} placeholder="Tìm kiếm đối tác" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                        <span className="bx bx-search-alt"></span>
                    </form>
                </div>
            </div>
            <div className="category-manager-wapper">
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Tên đối tác</th>
                            <th>Logo</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody row="10">
                       {listPartner ? listPartner.map((partner, index) => 
                            <tr key={index}>
                                <th>
                                    {partner.id}
                                </th>
                                <td style={{'width':'30%'}}>{partner.name}</td>
                                <td style={{'width':'40%', height:'150px'}}>
                                    <img style={{width:'100%'}} src={partner.image} alt=""/>
                                </td>
                                <td>
                                    <Link to={`/main/partner/edit/${partner.id}`}><i className="mdi mdi-pencil font-size-18 mr-3"></i></Link> 
                                    <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                        handleShow();
                                        setIdPartnerCurr(partner.id)
                                        setNamePartnerCurr(partner.name)
                                    }} className="mdi mdi-delete font-size-18"></i>
                                </td>
                            </tr>
                       ) : ''}
                    </tbody>
                </table>
                {numberPage <= 10 ? '' : 
                <Pagination 
                    onChange={(value) => setcurrentPage(value)}
                    total={numberPage}
                    current={currentPage}
                    showSizeChanger= {false}
                    /> 
                }
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá đối tác</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá đối tác <span style={{'color':'blue'}}>{namePartnerCurr}</span> này ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleClose();
                        handleDelete(idPartnerCurr)
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PartnerManager
