import React, {useEffect, useState} from 'react'
import {toast, ToastContainer} from "react-toastify";
import { Link } from 'react-router-dom';
import ENDPOINT from "../../ultils/EndPoint";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {Pagination} from "antd";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';
function StaticPage() {

    const [listStaticpage, setListStaticPage] = useState([]);
    const [customersPerPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [total, setTotal] = useState();

    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    useEffect(() => {
        const getTerm = async () => {
            try {
                let query = '';
                const data = {
                    per_page: customersPerPage,
                    page: currentPage,
                };
                Object.keys(data).map((key, index) =>
                    query += (index === 0 ? '?' : "&") + key + '=' + data[key]
                )
                const  res = await axios.get(`${ENDPOINT.STATICPAGE + query}`, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                    }
                })
                setListStaticPage(res.data.data.records)
                setTotal(res.data.data.total_record)
            } catch(error) {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }
        }
        getTerm();

    }, [currentPage, customersPerPage, dispatch])

    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.STATICPAGE}/${id}`, config)
            .then(function (response) {
                toast.success('Xóa trang tĩnh thành công !')
                setTimeout(() => {
                    window.location.href = '/main/static-page'
                }, 2000);
            })
            .catch(error => {
                    toast.error(error.response.data.error)
                }
            )
    }

    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="d-flex justify-content-between">
                <div className="operatelog">
                    <div className="d-flex justify-content-between align-self-center">
                        <h1 className="customer-title mb-3">Danh sách trang tĩnh</h1>
                    </div>
                </div>
                <Link to ="/main/static-page/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
            </div>
            <div className="table-responsive">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <th style={{ 'width': '10%'}}>STT</th>
                        <th>Tiêu đề</th>
                        <th>Link</th>
                        <th>Hành động</th>
                    </thead>
                    <tbody>
                    {listStaticpage.map((listStatic, index) =>
                        <tr key={index}>
                            <td style={{ 'width': '10%'}}>{listStatic.id}</td>
                            <td>{listStatic.title}</td>
                            <td><a target="_blank" href={`${process.env.REACT_APP_URL}static-page/` + listStatic.slug}>{listStatic.slug}</a></td>
                            <td style={{ 'width': '10%'}}>
                                <Link to={`/main/static-page/edit/${listStatic.id}`}>
                                    <i className="mdi mdi-pencil font-size-18"></i>
                                </Link>
                                <Link  onClick={() => {handleShow();  setIdUserCurr(listStatic.id); setNameUserCurr(listStatic.title)}} to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18"></i>
                                </Link>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                {total <= 10 ? '' :
                    <Pagination
                        onChange={(value) => setcurrentPage(value)}
                        total={total}
                        current={currentPage}
                        showSizeChanger= {false}
                    />
                }
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá trang tĩnh</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá trang tĩnh <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default StaticPage;
