import React, {useState, useEffect} from 'react';
import $, {trim} from 'jquery';
import ENDPOINT from '../../ultils/EndPoint';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import {useParams} from "react-router";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import EditorToolbar, {formats, modules} from "../toolbar/QuillToolbar";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function StaticManager() {
    const {id} = useParams();
    const [titleStatic, setTitleStatic] = useState('');
    const dispatch = useDispatch();
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKey, setMetaKey] = useState('');
    const [Content, setContent] = useState('');
    const [metaDes, SetMetaDes] = useState('');
    const [metaLink, setMetaLink] = useState('');

    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');

    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };


    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // others
    const [disable, setDisable]= useState(false);



    const handleTitle = (e) => {
        setTitleStatic(e.target.value);
    }
    const handleRemoveTitle = () => {
        validationMsg.titleStatic = ''
        $('.name_category').removeClass('danger_border')
    }



    const handleMetaDescription = (event) => {
        setContent(event);
    }

    const handleMetaTitle = (e) => {
        setMetaTitle((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMetaTitle = () => {
        validationMsg.metaTitle = ''
        $('.title_category').removeClass('danger_border')
    }


    const handleMetaKey = (e) => {
        setMetaKey((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveKey = () => {
        validationMsg.metaKey = ''
        $('.key_category').removeClass('danger_border')
    }
    const handleMetaDes = (e) => {
        SetMetaDes((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveDes = () => {
        validationMsg.metaDes = ''
        $('.detail_content').removeClass('danger_border')
    }




    const validateAll = () => {
        const msg = {};

        if(titleStatic.length > 255) {
            msg.titleStatic = 'Thông tin tên tin trang không được quá 255 kí tự'
            $('.name_category').addClass('danger_border');
        }else if( titleStatic.charAt(0) === '' ){
            msg.titleStatic = 'Vui lòng nhập tên trang'
            $('.name_category').addClass('danger_border');
        }else if(titleStatic.slice(0, 2) === ' '){
            msg.titleStatic = 'Vui lòng nhập tên trang'
            $('.name_category').addClass('danger_border');
        }

        if(Content.length < 0) {
            msg.Content = 'Không được để trống nội dung '
        }else if( Content.charAt(0) === '' ){
            msg.Content = 'Vui lòng nhập nội dung'
        }else if(Content.slice(0, 2) === ' '){
            msg.Content = 'Vui lòng nhập nội dung'
        }else if(Content === `<p><br></p>`) {
            msg.Content = 'Vui lòng nhập nội dung'
        }

        if(metaTitle.length <= 0) {
            msg.metaTitle = 'Không được để trống thẻ tiêu đề'
            $('.title_category').addClass('danger_border')
        }

        if(metaKey.length <= 0) {
            msg.metaKey = 'Không được để trống thẻ từ khoá'
            $('.key_category').addClass('danger_border')
        }

        if(metaDes.length <= 0) {
            msg.metaDes = 'Không được để trống thẻ tiêu đề'
            $('.detail_content').addClass('danger_border')
        }

        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // Edit
    useEffect(()=>{
        if(id) {
            axios.get(`${ENDPOINT.STATICPAGE}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (response) {
                if(response.status === 200) {
                    setTitleStatic(response.data.data.title);
                    setMetaTitle(response.data.data.meta_title);
                    setMetaKey(response.data.data.meta_key);
                    setContent(response.data.data.content);
                    SetMetaDes(response.data.data.meta_desc);
                    setMetaLink(response.data.data.slug);
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    },[id, dispatch]);


    const updateAdminInfo = new FormData();
    updateAdminInfo.append('title', trim(titleStatic));
    updateAdminInfo.append('meta_key', metaKey);
    updateAdminInfo.append('meta_title', metaTitle);
    updateAdminInfo.append('meta_desc', metaDes);
    updateAdminInfo.append('content', trim(Content));
    updateAdminInfo.append('slug', metaLink);

    // update
    const updateNews = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.STATICPAGE}/${id}`, updateAdminInfo,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Chỉnh sửa trang tĩnh thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/static-page'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Tên trang tĩnh đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )

    }



    const createStatic = new FormData();
    createStatic.append('title', trim(titleStatic));
    createStatic.append('meta_key', metaKey);
    createStatic.append('meta_title', metaTitle);
    createStatic.append('meta_desc', metaDes);
    createStatic.append('content', Content);
    createStatic.append('slug', metaLink);


    // Create
    const createNewsBtn = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.STATICPAGE}`, createStatic,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                     toast.success('Thêm trang tĩnh thành công !')
                     setTimeout(() => {
                         window.location.href = '/main/static-page'
                     }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Tên tiêu đề đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )
    }



    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa trang tĩnh</h1> : <h1 className="customer-title mb-3">Thêm mới trang tĩnh</h1>}
                </div>
            </div>
            <Form.Label className="col-2 pl-0 pr-0 ">Tên trang<span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control name_category"  aria-label="Username" aria-describedby="basic-addon1" value={titleStatic} onChange={handleTitle} onKeyDown={handleRemoveTitle} />
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.titleStatic}</p>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{msg}</p>
            <div className="input-group mt-3">
                <Form.Label className="col-2 pl-0 pr-0 ">Nội dung<span style={{'color':'red'}}>*</span></Form.Label>
                <EditorToolbar/>
                <ReactQuill
                    modules={modules}
                    formats={formats}
                    onChange={handleMetaDescription}
                    value={Content}
                />
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.Content}</p>
            <Form.Label className="mt-2 col-2 pl-0 pr-0 ">Thẻ tiêu đề <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control title_category" value={metaTitle} onKeyDown={handleRemoveMetaTitle} onChange={handleMetaTitle}   aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p>
            <Form.Label className="mt-2 col-2 pl-0 pr-0 ">Thẻ từ khoá <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control key_category" value={metaKey} onKeyDown={handleRemoveKey} onChange={handleMetaKey}  aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaKey}</p>
            <Form.Label className="mt-2 col-2 pl-0 pr-0 ">Thẻ mô tả <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control detail_content" value={metaDes} onKeyDown={handleRemoveDes} onChange={handleMetaDes}/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaDes}</p>
            <div>
                <Link to="/main/static-page"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                {id ? <button type="button"  className="btn btn-success mt-5" disabled={disable} onClick={updateNews}>Cập nhật</button> : <button type="button"  className="btn btn-success mt-5" disabled={disable} onClick={createNewsBtn}>Lưu</button>}
            </div>
        </div>
    )
}

export default StaticManager
