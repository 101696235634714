import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ENDPOINT, {listdeal} from '../../ultils/EndPoint';
import $ from 'jquery';
import {Button, Modal} from "react-bootstrap";
import Select from 'react-select';
import {Link} from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import {formatCurrency, finalPrice} from '../../helpers/function';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function DealProduct() {
  // set const 
  const [data, setData] = useState([]);
  const animatedComponents = makeAnimated();
  const [dataSelect, setDataSelect] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [multiDelete, setMultiDelete] = useState([]);
  const [changeDataCheck, setChangeDataCheck] = useState(false);
  const dispatch = useDispatch();

  let option = []
  if (dataSelect && dataSelect.length > 0) {
    dataSelect.forEach(role => {
      let roleDate = {}
      roleDate.value = role.id
      roleDate.label = role.name
      option.push(roleDate)
    })
  }
  const {id} = useParams();

  // set for modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [idProduct, setIdProduct] = useState('');
  const [nameProductCurr, setNameProductCurr] = useState('');

  // set constans admin token 
  const admin_token = localStorage.getItem('User-Admin') 

  // get data list
  useEffect(() => {
      let unmounted = false;
      axios.get(`${ENDPOINT.DEALLIST}/${id}/added`, {
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
          }
      })
      .then(function (response) {
          if (!unmounted) {
              if(response.status === 200) {
                setData(response.data.data)
              }
          }
      })
      .catch(error => {
        if(error.response.status === 401){
            dispatch(logoutSuccess(true))
        }
    }) 
      return () => { unmounted = true };
  }, [id, dispatch])

  // get data option
  useEffect(() => {
    let unmounted = false;
    axios.get(`${ENDPOINT.DEALLIST}/${id}/option`, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
        }
    })
    .then(function (response) {
        if (!unmounted) {
            if(response.status === 200) {
              setDataSelect(response.data.data);
              setChangeDataCheck(false)
            }
        }
    })
    .catch(error => {
      if(error.response.status === 401){
          dispatch(logoutSuccess(true))
      }
  }) 
    return () => { unmounted = true };
  }, [id, changeDataCheck, dispatch])

  // post new
  const ids = []
  selectedProduct && selectedProduct.map(select => 
    ids.push(select.value)
  )

  // params for create product
  const createProducts= new FormData();
  ids.map(multi =>
      createProducts.append('product_ids', multi)
  )


  const addNewProductInGroup = () => {
    return axios.post(`${ENDPOINT.DEALLIST}/${id}/store`, createProducts,  {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
      }
    })
    .then(function (response) {
      if(response.status === 200) {
        toast.success('Thêm sản phẩm vào deal thành công')
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } 
    })
    .catch(error => {
      
    }
  )
  }
  
  // set checked
  useEffect(() =>{
    $(function () {
        $('.footer').hide();
        $(document).ready(function () {
            $('#selectall').click(function () {
              $('.selectedId').prop('checked', this.checked)
            });
            $('.selectedId').change(function () {
                var check = ($('.selectedId').filter(":checked").length === $('.selectedId').length);
                $('#selectall').prop("checked", check);
            });
        });
    })
  })


  // delete one item 
  const deleteFormData = new FormData();
  deleteFormData.append('product_ids', idProduct)
  deleteFormData.append('group_id', parseInt(id))

  const handleDelete = () => {
    axios.delete(`${ENDPOINT.DEALLIST}/${id}/delete`,{
      headers: { Authorization: 'Bearer ' +  admin_token },
      data: deleteFormData
    })
      .then(function (response) {
          if(response.status === 200) {
              toast.success('Xóa sản phẩm thành công !')
              const remainingpartner = data.filter((result) => result.id !== idProduct)
              setData(remainingpartner)
              setChangeDataCheck(true)
          }
      })
      .catch(error => {
          console.log(error);
      }
    )
  }


  // handleMulti delete
  const handleMultiDelete =(e)=>{
    if($('#selectall').is(":checked") === true){
      setMultiDelete(data.map(a => a.id))
    }else if($('#selectall').is(":checked") === false && $('.selectedId').is(":checked") === false){
      const remainingpartner = multiDelete.filter((result) => result !== e.target.id)
      setMultiDelete(remainingpartner)
    }else {
      setMultiDelete([])
    }
  }

  // delete one item 
  const deleteMultiFormData = new FormData();
  multiDelete && multiDelete.map(multi =>
    deleteMultiFormData.append('product_ids', multi)
  )
  deleteMultiFormData.append('group_id', parseInt(id))

  // delete multi
  const deleteMultiProduct= async () => {
      let checkboxes = document.querySelectorAll('input[type="checkbox"].selectedId:checked')
      let productIds = [];
      let data = {}
      checkboxes.forEach(input => {
          let productId = input.getAttribute('product_id')
          productIds.push(parseInt(productId))
      })
      data.product_ids = productIds
      listdeal.deleteDealProduct(id, data).then(function (response) {
          if(response.status) {
              toast.success('Xóa sản phẩm thành công !')
              setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
      })
      .catch(error => {

      })
  }
  
  // handle change select product
  const handleChangeSelected = (e) => {
    setSelectedProduct(e);
  }

 
  return (
    <div className="pl-4 pr-4 mt-3">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Thêm sản phẩm vào deal</h1>
            </div>
        </div>
        <div className="d-flex align-items-center mt-3">
            <div className="input-group col-8 pl-0">
                <Select
                  className="col-12 pl-0 pr-0 select_addProduct_group"
                  isMulti
                  onChange={handleChangeSelected}
                  placeholder = {"Sản phẩm áp dụng"}
                  options={option}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                >
                </Select>
            </div>
            <div className="ml-3">
                <button type="button" onClick={addNewProductInGroup} className="btn btn-add"> + Thêm</button>
            </div>
          </div>
          <div className="d-flex">
             <div className="mt-3 mb-0">
                <button type="button" onClick={deleteMultiProduct} className="btn btn-danger"> Xoá đã chọn</button>
            </div>
          </div>
        <div className="category-manager-wapper mt-0">
            <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th className="title_address">
                        <div className="d-flex">
                            <input 
                              type="checkbox" 
                              name="payment" 
                              // onChange={handleCheckedDeleteAll} 
                              onChange={handleMultiDelete}
                              id="selectall"
                              />
                            <label htmlFor="checkbox"></label>
                        </div>
                    </th>
                    <th>ID</th>
                    <th>Tên sản phẩm</th>
                    <th>Danh mục</th>
                    <th>Thương hiệu</th>
                    <th>Giá bán(VNĐ)</th>
                    <th>Quy cách</th>
                    <th>Sản xuất</th>
                    <th>Hình ảnh</th>
                    <th>Trạng thái</th>
                    <th>Loại thuốc</th>
                    <th style={{'width':'100px'}}>Hành động</th>
                </tr>
                </thead>
                <tbody row="10">
                  {data ? 
                  data
                  .map((product, index) => 
                        <tr key={index}>
                            <th className="title_address">
                                <div className="d-flex">
                                    <input
                                      type="checkbox" 
                                      name="payment" 
                                      id={product.id} 
                                      onChange={handleMultiDelete} 
                                      product_id={product.id}
                                      className="selectedId"
                                      />
                                    <label htmlFor="checkbox"></label>
                                </div>
                            </th>
                            <th style={{'fontWeight': 'normal'}}>
                                {product.id}
                            </th>
                            <th>
                              <Link to={{pathname: `${process.env.REACT_APP_URL}san-pham/${product.slug}`}} target="_blank">
                                  {product.name}
                              </Link>
                            </th>
                            <th>
                                {product.category.name}
                            </th>
                            <th>
                                {product.brand.name}
                            </th>
                            <th style={{width:'12%'}}>
                                {
                                    product.type.id === 2 
                                    ? formatCurrency(finalPrice(product))
                                    : ''
                                }
                                {/* {
                                    product.type.id === 2 
                                    ? <span style={{textDecoration:'line-through'}}>{formatCurrency(product.price)} đ</span>
                                    : ''
                                } */}
                            </th>
                            <th>
                                {product.capacity}
                            </th>
                            <th>
                                {product.made_in}
                            </th>
                            <th>
                                {product.medias !== null 
                                ?
                                  product.medias.map((check, index) => 
                                      check.collection_name === 'single' ? <img key={index} alt="" style={{'width':'100px', 'height':'100px'}} src={check.image} /> : ''
                                  )
                                : 
                                ''}
                            </th>
                            <th>
                                {product.status === 0 ? 'Hết hàng' : 'Đang bán'}
                            </th>
                            <th>
                                {product.type !== null ? product.type.name : ''}
                            </th>
                            <td style={{'width':'100px'}}>
                                <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                    handleShow();
                                    setIdProduct(product.id)
                                    setNameProductCurr(product.name)
                                }} className="mdi mdi-delete font-size-18"></i>
                            </td>
                        </tr>
                  ) : ''}
                </tbody>
            </table>
            {/* {numberPage <= 10 ? '' : 
                <Pagination 
                    onChange={(value) => setcurrentPage(value)}
                    total={numberPage}
                    current={currentPage}
                    showSizeChanger= {false}
                /> 
            } */}
            
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá sản phẩm khỏi nhóm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá sản phẩm <span style={{'color':'blue'}}>{nameProductCurr}</span> này ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleClose();
                        handleDelete(idProduct)
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>
                <Link to="/main/deal"><button type="button" className="btn btn-secondary">Quay lại</button></Link> 
            </div>
    </div>
    );
}

export default DealProduct
