import {
    all,
    take,
    put,
    call,
} from 'redux-saga/effects';
import { 
    LOGIN_REQUEST,
} from '../constans/ActionType';
import { 
    loginSuccess, loginFail
} from '../actions/Auth';
import { signIn } from '../ultils/Axios';

function* authenticate(user) {
    try {
        let data;
        data = yield call(signIn, user);
        yield put(loginSuccess(data.user));

        if(data) {
            localStorage.setItem('User-Admin', data.data.token)
        }
    } catch (error) {
        yield put(loginFail(error.request.status));
    }
}

function* authFlow() {
    while (true) {
        const { payload } = yield take(LOGIN_REQUEST);
        yield call(authenticate, payload);
    }
}
  
export default function* flowAuth() {
    yield all([authFlow()]);
}