import React, {useState, useEffect} from 'react';
import '../Website.scss';
import { useParams, useHistory } from 'react-router';
import $, { trim } from 'jquery';
import ENDPOINT from '../../../ultils/EndPoint';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../../actions/Auth';

function StoreEditCreate() {
    const history = useHistory();
    // set const 
    const {id} = useParams();
    // set const info 
    const [nameStore, setNameStore] = useState('');
    const [adressStore, setAdressStore] = useState('');
    const [phoneStore, setPhoneStore] = useState('');
    // set for validate
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');
    const dispatch = useDispatch();

    // check phone number or not 
    const isPhoneNumber = new RegExp("^[0-9]*$")

    // others
    const [disable, setDisable]= useState(false);

    // test adress
    const testAdress = /^\s*\d[\d\s]*$/

    // handle all change
    const handleChangePhone = (e) => {
        setPhoneStore(e.target.value)
    }
    const handleRemoveMessPhone = () => {
        validationMsg.phoneStore = '';
        $('.phone_website').removeClass('color_danger')
    }

    const handleChangeAdress = (e) => {
        setAdressStore((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMessAdress = () => {
        validationMsg.adressStore = '';
        $('.adress_store').removeClass('color_danger')
    }

    const handleChangeName = (e) => {
        setNameStore((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveMessName = () => {
        validationMsg.nameStore = '';
        setMsg('')
        $('.name_store').removeClass('color_danger')
    }

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(nameStore.length > 50 ){
            msg.nameStore = 'Tên cửa hàng không dài quá 50 ký tự'
            $('.name_store').addClass('color_danger')
        }else if( nameStore.charAt(0) === '' ){
            msg.nameStore = 'Vui lòng nhập tên cửa hàng'
            $('.name_store').addClass('color_danger')
        }else if(nameStore.slice(0, 2) === ' ' ){
            msg.nameStore = 'Vui lòng nhập tên cửa hàng'
            $('.name_store').addClass('color_danger')
        }

        if(adressStore.length > 255 ){
            msg.adressStore = ' Trường địa chỉ tối đa 255 kí tự.Vui lòng nhập lại.'
            $('.adress_store').addClass('color_danger')
        }else if( adressStore.charAt(0) === '' ){
            msg.adressStore = 'Vui lòng nhập địa chỉ'
            $('.adress_store').addClass('color_danger')
        }else if(adressStore.slice(0, 2) === ' ' ){
            msg.adressStore = 'Vui lòng nhập địa chỉ'
            $('.adress_store').addClass('color_danger')
        }else if(testAdress.test(adressStore)){
            msg.adressStore = 'Địa chỉ không chính xác. Vui lòng nhập lại'
            $('.adress_store').addClass('color_danger')
        }

        if(phoneStore.length <= 0) {
            msg.phoneStore = 'Vui lòng nhập số điện thoại'
            $('.phone_website').addClass('color_danger')
        }
        else if ( phoneStore.length > 0 && (!isPhoneNumber.test(phoneStore))){
            $('.phone_website').addClass('color_danger')
            msg.phoneStore = 'Số điện thoại không hợp lệ.'
        }
        // else if(phoneStore.charAt(0) !== '0') {
        //     $('.phone_website').addClass('color_danger')
        //     msg.phoneStore = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
        // }

        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // create new adress
    const handleCreateAdressStore = ()=> {
        const isValidate = validateAll()
        if(!isValidate) return

        const data = {
            address : trim(adressStore),
            name : trim(nameStore),
            phone : phoneStore,
        }

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.STORE}`, data,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Thêm địa chỉ cửa hàng thành công')
                setTimeout(() => {
                    history.push('/main/store')
                }, 1000);
            } 
          })
          .catch(error => {
            setMsg('Tên của cửa hàng đã tồn tại. Vui lòng nhập tên khác')
            $('.name_store').addClass('color_danger')
          }
        )
    }

    // show for edit
    useEffect(() => {
        if(id) {
            let unmounted = false;
            axios.get(`${ENDPOINT.STORE}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setNameStore(response.data.data.name)
                        setAdressStore(response.data.data.address)
                        setPhoneStore(response.data.data.phone)
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
            return () => { unmounted = true };
        }
    }, [id, dispatch])

    // update info
    const updateInforStore = () => {
        const isValidate = validateAll()
        if(!isValidate) return
    
        const data = {
            address : trim(adressStore),
            name : trim(nameStore),
            phone : phoneStore,
        }
    
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);
    
        return axios.put(`${ENDPOINT.STORE}/${id}`, data,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                toast.success('Chỉnh sửa địa chỉ cửa hàng thành công')
                setTimeout(() => {
                    history.push('/main/store')
                }, 1000);
            } 
          })
          .catch(error => {
            setMsg('Tên của cửa hàng đã tồn tại. Vui lòng nhập tên khác')
            $('.name_store').addClass('color_danger')
          }
        )
    }

    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className="operatelog partner">
            <div className="d-flex justify-content-between align-self-center">
                {id ? <h1 className="customer-title mb-3">Chỉnh sửa cửa hàng</h1> : <h1 className="customer-title mb-3">Thêm mới cửa hàng</h1>} 
            </div>
        </div>
       
        <Form.Label className="col-2 pl-0 pr-0 ">Tên cửa hàng <span style={{'color':'red'}}>*</span> </Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                className="form-control name_partner name_store" 
                value={nameStore} onChange={handleChangeName} 
                onKeyDown={handleRemoveMessName} 
                aria-label="Username" 
                aria-describedby="basic-addon1"
            />
        </div>
        <p style={{'fontSize': '12px', 'marginTop':'0px', 'marginBottom':'0px'}} className="text-danger msg_name">{msg}</p> 
        <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.nameStore}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Địa chỉ <span style={{'color':'red'}}>*</span> </Form.Label>
        <div className="input-group">
            <input type="text" className="form-control name_partner adress_store" value={adressStore} aria-label="Username" onChange={handleChangeAdress} onKeyDown={handleRemoveMessAdress} aria-describedby="basic-addon1"/>
        </div>
        <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.adressStore}</p> 
        {/* ====================================================================== */}
        <Form.Label className="col-2 pl-0 pr-0 ">Số điện thoại <span style={{'color':'red'}}>*</span></Form.Label>
        <div className="input-group">
            <input 
                type="text" 
                name="inputForNumber" 
                value={phoneStore} 
                className="form-control name_partner phone_website" 
                onKeyDown={handleRemoveMessPhone} 
                onChange={handleChangePhone} 
                aria-label="Username" 
                aria-describedby="basic-addon1"/>
        </div>
        <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.phoneStore}</p> 
        {/* ====================================================================== */}
        <div >
            <Link to="/main/store"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link> 
            {id ? <button type="button" onClick={updateInforStore} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" onClick={handleCreateAdressStore} disabled={disable} className="btn btn-success mt-5">Lưu</button>}
        </div>
    </div>
    )
}

export default StoreEditCreate
